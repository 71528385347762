<template>
  <Menu />

  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      Watch Detail List
    </h6>
    <hr />
    <div class="filter-container">
      <form @submit.prevent="showList">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-2">
                <label for="referenceFilter">Reference No</label>
                <input
                  type="text"
                  id="referenceFilter"
                  v-model="apiFliters.reference_no"
                  class="form-control"
                />
              </div>

              <div class="col-md-3">
                <label for="storeFilter">Store</label>
                <v-select
                  multiple
                  v-model="apiFliters.store"
                  :options="storeModel"
                  :reduce="(store) => store.id"
                  label="store_name"
                />
              </div>
              <div class="col-md-2">
                <label for="minPrice">Min Price</label>
                <input
                  type="number"
                  id="minPrice"
                  v-model="apiFliters.gte_first_price"
                  class="form-control"
                />
              </div>
              <div class="col-md-2">
                <label for="maxPrice">Max Price</label>
                <input
                  type="number"
                  id="maxPrice"
                  v-model="apiFliters.lte_first_price"
                  class="form-control"
                />
              </div>
              <!-- <div class="col-md-2 align-self-center" align="left">
                    <div>
                        <input class="form-check-input form-check-inline" type="checkbox" v-model="apiFliters.active" id="stock">
                        <label class="form-check-label" for="stock">Stock</label>
                    </div>
                </div> -->

              <div class="col-md-2">
                <label for="saleStatus">Sale Status</label>
                <select
                  @change="selectChange"
                  v-model="apiFliters.sale_status"
                  id="saleStatus"
                  class="form-select"
                >
                  <option values="">All</option>
                  <option value="active=true">Stock</option>
                  <!-- <option value="sale__sale_id__is_paid=true&sale__is_last=true">Completed</option>
                        <option value="sale__sale_id__sale_type_id__sale_type_id__in=2,6&sale__sale_id__is_paid=false&sale__is_last=true&sale__is_return=false">Awaiting</option>
                        <option value="sale__sale_id__sale_type_id__sale_type_id=9&sale__is_last=true">Return</option>
                        <option value="sale__sale_id__sale_type_id__sale_type_id=8&sale__is_last=true">Consignee</option> -->
                  <option value="sale_status=completed">Completed</option>
                  <option value="sale_status=awaiting">Awaiting</option>
                  <option value="sale_status=consignee">Consignee</option>
                  <!-- <option value="sale__sale_id__sale_status=canceled">
                                    Canceled
                                </option> -->
                  <option value="sale_status=return">Return</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- 
            <div class="row mt-2">
                    <div class="col-sm-6 top-left">
                        <h5 class="marginright">Jewel Filters</h5>
                    </div>
                    <hr>

            </div> -->
        <div class="row mt-1 justify-content-center">
          <div class="col-md-10">
            <div class="row">
              <!-- <div class="col-md-2">
                            <label for="modelNo">Jewel & Watch</label>
                            <select @change="selectChange" v-model="apiFliters.jewel_watch" id="property"
                                class="form-select">
                                <option></option>
                                <option v-bind:value="0">Jewel</option>
                                <option v-bind:value="1">Watch</option>
                            </select>
                        </div> -->
              <div class="col-md-2">
                <label for="modelFilter">Model No</label>
                <input
                  type="text"
                  id="modelFilter"
                  v-model="apiFliters.model_no"
                  class="form-control"
                />
              </div>

              <div class="col-md-3">
                <label for="subType">Tag</label>
                <v-select
                  multiple
                  v-model="apiFliters.tag"
                  :options="tagModel"
                  :reduce="(tag) => tag.id"
                  label="name"
                />
              </div>
              <!-- 
                        <div class="col-md-2">
                            <label for="subType">Sub Type (Jewel)</label>
                            <v-select multiple v-model="apiFliters.subtype" :options="subTypeModel"
                                :reduce="(subtype) => subtype.id" label="name" />
                        </div> -->
              <!-- 
                        <div class="col-md-2">
                            <label for="productType">Product Type (Jewel)</label>
                            <v-select multiple v-model="apiFliters.product_type" :options="producTypeModel"
                                :reduce="(product_type) => product_type.id" label="type_name" />
                        </div> -->

              <div class="col-md-2">
                <label for="productType">Condition</label>
                <v-select
                  multiple
                  v-model="apiFliters.condition"
                  :options="conditionModel"
                  :reduce="(condition) => condition.id"
                  label="name"
                />
              </div>

              <div class="col-md-4">
                <label for="productType">Brand (Watch)</label>
                <v-select
                  multiple
                  v-model="apiFliters.watch_brand"
                  :options="brandModel"
                  :reduce="(brand) => brand.id"
                  label="brand_name"
                />
              </div>
            </div>
            <div class="col-md-2">
              <label for="originalReferenceFilter"
                >Original Reference (Watch)</label
              >
              <input
                type="text"
                id="originalReferenceFilter"
                v-model="apiFliters.original_reference"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center mt-3">
          <div class="col-md-2" style="flex-basis: fit-content">
            <button type="button submit" class="btn btn-secondary">
              Filter
            </button>
          </div>

          <!-- <div class="col-md-2">
                    <button @click="showGallery" type="button" class="btn btn-outline-dark">Show Photo</button>
                </div> -->
        </div>
      </form>
      <div v-if="loading === true" class="row justify-content-center mt-5">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div class="row mt-4 justify-content-center" v-if="showMode === 'list'">
      <div
        v-for="cell in data"
        :key="cell.product_id"
        class="col-10 card mainCard mt-3"
      >
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-3">
            <div class="row justify-content-center">
              <div
                class="col-12 justify-content-center align-items-center d-flex"
              >
                <Image v-bind:src="cell['thumbnail']" height="170" preview />
              </div>
              <!-- <div class="row sellerName">{{cell["seller"]}}</div> -->
              <!-- <div class="row sellerName text-center justify-content-center  mt-1" style="font-size:16px">Emre Kurşun</div> -->
              <!-- <div class="row sellerName text-center justify-content-center fw-bold  text-muted" style="font-size:13px">Consignee</div> -->

              <div
                class="row sellerName text-center justify-content-center fw-bold mt-1"
                style="font-size: 16px"
              >
                {{ cell["seller"] }}
              </div>
              <div
                class="row sellerName text-center justify-content-center text-muted fw-bold"
                style="font-size: 14px"
              >
                {{ cell["sale_status"] }}
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-9 col-xl-9">
            <div class="table-responsive">
              <table class="table table-hover table-borderless infoTable mt-2">
                <tbody>
                  <tr class="">
                    <td class="fw-bold rowTitle">Condition</td>
                    <td>{{ cell["watch"][0]["condition_name"] }}</td>
                  </tr>

                  <tr class="">
                    <td class="fw-bold rowTitle">Original Reference</td>
                    <td>{{ cell["watch"][0]["original_reference"] }}</td>
                    <td class="fw-bold rowTitle">Store</td>
                    <td>{{ cell["store_name"] }}</td>
                  </tr>

                  <tr class="">
                    <td class="fw-bold rowTitle">Reference No</td>
                    <td>
                      <router-link
                        :to="{ path: '/products/' + cell['product_id'] }"
                        >{{ cell["reference_no"] }}</router-link
                      >
                    </td>
                    <!-- <td>{{ cell["reference_no"] }}</td> -->
                    <td class="fw-bold rowTitle">Purchase Store</td>
                    <td>{{ cell["watch"][0]["purchase_store_name"] }}</td>
                  </tr>
                  <tr class="">
                    <td class="fw-bold rowTitle">Brand</td>
                    <td>{{ cell["watch"][0]["brand_name"] }}</td>
                    <td class="fw-bold rowTitle">Purchase Date</td>
                    <td>{{ cell["watch"][0]["purchase_date"] }}</td>
                  </tr>
                  <tr class="">
                    <td class="fw-bold rowTitle">Model</td>
                    <td>{{ cell["watch"][0]["model"] }}</td>
                    <td class="fw-bold rowTitle">Ticket Price</td>
                    <td>{{ cell["first_price"] }}</td>
                  </tr>
                  <tr class="">
                    <td class="fw-bold rowTitle">Customer</td>
                    <td>{{ cell["customer"] }}</td>
                    <td class="fw-bold rowTitle">Currency</td>
                    <td>{{ cell["currency"] }}</td>
                  </tr>
                  <tr class="">
                    <td class="fw-bold rowTitle">Sale Date</td>
                    <td>{{ cell["sale_date"] }}</td>
                    <td class="fw-bold rowTitle">Sold Price</td>
                    <td>{{ cell["sold_price"] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center mt-3">
        <div class="col-md-4 align-self-center mx-auto">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li
                class="mr-2 list-inline-item"
                style="margin-right: 10px; margin-top: 10px"
              >
                <strong>{{ pageNumber }}/{{ maxPage }}</strong>
              </li>

              <li
                :class="[
                  'page-item',
                  Number(pageNumber) == 1 ? 'disabled' : '',
                ]"
              >
                <a
                  @click="clickPage(Number(pageNumber) - 1)"
                  class="page-link"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li
                :class="[
                  'page-item',
                  Number(pageNumber) == item ? 'active' : '',
                ]"
                v-for="(item, index) in pageNumberRange"
                :key="index"
              >
                <a @click="clickPage(item)" class="page-link" href="#">{{
                  item
                }}</a>
              </li>
              <li
                :class="[
                  'page-item',
                  Number(pageNumber) == maxPage ? 'disabled' : '',
                ]"
              >
                <a
                  @click="clickPage(Number(pageNumber) + 1)"
                  class="page-link"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { getAPI } from "@/global/callAPI";
import ListingGallery from "@/components/ListingGallery.vue";
import ProductList from "@/components/ProductList.vue";
import Table from "@/components/Table.vue";

export default {
  components: { Menu, ListingGallery, ProductList, Table },
  data() {
    return {
      endPoint: null,
      tableKey: 0,
      apiFliters: {
        reference_no: this.$route.query.reference_no || null,
        store: this.$route.query.store || null,
        lte_first_price: this.$route.query.lte_first_price || null,
        gte_first_price: this.$route.query.gte_first_price || null,
        active: this.$route.query.active || null,
        model_no: this.$route.query.model_no || null,
        sale_status: this.$route.query.sale_status || "active=true",
        watch_brand: this.$route.query.watch_brand || null,
        model_no: this.$route.query.model_no || null,
        tag: this.$route.query.tag || null,
        condition: this.$route.query.condition || [1],
        original_reference: null,
      },
      storeModel: [],
      subTypeModel: [],
      producTypeModel: [],
      tagModel: [],
      showMode: null,
      products: [],
      brandModel: [],
      conditionModel: [],
      loading: false,

      data: [],
      pageNumber: this.$route.query.pageNumber || 1,
      maxPage: 0,
      totalItem: 0,
      pageNumberRange: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      limitNumber: 10,
      // cell:{watch:[]}
    };
  },
  methods: {
    showList() {
      this.createEndPoint();
      this.showMode = "list";
      this.pageNumber = 1;
      // this.$router.push({ query: Object.assign({}, this.$route.query, {
      //     pageNumber:1
      // }) })
      this.getData();
    },
    showGallery() {
      this.createEndPoint();
      this.showMode = "gallery";
    },
    createEndPoint() {
      this.products = [];
      let filters = this.apiFliters;
      let queryList = [
        "search=" + filters.reference_no,

        // "reference_no__iexact="+filters.reference_no,
        "store__id__in=" + filters.store,
        "first_price__lte=" + filters.lte_first_price,
        "first_price__gte=" + filters.gte_first_price,
        // "active=" + filters.active,
        "tags__tag__id__in=" + filters.tag,

        // "jewel__subtypes__subtype__id__in=" + filters.subtype,
        // "jewel__product_type__product_type__id__in=" + filters.product_type,
        // "jewel__product__product_id__isnbull=" + filters.jewel_watch,
        filters.sale_status,
        "watch__brand__id__in=" + filters.watch_brand,
        "model_no__icontains=" + filters.model_no,
        "watch__condition__id__in=" + filters.condition,
        "watch__original_reference__icontains=" + filters.original_reference,
      ];
      // this.$router.push({ query: Object.assign({}, this.$route.query, {
      //     reference_no:filters.reference_no,
      //     store:filters.store,
      //     lte_first_price:filters.lte_first_price,
      //     gte_first_price:filters.gte_first_price,
      //     tag:filters.tag,
      //     watch_brand:filters.watch_brand,
      //     model_no:filters.model_no,

      // }) })

      let query = queryList
        .join("&")
        .replaceAll("null", "")
        .replace("nbull", "null");
      console.log(query);
      let endPoint = "watch-detail-list?&" + query;

      this.endPoint = endPoint;
      this.tableKey += 1;
      this.showMode = "list";
    },

    clickPage(newPageNumber) {
      this.pageNumber = newPageNumber;
      // this.$router.push({ query: Object.assign({}, this.$route.query, {
      //     pageNumber:newPageNumber
      // }) })
      // this.$router.push({path:this.$route.path,query:{
      //     pageNumber:newPageNumber,order:this.sortInput
      // }})
      this.getData();
    },
    range() {
      const step = 1;
      let start =
        this.maxPage > 10
          ? this.pageNumber < 3
            ? 1
            : Number(this.pageNumber) - 2
          : 1;
      let end =
        this.maxPage > 10
          ? this.pageNumber < 3
            ? 11
            : Number(this.pageNumber) + 7
          : this.maxPage + 1;
      if (end > this.maxPage + 1) {
        end = this.maxPage + 1;
      }

      let output = [];
      if (typeof end === "undefined") {
        end = start;
        start = 0;
      }
      for (let i = start; i < end; i += step) {
        output.push(i);
      }
      this.pageNumberRange = output;
    },

    getData() {
      this.loading = true;
      const limit = "&limit=" + this.limitNumber;
      getAPI(
        this.endPoint +
          `&offset=${(Number(this.pageNumber) - 1) * this.limitNumber}` +
          limit
      ).then((res) => {
        this.data = res.data.results;
        this.totalItem = res.data.count;
        this.maxPage = Math.ceil(res.data.count / this.limitNumber);
        this.range();
        this.loading = false;
      });
    },
  },
  created() {
    getAPI("stores?limit=10000").then((res) => {
      this.storeModel = res.data.results;
    });

    getAPI("watch-brands?limit=10000&order=brand_name").then((res) => {
      this.brandModel = res.data.results;
    });
    getAPI("product-tags?limit=10000&ordering=name&").then((res) => {
      this.tagModel = res.data.results;
    });
    getAPI("watch-conditions?limit=1000&ordering=name&").then((res) => {
      this.conditionModel = res.data.results;
    });
  },
};
</script>

<style scoped>
input {
  border-radius: 0px;
}

select {
  border-radius: 0px;
}

td {
  text-align: center;
  vertical-align: middle;
}

th {
  text-align: center;
  vertical-align: middle;
}
.infoTable {
  border: 2px;
  background: transparent;
}

.mainCard {
  background: transparent;
  padding: 10px;
}
</style>
