<template>
    <Toast />
    <Menu />

    <div class="container-fluid">
        <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px;">Destroy Product</h6>
        <hr>

        <div class="row justify-content-center">

            <div class="col-md-3">
                <div class="mb-3">
                  <label for="" class="form-label">Reference List</label>
                  <textarea v-model="reference" class="form-control" name="" id="" rows="5"></textarea>
                  <small id="helpId" class="form-text text-muted">Enter one reference per line. You can not destroy a sold product, you must first create a return sale.</small>

                </div>
            </div>

            <div class="col-md 2">
                <div class="row mb-2 mt-4">
                    <div class="col-md-2 d-grid gap-2">
                        <button type="button" id="editStoneTypeButton" @click="destroyProduct()" class="btn btn-danger">Destroy Products</button>

                    </div>
                </div>

            </div>
        </div>
        <div v-if="loading" class="row">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

        <div class="row">
            <label for="success">Successful Products:</label>
            <p>{{ successList }}</p>
        </div>

        <div class="row mt-2">
            <label for="error" class="mb-2">Errors:</label>
            <div class="error" v-for="error in errorList" :key="error.product">
            <p><strong>{{ error.product }}</strong>: {{ error.detail }}</p>
            </div>
        </div>

    </div>



</template>

<script>
import Menu from '@/components/Menu';
import {getAPI, postAPI, patchAPI,exportProducts} from '@/global/callAPI';

export default {
    components: {Menu},
    data () {
        return {
            reference:null,
            fileName:"Product Export",
            products:[],
            errorList:[],
            successList:[],
            loading:false,
            totalEnteredProduct:null,
            totalUniqueProduct:null,
            totalPrintedProduct:null,
            totalDuplicate:null,
        }
    },
    methods: {
        destroyProduct() {
        this.loading = true
        var lines = this.reference.split('\n');
        let unique = [...new Set(lines)];
        let payload = {reference_no_list:unique}
        this.totalDuplicate = lines.length - unique.length
        const end = "destroy-products"
        postAPI(end,payload).then(res => {
                const data = res.data
                this.successList = data["successful_products"]
                this.errorList = data["errors"]
                this.loading = false
                })

        }
    }

}
</script>

<style scoped>

input {
	border-radius: 0px;
}

select {
	border-radius: 0px;
}
td {
    text-align: center; 
    vertical-align: middle;
}
th {
    text-align: center; 
    vertical-align: middle;
}
.form-control {
    border-radius: 0px;
}
</style>