<template>
  <div class="row mb-2">
    <div class="col-md-2 col-6">
      <label for="referenceFilter">Search</label>
      <input
        type="text"
        id="referenceFilter"
        v-model="apiFilters.search"
        class="form-control"
      />
    </div>
    <div class="col-md-2 col-6">
      <label for="store">Store</label>
      <v-select
        multiple
        v-model="apiFilters.store"
        :options="storeModel"
        :reduce="(store) => store.name"
        label="name"
      />
    </div>
    <div class="col-md-2 col-6">
      <label for="minPrice">Min Price</label>
      <input
        type="number"
        id="minPrice"
        v-model="apiFilters.min_price"
        class="form-control"
      />
    </div>
    <div class="col-md-2 col-6">
      <label for="maxPrice">Max Price</label>
      <input
        type="number"
        id="maxPrice"
        v-model="apiFilters.max_price"
        class="form-control"
      />
    </div>

    <div class="col-md-2 col-6">
      <label for="productType">Product Type</label>
      <v-select
        multiple
        v-model="apiFilters.product_type"
        :options="productTypeModel"
        :reduce="(product_type) => product_type.type_name"
        label="type_name"
      />
    </div>
    <div class="col-md-2 col-6 form-check d-flex align-items-center">
      <input
        class="form-check-input me-1"
        type="checkbox"
        v-model="apiFilters.stock"
        id="flexCheckChecked"
        checked
      />
      <label class="form-check-label" for="flexCheckChecked">
        Show only products in stock
      </label>
    </div>
  </div>
  <div class="align-items-center justify-content-center d-flex">
    <button
      type="button"
      class="btn btn-outline-dark mt-2"
      @click="
        $emit('filter', {
          offset: 0,
          filters: apiFilters,
        })
      "
    >
      Filter
    </button>
  </div>
</template>
<script>
import { getACTIVE_IP } from "@/global/callAPI";
import axios from "axios";
export default {
  name: "ProductFilters",
  props: {
    pageSize: {
      type: Number,
      default: 30,
    },
  },
  data: () => {
    return {
      apiFilters: {},
      productTypeModel: [],
      storeModel: [],
    };
  },
  emits: ["filter"],
  methods: {
    getProducTypes() {
      const basePoint = getACTIVE_IP() + "merchant-product-types?limit=10000";
      axios.get(basePoint).then((res) => {
        this.productTypeModel = res.data.results;
      });
    },
    getQueryParam({ name, type }) {
      if (type === "list") {
        // first decode the query param

        const queryParam = this.$route.query[name];
        if (queryParam) {
          const decodedQueryParam = decodeURIComponent(queryParam);
          return decodedQueryParam.split(",");
        } else {
          return [];
        }
      } else if (type === "boolean") {
        return this.$route.query[name]
          ? this.$route.query[name] === "true"
          : false;
      } else if (type === "number") {
        return this.$route.query[name]
          ? parseInt(this.$route.query[name])
          : null;
      } else {
        return this.$route.query[name] || "";
      }
    },
    getStores() {
      const basePoint = getACTIVE_IP() + "merchant-stores?limit=10000";
      axios.get(basePoint).then((res) => {
        this.storeModel = res.data.results;
      });
    },
  },
  created() {
    this.apiFilters = {
      search: this.getQueryParam({ name: "search", type: "string" }),
      store: this.getQueryParam({ name: "store", type: "list" }),
      min_price: this.getQueryParam({ name: "min_price", type: "number" }),
      max_price: this.getQueryParam({ name: "max_price", type: "number" }),
      model_no: this.getQueryParam({ name: "model_no", type: "string" }),
      product_type: this.getQueryParam({ name: "product_type", type: "list" }),
      stock: this.getQueryParam({ name: "stock", type: "boolean" }),
    };
    this.getProducTypes();
    this.getStores();
    // emit filter event
    this.$emit("filter", {
      offset: 0,
      filters: this.apiFilters,
    });
  },
};
</script>
