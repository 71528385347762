<template>
  <div class="card-container container p-2 col-6 col-md-3 col-lg-2">
    <div class="card p-2">
      <Image
        class="card-img-top d-flex align-items-center justify-content-center"
        v-bind:src="product['thumbnail']"
        height="120"
        preview
      />
      <div class="card-body p-2">
        <h6 class="card-title text-center fs-6">{{ product.reference_no }}</h6>
        <ul class="list-group list-group-flush">
          <li class="list-group-item py-1">
            <span v-if="product.active" class="badge bg-success fw-bold"
              >Stock</span
            >
            <span v-else class="badge bg-secondary fw-bold"
              >Contact Supplier</span
            >
          </li>
          <li class="list-group-item py-1">
            Price: <b>{{ formatPrice(product.first_price) }}</b>
          </li>
          <li class="list-group-item py-1">Store: {{ product.store }}</li>
          <li
            v-if="product['jewel_watch'] == 'jewel'"
            class="list-group-item py-1"
          >
            Gold Karat: {{ product.gold_karat }}
          </li>
          <li
            v-if="product['jewel_watch'] == 'jewel'"
            class="list-group-item py-1"
          >
            Gross Weight: {{ product.gross_weight }}
          </li>
          <li
            v-if="product['jewel_watch'] == 'jewel'"
            class="list-group-item py-1"
          >
            Net Weight: {{ product.net_weight }}
          </li>
          <li
            v-if="product['jewel_watch'] == 'jewel'"
            class="list-group-item py-1"
          >
            Stone Info: {{ product.stone_info }}
          </li>
          <li
            v-if="product['jewel_watch'] == 'watch'"
            class="list-group-item py-1"
          >
            Brand: {{ product.brand }}
          </li>
          <li
            v-if="product['jewel_watch'] == 'watch'"
            class="list-group-item py-1"
          >
            Model: {{ product.model }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductItem",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatStock(status) {
      return status ? "Stock" : "Contact Supplier";
    },
    formatPrice(value) {
      if (value === null || typeof value !== "number" || isNaN(value)) {
        return "";
      } else {
        let formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });

        return formatter.format(value);
      }
    },
  },
};
</script>

<style scoped>
table {
  border: none; /* Hücre kenarlıklarını birleştirerek çerçeve görünümünü kaldırır */
}

td,
li {
  border: none; /* Hücrelerin kenarlıklarını kaldırır */
}
.card {
  height: 27rem;
  margin-bottom: 20px;
  min-width: 15rem;
}

.card-container {
  height: 27rem;
  margin-bottom: 20px;
  min-width: 15rem;
}
.card-img-top {
  object-fit: cover; /* Resmin kartın boyutlarına sığacak şekilde oranını korumasını sağlar */
  height: 120px; /* Kart resim yüksekliği */
}

.card-body {
  height: calc(100% - 120px); /* Kart yüksekliği - resim yüksekliği */
  overflow: scroll;
}
.card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  overflow: hidden;
  margin-bottom: 20px;
}

.badge {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: white;
}
.bg-success {
  background-color: #28a745;
}
.bg-secondary {
  background-color: #6c757d;
}
</style>
