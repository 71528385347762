<template>
        <Menu/>

    <div id="productTransfer" class="container-fluid">
        <TabMenu :model="items" />

        <div class="row justify-content-center">
            <div class="col-md-11">
                <Table
                        excelName = "product-transfer"
                        excelTabName = "Transfers"
                        :tableInfo = "columns"
                        :useCreate = true
                        :showRoute = true
                        detailPath = "/transfers/"
                        idName = "transfer"
                        :endPoint = "endPoint"

                        />
            </div>
        </div>
        <!-- <DataTable  

        selectionMode="single"                   
        :value="transfer" 
        responsiveLayout="scroll">

            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"></Column>
        </DataTable> -->

    </div>

</template>

<script>
import Table from '@/components/Table';

import Menu from '@/components/Menu';
import {getAPI, postAPI, patchAPI, deleteAPI} from '@/global/callAPI';
export default {
    name:"ProductCatalog",
    components: {Menu,Table},
    data: function () {
        return {
            endPoint:"transfer-products?product="+this.$route.params.id,

    items: [
		{label: 'Edit', icon: 'pi pi-fw pi-pencil', to: "/products/" + this.$route.params.id},
		{label: 'Photo', icon: 'pi pi-fw pi-image', to: '/products/photo/' + this.$route.params.id },
		{label: 'Sale', icon: 'pi pi-fw pi-shopping-cart', to: '/products/sales/' + this.$route.params.id },
		{label: 'Catalog', icon: 'pi pi-fw pi-tags', to: '/products/catalog/' + this.$route.params.id },
        {label: 'Transfer', icon: 'pi pi-fw pi-sync', to: '/products/transfer/' + this.$route.params.id }

        ],
    columns: [
            {field: 'created_date', header: 'Date',isDateTime:true,order:true},
            {field: 'sender_store', header: 'Sender Store',order:true},
            {field: 'target_store', header: 'Target Store'},
            {field: 'sender', header: 'Sender',order:true},
            {field: 'receiver', header: 'Receiver',order:true},
            {field: 'is_completed', header: 'Is Completed',order:true},
            {field: 'is_canceled', header: 'Is Canceled',order:true},
            {field: 'description', header: 'Description'},
        ],
    transfer: [],
    products: null}
    },
    created() {

    // getAPI('products/' + this.$route.params.id).then((res) => {
    //     this.transfer = res.data.product_transfers;
    //     this.transfer.forEach((element,index) => 
    //     this.transfer[index]["created_date"] = new Date(element["created_date"]).toLocaleDateString()
    //     )
    //     }); 
        // this.columns = [
        //     {field: 'created_date', header: 'Date'},
        //     {field: 'sender_store', header: 'Sender Store'},
        //     {field: 'target_store', header: 'Target Store'},
        //     {field: 'sender', header: 'Sender'},
        //     {field: 'receiver', header: 'Receiver'},
        //     {field: 'is_completed', header: 'Is Completed'},
        //     {field: 'is_canceled', header: 'Is Canceled'},
        //     {field: 'description', header: 'Description'},
        // ];


    },

    methods: {

    rowClick(e) {this.$router.push({ path: '/sales/' +e.data.sale_id}) },

    
    }
}
</script>

<style scoped>


</style>