import { postAPI } from "@/global/callAPI";

const initialTransactionModel = {
  date: new Date().toLocaleDateString("fr-CA"),
  description: null,
  invoice_no: null,
  amount: null,
  transaction_type: null,
  transaction_method_new: null,
  payment_method: null,
  payment_type: null,
  firm: null,
};

const initialStorageTransactionModel = {
  storage: null,
  amount: null,
  description: null,
  transaction: null,
};

function resetModels(transactionModel, storageTransactionModel) {
  Object.assign(transactionModel, initialTransactionModel);
  Object.assign(storageTransactionModel, initialStorageTransactionModel);
}

function saveStorageTransaction(storageTransactionModel, id, Toast2) {
  if (storageTransactionModel.storage === null) {
    return;
  }
  storageTransactionModel.transaction = id;
  postAPI("storage-transactions", storageTransactionModel).then((res) => {
    resetModels({}, storageTransactionModel);
    Toast2.fire({
      icon: "success",
      title: "Storage updated!",
      text: "Please refresh the filter.",
    });
  });
}

export function submitForm(transactionModel, storageTransactionModel, Toast2) {
  let payload = transactionModel;
  payload["amount"] = payload["amount"] || null;
  if (payload.amount === null || payload.amount === "") {
    window.alert("Please enter an amount!");
    return true;
  }
  postAPI("accounting-transactions", payload).then((res) => {
    if (res) {
      saveStorageTransaction(storageTransactionModel, res.data.id, Toast2);
      Toast2.fire({
        icon: "success",
        title: "Transaction added!",
        text: "Please refresh the filter.",
      });
      resetModels(transactionModel, {});
    }
  });
}
