<template>
    <Menu/>
    <!-- DELETE -->
    <div class="container-fluid">
        <h3 class="text-left font-weight-bold text-info text-dark">New Gam</h3>
		<hr style="height:0.5px;margin-bottom:30px">
        <div class="form-group">

            <div class="row">

                <div class="col-md-4">
                    <!-- Type Input -->
                    <div class="row align-items-start">
                        <div class="col-md-9">
                            <label for="typeInput">Type</label>
                            <!-- <TreeSelect
                            class="form-control"  
                            :filter="true"
                            :options="stoneTypeModel"
                            selectionMode="single" 
                            v-model="selectedStoneType"
                            ></TreeSelect >                         -->
                            <Tree :value="stoneTypeModel"
                            :filter="true"
                            filterMode="strict"
                            selectionMode="single" 
                            v-model:selectionKeys="selectedStoneType"
                            scrollHeight="200px"
                            @node-select="editShow"
                            ></Tree>

                        </div>
                        <div class="col">
                            
                                <div class="row">
                                    <button type="button" id="newStoneButton" @click="addNew" class="btn btn-outline-dark">Add</button>
                                </div>
                                <div class="row">
                                    <button type="button" id="editStoneTypeButton" v-if="typeEdit" @click="editStoneType" class="btn btn-outline-dark">Edit</button>
                                </div>
                            
                        </div>
                    </div>
                    <!-- Date Input -->
                    <div class="row">
                        <div class="col-md-9">
                            <label for="dateInput">Date</label>
                            <input id="dateInput" type="date" v-model="submitModel.date" class="form-control">
                        </div>
                    </div>
                    <!-- Number Input -->
                    <div class="row">
                        <div class="col-md-6">
                            <label for="numberInput">Number</label>
                            <input v-model="submitModel.number" id="numberInput" type="number" class="form-control">
                        </div>

                        <!-- Weight Input -->
                        <div class="col-md-6">
                            <label for="weightInput">Total Weight</label>
                            <input v-model="submitModel.first_weight" id="weightInput" step=any type="number" class="form-control">
                        </div>
                    </div>
                    
                    <!-- Manufacturer Input -->
                    <div class="row">
                        <div class="col-md-8">
                            <label for="manufactureInput">Manufacturer</label>
							<select v-model="submitModel.manufacturer" id="manufactureInput" class="form-control">
							    <option v-for="p in manufacturerModel" :key="p.manufacturer_id" v-bind:value="p.manufacturer_id" >{{p.name_surname}}</option>
						    </select>                        
                        </div>
                    <!-- Price Input -->
                        <div class="col-md-4">
                            <label for="priceInput">Price ($)</label>
                            <input v-model="submitModel.price" id="priceInput" step=0.02 type="number" class="form-control">
                        </div>
                    </div>

                    <!-- Certificate Input -->
                    <div class="row">
                        <div class="col-md-9">
                            <label for="certificateInput">Certificate</label>
                            <input v-model="submitModel.certificate" id="certificateInput" type="text" class="form-control">
                        </div>
                    </div>
                    <!-- Description -->
                    <div class="row">
                        <div class="col-md-12">
                            <label for="descriptionInput">Description</label>
                            <textarea v-model="submitModel.detail" id="descriptionInput" class="form-control" rows="3"></textarea>
                        </div>
                    </div>
                </div>

                <!-- Other Grid -->
                <div class="col-md-6 offset-md-1">
                    <div class="row">
                        <div class="col-md-12">
                            <label for="stones">Stones </label>
                            <select id="stones" v-model="selectedStone" class="form-select" @change="chooseStone" size="6">
                                <option v-bind:value="s.id" v-for="s in stoneModel" :key="s.id">{{s.label}}</option>
                            </select>
                        </div>
                        <div class="row">
                            <div v-if="mod === 'edit'" class="col-md-5">
                                <label for="costShow">Cost</label>
                                <input class="form-control" id="costShow" v-model="stoneCost" type="text" disabled>
                            </div>

                            <div v-if="mod === 'edit'" class="col-md-5">
                                <label for="balanceShow">Balance</label>
                                <input class="form-control" id="balanceShow" v-model="submitModel.weight" type="number" step="any" disabled>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <button id="form-submit" type="submit" @click="submit" >Submit</button>
            

        <!--Stone Type Modal -->
            <div class="modal fade" id="stoneType" tabindex="-1" role="dialog" aria-labelledby="editLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editLabel" v-if="modelMode == 'new'">New Stone Type</h5>
                            <h5 class="modal-title" id="editLabel" v-if="modelMode == 'edit'">Edit Stone Type / {{this.typeSubmitModel.id}}</h5>

                            <button type="button" class="close" data-bs-dismiss="modal" @click="closeModel"  aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>                            
                        </div>

                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-9">
                                    <label for="typeInput">Top Type</label>
                                    <Tree :value="stoneTypeModel"
                                    selectionMode="single" 
                                    v-model:selectionKeys="selectedStoneType2"
                                    scrollHeight="200px"
                                    ></Tree>
                                    
                                    </div>  
                            </div>
                            <div class="row ">
                                <div class="col-md-9">
                                    <label for="newTypeNameInput" >Name</label>
                                    <input v-model="typeSubmitModel.name" id="newTypeNameInput" type="text" class="form-control" style="margin-bottom: 20px;" required>
                                </div>
                            </div>
                            <div class="modal-footer ">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button @click="deleteStoneType()" type="button" class="btn btn-danger" v-if="modelMode == 'edit'">Delete</button>
                                <button @click="submitStoneType()" type="button" class="btn btn-primary" >Save changes</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

    


        </div>
    </div>
</template>

<script>
import {getAPI, postAPI, patchAPI, deleteAPI} from '../global/callAPI';
import Menu from '../components/Menu';

export default {
    name:"StonePage",
    components: {Menu},
    data: function() {

     return {
        stoneTypeModel:[],
        stoneModel:[],
        manufacturerModel: [],
        isShow: false,
        selectedStone:[],
        selectedStoneType: null,
        selectedStoneType2: null,
        typeEdit: false,
        modelMode: "new",
        stoneCost: 0,
        mod:"new",
        stoneCost:0,

        submitModel: {
            date: null,
            number: null,
            first_weight: null,
            price: null,
            certificate: null,
            detail:null,
            stone_type: null,
            manufacturer: null,
        },

        typeSubmitModel: {
            name: null,
            top_type: null,
        },

     }
    },
    methods: {
        submit: function() {
            let mod = this.mod

            if (mod === "new") {
                this.submitModel.stone_type = parseInt(Object.keys(this.selectedStoneType)[0]);
                postAPI('stones', this.submitModel).then(
                    getAPI('stones').then((res) => {this.stoneModel = res.data.results})
                );
                Toast2.fire({icon: 'success', title: 'Gam added!', text: 'Please refresh the page.'});
            } else if (mod === "edit") {
                this.submitModel.stone_type = parseInt(Object.keys(this.selectedStoneType)[0]);
                let id = this.submitModel.id
                patchAPI('stones/' + id, this.submitModel)
                Toast2.fire({icon: 'success', title: 'Gam updated!', text: 'Please refresh the page.'});

            }
        },
        submitStoneType () {
            let modelMode = this.modelMode;
            if (modelMode === "new") {

            this.typeSubmitModel.top_type = parseInt(Object.keys(this.selectedStoneType2)[0]);
            postAPI('stone-types', this.typeSubmitModel)
            Toast2.fire({icon: 'success', title: 'Stone Type added!',text:'Please refresh the page.'});
            // jQuery.noConflict();
            // $('#stoneType').modal('hide');
            jQuery.noConflict();
            jQuery('#stoneType').modal('hide');

            } else if (modelMode === "edit") {
                let stoneType = Object.keys(this.selectedStoneType)[0];
                this.typeSubmitModel.top_type = parseInt(Object.keys(this.selectedStoneType2)[0]);
                patchAPI('stone-types/' + stoneType, this.typeSubmitModel)
                Toast2.fire({icon: 'success', title: 'Stone Type updated!',text:'Please refresh the page.'});
                // jQuery.noConflict();
                // $('#stoneType').modal('hide');
                jQuery('#stoneType').modal('hide');
            }
            this.reset()
        },

        deleteStoneType () {
            let stoneType = Object.keys(this.selectedStoneType)[0];
            deleteAPI('stone-types/' + stoneType, this.typeSubmitModel);
            Toast2.fire({icon: 'success', title: 'Stone Type deleted!',text:'Please refresh the page.'});
            // jQuery.noConflict();
            // $('#stoneType').modal('hide');
            jQuery('#stoneType').modal('hide');
            this.reset()
        },

        editShow () {
            this.typeEdit = true
        },
        selectStoneType () {

            this.submitModel.stone_type = parseInt(Object.keys(this.selectedStoneType)[0]);
        },
        reset () {
            this.typeSubmitModel = {
            name: null,
            top_type: null,
        }
        },
        editStoneType() {
            this.reset();
            let stoneType = Object.keys(this.selectedStoneType)[0];
            getAPI('stone-types/' + stoneType).then((res) => {
                this.typeSubmitModel = res.data;
                let obj = {};
                obj[res.data.top_type] = true;
                this.selectedStoneType2 = obj;
                });
            this.modelMode = "edit";
            // jQuery.noConflict();
            // $('#stoneType').modal('show'); 
            jQuery('#stoneType').modal('show'); 

        },

        addNew(){
            this.reset();
            this.modelMode = "new";
            // jQuery.noConflict();
            // $('#stoneType').modal('show');
            jQuery('#stoneType').modal('show');
        },

        showStone: function() {
            this.isShow = true
        },

        // Stone Methods
       chooseStone () {
           this.mod = "edit"
           let selectedStone = this.selectedStone;
           let deneme = this.stoneModel.filter(
               function (stone) {
                   return stone.id === selectedStone
               }
           );
           deneme = deneme[0]
           this.submitModel = deneme

           let id = deneme.stone_type;
           let obj = {};
           obj[id] = true
           this.selectedStoneType = obj
           this.stoneCostCalculator()

       },
       stoneCostCalculator () {
           let price = this.submitModel.price
           let weight = this.submitModel.first_weight
           this.stoneCost = this.formatCurrency( price * weight )
       },
        formatCurrency(value) {
            return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        },
        expandAll() {
            for (let node of this.nodes) {
                this.expandNode(node);
            }
            this.expandedKeys = {...this.expandedKeys};
        },
        collapseAll() {
            this.expandedKeys = {};
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        }

    },

    created: function() {
		getAPI('tree-stone-types').then((res) => {this.stoneTypeModel = res.data.results});
		getAPI('stones').then((res) => {this.stoneModel = res.data.results});
        getAPI('manufacturers?type_id=5&limit=1000').then((res) => {this.manufacturerModel = res.data.results});
    }

}
</script>

<style scoped lang="scss">



button {
    margin-right: .5rem;
}

::v-deep(.p-tree) {
    a {
        color: #2196f3;
        text-decoration: none;
    }
}

#form-submit {
  background-color: black;
  width: 120px;
  margin-left:240 px;
  margin-right:auto;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  position: absolute;
  margin-top: 20px;
  left: 50%;

}
</style>