<template>
  <Toast />
  <div class="container-fluid">
    <Menu />
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>Storage</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />
    <form class="row" @submit.prevent="createStorageGroup">
      <div class="col-md-2">
        <label for="name" class="text-bold">Name</label>
        <input
          type="text"
          id="name"
          v-model="storagePayload.name"
          class="form-control"
        />
      </div>
      <div class="col-md-3 fluid align-self-end">
        <button
          type="submit"
          id="newStorageButton"
          class="btn btn-outline-secondary"
          :disabled="loading"
        >
          Create New Storage Group
        </button>
      </div>
    </form>
    <hr class="mt-3" />
    <form @submit.prevent="createStorageTransaction" class="row mt-5">
      <div class="col-md-2">
        <label for="storage" class="text-bold">Add to Storage</label>
        <v-select
          label="name"
          :options="storageModel"
          v-model="storageTransactionModel.storage"
          :reduce="(storage) => storage.id"
        />
      </div>
      <div class="col-md-2">
        <label for="storageAmount" class="text-bold">Storage Amount</label>
        <input
          type="number"
          step="0.01"
          placeholder="0.00 gr"
          id="storageAmount"
          v-model="storageTransactionModel.amount"
          class="form-control"
          :disabled="storageTransactionModel.storage === null"
        />
      </div>
      <div class="col-md-4">
        <label for="storageDescription" class="text-bold"
          >Storage Description</label
        >
        <input
          type="text"
          id="storageDescription"
          v-model="storageTransactionModel.description"
          class="form-control"
          :disabled="storageTransactionModel.storage === null"
        />
      </div>
      <div class="col-md-2 align-self-center">
        <button
          type="submit"
          id="newStorageTransactionButton"
          class="btn btn-secondary"
          :disabled="loading"
        >
          Create Storage Transaction
        </button>
      </div>
    </form>
    <hr class="mt-3" />
    <div class="row">
      <div class="col-md-2">
        <label for="storage" class="text-bold">Filter by Storage</label>
        <v-select
          label="name"
          :options="storageModel"
          v-model="apiFilters.storage"
          :reduce="(storage) => storage.id"
          multiple
        />
      </div>
      <div class="col-md-2">
        <label for="firm" class="text-bold">Filter by Firm</label>
        <v-select
          label="firm_name"
          :options="firmModel"
          v-model="apiFilters.firm"
          :reduce="(firm) => firm.manufacturer_id"
          multiple
        />
      </div>
      <div class="col-md-2 align-self-center">
        <button
          type="button"
          id="filterButton"
          class="btn btn-secondary"
          @click="saveFilters"
        >
          Filter
        </button>
      </div>
    </div>
    <div class="row justify-content-center transactionTable mt-5">
      <div class="col-md-11">
        <Table
          excelName="storage-transactions-export"
          excelTabName="Storage Transactions"
          :tableInfo="tableData"
          :endPoint="endPoint"
          :showRoute="false"
          :useCreate="true"
          :key="tableKey"
          :customColumn="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import Menu from "@/components/Menu";
import Table from "@/components/Table.vue";

export default {
  name: "Transactions",
  components: { Menu, Table },

  data: function () {
    return {
      tableKey: 0,
      endPoint: null,
      showTable: false,
      loading: false,
      storageTransactionModel: {
        storage: null,
        amount: null,
        description: null,
        transaction: null,
      },
      store_name: localStorage.getItem("store"),
      user: localStorage.getItem("username"),
      dateFilter: null,
      tableData: [
        // { header: "Id", field: "id", order: true },
        { header: "Amount", field: "amount" },
        { header: "Storage", field: "storage_name" },
        { header: "Firm", field: "firm_name" },
        { header: "Date", field: "date" },
        { header: "Rest", field: "rest" },
        { header: "Description", field: "description" },
      ],
      transactionMethodModel: [],
      typeModel: [],
      storageModel: [],
      storagePayload: {
        name: null,
      },
      apiFilters: {
        storage: [],
        firm: [],
      },
      firmModel: [],
    };
  },

  methods: {
    getStorage() {
      getAPI("storages?limit=10000").then(
        (res) => (this.storageModel = res.data.results)
      );
    },
    createStorageGroup() {
      this.loading = true;
      postAPI("storages", this.storagePayload)
        .then((res) => {
          this.getStorage();
          this.storagePayload = { name: null };
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveFilters() {
      const filters = this.apiFilters;
      let queryList = [
        "storage__id__in=" + filters.storage,
        "transaction__firm__manufacturer_id__in=" + filters.firm,
      ];
      let query = queryList.join("&").replaceAll("null", "");
      this.endPoint = "storage-transactions?" + query;
      console.log(this.endPoint);
      this.tableKey += 1;
    },
    createStorageTransaction() {
      this.loading = true;
      postAPI("storage-transactions", this.storageTransactionModel)
        .then((res) => {
          this.tableKey += 1;
          this.storageTransactionModel = {
            storage: null,
            amount: null,
            description: null,
            transaction: null,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.endPoint = "storage-transactions?";
    getAPI("storages?limit=10000").then(
      (res) => (this.storageModel = res.data.results)
    );
    getAPI("manufacturers?limit=10000").then((res) => {
      this.firmModel = res.data.results;
    });
  },
};
</script>

<style scoped></style>
