<template>
  
<Toast />
<div class="container-fluid">
    <Menu/>
    <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px"><strong>New User</strong> </h6>
	<hr style="height:0.5px;margin-bottom:30px">

    <div class="row justify-content-center ">
            <div class="col-md-2">
                <label for="username" class="text-bold">Username</label>
                <input type="text" id="store" v-model="userModel.username" class="form-control" required>
            </div>

            <div class="col-md-2">
                <label for="password" class="text-bold">Password</label>
                <input type="text" id="password" v-model="userModel.password" class="form-control" required>
            </div>

            <div class="col-md-2">
                <label for="store" class="text-bold">Store</label>
                <v-select  v-model="userModel.store"  :options="storeModel"         
                                    :reduce = "store => store.id" label="store_name"  />
            </div>  

    </div>

    <div class="row justify-content-center ">
            <div class="col-md-2">
                <label for="name" class="text-bold">Name</label>
                <input type="text" id="name" v-model="userModel.name" class="form-control">
            </div>

            <div class="col-md-2">
                <label for="group" class="text-bold">Group</label>
                <v-select multiple id="group"  v-model="userModel.groups"  :options="groupModel"         
                                    :reduce = "group => group.id" label="name"  />

            </div>  
            <div class="col-md-2"></div>

    </div>

    <div class="row justify-content-center mt-3 mb-3">
        <div class="col-md-1">
            <button @click="submit" id="" class="btn btn-secondary" type="submit" >Submit</button>
        </div>
    </div>

<hr>
    <div class="row justify-content-center">
        <div class="col-md-10">
            
            <Table
                :key="componentKey"
                excelName = "user-export"
                excelTabName = "users"
                :tableInfo = "tableInfo"
                :showRoute = false
                :useCreate = true
                endPoint = "user-list?ordering=-id"
                limit=20
            />
        
        </div>
    </div>

   

</div>
</template>

<script>

import {getAPI, postAPI, patchAPI, deleteAPI} from '@/global/callAPI';
import Menu from '@/components/Menu';
import Table from '@/components/Table';
export default {
    name:"NewUser",
    components: {Menu, Table},
    data: function () {
        return {
            componentKey:0,
            tableInfo: [
                {field: 'id', header: 'Id',order:true},
                {field: 'username', header: 'Username',order:true},
                {field: 'is_active', header: 'Active',order:true},
                {field: 'name', header: 'Name',order:true},
                {field: 'is_superuser', header: 'Super User'},
                {field: 'store_name', header: 'Store'},

            ],
            userModel:{
                username:null,
                password:null,
                name:null,
                groups:[],
                store:null,


            },
            storeModel:[],
            groupModel:[],
            userListModel:[],
        }
    },

    created: function() {

        getAPI('stores?limit=10000').then((res) => {this.storeModel = res.data.results});
        getAPI('groups').then((res) => {this.groupModel = res.data.results});
        // getAPI('user-list').then((res) => {this.userListModel = res.data.results});

    },  
    
    methods: {
        submit () {
            let payload = this.userModel
            postAPI('register', payload).then((res) => {
                if (res) {
                    this.userModel ={
                                username:null,
                                password:null,
                                name:null,
                                groups:[],
                                store:null}
                    this.componentKey += 1
                    Toast2.fire({icon: 'success', title: 'User added!'});

                }
                // getAPI('user-list').then((res) => {
                //     this.userListModel = res.data.results;
                    

                // });

            })
        },

    },

}
</script>

<style>

</style>