<template>
    <Toast />
    <Menu />

    <div class="container-fluid">
        <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px;">Product Export</h6>
        <hr>

        <div class="row justify-content-center">
            <div class="col-md-2 ">
                  <label for="refId" class="form-label">File Name</label>
                  <input type="text" v-model="fileName"
                    class="form-control" name="" id="" aria-describedby="refId">
                  <!-- <small id="helpId" class="form-text text-muted">Enter the reference of products.</small> -->
            </div>
            <div class="col-md-3">
                <div class="mb-3">
                  <label for="" class="form-label">Reference List</label>
                  <textarea v-model="reference" class="form-control" name="" id="" rows="5"></textarea>
                  <small id="helpId" class="form-text text-muted">Enter one reference per line.</small>

                </div>
            </div>

            <div class="col-md 2">
                <div class="row mb-2 mt-5">
                    <div class="col-md-3 d-grid gap-2">
                        <button type="button" id="editStoneTypeButton" @click="exportExcel()" class="btn btn-outline-dark">Jewel Export Excel</button>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3 d-grid gap-2">
                        <button type="button" id="editStoneTypeButton" @click="watchExportExcel()" class="btn btn-outline-dark">Watch Export Excel</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 d-grid gap-2">
                        <button type="button" id="editStoneTypeButton" @click="exportPDF()" class="btn btn-outline-dark">Export PDF</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="row">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
        <div class="row">

            <div v-if="showPDF" class="row gallery">
                <div class="col-md-12">
                    <ul>
                        <li>Number of Products Entered: {{totalEnteredProduct}}</li>
                        <li>Number of Unique Products Entered: {{totalUniqueProduct}}</li>
                        <li>Number of Unique Products Printed: {{totalPrintedProduct}}</li>
                    </ul>

                </div>
                <div class="col-md-2">
                    <button class="btn no-print btn-outline-dark ml-4" @click="printWindow">Print</button>
                </div>
                <div class="col-md-12">
                    <div id="gallery" style="@media print{margin:0px}">
                        <div class="gallery row justify-content-center " style="margin: 0 auto;"> 

                            <div v-for="(product,index) in products" :key="product.id"  class="gallery-item col-sm-1" style="width:min-content;margin:0 !important;page-break-inside: avoid !important;;height: auto;margin: 6px;border: 1px solid #ccc;font-size: x-small;">
                                <p class="text-left" style="font-size: 13px;margin-left: 0px;">{{index+1}}</p>

                                <img @dblclick="toProduct(product.product)" v-bind:src="product.thumbnail" style="width: 125px;height: 125px;@media print{width:125px;height:125px}">
                                <div class="description" style="width: 125px;@media print{width:125px}">
                                    <div class="row justify-content-center">

                                        <div class="col-md-12 " style="font-weight: bold;">
                                            <p class="text-center" style="font-size: 13px;">{{product.reference_no}}</p>
                                            <!-- <p style="font-size: smaller;">Reference:</p> -->
                                        
                                        </div>
                                            <!-- <div class="col-md-5 " style="font-weight: bold;">
                                                <p @dblclick="toProduct(product.product)" style="font-size: smaller;">Ref:{{product.reference_no}}</p>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>            
                    </div>
                </div>

            
    
        </div>
    </div>



</template>

<script>
import Menu from '@/components/Menu';
import {getAPI, postAPI, patchAPI,exportProducts} from '@/global/callAPI';
import CatalogGallery from '@/views/Catalog/CatalogGallery';
import { watch } from 'vue';

export default {
    components: {Menu,CatalogGallery},
    data () {
        return {
            reference:null,
            fileName:"Product Export",
            products:[],
            showPDF: false,
            loading:false,
            totalEnteredProduct:null,
            totalUniqueProduct:null,
            totalPrintedProduct:null,
            totalDuplicate:null,
        }
    },
    methods: {

        exportExcel() {
            this.loading = true
            var lines = this.reference.split('\n');
            let unique = [...new Set(lines)];
            let payload = {reference_no_list:unique}
            this.totalDuplicate = lines.length - unique.length
            exportProducts(payload)
            .then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', (this.fileName || "Product Export") +'.xlsx');
                document.body.appendChild(fileLink);    
                fileLink.click();
                this.loading=false
                window.alert(
                    `Successful!\nTotal Duplicate: ${this.totalDuplicate}\nDon't forget to check the errors tab in the file!`
                    )
            });
        },
        watchExportExcel() {
            this.loading = true
            var lines = this.reference.split('\n');
            let unique = [...new Set(lines)];
            let payload = {reference_no_list:unique}
            this.totalDuplicate = lines.length - unique.length
            exportProducts(payload,true)
            .then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', (this.fileName || "Product Export") +'.xlsx');
                document.body.appendChild(fileLink);    
                fileLink.click();
                this.loading=false
                window.alert(
                    `Successful!\nTotal Duplicate: ${this.totalDuplicate}\nDon't forget to check the errors tab in the file!`
                    )
            });
        },
        exportPDF(){
            function onlySpaces(str) {
                return /^\s*$/.test(str);
                }

            this.loading = true
            console.log()

            try{
            var lines = this.reference.split('\n');
            console.log(lines)
            if (onlySpaces(this.reference)){
                window.alert("Please enter product!")
                return false
            }
            } catch{
                window.alert("Please enter product!")
                return false
            }
            let totalProduct = lines.length
 
            this.totalEnteredProduct = totalProduct
            let unique = [...new Set(lines)];
            this.totalUniqueProduct = unique.length

            var refFilter = lines.join(",")
            getAPI("product-search?ordering=reference_no&limit=10000&"+"reference_no__in="+refFilter)
            .then(res => {
                this.products = res.data.results
                this.showPDF = true
                this.loading = false
                this.totalPrintedProduct = res.data.results.length
            })
        },
        printWindow: function () {
            var printContent = document.getElementById("gallery");
            var windowUrl = 'FANCY';
            var uniqueName = new Date();
            var windowName = 'Print' + uniqueName.getTime();

            var printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=0,height=0');
            printWindow.document.write('<head><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1"><title>TERZIHAN</title><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous"></head>');
            printWindow.document.write(printContent.innerHTML);
        },

    }

}
</script>

<style scoped>

input {
	border-radius: 0px;
}

select {
	border-radius: 0px;
}
td {
    text-align: center; 
    vertical-align: middle;
}
th {
    text-align: center; 
    vertical-align: middle;
}
.form-control {
    border-radius: 0px;
}
</style>