<template>
  <Menu />

  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      Product List
    </h6>
    <hr />
    <div class="filter-container">
      <form @submit.prevent="showList">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-2">
                <label for="referenceFilter">Reference No</label>
                <input
                  type="text"
                  id="referenceFilter"
                  v-model="apiFilters.reference_no"
                  class="form-control"
                />
              </div>

              <div class="col-md-2">
                <label for="storeFilter">Store</label>
                <v-select
                  multiple
                  v-model="apiFilters.store"
                  :options="storeModel"
                  :reduce="(store) => store.id"
                  label="store_name"
                />
              </div>
              <div class="col-md-2">
                <label for="minPrice">Min Price</label>
                <input
                  type="number"
                  id="minPrice"
                  v-model="apiFilters.gte_first_price"
                  class="form-control"
                />
              </div>
              <div class="col-md-2">
                <label for="maxPrice">Max Price</label>
                <input
                  type="number"
                  id="maxPrice"
                  v-model="apiFilters.lte_first_price"
                  class="form-control"
                />
              </div>
              <!-- <div class="col-md-2 align-self-center" align="left">
                    <div>
                        <input class="form-check-input form-check-inline" type="checkbox" v-model="apiFilters.active" id="stock">
                        <label class="form-check-label" for="stock">Stock</label>
                    </div>
                </div> -->

              <div class="col-md-2">
                <label for="saleStatus">Sale Status</label>
                <v-select
                  multiple
                  v-model="apiFilters.sale_status"
                  :options="saleStatus"
                  :reduce="(status) => status.value"
                  label="name"
                />
              </div>
              <div class="col-md-2">
                <label for="subType">Manufacturer</label>
                <v-select
                  multiple
                  v-model="apiFilters.manufacturer"
                  :options="manufacturerModel"
                  :reduce="(man) => man.manufacturer_id"
                  label="firm_name"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- 
            <div class="row mt-2">
                    <div class="col-sm-6 top-left">
                        <h5 class="marginright">Jewel Filters</h5>
                    </div>
                    <hr>

            </div> -->
        <div class="row mt-1 justify-content-center">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-2">
                <label for="modelNo">Jewel & Watch</label>
                <select
                  @change="selectChange"
                  v-model="apiFilters.jewel_watch"
                  id="property"
                  class="form-select"
                >
                  <option></option>
                  <option v-bind:value="0">Jewel</option>
                  <option v-bind:value="1">Watch</option>
                </select>
              </div>

              <div v-if="apiFilters.jewel_watch === 1" class="col-md-2">
                <label for="productType">Condition</label>
                <v-select
                  multiple
                  v-model="apiFilters.condition"
                  :options="conditionModel"
                  :reduce="(condition) => condition.id"
                  label="name"
                />
              </div>
              <div v-if="apiFilters.jewel_watch === 0" class="col-md-2">
                <label for="productType">Jewel Type</label>
                <v-select
                  multiple
                  v-model="apiFilters.jewel_type"
                  :options="jewelTypeModel"
                  :reduce="(type) => type.id"
                  label="name"
                />
              </div>
              <div class="col-md-2">
                <label for="modelFilter">Model No</label>
                <input
                  type="text"
                  id="modelFilter"
                  v-model="apiFilters.model_no"
                  class="form-control"
                />
              </div>

              <div class="col-md-2">
                <label for="subType">Tag</label>
                <v-select
                  multiple
                  v-model="apiFilters.tag"
                  :options="tagModel"
                  :reduce="(tag) => tag.id"
                  label="name"
                />
              </div>

              <div class="col-md-2">
                <label for="subType">Sub Type (Jewel)</label>
                <v-select
                  multiple
                  v-model="apiFilters.subtype"
                  :options="subTypeModel"
                  :reduce="(subtype) => subtype.id"
                  label="name"
                />
              </div>

              <div class="col-md-2">
                <label for="productType">Product Type (Jewel)</label>
                <v-select
                  multiple
                  v-model="apiFilters.product_type"
                  :options="producTypeModel"
                  :reduce="(product_type) => product_type.id"
                  label="type_name"
                />
              </div>
              <div class="col-md-2">
                <label for="productType">Brand (Watch)</label>
                <v-select
                  multiple
                  v-model="apiFilters.watch_brand"
                  :options="brandModel"
                  :reduce="(brand) => brand.id"
                  label="brand_name"
                />
              </div>

              <div class="col-md-2">
                <label for="originalReferenceFilter"
                  >Original Reference (Watch)</label
                >
                <input
                  type="text"
                  id="originalReferenceFilter"
                  v-model="apiFilters.watch_original_reference"
                  class="form-control"
                />
              </div>

              <div class="col-md-2">
                <label for="createdAtStart">Created Date (Start)</label>
                <input
                  id="created_date_start"
                  type="date"
                  class="form-control"
                  v-model="apiFilters.created_date__gte"
                />
              </div>
              <div class="col-md-2">
                <label for="createdAtEnd">Created Date (End)</label>
                <input
                  id="created_date_end"
                  type="date"
                  class="form-control"
                  v-model="apiFilters.created_date__lte"
                />
              </div>
              <div class="col-md-2">
                <label for="productionCode">Production Code</label>
                <input
                  id="productionCode"
                  type="text"
                  class="form-control"
                  v-model="apiFilters.production_code"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center mt-3">
          <div class="col-md-2" style="flex-basis: fit-content">
            <button type="button submit" class="btn btn-secondary">
              Filter
            </button>
          </div>

          <!-- <div class="col-md-2">
                    <button @click="showGallery" type="button" class="btn btn-outline-dark">Show Photo</button>
                </div> -->
        </div>
      </form>
      <div v-if="loading === true" class="row justify-content-center mt-5">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div class="row gallery-container" v-if="showMode === 'gallery'">
      <ListingGallery :products="products" :items="galleryItems" />
    </div>

    <div class="row justify-content-center" v-if="showMode === 'list'">
      <!-- <ProductList 
        :products="products"
        :listItems = "listItems"
        /> -->
      <div class="col-md-11">
        <Table
          excelName="products-export"
          excelTabName="Products"
          :tableInfo="tableInfo"
          :endPoint="endPoint"
          :tableExcelInfo="tableExcelInfo"
          showRoute="true"
          detailPath="/products/"
          idName="product_id"
          :useCreate="true"
          :key="tableKey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { getAPI } from "@/global/callAPI";
import ListingGallery from "@/components/ListingGallery.vue";
import ProductList from "@/components/ProductList.vue";
import Table from "@/components/Table.vue";
import store from "@/store";

export default {
  components: { Menu, ListingGallery, ProductList, Table },
  data() {
    return {
      groups: localStorage.getItem("groups"),
      store: store.state.store,

      endPoint: null,
      tableKey: 0,
      tableInfo: [
        // {header:"Id",field:"product_id",order:true},
        { header: "Thumbnail", field: "thumbnail", isImage: true },
        {
          header: "Crated At",
          field: "created_date",
          isDateTime: true,
          order: true,
        },
        // product_type_name
        { header: "Type", field: "product_type_name" },

        { header: "Reference", field: "reference_no", order: true },
        { header: "Model", field: "model_no", order: true },
        // {header:"Detail",field:"detail"},
        { header: "Tag Price", field: "first_price", order: true },
        { header: "Store", field: "store_name", order: true },
        { header: "Stones", field: "stone_info" },
        { header: "PCode", field: "production_code" },

        { header: "Status", field: "sale_status" },
      ],
      tableExcelInfo: [
        { header: "Id", field: "product_id", order: true },
        { header: "Thumbnail", field: "thumbnail", isImage: true },
        {
          header: "Crated At",
          field: "created_date",
        },

        { header: "Reference", field: "reference_no", order: true },
        { header: "Model", field: "model_no", order: true },
        // {header:"Detail",field:"detail"},
        { header: "Tag Price", field: "first_price", order: true },
        { header: "List Price(EUR)", field: "second_price", order: true },
        { header: "List Price(USD)", field: "third_price", order: true },
        { header: "List Price(AED)", field: "fourth_price", order: true },
        // {
        //   header: "Master Price",
        //   field: "jewel__master_price",
        //   order: true,
        // },

        { header: "Store", field: "store_name", order: true },
        { header: "Stones", field: "stone_info" },
        { header: "PCode", field: "production_code" },

        { header: "Status", field: "sale_status" },
        //active
        { header: "Active", field: "active" },
        //description
        { header: "Description", field: "description" },
        //ticket_info
        { header: "Ticket Info", field: "ticket_info" },
        { header: "Master Price", field: "jewel__master_price", order: true },
      ],
      apiFilters: {
        reference_no: null,
        store: null,
        lte_first_price: null,
        gte_first_price: null,
        active: null,
        subtype: null,
        product_type: null,
        model_no: null,
        jewel_watch: null,
        sale_status: ["active"],
        watch_brand: null,
        model_no: null,
        production_code: null,
        tag: null,
        manufacturer: null,
        condition: this.$route.query.condition || [1],
        watch_original_reference: null,
        jewel_type: this.$route.query.jewel_type || [1],
        created_date__gte: null,
        created_date__lte: null,
      },
      storeModel: [],
      subTypeModel: [],
      producTypeModel: [],
      manufacturerModel: [],
      tagModel: [],
      showMode: null,
      products: [],
      brandModel: [],
      galleryItems: [
        { id: 4, name: "Model", value: "model_no", show: true },
        { id: 1, name: "Detail", value: "detail", show: true },
        { id: 2, name: "Tag Price", value: "first_price", show: true },
        { id: 3, name: "Store", value: "store_name", show: true },
        { id: 4, name: "Status", value: "sale_status", show: true },
      ],
      loading: false,
      listItems: [
        { id: 5, name: "Reference", value: "reference_no", sortable: true },
        { id: 4, name: "Model", value: "model_no", sortable: true },
        { id: 1, name: "Detail", value: "detail", sortable: true },
        { id: 2, name: "Tag Price", value: "first_price", sortable: true },
        { id: 3, name: "Store", value: "store_name", sortable: true },
        { id: 4, name: "Status", value: "sale_status", sortable: true },
      ],
      conditionModel: [],
      jewelTypeModel: [],
      saleStatus: [
        //sale__sale_id__sale_status__in
        { id: 1, name: "Active", value: "active" },
        {
          id: 2,
          name: "Completed",
          value: "completed",
        },

        {
          id: 3,
          name: "Awaiting",
          value: "awaiting",
        },
        {
          id: 4,
          name: "Consignee",
          value: "consignee",
        },
        {
          id: 5,
          name: "Return",
          value: "return",
        },
      ],
    };
  },
  methods: {
    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }

      let user_groups = store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },
    showList() {
      this.createEndPoint();
      this.showMode = "list";
    },
    showGallery() {
      this.createEndPoint();
      this.showMode = "gallery";
    },
    createSaleStatusFilter() {
      const status = this.apiFilters.sale_status;
      const statusFilterStrFinal =
        status.length > 0 ? "sale_status=" + status.join(",") : "";
      return statusFilterStrFinal;
    },
    createEndPoint() {
      this.products = [];
      // this.loading = true
      let filters = this.apiFilters;

      let queryList = [
        "search=" + filters.reference_no,

        // "reference_no__iexact="+filters.reference_no,
        "store__id__in=" + filters.store,
        "first_price__lte=" + filters.lte_first_price,
        "first_price__gte=" + filters.gte_first_price,
        // "active=" + filters.active,
        "tags__tag__id__in=" + filters.tag,

        "jewel__subtypes__subtype__id__in=" + filters.subtype,
        "jewel__product_type__product_type__id__in=" + filters.product_type,
        "jewel__product__product_id__isnbull=" + filters.jewel_watch,
        this.createSaleStatusFilter(),
        "watch__brand__id__in=" + filters.watch_brand,
        "model_no__icontains=" + filters.model_no,
        "production_code__icontains=" + filters.production_code,
        "manufacturers__manufacturer__manufacturer_id__in=" +
          filters.manufacturer,
        "watch__original_reference__icontains=" +
          filters.watch_original_reference,
        "created_date__gte=" + filters.created_date__gte,
        "created_date__lte=" + filters.created_date__lte,
      ];
      if (filters.jewel_watch === 1) {
        queryList.push("watch__condition__id__in=" + filters.condition);
      }
      if (filters.jewel_watch === 0) {
        queryList.push("jewel__type__id__in=" + filters.jewel_type);
      }
      let query = queryList
        .join("&")
        .replaceAll("null", "")
        .replace("nbull", "null");
      console.log(query);
      let endPoint = "products?&" + query;

      this.endPoint = endPoint;
      this.tableKey += 1;
      this.showMode = "list";
      // getAPI(endPoint).then(res=> {

      //     this.products = res.data.results;
      //     this.loading = false
      // })
    },
  },
  created() {
    if (this.hasPermission(["super_user", "office"])) {
      // put master price 10th column
      this.tableInfo.splice(10, 0, {
        header: "Master Price",
        field: "jewel__master_price",
        order: true,
      });
      this.tableExcelInfo.splice(10, 0, {
        header: "Master Price",
        field: "jewel__master_price",
        order: true,
      });
    }

    getAPI("stores?limit=10000").then((res) => {
      this.storeModel = res.data.results;
    });
    getAPI("jewel-subtypes?limit=10000").then((res) => {
      this.subTypeModel = res.data.results;
    });
    getAPI("product-types?limit=10000").then((res) => {
      this.producTypeModel = res.data.results;
    });
    getAPI("watch-brands?limit=10000&order=brand_name").then((res) => {
      this.brandModel = res.data.results;
    });
    getAPI("product-tags?limit=10000&ordering=name&").then((res) => {
      this.tagModel = res.data.results;
    });

    getAPI("manufacturers?limit=10000&ordering=firm_name&").then((res) => {
      this.manufacturerModel = res.data.results;
    });
    getAPI("watch-conditions?limit=10000&ordering=name&").then((res) => {
      this.conditionModel = res.data.results;
    });

    getAPI("jewel-types?limit=10000").then((res) => {
      this.jewelTypeModel = res.data.results;
    });
  },
};
</script>

<style scoped>
input {
  border-radius: 0px;
}

select {
  border-radius: 0px;
}

td {
  text-align: center;
  vertical-align: middle;
}

th {
  text-align: center;
  vertical-align: middle;
}
</style>
