<template>
  <Menu />

  <AddProperty
    endPoint="cost-types"
    pageName="Add End of Day Expense Type"
    fieldName="name"
    :extraSubmitData="extraSubmitData"
    @selected="selectChange"
    @reset="reset"
  >
    <template v-slot:extra>
      <div class="col-md-2">
        <label for="" class="form-label">Cost Group</label>
        <v-select
          v-model="extraSubmitData.group"
          :options="costGroupData"
          label="name"
          :reduce="(costGroup) => costGroup.id"
        />
      </div>
    </template>
  </AddProperty>
</template>

<script>
import Menu from "@/components/Menu";
import AddProperty from "@/components/AddProperty.vue";
import { getAPI } from "@/global/callAPI";
export default {
  components: { Menu, AddProperty },
  data: function () {
    return {
      costGroupData: [],
      extraSubmitData: {
        group: null,
      },
    };
  },

  methods: {
    selectChange(selectedProperty) {
      this.extraSubmitData.group = selectedProperty.group;
    },
    reset() {
      this.extraSubmitData.group = null;
    },
  },
  created() {
    getAPI("cost-groups?limit=1000000").then((res) => {
      this.costGroupData = res.data.results;
    });
  },
};
</script>

<style></style>
