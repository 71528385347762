<template>

<div class="row">

    <div class="col-md-3">
    <SelectProduct
        :product-model="productModel"
        v-model="selected"
        :selected="selected"
    ></SelectProduct>

    <p>{{selected}}</p>
    </div>

    <div class="col-md-3">
       <SelectCustomer
            :customer-model="customerModel"
            v-model="selectedCustomer"
            :selected="selectedCustomer">
        </SelectCustomer>

        <p>{{selectedCustomer}}</p>
    </div>

    <div class="col-md-3">
       <SelectStone
        :stone-model="stoneModel"
        v-model="selectedStone"
        :selected="selectedStone">
       </SelectStone>

        <p>{{selectedStone}}</p>
    </div>

</div>

<!-- <p>{{productModel}}</p>
<p>{{customerModel}}</p>
<p>{{stoneModel}}</p> -->

</template>

<script>
import SelectProduct from '@/components/SelectProduct.vue';
import SelectCustomer from '@/components/SelectCustomer.vue';
import SelectStone from '@/components/SelectStone.vue';

export default {

    components: {SelectProduct, SelectCustomer,SelectStone},
    data: function() {
        return {
            productModel:[],
            selected:3,
            selected2:5,
            selected3:6,
            selectedCustomer:98,
            customerModel: [],
            selectedStone:7,
            stoneModel: []
        }
    },

    created: function() {
        }
}
</script>

<style scoped>

</style>