<template>
    <Menu />
    <div class="container-fluid">
        <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px;">Export Data</h6>
        <hr>
        <div class="row justify-content-center">
            <div class="col-md-2 mb-3 mt-4 d-grid gap-2">
                    <button @click="exportCatalog" type="button" class="btn btn-outline-dark" :disabled="loading">
                    <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Catalog
                </button>
            </div>
        <div class="row justify-content-center">
            <div class="col-md-2 mb-3 d-grid gap-2">
                    <button @click="exportSales" type="button " class="btn btn-outline-dark" :disabled="loading">
                    <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Sales
                </button>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-2 mb-3 d-grid gap-2">
                    <button @click="exportProducts" type="button " class="btn btn-outline-dark" :disabled="loading">
                    <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Products
                </button>
            </div>
        </div>

        <!-- <div class="row justify-content-center">
            <div class="col-md-2 mb-3 d-grid gap-2">
                    <button @click="exportProducts" type="button " class="btn btn-outline-dark" :disabled="loading">
                    <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Products
                </button>
            </div>
        </div> -->
        </div>
    </div>
    
    </template>
    
    <script>
import {excelExport} from '@/global/callAPI';
import Menu from '@/components/Menu';
export default {
    components: {Menu},
    data: function () {
        return {
            loading:false,
        }
    },

    methods: {
        exportCatalog(){
            this.loading = true
            excelExport("export-catalog")
                .then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download','catalog-data'+'.xlsx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.loading = false

                });
        },
        exportSales(){
            this.loading = true
            excelExport("export-database-sale")
                .then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download','sales-data'+'.xlsx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.loading = false

                });
        },
        exportProducts(){
            this.loading = true
            excelExport("database-export-products")
                .then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download','products-data'+'.xlsx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.loading = false

                });
        },

    },
    

}
</script>

<style>

</style>