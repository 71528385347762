<template>
  <div class="d-flex justify-content-between mb-3">
    <h5 class="text-left text-info text-dark" style="display: inline">
      Documents
    </h5>
    <div class="new-item">
      <!-- <button type="button" class="btn btn-primary">New +</button> -->
      <div class="input-group">
        <input
          type="file"
          class="form-control"
          id="inputGroupFile04"
          aria-describedby="inputGroupFileAddon04"
          aria-label="Upload"
          @change="changeFile"
        />
        <button
          class="btn btn-outline-secondary"
          type="button"
          id="inputGroupFileAddon04"
          @click="upload"
          :disabled="!file || buttonDisabled"
        >
          Upload
        </button>
      </div>
    </div>
  </div>
  <div class="docs">
    <table class="table table-bordered" style="backgroun-color: transparent">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">File</th>
          <th scope="col">Created At</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in docs" :key="item.id">
          <th scope="row">{{ index + 1 }}</th>
          <td>
            <a :href="item.file" target="_blank">{{ item.name }}</a>
          </td>
          <td>{{ convertDate(item.created_at) }}</td>
          <td class="d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-sm btn-outline-danger"
              @click="deleteFile(item)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getAPI, uploadFile, deleteAPI } from "@/global/callAPI";
export default {
  name: "SaleDocs",
  components: {},
  data: function () {
    return {
      docs: [],
      file: null,
      buttonDisabled: false,
    };
  },
  methods: {
    getDocuments() {
      getAPI(`upload-sale-doc?sale=${this.$route.params.id}`).then((res) => {
        this.docs = res.data.results;
      });
    },
    convertDate(date) {
      if (!date) return;
      return new Date(date).toLocaleString();
    },
    changeFile(event) {
      this.file = event;
    },
    upload() {
      this.buttonDisabled = true;
      let data = new FormData();
      data.append("sale", this.$route.params.id);
      data.append("file", this.file.target.files[0]);
      uploadFile("upload-sale-doc", data)
        .then(() => {
          this.getDocuments();
          document.getElementById("inputGroupFile04").value = "";
        })
        .finally(() => {
          this.buttonDisabled = false;
        });
    },
    deleteFile(item) {
      if (window.confirm("Are you sure you want to delete this file?")) {
        deleteAPI(`upload-sale-doc/${item.id}`).then(() => {
          this.getDocuments();
        });
      }
    },
  },

  created: function () {
    this.getDocuments();
  },
};
</script>
