<template>
  <JewelItem v-if="jewel" />
  <WatchItem v-if="watch" />
</template>

<script>
import WatchItem from "@/views/Product/WatchItem";
import JewelItem from "@/views/Product/JewelItem";
import { getAPI } from "@/global/callAPI";

export default {
  name: "ProductItem",
  components: { WatchItem, JewelItem },

  data: function () {
    return {
      watch: null,
      jewel: null,
    };
  },

  created: function () {
    getAPI("products/" + this.$route.params.id).then((res) => {
      if (res.data.product_type_router_name === "Jewel") {
        this.jewel = true;
      } else if (res.data.product_type_router_name === "Watch") {
        this.watch = true;
      }
    });
  },
};
</script>

<style scoped></style>
