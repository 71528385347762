<template>
    <Menu />
    <Toast />
    <div class="container-fluid">
        <h6 v-if="catalogNameModel.length > 0" class="text-center font-weight-bold text-info text-dark"
            style="font-size:15px"><strong>Catalog Page</strong> </h6>
        <hr style="height:0.5px;margin-bottom:30px">

        <div class="row">
            <div class="col-md-4">
                <label for="catalogName" class="text-bold">Catalog Name</label>

                <v-select :key="selectedCatalog" :options="catalogNameModel" label="name" v-model="selectedCatalog"
                    :reduce="(catalog) => catalog.id" @option:selected="productsOfCatalog">
                </v-select>

                <!-- <select @change="productsOfCatalog" id="catalogName" v-model="selectedCatalog" class="form-control" >
						<option class="form-control" v-bind:value="c.id" v-for="c in catalogNameModel" :key="c.id">{{c.name}}</option>
					</select> -->
                <small class="form-text text-muted">After update a product , don't forget to submit it!</small>
            </div>

            <div class="col-md-2 align-self-center">
                <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" :value=false v-model="isShowStock" @change="showStockChange" id="flexRadioDefault1" >
                <label class="form-check-label" for="flexRadioDefault1">
                    Show All
                </label>
                </div>
                <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" :value=true id="flexRadioDefault2" v-model="isShowStock"  @change="showStockChange" >
                <label class="form-check-label" for="flexRadioDefault2">
                    Show Stock Products
                </label>
                </div>


            </div>
            <!-- New Catalog -->
            <!-- <div class="col-md-1">
                <div class="row">
                    <button type="button" id="newStoneButton" @click="addNewCatalog" class="btn btn-outline-dark">Add</button>
                    <Dialog header="Add Catalog" v-model:visible="displayNewCatalog" :style="{width: '30vw'}">

                        <input class="form-control" v-model="newCatalogModel" :style="{width: '27vw',height:'10vh'}"  />
                        <template #footer>
                            <Button label="No" icon="pi pi-times" @click="closeBasic" class="p-button-text"/>
                            <Button label="Yes" icon="pi pi-check" @click="catalogAdd" autofocus />
                        </template>
                    </Dialog>
                </div> -->


            <!-- Edit Catalog -->
            <!-- <div class="row">
                    <button type="button" id="editStoneTypeButton" v-if="selectedCatalog" @click="editCatalogButton" class="btn btn-outline-dark">Edit</button>
                    <Dialog header="Edit Catalog" v-model:visible="displayEditCatalog" :style="{width: '30vw'}">

                        <input class="form-control" v-model="selectedCatalogName" :style="{width: '27vw',height:'10vh'}"  />
                        <template #footer>
                            <Button label="No" icon="pi pi-times" @click="closeBasic" class="p-button-text"/>
                            <Button label="Yes" icon="pi pi-check" @click="catalogEdit" autofocus />
                        </template>
                    </Dialog>                


                </div>
            </div> -->

            <div class="col-md-2">
                <!-- <div class="row"> -->
                <!-- <button type="button"  v-if="selectedCatalog" @click="openBasic" class="btn btn-outline-primary">Add Multi Product</button> -->
                <!-- <Button label="Add Product" icon="pi pi-external-link" v-if="selectedCatalog" @click="openBasic" /> -->
                <!-- <Dialog header="Add Product" v-model:visible="displayBasic" :style="{width: '30vw'}">
                    <textarea class="form-control" v-model="multiProduct" :style="{width: '27vw',height:'30vh'}"  ></textarea>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" @click="closeBasic" class="p-button-text"/>
                        <Button label="Yes" icon="pi pi-check" @click="productAdd" autofocus />
                    </template>
			    </Dialog>
                </div> -->


                <!-- <div class="row mt-1">
                <button type="button"   @click="openDeleteProduct" class="btn btn-outline-danger">Delete Product</button>
			    <Dialog header="Delete Product" v-model:visible="displayDelete" :style="{width: '30vw'}">
                    <textarea class="form-control" v-model="multiProductDelete" :style="{width: '27vw',height:'30vh'}"  ></textarea>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" @click="closeBasic" class="p-button-text"/>
                        <Button label="Yes" icon="pi pi-check" @click="deleteProduct" autofocus />
                    </template>
			    </Dialog>
                </div> -->
            </div>





        </div>
        <div v-if="mainCatalogModel" class="row mb-2">
            <div class="col-md-1 offset-md-10">
                <button @click="createExcel" type="button" class="btn btn-outline-success" style="">Export</button>
            </div>
            <div class="col-md-1 ">
                <button @click="submitProduct" type="button" class="btn btn-outline-success" style="">Submit</button>
            </div>
        </div>

        <!-- TAB MENU -->
        <TabMenu v-if="mainCatalogModel" :model="items" @click="clickPhoto()" v-model:activeIndex="activeIndex" />
        <router-view />
        <!-- <hr v-if="mainCatalogModel"> -->

        <div v-if="loading" class="row justify-content-center mt-2">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="products">
            <div v-if="mainCatalogModel && activeIndex === 0" class="row list mt-3">
                <div class="row mb-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-2">
                        <label for="multiplier">Multiplier</label>
                        <input type="number" v-model="multiplier" id="multiplier" placeholder="0.00"
                            @keyup.enter="multiPrice" class="form-control">
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <table class="table table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th scope="column">Product</th>
                                    <th style="width: 15%" scope="column">Date</th>
                                    <th style="width: 45%" scope="column">Description</th>
                                    <th scope="column">Price</th>
                                    <th scope="column">
                                        <!-- <button type="button" class="btn btn-sm btn-secondary" @click="newProductAdd">New</button> -->
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(d, i) in mainCatalogModel.products" :key="i">
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td>
                                        <!-- <select id="products" v-model="d.product" @change="ticketFinder(d.product, i)" class="form-control" required>
							        <option value="0">-Select-</option>
							        <option v-for="p in productModel" :key="p.product_id" v-bind:value="p.product_id" >{{p.reference_no}}</option>
						        </select> -->
                                        <input type="text" class="form-control" v-model="d.reference_no"
                                            v-if="d.reference_no" disabled>
                                        <!-- <SelectProduct
                                v-if="!d.reference_no"
                                    :product-model="productModel"
                                    v-model="d.product"
                                    :selected="d.product"
                                ></SelectProduct> -->

                                    </td>
                                    <td>
                                        <input class="form-control" type="date" v-model="d.created_at">
                                    </td>
                                    <td>
                                        <input class="form-control" v-model="d.description" type="text">
                                    </td>
                                    <td>
                                        <input class="form-control" v-model="d.price" step="any" type="number">
                                    </td>
                                    <td>
                                        <!-- <button @click="removeProduct(d,i)" type="button" class="btn btn-outline-dark btn-sm">
                                    <span class="material-icons-outlined">delete</span>
                                </button> -->
                                        <button @click="removeProduct(d, i)" type="button" id="remove-button"
                                            class="btn btn-danger btn-sm">
                                            <!-- <span class="material-icons-outlined">delete</span> -->
                                            <span>Delete</span>

                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div v-if="mainCatalogModel && activeIndex === 1" class="row gallery">
                <div class="col-md-12">

                    <!-- <CatalogGallery :products="mainCatalogModel.products" /> -->
                    <ListingGallery :products="mainCatalogModel.products" :items="galleryItems"
                        productField="product" />
                </div>
            </div>

        </div>



    </div>
</template>

<script>
import { getAPI, postAPI, patchAPI, deleteAPI } from '@/global/callAPI';
import Menu from '@/components/Menu';
import ListingGallery from '@/components/ListingGallery.vue'

import CatalogGallery from '@/views/Catalog/CatalogGallery';
import SelectProduct from '@/components/SelectProduct.vue';

export default {
    name: "Catalog",
    components: { Menu, CatalogGallery, SelectProduct, ListingGallery },
    data: function () {
        return {

            items: [
                { label: 'List', icon: 'pi pi-fw pi-table' },
                { label: 'Photo', icon: 'pi pi-fw pi-images' }
            ],
            vselectKey: 0,
            isActive: true,
            activeIndex: 0,
            catalogNameModel: [],
            selectedCatalog: Number(this.$route.query.id) || null,
            selectedCatalogName: null,
            displayBasic: false,
            displayDelete: false,
            multiProduct: null,
            multiProductDelete: null,
            productsToAdd: [],
            displayNewCatalog: false,
            displayEditCatalog: false,
            newCatalogModel: null,

            productModel: [], // To select product (created)

            mainCatalogModel: null,
            newProductModel: {
                product: null,
                description: null,
                price: null,
                created_at: new Date().toLocaleDateString("fr-CA")
            },
            multiplier: null,
            loading: false,
            galleryItems: [
                { id: 1, name: "Catalog", value: "name", show: true },
                // { id: 1, name: "Reference No", value: "reference_no", show: true },
                { id: 2, name: "Description", value: "description", show: true },
                { id: 3, name: "Price", value: "price", show: true },
                // {id:3,name:"Created At",value:"created_at",show:true},

            ],
            isShowStock:true,
            product_active:true,


        }
    },

    created: function () {
        getAPI("catalog?limit=100000&ordering=name&").then(res => {
            this.catalogNameModel = res.data.results;
            if (this.selectedCatalog) {
                this.productsOfCatalog();

            }
        })
        // getAPI('product-search?ordering=-product_id&limit=100&active=true').then((res) => {this.productModel = res.data.results});


    },
    methods: {
        // Catalog methods
        addNewCatalog() {
            this.displayNewCatalog = true;
        },

        catalogAdd() {
            let submit = { name: this.newCatalogModel }

            postAPI("catalog", submit)
                .then(res => { getAPI("catalog?limit=100000&ordering=name&").then(res => this.catalogNameModel = res.data.results) }
                )
                .then(this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Catalog crated!', life: 3000 }))
            this.closeBasic();

        },
        editCatalogButton() {
            this.selectedCatalogName = this.catalogNameModel.find(c => c.id === this.selectedCatalog)["name"]
            this.displayEditCatalog = true;
        },

        catalogEdit() {
            let submit = {
                name: this.selectedCatalogName
            }
            patchAPI("catalog/" + this.selectedCatalog, submit)
                .then(res => { getAPI("catalog?limit=100000&ordering=name&").then(res => this.catalogNameModel = res.data.results) }
                )
                .then(this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Catalog updated!', life: 3000 }))
            this.closeBasic();
        },

        clickPhoto() {
            this.productModel = [].concat.apply([], this.productModel)

            this.mainCatalogModel.products.map(item => {
                if (this.productModel.length > 0) {
                    console.log()
                    item.thumbnail = this.productModel.find(po => po.product_id === item.product)["thumbnail"]
                }
            })
        },
        // Products of Catalog
        productsOfCatalog() {
            const product_active = this.isShowStock
            this.mainCatalogModel = [];
            const id = this.selectedCatalog
            this.loading = true
            let end = ""
            if (product_active === true) {
                end = "catalog-products?limit=1000000&product__active=true&active=true&catalog=" + id
            } else {
                end = "catalog-products?limit=1000000&active=true&catalog=" + id

            }
            getAPI(end).then(response => {

                let idListMega = []
                response.data.results.forEach(item => {
                    idListMega.push(item.product)
                })
                const chunkSize = 50;
                    for (let i = 0; i < idListMega.length; i += chunkSize) {
                        const idList = idListMega.slice(i, i + chunkSize);

                if (idList.length > 0) {
                    getAPI('product-search?limit=100000000&product_id__in=' + idList.join(','))
                        .then(res => {
                            res.data.results.forEach(product => {
                                this.productModel.push(product)
                            })
                            this.mainCatalogModel["products"] = response.data.results;
                            this.$forceUpdate();
                            this.mainCatalogModel.products.map(item => {
                                let cDate = new Date(item.created_at)
                                item.created_at = cDate.toLocaleDateString("fr-CA")
                            });


                        })
                }
            }
                this.loading = false;
                this.$router.push({
                    path: this.$route.path, query: {
                        id: this.selectedCatalog
                    }
                })


            })



        },

        // Add Product methods
        openBasic() {
            this.displayBasic = true;
        },
        openDeleteProduct() {
            this.displayDelete = true;
        },
        closeBasic() {
            this.displayDelete = false;
            this.displayBasic = false;
            this.displayNewCatalog = false;
            this.displayEditCatalog = false;
        },
        newProductAdd() {
            let productModel = {
                product: null,
                description: null,
                price: null,
                created_at: new Date().toLocaleDateString("fr-CA"),
            }
            this.mainCatalogModel.products.push(productModel)
        },
        removeProduct(object, i) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                cancelButtonColor: '#3085d6',
                confirmButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).
                then((result) => {
                    if (result.value) {

                        if (object.id) {
                            let payload = { reference_no: object.reference_no }
                            postAPI("catalog-product-deactive", payload)

                                .then((res) => {
                                    var is_removed = res.data["is_removed"]
                                    if (is_removed === true) {
                                        Toast2.fire({ title: 'Delete  successfully' });
                                        this.mainCatalogModel.products.splice(i, 1);

                                    } else {
                                        Toast2.fire({ title: 'Error: Product could not be deleted' });

                                    }
                                })
                        }
                        // deleteAPI('catalog-products/'+ object.id)
                        // Toast2.fire({title: 'Canceled  successfully'});
                        // this.mainCatalogModel.products.splice(i, 1);

                    }
                })
        },

        productAdd() {

            const catalog = this.selectedCatalog
            let idList = []
            this.mainCatalogModel.products.forEach((element, index) => {
                idList.push(
                    element["product"]
                )
            })

            var lines = this.multiProduct.split('\n');
            lines = [...new Set(lines)];
            for (var i = 0; i < lines.length; i++) {
                let reference_no = lines[i]
                let payload = { reference_no: reference_no }
                postAPI("catalog-product-control", payload)
                    .then((response) => response)

                    .then(
                        (res) => {
                            var result = res.data
                            var can_add = result["can_add"]
                            var stock = result["stock"]
                            if (can_add === false) {
                                if (stock === false) {
                                    this.$toast.add({ severity: 'error', summary: 'Error', detail: `${reference_no} doesn't added! It's not in stock!`, life: 2000 });
                                } else {
                                    this.$toast.add({ severity: 'error', summary: 'Error', detail: `${reference_no} doesn't added! It's in another catalog!`, life: 2000 });
                                }
                            } else {
                                if (idList.includes(result["product_id"])) {
                                    this.$toast.add({ severity: 'error', summary: 'Error', detail: `You have already added ${reference_no} to this catalog!`, life: 2000 });

                                } else {
                                    let detail = {
                                        catalog: catalog,
                                        product: result["product_id"],
                                        description: null,
                                        created_at: new Date().toLocaleDateString("fr-CA"),
                                        price: result["first_price"]
                                    };
                                    this.mainCatalogModel.products.push(detail);
                                    this.productsToAdd.push(detail);
                                }
                            }
                        })
            }
            this.closeBasic()

        },
        deleteProduct() {
            const catalog = this.selectedCatalog
            var lines = this.multiProductDelete.split('\n');
            lines = [...new Set(lines)];
            for (var i = 0; i < lines.length; i++) {
                let reference_no = lines[i]
                let payload = { reference_no: reference_no }
                postAPI("catalog-product-deactive", payload)
                    .then((response) => response.json())
                    .then((res) => {

                        var is_removed = res["is_removed"]
                        var result = res["result"]
                        if (is_removed === true) {
                            this.$toast.add({ severity: 'success', summary: 'Success', detail: `${reference_no} removed from catalogs!`, life: 3000 })
                        } else {
                            this.$toast.add({ severity: 'error', summary: 'Error', detail: result, life: 3000 });

                        }
                    })

            }
            this.closeBasic()

        },

        // productAdd2 () {
        //     const catalog = this.selectedCatalog
        // 	var lines = this.multiProduct.split('\n');
        // 	for(var i = 0;i < lines.length;i++){
        // 		const id = lines[i]
        // 		try {
        // 		    getAPI("catalog-product-control?active=true&reference_no=" + lines[i]).then((res) => {
        // 			if (res.data.results[0]) {
        // 				// this.productModel.push(res.data.results[0]);
        // 				let detail = {
        //                     catalog:catalog,
        // 					product: res.data.results[0]["product_id"],
        // 					description: null,
        //                     created_at:new Date().toLocaleDateString("fr-CA"),
        //                     price: res.data.results[0]["first_price"]
        // 				};
        //                 this.mainCatalogModel.products.push(detail);
        // 				this.productsToAdd.push(detail);
        // 			} else {
        // 				this.$toast.add({severity:'error', summary: 'Error', detail:`${id} doesn't added!`, life: 1000});
        // 			}});
        // 		} catch (error) {
        // 			this.$toast.add({severity:'error', summary: 'Error', detail:`${id} doesn't added!`, life: 1000});
        // 		}}

        // 	this.closeBasic()

        // },

        ticketFinder(product, id) {
            let price = this.productModel.find(item => item.product_id === product)["first_price"]
            this.mainCatalogModel.products[id].price = price


        },

        submitProduct() {
            let products = this.mainCatalogModel.products
            let catalog = this.mainCatalogModel.id
            let productIds = []
            let successful = 0
            
            const maxIndex = this.mainCatalogModel.products.length -1
            this.mainCatalogModel.products.forEach((element, index) => {

                element.catalog = catalog;
                if (productIds.includes(element["product"])) {
                    // this.$toast.add({severity:'error', summary: 'Error', detail:`You have already added ${index}. index to this catalog!`, life: 2000});

                } else {
                    productIds.push(element["product"])

                    if (element.id) {
                        patchAPI("catalog-products/" + element.id, element)
                        .then(response => { 
                            if (response.status == 200) {
                                // console.log(successful)
                                successful += 1
                            }
                            else {
                                this.$toast.add({ severity: 'error', summary: 'Error', detail: element.id, life: 3000 })                        
                            }
                    })

                        // .then(this.$toast.add({severity:'success', summary: 'Success', detail:`${index}. product updated!`, life: 3000}))

                    } else {
                        element["catalog"] = this.selectedCatalog
                        postAPI("catalog-products", element)
                        .then(response => { 
                            if (response) {
                                console.log(successful)
                                successful += 1
                                // this.$toast.add({ severity: 'success', summary: 'Success', detail: `Products updated!`, life: 3000 })                        
                            } else {
                                this.$toast.add({ severity: 'error', summary: 'Error', detail: "Product couldn't created", life: 3000 })                        

                            }
                    })
                        // .then(this.$toast.add({severity:'success', summary: 'Success', detail:`${index}. product created!`, life: 3000}))

                    }
                }

                if (maxIndex === index){
                    console.log(maxIndex,index)
                    // console.log(successful)
                    // this.$toast.add({ severity: 'success', summary: 'Success', detail: `Products updated! Total Product:${maxIndex + 1} Successful Products:${successful}` , life: 3000 })      
                        this.$toast.add({ severity: 'success', summary: 'Success', detail: `Products updated!` , life: 3000 })                        
                    
                }
            })


            

            
            // .this.productsOfCatalog()
        },
        createExcel() {
            const XLSX = require('xlsx')

            // array of objects to save in Excel

            let exportArray = []
            let catalog = this.catalogNameModel.find(item => item.id === this.selectedCatalog)["name"]
            this.mainCatalogModel.products.forEach(item => {
                let id = item.id
                this.productModel = [].concat.apply([], this.productModel)
                let reference_no = this.productModel.find(pro => pro.product_id === item.product)["reference_no"]


                let description = item.description
                let price = item.price
                let date = item.created_at

                let object1 = {
                    ID: id,
                    Reference: reference_no,
                    Date: date,
                    Description: description,
                    Price: price,

                }
                exportArray.push(object1)
            })
            let binary_univers = exportArray

            let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

            // Create a new Workbook
            var wb = XLSX.utils.book_new()

            // Name your sheet
            XLSX.utils.book_append_sheet(wb, binaryWS, 'Catalog Products')

            // export your excel

            XLSX.writeFile(wb, catalog + '.xlsx');

        },
        multiPrice() {
            let multi = this.multiplier
            this.mainCatalogModel.products.forEach(
                product => {
                    let id = product.product
                    console.log(this.productModel)
                    this.productModel = [].concat.apply([], this.productModel)
                    let originalPrice = this.productModel.find(product => product.product_id == id)["first_price"]
                    product.price = (originalPrice * multi).toFixed(2)
                }
            )

        },

        showStockChange () {
            console.log("Show Stock:",this.isShowStock)
            this.productsOfCatalog()
        }

    }
}

</script>

<style scoped>
#awaitingCheck {
    width: 4em;
    height: 2em;
    align-content: center;
}

#checkBox {
    width:5em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-items: center;
}
</style>