<template>

    <div class="container-fluid">
        <Menu />
        <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px"><strong>New Manufacturer</strong> </h6>
	    <hr style="height:0.5px;margin-bottom:30px">

        <form @submit.prevent="submitManufacturer" id="createManufacturer">
            <div class="row justify-content-center ">
                <!--Firm Name Input -->
                <div class="col-md-2">
                    <label for="firmNameInput">Firm Name</label>
                    <input class="form-control" type="text" name="Firm Name" 
                        id="firmNameInput" v-model="manufacturerModel.firm_name" required>
                </div>

                <!-- Contact Name Input -->
                <div class="col-md-2">
                    <label for="contactNameInput">Contact Name</label>
                    <input class="form-control" type="text" name="Contact Name" 
                        id="contactNameInput" v-model="manufacturerModel.name_surname" required>
                </div>

                <!-- Mail Input -->
                <div class="col-md-2">
                    <label for="typeInput">Type</label>
					<select id="typeInput" v-model="manufacturerModel.type_id" class="form-control" required>
						<option v-bind:value="t.manufacturer_type_id" v-for="t in typeModel" :key="t.manufacturer_type_id">{{t.manufacturer_type}}</option>
					</select>
                </div>
            </div>

            <div class="row justify-content-center ">
                <!--Mobile Phone Input -->
                <div class="col-md-2">
                    <label for="mobilePhoneInput">Mobile Phone Number</label>
                    <input class="form-control" type="text" name="Mobile Phone" 
                        id="mobilePhoneInput" v-model="manufacturerModel.mobile_phone" >
                </div>

                <!-- Phone Input -->
                <div class="col-md-2">
                    <label for="phoneNumberInput">Phone Number</label>
                    <input class="form-control" type="text" name="Phone Number" 
                        id="phoneNumberInput" v-model="manufacturerModel.phone" >
                </div>

                <!-- Fax -->
                <div class="col-md-2">
                    <label for="faxInput">Fax</label>
                    <input class="form-control" type="text" name="Fax" 
                        id="faxInput" v-model="manufacturerModel.fax">
                </div>
            </div>

            <div class="row justify-content-center ">

                <!-- Mail Input -->
                <div class="col-md-2">
                    <label for="mailInput">Mail</label>
                    <input class="form-control" type="email" name="Mail" 
                        id="mailInput" v-model="manufacturerModel.mail">
                </div>

                <!-- Address Input -->
                <div class="col-md-4">
                    <label for="addressInput">Address</label>
                    <input class="form-control" type="text" name="Address" 
                        id="addressInput" v-model="manufacturerModel.address">
                </div>

            </div>
           
                <div class="row justify-content-center mt-4">
                    <div class="col-md-1">
                        <button id="" class="btn btn-outline-secondary" type="submit" >Submit</button>
                    </div>
                </div>
            
        </form>

    </div>
</template>

<script>
import Menu from '@/components/Menu';
import {getAPI, postAPI} from '@/global/callAPI';

export default {
    name:"NewManufacturer",
    components: {Menu},
    data: function () {
        return {
            manufacturerModel: {
                name_surname:null,
                firm_name:null,
                address:null,
                phone:null,
                mobile_phone:null,
                fax:null,
                mail:null,
                type_id:null,
            },
            typeModel:null,

        }
    },

    methods: {
        submitManufacturer: function() {
            let submit = this.manufacturerModel;
             postAPI('manufacturers', submit )
            .then(res => this.$router.push('/manufacturers/'+res.data.manufacturer_id));
        }

    },
    created: function () {

        getAPI("manufacturer-types?limit=1000").then(res => this.typeModel = res.data.results)

    },
}
</script>

<style>
.row justify-content-center  {
    padding: 5px;
}
</style>