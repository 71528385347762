<template>
  <Toast />
  <Menu />

  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>Admin End Of Day</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />

    <div class="row align-items-end">
      <div class="col-md-2">
        <label for="storeFilter">Store</label>
        <v-select
          multiple
          v-model="apiFilters.store"
          :options="storeModel"
          :reduce="(store) => store.id"
          label="store_name"
        />
      </div>

      <div class="col-md-2">
        <label for="range" class="text-bold">Start Date</label>
        <input
          type="date"
          v-model="apiFilters.gte__date"
          id="gte_date"
          class="form-control"
        />
      </div>

      <div class="col-md-2">
        <label for="range" class="text-bold">End Date</label>
        <input
          type="date"
          v-model="apiFilters.lte__date"
          id="lte_date"
          class="form-control"
        />
      </div>

      <div class="col-md-2">
        <label for="costType" class="text-bold">Cost Type</label>
        <v-select
          multiple
          class="p-datatable-sm"
          id="costType"
          :options="costTypeModel"
          label="name"
          v-model="apiFilters.cost_type"
          :reduce="(cost_type) => cost_type.id"
        />
      </div>
      <div class="col-md-2">
        <label for="costGroup" class="text-bold">Cost Group</label>
        <v-select
          id="costGroup"
          v-model="apiFilters.cost_group"
          :options="costGroupData"
          label="name"
          :reduce="(costGroup) => costGroup.id"
          multiple
        />
      </div>

      <div class="col-md-2">
        <button
          type="button"
          @click="filterSubmit"
          id="submitFilter"
          class="btn btn-outline-dark"
        >
          Filter
        </button>
      </div>
    </div>

    <div class="row endTable" style="margin-top: 20px">
      <div class="row justify-content-center mt-5">
        <div class="col-md-6">
          <small
            >At first it shows the whole total, when the filter changes, the
            total changes according to filters.</small
          >
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">TL</th>
                <th scope="col">USD</th>
                <th scope="col">EUR</th>
                <th scope="col">CHF</th>
                <th scope="col">GBP</th>
                <th scope="col">Gold</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row justify-content-center">Total</th>
                <td>{{ sumModel["total_tl_cost"] || null }}</td>
                <td>{{ sumModel["total_usd_cost"] || null }}</td>
                <td>{{ sumModel["total_eur_cost"] || null }}</td>
                <td>{{ sumModel["total_chf_cost"] || null }}</td>
                <td>{{ sumModel["total_gbp_cost"] || null }}</td>
                <td>{{ sumModel["total_gold_cost"] || null }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-12">
        <Table
          v-if="showTable"
          excelName="cost-export"
          excelTabName="Costs"
          @deleteEvent="confirmDeleteCost"
          :tableInfo="[
            { header: 'Created At', field: 'created_at_date', order: false },

            { header: 'Date', field: 'date', order: true },
            { header: 'Cost', field: 'cost_name' },
            { header: 'Group', field: 'cost_group_name' },
            { header: 'Store', field: 'store_name' },
            { header: 'User', field: 'user_name' },
            { header: 'Description', field: 'description' },
            { header: 'TL', field: 'tl_cost', order: true },
            { header: 'USD', field: 'usd_cost', order: true },
            { header: 'EUR', field: 'eur_cost', order: true },
            { header: 'CHF', field: 'chf_cost', order: true },
            { header: 'GBP', field: 'gbp_cost', order: true },
            { header: 'Gold', field: 'gold_cost', order: true },
            { field: 'admin_description', header: 'Detail', editable: true },
            { header: '-', field: 'delete', isDelete: true },
          ]"
          :endPoint="endPoint"
          :showRoute="false"
          :useCreate="true"
          :key="tableKey"
          @inputEvent="adminDescription"
        />
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="deleteCostDialog"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
  >
    <div class="confirmation-content">
      <span>Are you sure you want to delete?</span>
    </div>
    <template #footer>
      <div class="row justify-content-center">
        <div class="offset-md-8 col-md-2">
          <button
            @click="deleteCostDialog = false"
            type="button"
            class="btn btn-outline-dark d-flex justify-content-center p-button-text"
          >
            No
          </button>
        </div>
        <div class="col-md-2">
          <button
            @click="deleteCost"
            type="button"
            class="btn btn-outline-danger d-flex justify-content-center"
          >
            Yes
          </button>
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script>
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import Menu from "@/components/Menu";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Table from "@/components/Table.vue";

export default {
  components: { Menu, Table },
  data: function () {
    return {
      tableKey: 0,
      endPoint: null,
      showTable: false,
      deleteCostDialog: false,
      costDelete: null,
      sumModel: {},
      endOfDayModel: {
        date: new Date().toLocaleDateString("fr-CA"),
        description: null,
        tl_cost: null,
        usd_cost: null,
        eur_cost: null,
        chf_cost: null,
        gbp_cost: null,
        gold_cost: null,
        store: null,
        user: null,
        cost_type: null,
      },
      costTypeModel: [],
      costGroupData: [],
      store_name: localStorage.getItem("store"),
      user: localStorage.getItem("username"),
      tableData: null,
      dateFilter: null,
      userModel: null,
      columns: [
        // {field:"date", header:"Date"},
        // {field:"cost_type", header:"Type"},
        // {field:"user", header:"User"},
        // {field:"description", header:"Description"},
        { field: "tl", header: "TL" },
        { field: "usd", header: "USD" },
        { field: "eur", header: "EUR" },
        { field: "chf", header: "CHF" },
        { field: "gbp", header: "GBP" },
        { field: "gold", header: "Gold" },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      dateRangeFilter: [new Date(), new Date()],
      loading: false,
      total: {
        tl: null,
        usd: null,
        eur: null,
        chf: null,
        gbp: null,
        gold: null,
      },
      storeModel: [],
      apiFilters: {
        store: null,
        lte__date: null,
        gte__date: null,
        // lte__date:new Date().toISOString().split('T')[0],
        // gte__date:new Date(new Date().getFullYear(),new Date().getMonth(),2).toISOString().split('T')[0],

        cost_type: null,
        cost_group: null,
      },
    };
  },
  end: {
    date: new Date().toLocaleDateString("fr-CA"),
    description: null,
    tl_cost: null,
    usd_cost: null,
    eur_cost: null,
    chf_cost: null,
    gbp_cost: null,
    gold_cost: null,
    store: null,
    user: null,
    cost_type: null,
  },
  created: function () {
    this.end = this.endOfDayModel;
    let yourDate = new Date();
    yourDate = yourDate.toISOString().split("T")[0];
    let dateFilter = "date__lte=" + yourDate + "&date__gte=" + yourDate + "&";
    this.dateFilter = dateFilter;

    getAPI("cost-groups?limit=10000&ordering=name").then((res) => {
      this.costGroupData = res.data.results;
    });
    getAPI("cost-types?limit=10000").then((res) => {
      this.costTypeModel = res.data.results;
    });

    getAPI("user-list?limit=10000").then((res) => {
      this.userModel = res.data.results;
    });

    getAPI("sum-end-of-day?limit=20&").then((res) => {
      this.sumModel = res.data;
    });

    // getAPI('end-of-day?limit=10000&ordering=-id&' + dateFilter).then((res) => {
    //     this.tableData = res.data.results;
    //     this.totalCalculate();
    // })
    getAPI("stores").then((res) => {
      this.storeModel = res.data.results;
    });
  },
  methods: {
    confirmDeleteCost(data) {
      this.deleteCostDialog = true;
      this.costDelete = data;
    },
    deleteCost() {
      deleteAPI("end-of-day/" + this.costDelete["id"]).then((res) => {
        this.deleteCostDialog = false;
        this.tableKey += 1;
      });
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },

    totalCalculate() {
      const value = this.tableData;
      var tl = 0;
      var usd = 0;
      var eur = 0;
      var chf = 0;
      var gbp = 0;
      var gold = 0;

      for (const item of value) {
        tl += item.tl_cost;
        usd += item.usd_cost;
        eur += item.eur_cost;
        chf += item.chf_cost;
        gbp += item.gbp_cost;
        gold += item.gold_cost;
      }
      this.total.tl = tl;
      this.total.usd = usd;
      this.total.eur = eur;
      this.total.chf = chf;
      this.total.gbp = gbp;
      this.total.gold = gold;
    },
    filterSubmit() {
      let filters = this.apiFilters;
      let queryList = [
        "store__id__in=" + filters.store,
        "date__lte=" + filters.lte__date,
        "date__gte=" + filters.gte__date,
        "cost_type__id__in=" + filters.cost_type,
        "cost_type__group__id__in=" + filters.cost_group,
      ];
      let query = queryList.join("&").replaceAll("null", "");
      this.endPoint = "end-of-day?ordering=-date,-id&" + query;

      getAPI("sum-end-of-day?limit=20&" + query).then((res) => {
        this.sumModel = res.data;
      });

      this.tableKey += 1;
      this.showTable = true;
      // getAPI('end-of-day?limit=10000&ordering=-id&'+query).then( (res) => {
      //     this.tableData = res.data.results
      // } )
    },
    adminDescription(row) {
      try {
        let payload = { admin_description: row.admin_description };
        patchAPI("end-of-day/" + row.id, payload);
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Description updated",
          life: 1000,
        });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
.endTable td {
  font-size: smaller;
}
</style>
