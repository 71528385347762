<template>
  <Toast />
  <Menu />

  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>Upload Product Photo</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />

    <div class="row">
      <div class="col-md-12">
        <input
          ref="uploader"
          type="file"
          class="form-control"
          accept="image/jpg,image/jpeg,image/png"
          @change="uploadImage"
          multiple
        />
      </div>
    </div>

    <div class="row mt-4 justify-content-center">
      <div class="col-md-2 d-grid gap-2">
        <button class="btn btn-secondary" @click="upload">Upload</button>
      </div>
    </div>

    <div class="row" v-if="'target' in this.files">
      <span><strong>Results</strong></span>
      <span
        >Upload Rate: {{ errorList.length || 0 + successList.length || 0 }} /
        {{ this.files.target.files.length }}</span
      >
      <span
        >Successful Images ( {{ successList.length }} ):
        <span>{{ successList }}</span></span
      >
      <span
        >Errors ( {{ errorList.length }} ): <span>{{ errorList }}</span></span
      >
    </div>

    <!-- <div class="row mt-3 justify-content-center">
        <div class="col-md-10">
            <table class="table table-bordered" v-if="files">
            <thead>
                <tr>
                <th scope="col">File Name</th>
                <th scope="col">Reference</th>
                <th scope="col">Status</th>


                </tr>
            </thead>
            <tbody>
                <tr v-for="image in files.target.files" :key="image">
                <td >{{image.name}}</td>
                <td >{{image.name.replace(/\.[^/.]+$/, "")}}</td>

                <td v-if="image['status'] ==  1" style="color:green"> <strong>Success</strong> </td>
                <td v-if="image['status'] == 3">{{image["status"] || 0}}</td>

                </tr>
                
            </tbody>
            </table>
        </div>
       </div>      -->
  </div>
</template>

<script>
import {
  getAPI,
  postAPI,
  patchAPI,
  deleteAPI,
  uploadProductPhoto,
} from "@/global/callAPI";
import Menu from "@/components/Menu";
import Table from "@/components/Table";
export default {
  name: "UploadPhoto",
  components: { Menu, Table },
  data: function () {
    return {
      previewImage: null,
      files: {},
      successList: [],
      errorList: [],
    };
  },

  created: function () {},

  methods: {
    uploadImage(event) {
      this.errorList = [];
      this.successList = [];
      this.files = event;
    },

    upload() {
      this.errorList = [];
      this.successList = [];
      for (let i = 0; i < this.files.target.files.length; i++) {
        let data = new FormData();
        data.append("name", "product-picture");
        data.append("file", this.files.target.files[i]);
        console.log(data);
        uploadProductPhoto(data).then((res) => {
          if (res.data.success === true) {
            this.successList.push(this.files.target.files[i].name);
          }
          if (res.data.success === false) {
            this.errorList.push({
              image: this.files.target.files[i].name,
              result: res.data.message,
            });
          }
          // console.log(this.files.target.files[i]["status"])
        });
      }
    },
  },
};
</script>

<style></style>
