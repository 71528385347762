<template>
<CatalogGallery :products="mainCatalogModel" />

</template>

<script>
import CatalogGallery from '@/views/Catalog/CatalogGallery';
import {getAPI} from '@/global/callAPI';

export default {
    data: function (){ return{
        mainCatalogModel: null,
    }},
    components: {CatalogGallery},
    created: function() {
        getAPI("catalog/detail/" + this.$route.params.id).then(res => this.mainCatalogModel = res.data.products)


    },
}
</script>

<style>

</style>