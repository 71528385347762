<template>
  <Toast />
  <Menu />

  <div class="container-fluid">
    <h5 class="text-center font-weight-bold text-info text-dark" style="">
      {{ transfer.mode.charAt(0).toUpperCase() + transfer.mode.slice(1) }}:
      {{ transfer.id }}
    </h5>
    <hr />
    <div class="row"></div>
    <div class="row justify-content-center">
      <div class="col-md-2">
        <label for="descriptionInput">Id</label>
        <input
          type="text"
          class="form-control"
          name="Id"
          v-model="transfer.id"
          id="descriptionInput"
          disabled
        />
      </div>
      <div class="col-md-2">
        <label for="receiverInput">Status</label>
        <input
          type="text"
          class="form-control"
          name="Id"
          v-model="transfer.status"
          id="receiverInput"
          disabled
        />
      </div>
      <div class="col-md-2">
        <label for="descriptionInput">Create Date</label>
        <input
          type="text"
          class="form-control"
          name="Id"
          v-model="transfer.created_date"
          id="descriptionInput"
          disabled
        />
      </div>
      <div class="col-md-1 align-self-end">
        <button @click="excelExport" type="button" class="btn btn-success">
          Excel Export
        </button>
      </div>
      <div
        class="col-md-1 align-self-end"
        v-show="hasPermission(['limited_seller', 'seller', 'office'])"
      >
        <button
          @click="deleteDialog = true"
          type="button"
          class="btn btn-danger"
        >
          Delete
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-2">
        <label for="storeInput">Sender Store</label>
        <input
          type="text"
          class="form-control"
          name="Id"
          v-model="transfer.sender_store_name"
          id="senderStore"
          disabled
        />
      </div>
      <div class="col-md-2">
        <label for="senderInput">Sender</label>
        <input
          type="text"
          class="form-control"
          name="Id"
          v-model="transfer.sender_name"
          id="senderInput"
          disabled
        />
      </div>
      <div class="col-md-2">
        <label for="storeInput">Receiver Store</label>
        <input
          type="text"
          class="form-control"
          name="Id"
          v-model="transfer.target_store_name"
          id="targetStore"
          disabled
        />
      </div>
      <div class="col-md-2">
        <label for="receiverInput">Receiver</label>
        <input
          type="text"
          class="form-control"
          name="Id"
          v-model="transfer.receiver_name"
          id="receiverInput"
          disabled
        />
      </div>
    </div>

    <div class="row justify-content-center">
      <!-- Header text area -->
      <div class="col-md-2" v-if="transfer.mode === 'order'">
        <label for="headerInput">Header</label>
        <input
          type="text"
          class="form-control"
          name="header"
          v-model="transfer.order_header"
          id="headerInput"
          disabled
        />
      </div>
      <div class="col-md-2" v-if="transfer.mode === 'order'">
        <!-- start_date -->
        <label for="dateInput">Start Date</label>
        <input
          type="date"
          class="form-control"
          name="date"
          v-model="transfer.start_date"
          id="dateInput"
          disabled
          required
        />
      </div>
      <div class="col-md-2" v-if="transfer.mode === 'order'">
        <!-- end_date -->
        <label for="dateInput">End Date</label>
        <input
          type="date"
          class="form-control"
          name="date"
          v-model="transfer.end_date"
          id="dateInput"
          disabled
          required
        />
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8">
        <label for="status">Description</label>
        <textarea
          type="text"
          class="form-control"
          name="Description"
          v-model="transfer.description"
          id="status"
          @change="updateDescription"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <!-- <TabMenu 
                :model="items"
                            /> -->
      <!-- Transfer Product Table -->

      <div class="col-md-1 align-self-end">
        <h5
          class="text-left text-info text-dark"
          style="margin-top: 10px; font-size: 13px"
        >
          Products
        </h5>
      </div>

      <!-- <div class="col-md-7"></div> -->
      <div class="col-md-6"></div>
      <div class="align-items-end col-md-1 mt-1 mb-1" text-right>
        <button
          v-if="hasPermission(['limited_seller', 'seller', 'office'])"
          class="btn btn-primary"
          @click="acceptAll()"
        >
          Accept All
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8">
        <table id="transferProductsTable" class="table table-bordered">
          <thead>
            <tr>
              <th style="width: 2%" scope="column">#</th>
              <th
                style="width: 10%"
                scope="column"
                v-if="transfer.mode === 'transfer'"
              >
                Thumbnail
              </th>
              <th
                style="width: 20%"
                scope="column"
                v-if="transfer.mode === 'transfer'"
              >
                Reference No
              </th>
              <th style="width: 49%" scope="column">Description</th>
              <th style="width: 14%; text-align: center" scope="column">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(d, i) in transfer.products" :key="i">
              <td style="text-align: center">
                {{ i + 1 }}
              </td>
              <td v-if="transfer.mode === 'transfer'">
                <Image
                  v-bind:src="d.thumbnail"
                  alt="Image"
                  width="80"
                  preview
                />
              </td>

              <td v-if="transfer.mode === 'transfer'">
                <div class="form-group">
                  <!-- <select id="products" v-model="d.product" class="form-control" disabled>
                                        <option value="0">-Select-</option>
                                        <option v-for="p in productModel" :key="p.product_id" v-bind:value="p.product_id" >{{p.reference_no}}</option>
                                    </select> -->
                  <input
                    type="text"
                    class="form-control"
                    v-model="d.reference_no"
                    disabled
                  />
                </div>
              </td>

              <td>
                <div class="form-group">
                  <textarea
                    type="text"
                    id="productDescription"
                    v-model="d.description"
                    class="form-control"
                    readonly
                  />
                </div>
              </td>

              <td style="text-align: center">
                <!-- <button @click="removeProduct(i)" type="button" id="remove-button" class="btn btn-outline-dark btn-sm">
                                    <span class="material-icons-outlined">delete</span>
                                </button> -->
                <div
                  class="completed text-success"
                  v-if="d.is_completed === true"
                >
                  Completed
                </div>
                <div class="canceled text-danger" v-if="d.is_canceled === true">
                  Canceled
                </div>

                <div
                  class="row justify-content-center"
                  v-if="d.is_canceled != true && d.is_completed != true"
                >
                  <div class="col-md-6">
                    <!-- <input class="form-check-input"  type="checkbox" value="true" id="checkbox1">
                                            <label for="checkbox" style="color:green">Accept</label> -->
                    <button
                      type="button"
                      @click="productCompleted(d, i)"
                      class="btn btn-outline-success btn-sm"
                    >
                      <span class="material-icons-outlined">done</span>
                    </button>
                  </div>

                  <div class="col-md-6">
                    <!-- <input class="form-check-input" type="checkbox" value="true" id="checkbox1">
                                            <label for="checkbox" style="color:red">Cancel</label> -->
                    <button
                      type="button"
                      @click="productCanceled(d, i)"
                      class="btn btn-outline-danger btn-sm"
                    >
                      <span class="material-icons-outlined">close</span>
                    </button>
                  </div>
                </div>
                <!-- <button type="button" v-on:click="removeProduct(i)" class="btn btn-danger" tabindex="99" >
                                    <span aria-hidden="true">&minus;</span>
                                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--  END Transfer Product Table -->
    </div>

    <!-- <div v-if="transfer.products.length > 0">
			<button id="form-submit" >Submit</button>
		</div> -->
  </div>

  <Dialog
    v-model:visible="deleteDialog"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
  >
    <div class="confirmation-content">
      <span>Are you sure you want to delete?</span>
    </div>
    <template #footer>
      <div class="row justify-content-center">
        <div class="offset-md-8 col-md-2">
          <button
            @click="deleteDialog = false"
            type="button"
            class="btn btn-outline-dark d-flex justify-content-center p-button-text"
          >
            No
          </button>
        </div>
        <div class="col-md-2">
          <button
            @click="deleteTransfer"
            type="button"
            class="btn btn-outline-danger d-flex justify-content-center"
          >
            Yes
          </button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import {
  getAPI,
  postAPI,
  patchAPI,
  deleteAPI,
  transferExcelExport,
} from "@/global/callAPI";
import Menu from "@/components/Menu";
import SelectProduct from "@/components/SelectProduct.vue";

export default {
  name: "NewTransfer",
  components: { Menu, SelectProduct },
  data: function () {
    return {
      deleteDialog: false,
      items: [{ label: "Products", icon: "pi pi-fw pi-tag" }],
      autoMultiplier: null,
      productModel: [],
      selectedProduct: null,
      storeModel: null,
      displayBasic: false,
      multiProduct: null,
      transfer: {
        target_store: null,
        description: null,
        products: [],
      },
      transferProduct: {
        product: null,
        description: null,
      },
      completed: "Completed",
      canceled: "Rejected",
    };
  },
  created: function () {
    // getAPI('stores?limit=10000').then((res) => {this.storeModel = res.data.results});
    // getAPI('product-search?ordering=-product_id&limit=100&active=true').then((res) => {this.productModel = res.data.results});
    getAPI("transfers/" + this.$route.params.id).then((res) => {
      this.transfer = res.data;
      let date = this.transfer.created_date;
      let cDate = new Date(date);
      this.transfer.created_date = cDate.toLocaleDateString();
    });
  },

  methods: {
    rejectAll() {
      function reject(route, transfer) {
        let data = { is_canceled: true };
        patchAPI("transfer/update/" + route.params.id, data).then((res) => {
          if (res) {
            transfer.is_canceled = true;
            transfer.products.forEach((element) => {
              if (element.is_completed == false) {
                element.is_canceled = true;
              }
            });
          }
        });
      }

      Swal.fire({
        title: "Are you sure reject all products?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reject!",
      }).then((result) => {
        if (result.value) {
          try {
            reject(this.$route, this.transfer);
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: `Transfer rejected!`,
              life: 1000,
            });
          } catch (error) {
            this.$toast.add({
              severity: "errror",
              summary: "Error",
              detail: `Proccess failed!`,
              life: 1000,
            });
          }
        }
      });
    },
    addProduct: function (e) {
      e.preventDefault();
      let detail = {
        product: null,
        description: null,
      };
      this.transfer.products.push(detail);
    },

    removeProduct: function (id) {
      this.transfer.products.splice(id, 1);
    },
    openBasic() {
      this.displayBasic = true;
    },
    closeBasic() {
      this.displayBasic = false;
    },

    productCompleted(data, index) {
      const payload = { is_completed: true };
      let id = data.id;
      let status = null;

      // try {
      patchAPI("transfer/update/" + id, payload)
        // .then(res => status = res.status)
        .then((res) => {
          status = res.status;
          if (String(status).charAt(0) === "2") {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: `Product accepted!`,
              life: 1000,
            });
            this.transfer.products.find(
              (item) => item.id === id
            ).is_completed = true;
          } else if (String(status).charAt(0) === "4") {
            this.$toast.add({
              severity: "error",
              summary: "Permission Error",
              detail: `You don't have permission or try login again!`,
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: `Process failed. Try again!`,
              life: 1000,
            });
          }
        });
    },
    productCanceled(data, index) {
      const payload = { is_canceled: true };
      let id = data.id;
      patchAPI("transfer/update/" + id, payload).then((res) => {
        let status = res.status;
        if (String(status).charAt(0) === "2") {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: `Product canceld!`,
            life: 1000,
          });
          this.transfer.products.find(
            (item) => item.id === id
          ).is_canceled = true;
        } else if (String(status).charAt(0) === "4") {
          this.$toast.add({
            severity: "error",
            summary: "Permission Error",
            detail: `You don't have permission or try login again!`,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: `Process failed. Try again!`,
            life: 1000,
          });
        }
      });
    },
    excelExport: function () {
      transferExcelExport(this.$route.params.id).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          this.transfer.id + "-referral-form" + ".xlsx"
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }
      let user_groups = this.$store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },
    deleteTransfer() {
      const transferId = this.$route.params.id;
      console.log(transferId);
      deleteAPI("transfers/" + transferId).then((res) => {
        if (res["status"] === 204) {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: `Transfer Deleted!`,
            life: 1000,
          });
          this.$router.push("/home");
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: res.statusText,
            life: 1000,
          });
          this.deleteDialog = false;
        }
      });
    },
    accept(route, transfer) {
      let data = { is_completed: true };
      transfer.products.forEach((element) => {
        console.log(element);
        if (element.is_canceled == false) {
          this.productCompleted(element, 3);
        }
      });
    },
    acceptAll(route, transfer) {
      // function accept(route, transfer) {
      //     let data = { is_completed: true }
      //     transfer.products.forEach(element => {
      //             console.log(element)
      //         if (element.is_canceled == false) {

      //             productCompleted(element,3)

      //             }
      //     });
      //     // patchAPI("transfer/update/" + route.params.id, data)
      //     //     .then(res => {
      //     //         if (res) {
      //     //             transfer.is_completed = true;
      //     //             transfer.products.forEach(element => {
      //     //                 if (element.is_canceled == false) { element.is_completed = true }
      //     //             });
      //     //         }

      // };
      Swal.fire({
        title: "Are you sure accept all products?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, accept!",
      }).then((result) => {
        if (result.value) {
          try {
            this.accept(this.$route, this.transfer);
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: `Transfer accepted!`,
              life: 1000,
            });
          } catch (error) {
            console.log(error);
            this.$toast.add({
              severity: "errror",
              summary: "Error",
              detail: `Proccess failed!`,
              life: 1000,
            });
          }
        }
      });
    },
    updateDescription() {
      const payload = { description: this.transfer.description };
      patchAPI("transfers/" + this.$route.params.id, payload).then((res) => {
        if (res.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: `Description updated!`,
            life: 1000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: `Process failed. Try again!`,
            life: 1000,
          });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
hr {
  border-top: 1px solid black;
}

#remove-button {
  border: 0px;
}

.submit-col {
  display: flex;
}

#submitAll {
  align-self: flex-end;
  width: auto;
}

input[type="checkbox"]#checkbox1:checked + span {
  border-color: red;
  background-color: red;
}
</style>
