<template>
  <Menu />
  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>Manufacturer List</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />

    <div class="row justify-content-center">
      <div class="col-md-2">
        <label for="">Firm Name</label>
        <input type="text" class="form-control" v-model="filters.firm_name" />
      </div>

      <div class="col-md-2">
        <label for="">Contact Name</label>
        <input
          type="text"
          class="form-control"
          v-model="filters.name_surname"
        />
      </div>

      <div class="col-md-2">
        <label for="typeInput">Type</label>
        <select
          id="typeInput"
          v-model="filters.type"
          class="form-control"
          required
        >
          <option
            v-bind:value="t.manufacturer_type_id"
            v-for="t in typeModel"
            :key="t.manufacturer_type_id"
          >
            {{ t.manufacturer_type }}
          </option>
        </select>
      </div>

      <div class="col-md-2 my-3">
        <button id="" class="btn btn-outline-secondary" @click="applyFilter">
          Filter
        </button>
      </div>
    </div>

    <div class="row justify-content-center mt-5">
      <div class="col-md-6">
        <small
          >The data in the <b>Total</b> row changes only when the firm
          changes.</small
        >
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">TL</th>
              <th scope="col">USD</th>
              <th scope="col">EUR</th>
              <th scope="col">CHF</th>
              <th scope="col">GBP</th>
              <th scope="col">ALTIN</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row justify-content-center">Subtotal</th>
              <td>{{ sumModel["TL"] || null }}</td>
              <td>{{ sumModel["USD"] || null }}</td>
              <td>{{ sumModel["EUR"] || null }}</td>
              <td>{{ sumModel["CHF"] || null }}</td>
              <td>{{ sumModel["GBP"] || null }}</td>
              <td>{{ sumModel["ALTIN"] || null }}</td>
            </tr>
            <!-- <tr>
              <th scope="row justify-content-center">Total</th>
              <td>{{ grandTotal["TL"] || null }}</td>
              <td>{{ grandTotal["USD"] || null }}</td>
              <td>{{ grandTotal["EUR"] || null }}</td>
              <td>{{ grandTotal["CHF"] || null }}</td>
              <td>{{ grandTotal["GBP"] || null }}</td>
              <td>{{ grandTotal["ALTIN"] || null }}</td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
    <div class="row justify-content-center" v-if="showTable">
      <div class="col-md-11">
        <Table
          v-if="showTable"
          excelName="manufacturer-export"
          excelTabName="Manufacturers"
          :tableInfo="tableInfo"
          :endPoint="endPoint"
          showRoute="true"
          detailPath="/manufacturers/"
          idName="manufacturer_id"
          :useCreate="true"
          :key="tableKey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { getAPI } from "@/global/callAPI";
import Table from "@/components/Table.vue";

export default {
  name: "ManufacturerList",
  components: { Menu, Table },
  data: function () {
    return {
      typeModel: [],
      sumModel: {},
      grandTotal: {},
      filters: {
        type: null,
        firm_name: null,
        name_surname: null,
      },
      endPoint: "manufacturers?",
      tableKey: 0,
      showTable: false,
      tableInfo: [
        { header: "Id", field: "manufacturer_id", order: true },
        { header: "Type", field: "type_name" },
        { header: "Firm Name", field: "firm_name", order: true },
        { header: "Contact Name", field: "name_surname" },
        { header: "Phone", field: "phone", order: true },
      ],
    };
  },
  methods: {
    applyFilter() {
      let firm_name = this.filters.firm_name || "";
      let name_surname = this.filters.name_surname || "";
      let type = this.filters.type || "";

      let queryList = [
        "firm_name__icontains=" + firm_name,
        "name_surname__icontains=" + name_surname,
        "type_id=" + type,
      ];
      let query = queryList.join("&");

      const transactionQueryList = [
        "firm__firm_name__icontains=" + firm_name,
        "firm__name_surname__icontains=" + name_surname,
        "firm__type_id=" + type,
      ];
      const transactionQuery = transactionQueryList.join("&");
      getAPI("sum-accounting-transactions?limit=1&" + transactionQuery).then(
        (res) => {
          this.sumModel = res.data;
        }
      );
      let endPoint = "manufacturers?" + query;
      this.endPoint = endPoint;
      this.tableKey += 1;
      this.showTable = true;
    },
  },
  created: function () {
    getAPI("manufacturer-types?limit=1000").then(
      (res) => (this.typeModel = res.data.results)
    );
  },
};
</script>

<style scoped></style>
