<template>
  <Menu />

  <AddProperty
    endPoint="cost-groups"
    pageName="Add End of Day Expense Group"
    fieldName="name"
  />
</template>

<script>
import Menu from "@/components/Menu";
import AddProperty from "@/components/AddProperty.vue";
export default {
  components: { Menu, AddProperty },
  data: function () {
    return {};
  },
};
</script>

<style></style>
