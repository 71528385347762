<template>
  <Toast />
  <div class="container-fluid">
    <Menu />
    <TabMenu :model="items" v-model:activeIndex="activeIndex" />
    <router-view />
    <!-- <h3 class="text-left font-weight-bold text-info text-dark"></h3> -->
    <h6
      class="text-center font-weight-bold text-info text-dark mt-3 mb-3"
      style="font-size: 15px; margin-bottom: -35px"
    >
      Customer:
      <strong>
        {{ customerModel.customer_name }}
        {{ customerModel.customer_surname }}</strong
      >
    </h6>

    <!-- <hr style="height:0.5px;margin-bottom:30px"> -->
    <div v-if="activeIndex === 0" id="editPage">
      <form @submit.prevent="submitCustomer" id="createCustomerModel">
        <div class="row mt-2">
          <div
            class="col-md-2 offset-md-11"
            v-show="hasPermission(['limited_seller', 'seller', 'office'])"
          >
            <button
              @click="deleteDialog = true"
              type="button"
              class="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <!-- Name Input -->
          <div class="col-md-2">
            <label for="nameInput">Name</label>
            <input
              class="form-control"
              type="text"
              name="Customer Name"
              id="nameInput"
              v-model="customerModel.customer_name"
              required
            />
          </div>
          <!-- Surname Input -->
          <div class="col-md-2">
            <label for="surnameInput">Surname</label>
            <input
              type="text"
              name="Surname"
              id="surnameInput"
              class="form-control"
              v-model="customerModel.customer_surname"
              required
            />
          </div>

          <div v-if="NewtagModel" class="col-md-2">
            <label for="customerTag">Tags</label>

            <v-select
              :options="NewtagModel"
              label="name"
              v-model="customerModel.tags"
              id="customerTag"
              :reduce="(tag) => tagConverter(tag.id)"
              :multiple="true"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <!-- Mobile Phone Input -->
          <div class="col-md-2">
            <label for="phoneInput">Mobile Phone</label>
            <input
              type="text"
              name="Mobile Phone"
              id="phoneInput"
              class="form-control"
              v-model="customerModel.gsm"
            />
          </div>
          <!-- Other Phone Input -->
          <div class="col-md-2">
            <label for="otherPhoneInput">Other Phone</label>
            <input
              type="text"
              name="Other Phone"
              id="otherPhoneInput"
              class="form-control"
              v-model="customerModel.phone1"
            />
          </div>

          <!-- Business Phone -->
          <div class="col-md-2">
            <label for="businessPhone">Business Phone</label>
            <input
              type="text"
              name="Business Phone"
              class="form-control"
              id="businessPhone"
              v-model="customerModel.business_phone"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <!-- Fax Input -->
          <div class="col-md-2">
            <label for="faxInput">Fax</label>
            <input
              type="text"
              name="Fax"
              class="form-control"
              id="faxInput"
              v-model="customerModel.fax"
            />
          </div>
          <!-- Address Input -->
          <div class="col-md-4">
            <label for="addressInput">Address</label>
            <input
              type="text"
              name="Address"
              class="form-control"
              id="addressInput"
              v-model="customerModel.address"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <!-- Disctrict Input -->
          <div class="col-md-2">
            <label for="districtInput">District</label>
            <input
              type="text"
              name="Disctrict"
              class="form-control"
              id="districtInput"
              v-model="customerModel.district"
            />
          </div>

          <!-- City Input -->
          <div class="col-md-2">
            <label for="cityInput">City</label>
            <input
              type="text"
              name="City"
              class="form-control"
              id="cityInput"
              v-model="customerModel.city"
            />
          </div>

          <!-- Country Input -->

          <div class="col-md-2">
            <label for="countryInput">Country</label>
            <input
              type="text"
              name="Country"
              class="form-control"
              id="countryInput"
              v-model="customerModel.country"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <!-- Tax Id Input -->

          <div class="col-md-2">
            <label for="taxIdInput">Tax Id</label>
            <input
              type="text"
              name="Tax Id"
              class="form-control"
              id="taxIdInput"
              v-model="customerModel.tax_id"
            />
          </div>
          <div class="col-md-4">
            <!-- Business Address Input -->

            <label for="businessAddressInput">Business Address</label>
            <input
              type="text"
              name="Business Address"
              class="form-control"
              id="businessAddressInput"
              v-model="customerModel.business_address"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <!-- Business Disctrict Input -->
          <div class="col-md-2">
            <label for="businessDistrictInput">Business District</label>
            <input
              type="text"
              name="Business District"
              class="form-control"
              id="businessDistrictInput"
              v-model="customerModel.business_district"
            />
          </div>

          <!-- Business City Input -->
          <div class="col-md-2">
            <label for="businessCityInput">Business City</label>
            <input
              type="text"
              name="Business City"
              class="form-control"
              id="businessCityInput"
              v-model="customerModel.business_city"
            />
          </div>

          <!-- Business Country Input -->

          <div class="col-md-2">
            <label for="businessCountryInput">Business Country</label>
            <input
              type="text"
              name="Business Country"
              class="form-control"
              id="businessCountryInput"
              v-model="customerModel.business_country"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <!-- Birth Date Input -->
          <div class="col-md-2">
            <label for="birthDateInput">Birth Date</label>
            <input
              type="date"
              name="Birth Date"
              class="form-control"
              id="birthDateInput"
              v-model="customerModel.birth_date"
            />
          </div>

          <!-- Finger Size Input -->
          <div class="col-md-1">
            <label for="fingerSizeInput">Finger Size</label>
            <input
              type="text"
              name="Finger Size"
              class="form-control"
              id="fingerSizeInput"
              v-model="customerModel.finger_size"
            />
          </div>

          <div class="col-md-1">
            <label for="genderInput">Gender</label>
            <select
              name="Gender"
              class="form-control"
              id="genderInput"
              v-model="customerModel.gender"
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <!-- E Mail Input -->
          <div class="col-md-2">
            <label for="emailInput">E-Mail</label>
            <input
              type="email"
              name="E-Mail"
              class="form-control"
              id="emailInput"
              v-model="customerModel.mail"
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <!-- Wedding Date -->
          <div class="col-md-2">
            <label for="weddingDateInput">Wedding Date</label>
            <input
              type="date"
              name="Wedding Date"
              class="form-control"
              id="weddingDateInput"
              v-model="customerModel.wedding_date"
            />
          </div>

          <!-- Partner Birth Date -->
          <div class="col-md-2">
            <label for="partnerBirthDateInput">Partner Birth Date</label>
            <input
              type="date"
              name="Partner Birth Date"
              class="form-control"
              id="partnerBirthDateInput"
              v-model="customerModel.partner_birth_date"
            />
          </div>

          <!-- Partner Email -->
          <div class="col-md-2">
            <label for="partnerEmailInput">Partner E-Mail</label>
            <input
              type="email"
              name="Partner E-Mail"
              class="form-control"
              id="partnerEmailInput"
              v-model="customerModel.partner_mail"
            />
          </div>
        </div>

        <div>
          <div class="row justify-content-center mt-3">
            <div class="col-md-1">
              <button id="" class="btn btn-secondary" type="submit">
                Submit
              </button>
              <!-- <button id="form-submit" type="submit" >Submit</button> -->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-show="activeIndex === 1 || activeIndex === 2" id="salesPage">
      <!-- <Table
        :id="this.$route.params.id"
        :excelName = excelName
        :excelTabName = excelTabName
        /> -->
      <CustomerItemSales
        :id="this.$route.params.id"
        :activeIndex="activeIndex"
      />
    </div>

    <div v-show="activeIndex === 3" id="paymentsPage">
      <CustomerItemPayments :id="this.$route.params.id" />
    </div>
    <div v-if="activeIndex === 4" id="summaryPage">
      <CustomerItemSaleProductSummary :id="this.$route.params.id" />
    </div>
    <div v-if="activeIndex === 5" id="summaryPage">
      <CustomerItemSaleSummary :id="this.$route.params.id" />
    </div>
  </div>
  <Dialog
    v-model:visible="deleteDialog"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
  >
    <div class="confirmation-content">
      <span>Are you sure you want to delete?</span>
    </div>
    <template #footer>
      <div class="row justify-content-center">
        <div class="offset-md-8 col-md-2">
          <button
            @click="deleteDialog = false"
            type="button"
            class="btn btn-outline-dark d-flex justify-content-center p-button-text"
          >
            No
          </button>
        </div>
        <div class="col-md-2">
          <button
            @click="deleteCustomer"
            type="button"
            class="btn btn-outline-danger d-flex justify-content-center"
          >
            Yes
          </button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Menu from "@/components/Menu";
import Table from "@/components/Table";

import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import CustomerItemSales from "@/views/Customer/CustomerItemSales.vue";
import CustomerItemPayments from "./CustomerItemPayments.vue";
import CustomerItemSaleProductSummary from "./CustomerItemSaleProductSummary.vue";
import CustomerItemSaleSummary from "./CustomerItemSaleSummary.vue";
export default {
  name: "CustomerItem",
  components: {
    Menu,
    CustomerItemSales,
    Table,
    CustomerItemPayments,
    CustomerItemSaleProductSummary,
    CustomerItemSaleSummary,
  },
  data: function () {
    return {
      deleteDialog: false,
      activeIndex: 0,
      tableData: [],

      customerModel: {
        customer_name: null,
        customer_surname: null,
        phone_1: null,
        business_phone: null,
        gsm: null,
        fax: null,
        address: null,
        district: null,
        city: null,
        country: null,
        tax_id: null,
        business_address: null,
        business_district: null,
        business_city: null,
        business_country: null,
        birth_date: null,
        wedding_date: null,
        mail: null,
        gender: null,
        finger_size: null,
        partner_birth_date: null,
        partner_mail: null,
        tags: [],
      },
      items: [
        { label: "Edit" },
        { label: "Sale" },
        { label: "Sale Products" },
        { label: "Payments" },
        { label: "Summary (Product)" },
        { label: "Summary (Sale)" },
      ],
      isActive: true,
      sales: null,
      NewtagModel: null,
    };
  },
  created: function () {
    getAPI("customers/" + this.$route.params.id).then((res) => {
      this.customerModel = res.data;
      this.sales = res.data.sales;
      this.customerModel["tags"] = this.customerModel["tags"].map(function (
        item
      ) {
        return { tag: item.tag };
      });
      this.excelName = `${this.customerModel.customer_name} ${this.customerModel.customer_surname} Sales`;
      this.excelTabName = "Sales";
    });
    getAPI("customer-tags?limit=10000&ordering=name").then((res) => {
      this.NewtagModel = res.data.results;
    });
  },

  methods: {
    tabChange: function () {
      if (this.activeIndex == 1) {
        this.isActive = true;
      } else if (this.activeIndex == 1) {
        this.isActive = false;
      }
    },
    submitCustomer: function () {
      let submit = this.customerModel;
      try {
        patchAPI("customers/" + this.$route.params.id, submit);
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Customer updated!",
          life: 1000,
        });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: error,
          life: 3000,
        });
      }
    },
    tagConverter(id) {
      return { tag: id };
    },
    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }
      let user_groups = this.$store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },
    deleteCustomer() {
      const customerId = this.$route.params.id;
      deleteAPI("customers/" + customerId).then((res) => {
        if (res["status"] === 204) {
          Toast2.fire({ icon: "success", title: "Customer Deleted!" });
          this.$router.push("/home");
        } else {
          Toast2.fire({ icon: "warning", title: res.statusText });
          this.deleteDialog = false;
        }
        console.log(productId);
      });
    },
  },
};
</script>

<style scoped>
.row justify-content-center {
  padding: 5px;
}
::v-deep(.tabmenudemo-content) {
  padding: 2rem 1rem;
}
</style>
