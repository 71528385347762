<template>
  <div>
    <Table
      excelName="customer-summary"
      excelTabName="Summary"
      :endPoint="endPoint"
      :tableInfo="tableInfo"
      idName="sale_id"
      :key="tableKey"
      :limit="100000"
      rowClassField="type"
    />
  </div>
</template>
<script>
import Table from "@/components/Table";
// import { getAPI } from "@/global/callAPI";

export default {
  name: "CustomerItemSummary",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    Table,
  },
  data() {
    return {
      items: [],
      tableKey: 0,
      showTable: true,
      endPoint: `customer-summary/${this.id}?type=product`,
      tableInfo: [
        { header: "Type", field: "type" },
        {
          header: "Sale Reference",
          field: "sale_reference",
          isRouter: true,
          routerPath: "/sales/",
          routerField: "sale_id",
        },
        {
          header: "Product Reference",
          field: "product_reference",
          isRouter: true,
          routerPath: "/products/",
          routerField: "product_id",
        },
        { header: "Date", field: "date" },
        { header: "Description", field: "description" },
        {
          header: "Sale Price",
          field: "sale_price",
          customFormat: true,
          customFormatter: this.priceFormatter,
        },
        {
          header: "Payment Price",
          field: "payment_price",
          customFormat: true,
          customFormatter: this.priceFormatter,
        },

        {
          header: "Balance",
          field: "balance",
          customFormat: true,
          customFormatter: this.priceFormatter,
        },
      ],
    };
  },
  methods: {
    priceFormatter(value) {
      if (value === null) {
        return "";
      }
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
  },

  mounted() {
    // getAPI(`customer-summary/${this.id}`).then((res) => {
    //   this.items = res.data.results;
    // });
  },
};
</script>

<style>
.table-striped tbody tr.Sale td {
  color: red !important;
}
.table-striped tbody tr.Payment td {
  color: green !important;
}
</style>
