<template>
        <Menu/>

    <div id="productCatalog" class="container-fluid">
        <TabMenu :model="items" />

        <div class="row justify-content-center">
            <div class="col-md-11">
                <Table
                        excelName = "product-catalog"
                        excelTabName = "Catalog"
                        :tableInfo = "columns"
                        :useCreate = true
                        :showRoute = false
                        :endPoint="endPoint"
                        :customColumn=true
                        />
            </div>
        </div>
<!-- 
        <DataTable  

        selectionMode="single"                   
        :value="catalog" 
        responsiveLayout="scroll">

            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"></Column>
        </DataTable> -->

    </div>

</template>

<script>
import Table from '@/components/Table';

import Menu from '@/components/Menu';
export default {
    name:"ProductCatalog",
    components: {Menu,Table},
    data: function () {
        return {
            endPoint:"catalog-products?product="+this.$route.params.id,
            columns:[
                {field: 'id', header: 'ID',order:true},
                {field: 'name', header: 'Name',order:true},
                {field: 'active', header: 'Is Active'},
                {field: 'created_at', header: 'Date'},
                {field: 'description', header: 'Description'},
                {field: 'price', header: 'Price',order:true},
                ],
    items: [
		{label: 'Edit', icon: 'pi pi-fw pi-pencil', to: "/products/" + this.$route.params.id},
		{label: 'Photo', icon: 'pi pi-fw pi-image', to: '/products/photo/' + this.$route.params.id },
		{label: 'Sale', icon: 'pi pi-fw pi-shopping-cart', to: '/products/sales/' + this.$route.params.id },
		{label: 'Catalog', icon: 'pi pi-fw pi-tags', to: '/products/catalog/' + this.$route.params.id },
        {label: 'Transfer', icon: 'pi pi-fw pi-sync', to: '/products/transfer/' + this.$route.params.id }

        ],
    catalog: [],
    products: null}
    },
    created() {

    // getAPI('products/' + this.$route.params.id).then((res) => {
    //     this.catalog = res.data.catalog; }); 

    //     this.columns = [
    //         {field: 'id', header: 'ID'},
    //         {field: 'name', header: 'Name'},
    //         {field: 'active', header: 'Is Active'},
    //         {field: 'created_at', header: 'Date'},
    //         {field: 'description', header: 'Description'},
    //         {field: 'price', header: 'Price'},
    //     ];
    },

    methods: {

    rowClick(e) {this.$router.push({ path: '/sales/' +e.data.sale_id}) },

    
    }
}
</script>

<style scoped>


</style>