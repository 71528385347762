<template>
  <nav
    class="navbar d-flex align-items-center justify-content-center"
    id="topbar"
  >
    <div class="image">
      <img
        src="@/assets/fancy-logo.png"
        class="img-fluid navbar-brand"
        alt="Responsive image"
      />
    </div>
    <!-- <div class="header-container mt-3">
      <h5 class="header-title">Merchant Dashboard</h5>
    </div> -->
  </nav>
</template>

<script></script>

<style>
.img-fluid {
  width: 100px;
}
</style>
