<template>
  <div
    class="modal fade payment-modal modal-active"
    id="payment"
    tabindex="-1"
    role="dialog"
    aria-labelledby="editLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editLabel">Edit Payment</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            @click="closeModel"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <!-- main product -->
            <div class="col-md-4">
              <div class="form-group">
                <label for="paymentPrice">Payment Price</label>
                <input
                  id="paymentPrice"
                  type="number"
                  placeholder="Price"
                  v-model="payment.price"
                  class="form-control"
                  style="margin-bottom: 10px"
                  required
                />
              </div>

              <label for="paymentType">Payment Type</label>
              <select
                id="paymentType"
                v-model="payment.payment_type"
                class="form-control"
                style="margin-bottom: 10px"
                required
              >
                <option
                  v-bind:value="c.payment_type_id"
                  v-for="c in paymentTypeModel"
                  :key="c.payment_type_id"
                >
                  {{ c.payment_type_name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-4">
              <div class="form-group">
                <label for="originalPayment">Payment Detail</label>
                <input
                  id="originalPayment"
                  type="text"
                  v-model="payment.real_payment"
                  class="form-control"
                  style="margin-bottom: 10px"
                />
              </div>

              <div class="form-group">
                <label for="paymentDate">Payment Date</label>
                <input
                  id="paymentDate"
                  type="date"
                  placeholder="Date"
                  v-model="payment.date"
                  class="form-control"
                  style="margin-bottom: 10px"
                  required
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="form-group col-md-8">
              <label for="originalPayment">Description</label>
              <textarea
                id="originalPayment"
                type="text"
                v-model="payment.description"
                class="form-control"
                style="margin-bottom: 10px"
              >
              </textarea>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="form-check col-md-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="payment.is_return_sale_payment"
                  id="flexCheckDefault"
                />
                <label class="form-check -label" for="flexCheckDefault">
                  Return Payment
                </label>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="form-check col-md-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="payment.is_end_of_day"
                  id="flexCheckDefault"
                />
                <label class="form-check -label" for="flexCheckDefault">
                  End of Day
                </label>
              </div>
            </div>
          </div>

          <div
            class="endofday-container row justify-content-center mt-3"
            v-if="payment.is_end_of_day"
          >
            <div class="col-md-8">
              <div class="form-group row">
                <label for="tlCost" class="text-bold col-md-4">TL Amount</label>
                <div class="col-md-8">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="tlCost"
                    v-model="payment.end_of_day_data.tl_cost"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="usdCost" class="text-bold col-md-4"
                  >USD Amount</label
                >
                <div class="col-md-8">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="usdCost"
                    v-model="payment.end_of_day_data.usd_cost"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="usdCost" class="text-bold col-md-4"
                  >EUR Amount</label
                >
                <div class="col-md-8">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="eurCost"
                    v-model="payment.end_of_day_data.eur_cost"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="usdCost" class="text-bold col-md-4"
                  >CHF Amount</label
                >
                <div class="col-md-8">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="chfCost"
                    v-model="payment.end_of_day_data.chf_cost"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="usdCost" class="text-bold col-md-4"
                  >GBP Amount</label
                >
                <div class="col-md-8">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="gbpCost"
                    v-model="payment.end_of_day_data.gbp_cost"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="usdCost" class="text-bold col-md-4"
                  >GOLD Amount</label
                >
                <div class="col-md-8">
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0.00"
                    id="goldCost"
                    v-model="payment.end_of_day_data.gold_cost"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            @click="paymentSubmit()"
            type="button"
            class="btn btn-primary"
            v-if="modal == 'new'"
          >
            Save changes
          </button>
          <button
            @click="updatePayment()"
            type="button"
            class="btn btn-primary"
            v-if="modal == 'edit'"
          >
            Apply changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAPI, postAPI, patchAPI } from "@/global/callAPI";
export default {
  data() {
    return {
      paymentTypeModel: null,
    };
  },
  props: {
    payment: {
      type: Object,
      default: () => {
        return {
          price: 0,
          payment_type: null,
          real_payment: 0,
          date: new Date().toISOString().substr(0, 10),
          description: null,
        };
      },
    },
    modal: {
      type: String,
      default: "new",
    },
  },
  created() {
    getAPI("payment-types/?limit=10000").then((res) => {
      this.paymentTypeModel = res.data.results;
    });
  },
  methods: {
    closeModel() {
      jQuery("#payment").modal("hide");
    },

    updatePayment() {
      console.log(this.payment);
      patchAPI("payments/" + this.payment.payment_id, this.payment).then(
        (response) => {
          Toast2.fire({
            title: "Payment updated successfully",
          });
          this.$emit("update");
        }
      );

      jQuery("#payment").modal("hide");
    },

    paymentSubmit() {
      postAPI("payments", this.payment).then((response) => {
        Toast2.fire({
          title: "Payment created successfully",
        });
        jQuery("#payment").modal("hide");
        this.$emit("submit");
      });
    },
  },
  emits: ["submit", "update"],
};
</script>
