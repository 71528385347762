<template>


<div class="row">
    <div class="col-md-2">
        <button type="button" @click="exportExcel" class="btn btn-outline-secondary">Export Excel</button>
        
    </div>
</div>

<div class="row gallery-container">
        <!-- <DataTable :value="products" responsiveLayout="scroll"
                    selectionMode="single" class="p-datatable-sm"
                    columnResizeMode="fit" :paginator="true"
                    :rows="20" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[10,20,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    @row-dblclick="rowClick($event)"

                    >
                <Column v-for="item in listItems" :key="item.id" :field="item.value" :header="item.name" :sortable="item.sortable"></Column>

        </DataTable> -->
    </div>



</template>

<script>

export default {
    data: function () {return {
       
    }},
    props: {
        products:{
            type: Array,
            default: null
        },
        listItems:{
            type: Array,
            default: []
        },
    },
    

    methods: {
        toProduct(e) {
            this.$router.push({ path: '/products/'+e.data.product_id })
        },
        exportExcel() {

        },
        
    }

}
</script>

<style>

</style>