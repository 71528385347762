<template>
  <div>
    <List :mode="mode" header="OrderList" />
  </div>
</template>

<script>
import List from "./List.vue";
export default {
  name: "OrderList",
  components: { List },
  data: () => {
    return {
      mode: "order",
    };
  },
};
</script>
