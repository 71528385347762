<template>
  <Menu />
  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      Merchant Settings
    </h6>
    <hr />
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="col-md-12">
          <div class="mb-3">
            <label for="" class="form-label"
              >Add / Delete Product (Reference List)</label
            >
            <textarea
              v-model="reference"
              class="form-control"
              name=""
              id=""
              rows="5"
            ></textarea>
            <small id="helpId" class="form-text text-muted"
              >Enter one reference per line.</small
            >
          </div>
        </div>

        <div class="row mb-2 mt-4">
          <div class="col-md-4 d-flex justify-content-between">
            <button
              type="button"
              id="editStoneTypeButton"
              @click="bulk('add')"
              class="btn btn-success"
            >
              Add
            </button>
            <button
              type="button"
              id="editStoneTypeButton"
              @click="bulk('delete')"
              class="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>

        <div v-if="loading" class="row">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-4 mb-2">
            <div>
              <label class="form-label" for="priceRate"
                >Merchant Price Rate</label
              >

              <input
                type="number"
                id="priceRate"
                v-model="priceRate"
                class="form-control"
              />
              <small id="helpId" class="form-text text-muted"
                >The price displayed in the system will be the product of the
                rate you entered here and the tag price.</small
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <button
                type="button"
                id="editStoneTypeButton"
                @click="updatePriceRate()"
                class="btn btn-secondary"
              >
                Update Price Rate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAPI, postAPI } from "@/global/callAPI";
import Menu from "@/components/Menu";
export default {
  components: { Menu },
  data() {
    return {
      reference: null,
      fileName: "Product Export",
      products: [],
      loading: false,
      totalDuplicate: 0,
      priceRate: null,
    };
  },
  methods: {
    getPriceRate() {
      getAPI("merchant-price-rate").then((res) => {
        this.priceRate = res.data.price_rate;
      });
    },

    updatePriceRate() {
      if (this.priceRate == null || this.priceRate == "") {
        alert("Please enter price rate");
        return;
      }

      const payload = {
        price_rate: this.priceRate,
      };
      const end = "merchant-price-rate";
      postAPI(end, payload)
        .then((res) => {
          Toast2.fire({
            icon: "success",
            title: "Success",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    bulk(action) {
      //control tag
      if (
        this.reference == null ||
        this.reference == "" ||
        this.reference == undefined
      ) {
        alert("Please enter reference");
        return;
      }

      this.loading = true;
      var lines = this.reference.split("\n");
      const products = [...new Set(lines)];
      const payload = {
        products: products,
        action: action,
      };
      const end = "merchant-add-delete-product";
      postAPI(end, payload)
        .then((res) => {
          Toast2.fire({
            icon: "success",
            title: "Success",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  created() {
    this.getPriceRate();
  },
};
</script>

<style></style>
