<template>
  <TopBar />
  <div class="p-md-5 p-2">
    <div class="row mb-5">
      <ProductFilters @filter="getProducts" />
    </div>
    <div class="total-product d-flex">
      <h5 class="">Total Products: {{ totalProduct }}</h5>
    </div>
    <div
      v-if="showLoading === true"
      class="d-flex justify-content-center mt-4 mb-4"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div class="d-flex">
      <div class="row">
        <ProductItem
          v-for="product in products"
          :key="product.product_id"
          :product="product"
          :pageSize="pageSize"
        />
      </div>
    </div>
    <div class="col-12 mt-3">
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="prevPage">Previous</a>
          </li>
          <li
            class="page-item"
            v-for="pageNumber in pagesArray"
            :key="pageNumber"
            :class="{ active: pageNumber === currentPage }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="changePage(pageNumber)"
              >{{ pageNumber }}</a
            >
          </li>
          <li
            class="page-item"
            :class="{ disabled: currentPage === totalPages }"
          >
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import TopBar from "./TopBar.vue";
import ProductItem from "./ProductItem.vue";
import { getACTIVE_IP } from "@/global/callAPI";
import VirtualScroller from "primevue/virtualscroller";
import axios from "axios";
import ProductFilters from "./ProductFilters.vue";
export default {
  name: "MerchantProducts",
  components: {
    TopBar,
    ProductItem,
    VirtualScroller,
    ProductFilters,
  },
  data: () => {
    return {
      products: [],
      totalProduct: 0,
      currentPage: 1,
      pageSize: 30,
      apiFilters: {},
      showLoading: false,
      maxPage: 10,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalProduct / this.pageSize);
    },
    pagesArray() {
      const totalVisiblePages = this.maxPage;
      let startPage = this.currentPage - Math.floor(totalVisiblePages / 2);
      let endPage = this.currentPage + Math.floor(totalVisiblePages / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(totalVisiblePages, this.totalPages);
      } else if (endPage > this.totalPages) {
        endPage = this.totalPages;
        startPage = Math.max(1, this.totalPages - totalVisiblePages + 1);
      }

      const pagesArray = [];
      for (let i = startPage; i <= endPage; i++) {
        pagesArray.push(i);
      }
      return pagesArray;
    },
  },
  methods: {
    changePage(pageNumber) {
      this.currentPage = pageNumber;
      this.getProducts({
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        filters: this.apiFilters,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Animasyonlu kaydırma
      });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.changePage(this.currentPage + 1);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.changePage(this.currentPage - 1);
      }
    },

    getProducts({ limit = this.pageSize, offset = 0, filters = {} }) {
      this.showLoading = true;
      // update query param
      this.$router.push({
        query: {
          ...this.$route.query,
          ...filters,
          limit,
          offset,
        },
      });
      const basePoint = getACTIVE_IP() + "merchant-products";
      this.apiFilters = filters;
      const params = {};
      params.limit = limit;
      params.offset = offset;
      for (const [key, value] of Object.entries(filters)) {
        params[key] = value;
      }

      // use axios get
      let filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, value]) => value !== null)
      );

      let queryParams = new URLSearchParams(filteredParams).toString();
      axios
        .get(`${basePoint}?${queryParams}`)

        .then((res) => {
          this.products = res.data.results;
          this.totalProduct = res.data.count;
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.page-link {
  color: #000000;
}
</style>
