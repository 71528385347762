import axios from "axios";
import store from "@/store";
import { ACTIVE_IP, ACTIVE_CUSTOM_IP } from "@/global/ip";
import router from "@/router";

function catchError(data, responseData) {
  if (data === 403) {
    Toast2.fire({
      icon: "error",
      title: "Permission denied!",
      text: "You don't have permission or try login again.",
    });
  } else if (data === 400) {
    console.log("400 geldi");
    console.log(responseData);
    Toast2.fire({
      icon: "error",
      title: "Failed!",
      text: JSON.stringify(responseData),
    });
  } else if (data === 401) {
    Toast2.fire({
      icon: "error",
      title: "Unauthorized!",
      text: "Please login again.",
    });
    router.push("/login");
  } else {
    Toast2.fire({
      icon: "error",
      title: "Failed!",
      text: "Process failed. Try again.",
    });
  }
}

export const getACTIVE_IP = function () {
  return ACTIVE_IP;
};

export const getAPI = async function (end) {
  let url = ACTIVE_IP + end;
  const response = await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    })
    .catch(function (error) {
      catchError(error.response.status);
    });
  return response;
};
export const postAPI = async function (end, data) {
  let post = await axios
    .post(ACTIVE_IP + end, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
        "Content-Type": "application/json",
      },
    })
    .catch(function (error) {
      catchError(error.response.status, error.response.data);
    });

  return post;
};

// export const postAPI = async function(end, data) {
//     let post = await fetch(ACTIVE_IP + end, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${store.state.accessToken}`
//         },
//         credentials: 'include',
//         body: JSON.stringify(data)
//     })
//     .catch(function(error) {catchError(error.response.status)});

//     return post
// }

export const postProductPhoto = async function (data) {
  let post = await axios
    .post(ACTIVE_IP + "product-photos", data, {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .catch(function (error) {
      catchError(error.response.status);
    });

  return post;
};

export const uploadProductPhoto = async function (data) {
  let post = await axios
    .post(ACTIVE_IP + "upload-product-photo", data, {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .catch(function (error) {
      catchError(error.response.status);
    });

  return post;
};
export const uploadFile = async function (endPoint, data) {
  let post = await axios
    .post(ACTIVE_IP + endPoint, data, {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .catch(function (error) {
      catchError(error.response.status);
    });

  return post;
};

export const postAPICustom = async function (end, data) {
  let post = await fetch(ACTIVE_CUSTOM_IP + end, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(data),
  }).catch(function (error) {
    catchError(error.response.status);
  });

  return post;
};

// export const patchAPI = async function(end, data) {
//     let post = await fetch(ACTIVE_IP + end, {
//             method: 'PATCH',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `Bearer ${store.state.accessToken}`
//             },
//             credentials: 'include',
//             body: JSON.stringify(data)
//         })
//     .catch(function(error) {catchError(error.response.status)});

//     return post

// }
export const patchAPI = async function (end, data) {
  let post = await axios
    .patch(ACTIVE_IP + end, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
        "Content-Type": "application/json",
      },
    })
    .catch(function (error) {
      catchError(error.response.status, error.response.data);
    });

  return post;
};

export const deleteAPI = async function (end) {
  let url = ACTIVE_IP + end;
  const response = await axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    })
    .catch(function (error) {
      catchError(error.response.status);
    });
  return response;
};

export const saleExcelExport = async function (id) {
  const response = await axios({
    url: ACTIVE_IP + "sale-excel/" + id,
    method: "GET",
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  }).catch(function (error) {
    catchError(error.response.status);
  });

  return response;
};

export const proformaExcelExport = async function (data) {
  const response = await axios({
    url: ACTIVE_IP + "sale-excel",
    method: "POST",
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken}`,
    },
    data: data,
  }).catch(function (error) {
    catchError(error.response.status);
  });

  return response;
};

export const exportProducts = async function (data, watch = false) {
  var url = ACTIVE_IP + "export-products";
  if (watch == true) {
    url = ACTIVE_IP + "export-watches";
  }

  let post = await axios
    .post(url, JSON.stringify(data), {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
        "Content-Type": "application/json",
      },
    })
    .catch(function (error) {
      catchError(error.response.status, error.response);
    });

  return post;
};

export const transferExcelExport = async function (id) {
  const response = await axios({
    url: ACTIVE_IP + "transfer-excel/" + id,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
      "Content-Type": "application/json",
    },
  }).catch(function (error) {
    catchError(error.response.status);
  });

  return response;
};

export const excelExport = async function (endPoint) {
  const response = await axios({
    url: ACTIVE_IP + endPoint,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
      "Content-Type": "application/json",
    },
  }).catch(function (error) {
    catchError(error.response.status);
  });

  return response;
};
export const putAPI = async function (end, data) {
  let post = await fetch(ACTIVE_IP + end, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(data),
  }).catch(function (error) {
    catchError(error.response.status);
  });

  return post;
};

// export const getAG = async function(end) {
//     let url = ACTIVE_IP + end
//     const response = await fetch(url)
//     return response
// }
