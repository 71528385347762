<template>
  <Menu />
  <div id="invoiceApp" class="container-fluid" v-if="productModel.jewel">
    <form id="mainProductForm">
      <TabMenu :model="items" />
      <router-view />

      <div class="row mt-2">
        <div
          class="col-md-2 offset-md-11"
          v-show="hasPermission(['limited_seller', 'seller'])"
        >
          <button
            @click="deleteDialog = true"
            type="button"
            class="btn btn-danger"
          >
            Delete
          </button>
        </div>
      </div>

      <div class="row" v-if="productModel.thumbnail">
        <!-- Photo Cell -->
        <div class="col-md-2 offset-md-4 mt-2">
          <!-- <img @click="showImageModel=true" class=" img-thumbnail thumbnail" v-bind:src="productModel.thumbnail" alt="thumbnail"> -->

          <Image
            v-bind:src="productModel.thumbnail"
            alt="Image"
            width="180"
            preview
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="referenceNumber">Reference Number</label>
          <input
            type="text"
            id="referenceNumber"
            v-model="productModel.reference_no"
            class="form-control"
            disabled
          />
        </div>

        <div class="col-md-2">
          <label for="store">Store</label>
          <select
            id="store"
            v-model="productModel.store"
            class="form-control"
            disabled
          >
            <option v-bind:value="s.id" v-for="s in storeModel" :key="s.id">
              {{ s.store_name }}
            </option>
          </select>
        </div>
        <div class="col-md-1">
          <label for="saleStatus">Status</label>
          <input
            type="text"
            id="saleStatus"
            v-model="productModel.sale_status"
            class="form-control"
            disabled
          />
        </div>

        <div class="col-md-2">
          <label for="saleStatus">Created Date</label>
          <input
            type="text"
            id="saleStatus"
            :value="cratedDateShower(productModel.created_date)"
            class="form-control"
            disabled
          />
        </div>
        <div class="form-group col align-self-end col-md-1">
          <button
            @click="submitJewelChanges"
            type="button"
            class="btn btn-secondary"
          >
            Submit Changes
          </button>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="modelNumber">Model Number</label>
          <input
            type="text"
            id="modelNumber"
            v-model="productModel.model_no"
            class="form-control"
            required
          />
        </div>
        <!-- <div class="col-md-2">
				<label for="store">Store</label>
				<select id="store" v-model="productModel.store" class="form-control" disabled>
					<option v-bind:value="s.id" v-for="s in storeModel" :key="s.id">{{s.store_name}}</option>	
				</select>	
			</div> -->

        <div class="col-md-2">
          <label for="firstPrice"> Tag Price</label>
          <input
            type="number"
            step=".01"
            id="firstPrice"
            v-model="productModel.first_price"
            class="form-control"
            required
          />
        </div>

        <div
          class="col-md-2"
          v-if="hasPermission(['limited_seller', 'seller'])"
        >
          <label for="masterPrice"> Master Price</label>
          <input
            type="number"
            id="masterPrice"
            v-model="productModel.jewel[0].master_price"
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-2"></div>

        <!-- <div class="form-group col align-self-end col-md-2"
					v-show="(hasPermission(['limited_seller', 'seller', 'office']) != true)">
				</div> -->
      </div>

      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="firstPrice"> List Price (EUR)</label>
          <input
            type="number"
            id="firstPrice"
            v-model="productModel.second_price"
            class="form-control"
          />
        </div>
        <div class="col-md-2">
          <label for="firstPrice"> List Price (USD)</label>
          <input
            type="number"
            id="firstPrice"
            v-model="productModel.third_price"
            class="form-control"
          />
        </div>
        <div class="col-md-2">
          <label for="firstPrice"> List Price (AED)</label>
          <input
            type="number"
            id="firstPrice"
            v-model="productModel.fourth_price"
            class="form-control"
          />
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="condition">Type</label>
          <select
            id="condition"
            v-model="productModel.jewel[0].type"
            class="form-control"
            required
          >
            <option v-for="t in jewelTypeModel" :key="t.id" v-bind:value="t.id">
              {{ t.name }}
            </option>
          </select>
        </div>
        <div class="col-md-2">
          <label for="productType">Product Type</label>
          <v-select
            :options="producTypeModel"
            v-model="productModel.jewel[0].product_type"
            id="productType"
            label="type_name"
            :multiple="true"
          />
          <!-- <MultiSelect id="productType"  v-model="productModel.jewel[0].product_type" :options="producTypeModel" :filter="true" optionLabel="type_name" /> -->
        </div>

        <div class="col-md-2">
          <label for="subType">Model</label>
          <!-- <MultiSelect id="subType"  v-model="productModel.jewel[0].subtypes" :options="subTypeModel" :filter="true" optionLabel="name" /> -->
          <v-select
            :options="subTypeModel"
            v-model="productModel.jewel[0].subtypes"
            id="subType"
            label="name"
            :multiple="true"
          />
          <!-- <p>{{productModel.jewel[0].subtypes}}</p> -->
        </div>

        <div v-if="newTagModel" class="col-md-2">
          <!-- getapi yi değiştirmeyi unutma -->
          <label for="productTag">Tags</label>
          <v-select
            :options="newTagModel"
            label="name"
            v-model="productModel.tags"
            :reduce="(tag) => tagConverter(tag.id)"
            :multiple="true"
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="goldKarat">Gold Karat</label>

          <select
            id="manufacturerType"
            v-model="productModel.jewel[0].karat"
            class="form-control"
          >
            <option :value="null" selected></option>
            <option v-for="k in karatModel" :key="k.id" v-bind:value="k.id">
              {{ k.name }}
            </option>
          </select>

          <!-- <input type="text" id="goldKarat" v-model="productModel.jewel[0].gold_karat" class="form-control"> -->
        </div>

        <div class="col-md-2">
          <label for="goldWeight">Gross Weight</label>
          <input
            type="text"
            id="goldWeight"
            v-model="productModel.jewel[0].gold_weight"
            class="form-control"
          />
        </div>

        <div class="col-md-2">
          <label for="netWeight">Net Weight</label>
          <input
            type="text"
            id="netWeight"
            v-model="productModel.jewel[0].net_weight"
            class="form-control"
          />
        </div>
        <!-- <div class="col-md-2">
					<label for="goldPrice">Gold Price</label>
					<input type="text" id="goldPrice" v-model="productModel.jewel[0].gold_price" class="form-control">
				</div> -->
        <div class="col-md-2 flex-column d-flex">
          <div class="mb-2" v-if="hasPermission(['limited_seller', 'seller'])">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="productModel.jewel[0].show_master_price"
              id="stock"
            />
            <label class="form-check-label" style="margin-left: 4px" for="stock"
              >Master Price</label
            >
          </div>

          <div class="mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="productModel.active"
              id="stock"
            />
            <label class="form-check-label" style="margin-left: 4px" for="stock"
              >Stock</label
            >
          </div>

          <div class="">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="productModel.show_in_merchant"
              id="merchantCheck"
            />
            <label class="form-check-label" style="margin-left: 4px" for="stock"
              >Show To Merchant</label
            >
          </div>
        </div>
        <!-- <div class="col-md-2 my-4">
					<input class="form-check-input" type="checkbox" v-model="productModel.active" id="stock">
					<label class="form-check-label" style="margin-left:4px;" for="stock">Stock</label>
				</div> -->
      </div>

      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="productionCode">Production Code</label>
          <input
            type="text"
            id="productionCode"
            v-model="productModel.production_code"
            class="form-control"
          />
        </div>

        <div class="col-md-2">
          <label for="ticketInfo">Ticket Info</label>
          <input
            type="text"
            id="ticketInfo"
            v-model="productModel.ticket_info"
            class="form-control"
          />
        </div>
        <div class="col-md-3">
          <label for="description">Description</label>
          <textarea
            type="text"
            id="description"
            v-model="productModel.description"
            class="form-control"
          ></textarea>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row justify-content-center">
        <!-- <div class="col-md-9">
                        <label for="tagsInput">Tags</label>
 							<Chips id="tagsInput" v-model="productModel.tag" class="form-control p-fluid" style="height:50px; border-style: none;" />
				</div> -->
      </div>

      <div class="row justify-content-center justify-content-center mt-3">
        <!-- Manufacturer -->

        <div class="col-md-5">
          <div>
            <p class="text-left text-bold text-info text-dark">
              Manufacturers
              <small class="form-text text-muted"
                >(If the manufacturer is empty, that line is deleted!)</small
              >
            </p>
            <!-- <button @click="manufShowClick" type="button" class="btn btn-outline-dark" style="font-size: 12px; padding: 6px 10px;margin-left:5px" >{{manufShowLabel}}</button> -->
          </div>
          <table id="manuf-table" class="table table-bordered">
            <thead>
              <tr class="text-center">
                <th>Type</th>
                <th>Manufacturer</th>
                <th>Price</th>
                <th>
                  <button
                    type="button"
                    v-on:click="addItem"
                    class="btn btn-success btn-sm"
                  >
                    <span area-hidden="true">&plus;</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in productModel.manufacturers" :key="i" style>
                <td width="20%">
                  <div class="form-group">
                    <select
                      id="manufacturerType"
                      v-model="d.type"
                      class="form-control"
                    >
                      <option value="0">-Select-</option>
                      <option
                        v-for="p in manufactureTypes.results"
                        :key="p.manufacturer_type_id"
                        v-bind:value="p.manufacturer_type_id"
                      >
                        {{ p.manufacturer_type }}
                      </option>
                    </select>
                  </div>
                </td>
                <td width="20%">
                  <div class="form-group">
                    <!-- <select id="products" v-model="d.manufacturer" class="form-control" >
							<option value="0">-Select-</option>
							<option v-for="p in manufacturers" :key="p.manufacturer_id" v-bind:value="p.manufacturer_id" >{{p.name_surname}}</option>
						</select> -->
                    <v-select
                      id="manufacturer"
                      :options="manufacturers"
                      label="firm_name"
                      v-model="d.manufacturer"
                      :reduce="(manufacturer) => manufacturer.manufacturer_id"
                    >
                    </v-select>
                  </div>
                </td>
                <td width="15%">
                  <div class="form-group">
                    <input
                      type="number"
                      id="Price"
                      v-model="d.price"
                      class="form-control"
                    />
                  </div>
                </td>
                <td width="10%">
                  <!-- <a href="">Delete</a> -->
                  <button
                    type="button"
                    v-on:click="removeItem(d, i)"
                    class="btn btn-danger btn-sm add-minus-button"
                    tabindex="80"
                  >
                    <span aria-hidden="true">&minus;</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Manufacturer END -->
        </div>

        <!-- Golds -->
        <div class="col-md-6">
          <div>
            <p class="text-left text-bold text-info text-dark">
              Golds
              <small class="form-text text-muted"
                >(If the stone id is empty, that line is deleted!)</small
              >
            </p>
            <!-- <button @click="goldShowClick" type="button" class="btn btn-outline-dark" style="font-size: 12px; padding: 6px 10px;margin-left:5px" >{{goldShowLabel}}</button> -->
          </div>

          <table id="manuf-table" class="table table-bordered">
            <thead>
              <tr class="text-center">
                <th>Stone Id</th>
                <th>Code</th>
                <th>Number</th>
                <th>Weight</th>
                <th>Price</th>
                <th>
                  <button
                    type="button"
                    v-on:click="addGold"
                    class="btn btn-success btn-sm"
                  >
                    <span area-hidden="true">&plus;</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in productModel.jewel[0].jewel_stone" :key="i">
                <td width="10%">
                  <div class="form-group">
                    <!-- <input type="float" id="Stone" v-model="d.stone"  class="form-control"> -->
                    <SelectStone
                      :stone-model="stoneModel"
                      v-model="d.stone"
                      :selected="d.stone"
                    >
                    </SelectStone>
                  </div>
                </td>
                <td width="10%">
                  <div class="form-group">
                    <input
                      type="text"
                      id="Code"
                      v-model="d.code"
                      class="form-control"
                    />
                  </div>
                </td>
                <td width="10%">
                  <div class="form-group">
                    <input
                      type="number"
                      step="0.01"
                      id="stoneNumber"
                      v-model="d.number"
                      class="form-control"
                    />
                  </div>
                </td>
                <td width="10%">
                  <div class="form-group">
                    <input
                      style="font-size: 12px"
                      type="number"
                      step="0.01"
                      id="stoneWeight"
                      v-model="d.weight"
                      class="form-control"
                    />
                  </div>
                </td>
                <td width="10%">
                  <div class="form-group">
                    <input
                      style="font-size: 12px"
                      type="number"
                      step="0.01"
                      id="stonePrice"
                      v-model="d.price"
                      class="form-control"
                    />
                  </div>
                </td>
                <td width="10%">
                  <button
                    type="button"
                    v-on:click="removeGold(d, i)"
                    class="btn btn-danger btn-sm"
                    tabindex="99"
                  >
                    <span aria-hidden="true">&minus;</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Golds END -->
        </div>
      </div>
    </form>
  </div>

  <Dialog
    v-model:visible="deleteDialog"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
  >
    <div class="confirmation-content">
      <span>Are you sure you want to delete?</span>
    </div>
    <template #footer>
      <div class="row justify-content-center">
        <div class="offset-md-8 col-md-2">
          <button
            @click="deleteDialog = false"
            type="button"
            class="btn btn-outline-dark d-flex justify-content-center p-button-text"
          >
            No
          </button>
        </div>
        <div class="col-md-2">
          <button
            @click="deleteJewel"
            type="button"
            class="btn btn-outline-danger d-flex justify-content-center"
          >
            Yes
          </button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Menu from "@/components/Menu";
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import SelectStone from "@/components/SelectStone.vue";

export default {
  name: "JewelItem",
  components: { Menu, SelectStone },
  data: function () {
    return {
      showImageModel: false,
      // title: this.productModel.reference_no,
      thumbnail: null,
      productModel: {},
      storeModel: null,
      manufShowLabel: "Hide",
      goldShowLabel: "Hide",
      manufShow: true,
      goldShow: true,
      manufacturers: [],
      producTypeModel: null,
      manufactureTypes: null,
      items: [
        {
          label: "Edit",
          icon: "pi pi-fw pi-pencil",
          to: "/products/" + this.$route.params.id,
        },
        {
          label: "Photo",
          icon: "pi pi-fw pi-image",
          to: "/products/photo/" + this.$route.params.id,
        },
        {
          label: "Sale",
          icon: "pi pi-fw pi-shopping-cart",
          to: "/products/sales/" + this.$route.params.id,
        },
        {
          label: "Catalog",
          icon: "pi pi-fw pi-tags",
          to: "/products/catalog/" + this.$route.params.id,
        },
        {
          label: "Transfer",
          icon: "pi pi-fw pi-sync",
          to: "/products/transfer/" + this.$route.params.id,
        },
      ],
      subTypeModel: null,
      producTypeModel: null,
      stoneModel: [],
      tagModel: null,
      NewtagModel: null,
      deleteDialog: false,
      karatModel: [],
      jewelTypeModel: [],
    };
  },
  created() {
    getAPI("product-tags?limit=1000&ordering=name&").then((res) => {
      this.newTagModel = res.data.results;
    });
    getAPI("stores").then((res) => {
      this.storeModel = res.data.results;
    });

    getAPI("gold-karats?limit=100000").then((res) => {
      this.karatModel = res.data.results;
    });
    getAPI("manufacturers?ordering=firm_name&limit=1000").then((res) => {
      this.manufacturers = res.data.results;
    });
    getAPI("product-types?limit=1000&ordering=type_name&").then((res) => {
      this.producTypeModel = res.data.results.map((item) => ({
        product_type: item.id,
        type_name: item.type_name,
      }));
    });
    getAPI("manufacturer-types?limit=1000&ordering=manufacturer_type&").then(
      (res) => {
        this.manufactureTypes = res.data;
      }
    );
    getAPI("products/" + this.$route.params.id).then((res) => {
      if (res.data.jewel[0].subtypes.length > 0) {
        res.data.jewel[0].subtypes = res.data.jewel[0].subtypes.map((item) => ({
          subtype: item.subtype,
          name: item.name,
        }));
      }

      if (res.data.jewel[0].product_type.length > 0) {
        res.data.jewel[0].product_type = res.data.jewel[0].product_type.map(
          (item) => ({ product_type: item.product_type, type_name: item.name })
        );
      }

      this.productModel = res.data;
      this.productModel["tags"] = this.productModel["tags"].map(function (
        item
      ) {
        return { tag: item.tag };
      });
      // if (this.productModel.tag) {
      // this.productModel.tag = res.data.tag.split(',');}
    });
    getAPI("jewel-subtypes?limit=1000&ordering=name&").then((res) => {
      this.subTypeModel = res.data.results.map((item) => ({
        subtype: item.id,
        name: item.name,
      }));
    });

    getAPI("jewel-types?limit=100000").then((res) => {
      this.jewelTypeModel = res.data.results;
    });
  },

  methods: {
    submitJewelChanges: function () {
      // if ( Array.isArray(this.productModel.tag)) {
      // 	this.productModel.tag = this.productModel.tag.toString();}
      this.fixFields();
      let payload = JSON.parse(JSON.stringify(this.productModel));
      // if (payload.tag) {
      // 	payload.tag = payload.tag.toString();
      // 	}
      delete payload["thumbnail"];
      patchAPI("products/" + this.$route.params.id, payload);
      Toast2.fire({
        title: "Product updated successfully",
      });
      // if (typeof this.productModel.tag === 'string') {this.productModel.tag = this.productModel.tag.split(",");}
      // this.productModel.tag = this.productModel.tag.split(",");
    },
    // Manufacture methods
    manufShowClick: function () {
      this.manufShow = !this.manufShow;
      if (this.manufShowLabel === "Show") {
        this.manufShowLabel = "Hide";
      } else {
        this.manufShowLabel = "Show";
      }
    },

    fixFields: function () {
      this.productModel.jewel[0].jewel_stone.forEach((element) => {
        if (element.weight == "") {
          element.weight = null;
        } else if (element.price == "") {
          element.price = null;
        }
      });
    },
    removeItem: function (data, id) {
      deleteAPI("product-manufacturers/" + +data["id"]);
      this.productModel.manufacturers.splice(id, 1);
      Toast2.fire({
        title: "Deleted successfully",
      });
    },
    addItem: function (e) {
      e.preventDefault();
      let detail = {
        manufacturer: null,
        type: null,
        price: null,
      };
      this.productModel.manufacturers.push(detail);
    },

    // Gold Methods
    goldShowClick: function () {
      this.goldShow = !this.goldShow;
      if (this.goldShowLabel === "Show") {
        this.goldShowLabel = "Hide";
      } else {
        this.goldShowLabel = "Show";
      }
    },
    removeGold: function (data, id) {
      console.log(data);
      deleteAPI("jewel-stones/" + data["id"]).then((res) => {
        this.productModel.jewel[0].jewel_stone.splice(id, 1);
        Toast2.fire({
          title: "Deleted successfully",
        });
      });
    },
    addGold: function (e) {
      e.preventDefault();
      let detail = {
        code: null,
        number: null,
        weight: null,
        stone: null,
        price: null,
      };
      this.productModel.jewel[0].jewel_stone.push(detail);
    },
    tagConverter(id) {
      return { tag: id };
    },

    openDeleteModal() {},

    deleteJewel() {
      const productId = this.$route.params.id;
      deleteAPI("products/" + productId).then((res) => {
        if (res["status"] === 204) {
          Toast2.fire({ icon: "success", title: "Jewel Deleted!" });
          this.$router.push("/home");
        } else {
          Toast2.fire({ icon: "warning", title: res.statusText });
          this.deleteDialog = false;
        }
        console.log(productId);
      });
    },

    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }
      let user_groups = this.$store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },

    cratedDateShower(date) {
      try {
        return date.slice(0, 10);
      } catch {
        return date;
      }
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  margin: 0 auto;
  padding: 9px;
  color: #fff;
}

.header h1 {
  font-size: 3.3em;
}

.text-bold {
  font-weight: 500;
}

.total-underline {
  border-bottom: 3px solid black;
  float: right;
}

hr {
  border-top: 1px solid black;
}

.btn.btn-danger.btn-sm {
  display: block;
  margin: auto;
}

#form-submit {
  background-color: black;
  width: 120px;
  margin-left: 240 px;
  margin-right: auto;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  position: absolute;
  margin-top: 20px;
  left: 50%;
}

#invoice-table {
  background-color: white !important;
}

/* .row justify-content-center  {
    margin-top: 20px;
} */

#manuf-table {
  /* width:40%; */
  margin-top: 10px;
}

#mainProductForm {
  margin-left: 2%;
  margin-top: 6%;
}

.p-multiselect {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.thumbnail {
  object-fit: cover;
  width: 180px;
  height: auto;
  float: right;
}

input {
  border-radius: 0px;
}

select {
  border-radius: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
