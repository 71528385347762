<template>
    <Menu/>
        <div class="container-fluid">
            <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px;"> Return List </h6>
            <hr>
    
            <div class="row justify-content-center">
            <div class="col-md-2">
                <label type="date" for="nameInput">Start Date</label>
                    <input class="form-control" type="date" name="Customer Name" 
                    id="nameInput" v-model="filterModel.start_date">
            </div>
    
            <div class="col-md-2">
                <label  for="nameInput">End Date</label>
                    <input class="form-control" type="date" name="Customer Name" 
                    id="nameInput" v-model="filterModel.end_date">
            </div>
            <div class="col-md-2">
                <label for="saleStatus">Sale Status</label>
                <select v-model="filterModel.sale_status" id="saleStatus" class="form-select" disabled>
                            <option value="return">Return</option>
     
                    </select>




            </div>

            <div class="col-md-2">
                <label for="saleStatus">Seller</label>
                    <select v-model="filterModel.seller" id="saleStatus" class="form-select" >
                        <option value="">All</option>
                        <option v-for="seller in sellerModel" :value="seller.id" :key="seller.id">{{seller.name}}</option>
                    </select>
                    <!-- <small>{{filterModel.seller}}</small> -->
                </div>

                <div class="col-md-2">
                <label for="customer">Customer</label>
                <SelectCustomer
                                id="customerId"
                                :customer-model="customerModel"
                                v-model="filterModel.customer"
                                :selected="filterModel.customer"
                                >
                </SelectCustomer>
                </div>

            <div class="col-md-1 my-3">
                <button id="" class="btn btn-outline-secondary " @click="applyFilter">Filter</button>
            </div>

            <div class="row justify-content-center">

                <div class="col-md-2">
                    <label for="modelNumber">Model Number</label>
                    <input type="text" id="modelNumber" v-model="filterModel.model_no" class="form-control" >
                </div>	
                <div class="col-md-2">
                    <label for="sale_type">Jewel & Watch</label>
                    <select id="sale_type" v-model="filterModel.product_type" class="form-control" required>
                        <option value="">All</option>
                        <option value="product_id__jewel__product__product_id__isnull=false">Jewel</option>
                        <option value="product_id__jewel__product__product_id__isnull=true">Watch</option>
                    </select>	
                </div>
       
            <div class="col-md-2 justify-content-center">
				<label for="subType">Jewel Model</label>
        		<!-- <MultiSelect id="subType"  v-model="filterModel.jewelSubtype" :options="subTypeModel" :filter="true" optionLabel="name" /> -->
                <v-select multiple v-model="filterModel.jewelSubtype"  :options="subTypeModel"         
                                :reduce = "type => type.id" label="name"  />
			</div>

            <div class="col-md-2">
				<label for="productType">Product Type</label>
                <v-select multiple v-model="filterModel.jewelProductType"  :options="producTypeModel"         
                            :reduce = "type => type.id" label="type_name"  />
			</div>

            <div class="col-md-2">
				<label for="productType">Watch Brand</label>
                <v-select multiple v-model="filterModel.watchBrand"  :options="brandModel"         
                                :reduce = "brand => brand.id" label="brand_name"  />
			</div>
            <div class="col-md-1"></div>
            </div>
            <div class="row mt-2">
                <hr>

            </div>
            
            <div class="row justify-content-center mt-2 mb-2">
                <div class="col-md-8">
                    <table class="table table-bordered" v-if="showTable">
                        <thead>
                            <tr>
                                <!-- <th>Count</th> -->
                                <!-- <th>Total Sale</th> -->
                                <th>Total Paid</th>
                                <th>Total Expense </th>
                                <!-- <th>Total Profit</th> -->
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <!-- <td>{{saleCount}}</td> -->
                                <!-- <td>{{formatCurrency(sumModel["total_sale"] + sumModel["total_expense"] || null )}}</td> -->
                                <td>{{formatCurrency(sumModel["total_sale"])}}</td>
                                <td>{{formatCurrency(sumModel["total_expense"])}}</td>
                                <!-- <td>{{formatCurrency(sumModel["total_profit"])}}</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-md-11">
                    <Table
                    v-if="showTable"
                    excelName = "deneme"
                    excelTabName = "deneme"
                    :tableInfo = "tableInfo"
                    showRoute = true
                    detailPath = "/sales/"
                    idName = "sale_id"
                    :useCreate = true
                    :key="tableKey"
                    :endPoint = "endPoint"
                />
                </div>
            </div>

            </div>
        </div>
    
    
    </template>
    
    <script>
import Menu from '@/components/Menu';
import {getAPI} from '@/global/callAPI';
import Table from '@/components/Table';
import SelectCustomer from '@/components/SelectCustomer';
import {formatCurrency} from '@/global/functions'

    export default {
        name:"CustomerList",
        components: {Menu, Table, SelectCustomer},
        data: function () {
            return {
                showTable:false,
                tableKey:0,
                endPoint:null,
                customerModel: [],
                tagModel:[],
                sellerModel: [],
                saleCount: 0,
                totalNetSale:null,
                totalSale:null,
                totalExpense:null,
                totalProfit:null,
                totalTicketPrice:null,
                totalSoldPrice:null,
                producTypeModel: [],
                subTypeModel:[],
                brandModel: [],
                sumModel:{},
                tableInfo: [
                    {field: 'thumbnail', header: 'Thumbnail',isImage:true},
                    {field: 'reference_no', header: 'Reference No'},
                    {field: 'sale_reference', header: 'Sale Reference'},
                    {field: 'sale_date', header: 'Date',order:true},
                    {field: 'customer', header: 'Customer'},
                    {field: 'seller', header: 'Seller',order:true},
                    {field: 'store', header: 'Store',order:true},
                    {field: 'ticket_price', header: 'Ticket Price', isCurrency:true},
                    {field: 'price', header: 'Paid', isCurrency:true,order:true},
                    {field: 'last_return_price', header: 'Sold Price', isCurrency:true},
                    {field: 'sale_status', header: 'Status'},

                    // {field: 'customer_surname', header: 'Surname'},
                    // {field: 'tags', header: 'Tags'},
    
                ],
                filterModel: {
                    start_date:null,
                    end_date:null,
                    customer:null,
                    // status:"sale_id__sale_type_id__sale_type_id=9&is_last=true",
                    seller: null,
                    product_type:null,
                    model_no:null,
                    jewelSubtype: null,
                    jewelProductType:null,
                    watchBrand:null,
                    sale_status:"return",

                },
                sales:null,
                oldFilters:null,
                genderFilter: ['male', 'female'],
                tagFilter:[],
                loading:false,
                tagInput:null,
    
        }},
        methods: {
        formatCurrency:formatCurrency,
        applyFilter(){
            const isEmpty = Object.values(this.filterModel).every(x => x === null || x === '' || x.length === 0);
            if (isEmpty === true) {window.alert("Please enter a filter!"); return false}

            function listToString(list) {try {
                console.log("oldu")
                console.log(list.toString())
                    return list.toString()
                }
                catch(err) {
                    console.log("error")
                    return list
                }}
            let end_date = this.filterModel.end_date || ""
            let start_date = this.filterModel.start_date || ""
            let customer = this.filterModel.customer || ""
            let status = this.filterModel.status || ""
            let seller = this.filterModel.seller || ""
            let product_type = this.filterModel.product_type || ""
            let model_no = this.filterModel.model_no || ""
            let jewelSubtype = this.filterModel.jewelSubtype || ""
            let jewelProductType = this.filterModel.jewelProductType || ""
            let watchBrand = listToString(this.filterModel.watchBrand) || ""
            let sale_status = this.filterModel.sale_status || ""

            let filterList = [
                `sale_id__sale_status=${sale_status}`,
                `date__lte=${end_date}`,
                `date__gte=${start_date}`,
                `sale_id__customer_id__id__in=${customer}`,
                `sale_id__seller_id__id=${seller}`,
                // `product_type=${product_type}`,
                product_type,
                // status,

                `product_id__model_no__icontains=${model_no}`,
                `product_id__jewel__subtypes__subtype__id__in=${jewelSubtype}`,
                `product_id__jewel__product_type__product_type__id__in=${jewelProductType}`,
                `product_id__watch__brand__id__in=${watchBrand}`,
    
            ]
            let query = filterList.join('&').replaceAll("isnull","nbullx").replaceAll("null","").replaceAll('nbullx','isnull')
            console.log(query)

            getAPI('sum-sale-products?limit=1&'+ query).then((res) => {
            this.sumModel = res.data
            })

            this.loading = true
            this.showTable =true
            this.endPoint = "sale-products?"+query
            this.tableKey += 1
            // let query = filterList.join('&').replaceAll("isnull","nbullx").replaceAll("null","").replaceAll('nbullx','isnull')
            // console.log(query)

            // this.loading = true
            // getAPI(`sale-products?`+ query)
            // .then(res => {
            //     this.sales = res.data.results;
            //     this.showTable =true
            //     this.calculator(res.data.results)
            //     });
        },
        calculator (value) {
            
            var netSale = 0;
            var sale = 0;
            var expense = 0;
            var profit = 0;
            var ticketPrice = 0;
            var soldPrice = 0;
            for (const item of  value) {
                netSale += item.price || 0
                ticketPrice += item.ticket_price || 0
                // expense += item.total_expense
                // sale += item.total_sale_price + item.total_expense
                profit += item.profit || 0
                soldPrice += parseFloat(item.last_return_price) || 0
                console.log(soldPrice,item.last_return_price)

            }

            this.totalNetSale = netSale
            this.totalTicketPrice = ticketPrice
            // this.totalSale = sale
            // this.totalExpense = expense
            this.totalProfit = profit
            this.saleCount = value.length || 0
            this.totalSoldPrice = soldPrice || 0
            

            },
            hasPermission (roles=null) {
                if (roles === null) {return true}
                let user_groups = this.$store.state.groups
                if (Array.isArray(user_groups)) {} else {user_groups = user_groups.split(',')}
                if (user_groups.some(r=> roles.includes(r)) ) {
                    return false
                } else {
                    return true
                }
            },
    
    
        },
        mounted: function () {
            let roles = ['limited_seller']
            // if (this.hasPermission(roles=roles),this) {
            //     this.tableInfo.splice(7, 0, {field: 'profit', header: 'Profit'})
            // }
            getAPI('user-name-list?limit=10000&ordering=name').then((res) =>{
                this.sellerModel = res.data.results;
            });
            getAPI('product-types?limit=1000&ordering=type_name&').then((res) => {this.producTypeModel = res.data.results})
		    getAPI('jewel-subtypes?limit=1000&ordering=name&').then((res) => {this.subTypeModel = res.data.results})
            getAPI('watch-brands?limit=10000&ordering=brand_name').then((res) => {this.brandModel = res.data.results})

            this.loading = true
            this.oldFilters = this.filters;
        
    
    }}
    </script>
    
    <style scoped>
input {
	border-radius: 0px;
}

select {
	border-radius: 0px;
}
td {
    text-align: center; 
    vertical-align: middle;
}
th {
    text-align: center; 
    vertical-align: middle;
}
.p-multiselect {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    height: 60%;
    border-radius: 0px;
}
    </style>