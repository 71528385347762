<template>
  <Menu />

  <div id="productPhoto" class="container-fluid">
    <TabMenu :model="items" />
    <div class="row justify-content-center">
      <div class="col-md-11">
        <Table
          excelName="product-sale-export"
          excelTabName="Sales"
          :tableInfo="columns"
          :useCreate="true"
          showRoute="true"
          detailPath="/sales/"
          idName="sale_id"
          :endPoint="endPoint"
        />
      </div>
    </div>

    <!-- <DataTable  

        selectionMode="single"                   
        @row-dblclick="rowClick($event)"
        :value="products" 
        responsiveLayout="scroll">

            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"></Column>
        </DataTable> -->
    <div class="row"></div>
  </div>
</template>

<script>
import Table from "@/components/Table";
import Menu from "@/components/Menu";
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
export default {
  name: "ProductSale",
  components: { Menu, Table },
  data: function () {
    return {
      endPoint: `sale-products?&ordering=-sale_product_id&product_id=${this.$route.params.id}`,
      items: [
        {
          label: "Edit",
          icon: "pi pi-fw pi-pencil",
          to: "/products/" + this.$route.params.id,
        },
        {
          label: "Photo",
          icon: "pi pi-fw pi-image",
          to: "/products/photo/" + this.$route.params.id,
        },
        {
          label: "Sale",
          icon: "pi pi-fw pi-shopping-cart",
          to: "/products/sales/" + this.$route.params.id,
        },
        {
          label: "Catalog",
          icon: "pi pi-fw pi-tags",
          to: "/products/catalog/" + this.$route.params.id,
        },
        {
          label: "Transfer",
          icon: "pi pi-fw pi-sync",
          to: "/products/transfer/" + this.$route.params.id,
        },
      ],
      columns: null,
      products: null,
      groups: localStorage.getItem("groups"),
      // columns:[
      //         {field: 'sale_date', header: 'Sale Date'},
      //         {field: 'customer', header: 'Customer'},
      //         {field: 'store', header: 'Store'},
      //         {field: 'seller', header: 'Seller'},
      //         {field: 'price', header: 'Price'},
      //         {field: 'status', header: 'Status'},
      //     ],
    };
  },
  created() {
    // getAPI('products/' + this.$route.params.id).then((res) => {
    //     this.products = res.data.sale; });
    this.columns = [
      { field: "sale_date", header: "Sale Date", order: true },
      { field: "customer", header: "Customer" },
      { field: "store", header: "Store" },
      { field: "seller", header: "Seller" },
      { field: "price", header: "Price", order: true },
      { field: "status", header: "Status", order: true },
    ];

    if (this.permission("super_user") === true) {
      this.columns.push({ field: "custom_profit", header: "MT" });
    }
  },

  methods: {
    rowClick(e) {
      this.$router.push({ path: "/sales/" + e.data.sale_id });
    },
    permission(n) {
      return this.groups.indexOf(n) > -1;
    },
  },
};
</script>

<style scoped></style>
