<template>
    <Menu/>
    <div class="container-fluid">

        <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px"><strong>Gem Page</strong> </h6>
	        <hr style="height:0.5px;margin-bottom:30px">
        <div class="form-group">

            <div class="row">

                
                <div class="col-md-4">
                    <!-- Type Input -->
                    <div class="row align-items-start">
                        <div class="col-md-9">
                            <!-- <label for="typeInput">Type</label> -->
                            <!-- <Tree :value="stoneTypeModel"
                            :filter="true"
                            filterMode="strict"
                            selectionMode="single" 
                            v-model:selectionKeys="selectedStoneType"
                            scrollHeight="200px"
                            @node-select="editShow"
                            ></Tree> -->

                        </div>
                        <!-- <div class="col">
                            
                                <div class="row">
                                    <button type="button" id="newStoneButton" @click="addNew" class="btn btn-outline-dark">Add</button>
                                </div>
                                <div class="row">
                                    <button type="button" id="editStoneTypeButton" v-if="typeEdit" @click="editStoneType" class="btn btn-outline-dark">Edit</button>
                                </div>
                            
                        </div> -->
                    </div>
                    <div class="row">
                                        <!-- Reference No Input -->
                        <div class="col-md-6">
                            <label for="referenceInput">Reference No</label>
                            <input v-if="mod === 'new'" id="referenceInput" @change="referenceController" type="text" v-model="submitModel.reference_no" class="form-control">
                            <input v-if="mod === 'edit'" id="referenceInput" @change="referenceController" type="text" v-model="submitModel.reference_no" class="form-control" >

                        </div>
                                            <!-- Date Input -->

                        <div class="col-md-6">
                            <label for="dateInput">Date</label>
                            <input id="dateInput" type="date" v-model="submitModel.date" class="form-control">
                        </div>

                    </div>
                    <!-- Number Input -->
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-6">
                            <label for="numberInput">Number</label>
                            <input v-model="submitModel.number" id="numberInput" type="number" class="form-control">
                        </div> -->

                        <!-- Weight Input -->
                        <!-- <div class="col-md-6">
                            <label for="weightInput">Total Weight</label>
                            <input v-model="submitModel.first_weight" id="weightInput" placeholder="0.00" step="0.01" type="number" class="form-control">
                        </div> -->
                    <!-- </div> -->
                    
                    <!-- Manufacturer Input -->
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-8">
                            <label for="manufactureInput">Manufacturer</label>
                            <v-select  v-model="submitModel.manufacturer" id="manufactureInput" :options="manufacturerModel"         
                                :reduce = "manuf => manuf.manufacturer_id" label="name_surname"  />
                        </div> -->
                    <!-- Price Input -->
                        <!-- <div class="col-md-4">
                            <label for="priceInput">Price ($)</label>
                            <input v-model="submitModel.price" id="priceInput" step=0.02 type="number" class="form-control">
                        </div> -->
                    <!-- </div> -->

                    <div class="row">
                                            <!-- Name Input -->
                        <div class="col-md-6">
                            <label for="nameInput">Name</label>
                            <input v-model="submitModel.name" id="nameInput" type="text" class="form-control">
                        </div>
                                            <!-- Certificate Input -->
                        <div class="col-md-6">
                            <label for="certificateInput">Certificate</label>
                            <input v-model="submitModel.certificate" id="certificateInput" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="row">
                        <div v-if="stonePropertyModel.length" class="col-md-6">
                            <label for="StonePropertyInfo">Properties</label>

                            <v-select  :options="stonePropertyModel" v-model="submitModel.stone_properties" id="StonePropertyInfo"          
                            :reduce = "property => stonePropertyConverter(property.id)" label="name" :multiple=true />
                        </div>
                    <!-- Price Input -->
                        <div class="col-md-6">
                            <label for="priceInput">Price ($)</label>
                            <input v-model="submitModel.base_price" id="priceInput" step=0.02 type="number" class="form-control">
                        </div>

                    </div>
                    <!-- Description -->
                    <div class="row">
                        <div class="col-md-12">
                            <label for="descriptionInput">Description</label>
                            <textarea v-model="submitModel.detail" id="descriptionInput" class="form-control" rows="3"></textarea>
                        </div>
                    </div>
                </div>

                <!-- Other Grid -->
                <div class="col-md-6 offset-md-1">
                    <div class="row">
                        <div class="col-md-2 offset-md-10">
                            <div class="d-grid gap-2"  v-show="hasPermission(['limited_seller', 'seller', 'office'])">
                              <button v-if="selectedStone" @click="deleteStone" type="button" name="" id="" class="btn btn-danger">Delete</button>
                            </div>
                        </div>
                        <div class="col-md-12">

                            <div class="row align-items-center justify-content-center">

                                <div class="col-md-4  offset-md-2">
                                    
                                    <label for="" class="form-label">Search</label>
                                    <input v-model="searchFilter" @keyup.enter="applyFilter" type="text" class="form-control" name="" id="" aria-describedby="helpId" placeholder="">
                                    <!-- <small id="helpId" class="form-text text-muted">To search reference and name</small> -->
                                    
                                </div>

                                <div class="col-md-6 ">
                                        <label for="filterProperties">Filter Props </label>
                                        
                                        <v-select  @option:deselected="applyFilter" @option:selected="applyFilter" :options="stonePropertyModel" v-model="filterProperties" id="filterProperties"          
                                        :reduce = "property => property.id" label="name" :multiple=true />
                                </div>
                                
                                <div class="row" v-if="loading">
                                    <div class="col-md-2 offset-md-5">
                                        <div class="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- <div class="col-md-1">
                                    <div class="d-grid gap-2">
                                      <button type="button" @click="applyFilter" class="btn btn-outline-dark">Filter</button>
                                    </div>
                                </div> -->

                            </div>

                            <label for="stones">Stones </label>
                            <select @scroll="scrollGem" id="stones" v-model="selectedStone" class="form-select" @change="chooseStone" size="6">
                                <option v-bind:value="s.id" v-for="s in stoneModel" :key="s.id">{{s.label}}</option>
                            </select>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-3 text-center">
                                <button class="btn btn-outline-dark" v-if="selectedStone" @click="reset()">Reset</button>
                            
                            </div>
                            <div class="col-md-3 text-center">
                                <button class="btn btn-outline-dark" v-if="selectedStone" @click="showNewStoneWeight()">New Stone</button>
                            </div>
                            <div class="col-md-3 text-center">
                                <button class="btn btn-outline-dark" @click="showStoneHistory" v-if="selectedStone">Transactions</button>
                            </div>
                            <div class="col-md-3 text-center">
                                <button class="btn btn-outline-dark" @click="showJewelStones" v-if="selectedStone">Jewels</button>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div v-if="mod === 'edit'" class="col-md-4">
                                <label for="costShow">Cost</label>
                                <input class="form-control" id="costShow" v-model="stoneCost" type="text" disabled>
                            </div>

                            <div v-if="mod === 'edit'" class="col-md-3">
                                <label for="balanceShow">Balance</label>
                                <input class="form-control" id="balanceShow" v-model="submitModel.weight" type="number" step="any" disabled>
                            </div>


                            <div v-if="mod === 'edit'" class="col-md-3">
                                <div class="">

                                </div>

                                <label for="balanceShow">Calculated Balance</label>
                                <input class="form-control" id="balanceShow" v-model="submitModel.balance" type="number" step="any" disabled>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
            <div class="row justify-content-center mt-4">
                <div class="col-md-2">
                <button class="btn btn-secondary" type="submit" @click="submit" >Submit</button>
            </div>
            </div>
            

        <!--Stone Type Modal -->
            <div class="modal fade" id="stoneType" tabindex="-1" role="dialog" aria-labelledby="editLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editLabel" v-if="modelMode == 'new'">New Stone Type</h5>
                            <h5 class="modal-title" id="editLabel" v-if="modelMode == 'edit'">Edit Stone Type / {{this.typeSubmitModel.id}}</h5>

                            <button type="button" class="close btn btn-outline-dark" data-bs-dismiss="modal" @click="closeModel"  aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>                            
                        </div>

                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-9">
                                    <label for="typeInput">Top Type</label>
                                    <Tree :value="stoneTypeModel"
                                    selectionMode="single" 
                                    v-model:selectionKeys="selectedStoneType2"
                                    scrollHeight="200px"
                                    ></Tree>
                                    
                                    </div>  
                            </div>
                            <div class="row ">
                                <div class="col-md-9">
                                    <label for="newTypeNameInput" >Name</label>
                                    <input v-model="typeSubmitModel.name" id="newTypeNameInput" type="text" class="form-control" style="margin-bottom: 20px;" required>
                                </div>
                            </div>
                            <div class="modal-footer ">
                                <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Close</button>
                                <button @click="deleteStoneType()" type="button" class="btn btn-outline-danger" v-if="modelMode == 'edit'">Delete</button>
                                <button @click="submitStoneType()" type="button" class="btn btn-outline-primary" >Save changes</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        <!-- New Stone Type END -->

        <!-- New Stone Model -->
            <div class="modal fade" id="newStoneWeight" tabindex="-1" role="dialog" aria-labelledby="editLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editLabel" >New Stone</h5>
                            <button type="button" class="close btn btn-outline-dark" data-bs-dismiss="modal" @click="closeModel"  aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>                            
                        </div>

                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-5">
                                    <label for="newStoneWeight">Stone Weight</label>
					                <input type="number" step='0.01' placeholder='0.00' id="newStoneWeight" v-model="newStoneWeightModel.weight" class="form-control">
                                </div>  
                                <div class="col-md-5">
                                    <label for="newStoneWeight">Stone Number</label>
					                <input type="number" step=".01" id="newStoneWeight" v-model="newStoneWeightModel.number" class="form-control">
                                </div>  

                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label for="NewStonemanufacturerInput">Manufacturer</label>

                                    <v-select  v-model="newStoneWeightModel.manufacturer" id="NewStonemanufacturerInput" :options="manufacturerModel"         
                                    :reduce = "manuf => manuf.manufacturer_id" label="firm_name"  />
                                </div>
                                <div class="col-md-5">
                                    <label for="newStonePrice">Price</label>
					                <input type="number" step='0.01' placeholder='$0.00' id="newStonePrice" v-model="newStoneWeightModel.price" class="form-control">
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-10">
                                    <label for="newStoneDescription">Description</label>
					                <input type="text" v-model="newStoneWeightModel.description" class="form-control">
                                </div>
                                
                            </div>

                            <div class="modal-footer ">
                                <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Close</button>
                                <button @click="submitNewStoneWeight()" type="button" class="btn btn-outline-primary" >Save changes</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        <!-- New Stone End -->

<!-- Show History Model -->

            <div  class="modal fade" id="stoneHistory" tabindex="-1" role="dialog" aria-labelledby="editLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editLabel" >Stone History</h5>
                            <button type="button" class="close btn btn-outline-dark" data-bs-dismiss="modal" @click="closeModel"  aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>                            
                        </div>

                        <div class="modal-body">
                            <!-- <div class="row">
                                <div class="col-md-2">
                                    <div class="d-grid gap-2">
                                      <button type="button" @click="createExcel(selectedStoneDetail.stone_transaction,(selectedStoneDetail.reference_no || 'stone-jewel') + '-transaction')" name="" id="" class="btn btn-outline-success">Export</button>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row">

                            </div>
                            <div  id="stoneTransactionTable" class="row">
                                <Table
                                    :key="stoneTransactionTableKey"
                                    v-if="showStoneTransactionTable"
                                    excelName = "stone-transaction"
                                    excelTabName = "Transactions"
                                    :tableInfo = "[
                                                    {field: 'id', header: 'Id'},
                                                    {field: 'created_at', header: 'Date'},
                                                    {field: 'manufacturer_name', header: 'Manufacturer'},
                                                    {field: 'number', header: 'Number'},
                                                    {field: 'weight', header: 'Weight'},
                                                    {field: 'price', header: 'Price'},
                                                    {field: 'description', header: 'Description'},

                                                ]"
                                    :showRoute = false
                                    :useCreate = true
                                    :endPoint = "stoneTransactionEndPoint" 
                                   
                                />

                            </div>

                            <div class="modal-footer ">
                                <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Close</button>
                                <!-- <button @click="submitNewStoneWeight()" type="button" class="btn btn-outline-primary" >Save changes</button> -->
                            </div>
                        </div>

                    </div>
                </div>
            </div>

<!-- Show History Model End -->

<!-- Show Jewel Model -->

            <div  class="modal fade " id="stoneJewels" tabindex="-1" role="dialog" aria-labelledby="editLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editLabel" >Jewels</h5>
                            <button type="button" class="close btn btn-outline-dark" data-bs-dismiss="modal" @click="closeModel"  aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>                            
                        </div>

                        <div class="modal-body">
                            <!-- <div class="row">
                                <div class="col-md-2">
                                    <div class="d-grid gap-2">
                                      <button type="button" @click="createExcel(selectedStoneDetail.stone_history,(selectedStoneDetail.reference_no || 'stone-jewel') + '-jewels')" name="" id="" class="btn btn-outline-success">Export</button>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row">

                            </div>
                            <div id="stoneTransactionTable" class="row">
                                <!-- burdayım -->
                                <Table
                                    :key="JewelStoneTableKey"
                                    v-if="showJewlStoneTable"
                                    excelName = "jewel-stone-transaction"
                                    excelTabName = "Transactions"
                                    :tableInfo = "[
                                                    {field: 'id', header: 'Id'},
                                                    {field: 'reference_no', header: 'Reference No'},
                                                    {field: 'code', header: 'Code'},
                                                    {field: 'number', header: 'Number'},
                                                    {field: 'weight', header: 'Weight'},

                                                ]"
                                    showRoute = true
                                    detailPath = "/products/"
                                    idName = "product_id"
                                    :useCreate = true
                                    :endPoint = "jewelStoneEndPoint" 
                                   
                                />
                                

                            </div>

                            <div class="modal-footer ">
                                <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Close</button>
                                <!-- <button @click="submitNewStoneWeight()" type="button" class="btn btn-outline-primary" >Save changes</button> -->
                            </div>
                        </div>

                    </div>
                </div>
            </div>

<!-- Show Jewel Model End -->
        </div>
    </div>

    <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <span>Are you sure you want to delete?</span>
        </div>
        <template #footer>
            <div class="row justify-content-center ">
                <div class="offset-md-8 col-md-2">
                    <button @click="deleteDialog = false" type="button" class="btn btn-outline-dark d-flex justify-content-center p-button-text">No</button>
                </div>
                <div class="col-md-2">
                    <button @click="deleteConfirmed" type="button" class="btn btn-outline-danger d-flex justify-content-center">Yes</button>
                </div>
            </div>       
            
        </template>
    </Dialog>

</template>

<script>
import {getAPI, postAPI, patchAPI, deleteAPI} from '@/global/callAPI';
import Menu from '@/components/Menu';
import Table from '@/components/Table';

export default {
    name:"StonePage",
    components: {Menu,Table},
    data: function() {

     return {
        jewelStoneEndPoint:null,
        showJewlStoneTable: false,
        JewelStoneTableKey:0,
        showStoneTransactionTable:false,
        stoneTransactionTableKey:0,
        stoneTransactionEndPoint:null,
        offset:200,
        totalCount:null,
        filterProperties:[],
        searchFilter:null,
        loading:false,
        stoneTypeModel:[],
        stoneModel:[],
        manufacturerModel: [],
        isShow: false,
        selectedStone:null,
        selectedStoneType: null,
        selectedStoneType2: null,
        typeEdit: false,
        modelMode: "new",
        stoneCost: 0,
        mod:"new",
        stoneCost:0,
        stonePropertyModel:[],

        submitModel: {
            date: null,
            number: null,
            first_weight: 0,
            price: 0,
            certificate: null,
            detail:null,
            stone_type: null,
            manufacturer: null,
            reference_no:null,
            stone_properties:[],
            base_price:null
        },

        typeSubmitModel: {
            name: null,
            top_type: null,
        },

        newStoneWeightModel: {
            stone:null,
            weight: null,
            price: null,
            add_delete :true,
            manufacturer:null,
            stone:null,
            description:null,
        
        },
        selectedStoneDetail : {stone_transaction: null, stone_history:null},
        deleteDialog: false,


     }
    },
    methods: {
        submit: function() {
            let mod = this.mod

            if (mod === "new") {
                // this.submitModel.stone_type = parseInt(Object.keys(this.selectedStoneType)[0]);
                this.submitModel.weight = this.submitModel.first_weight 
                postAPI('stones', this.submitModel).then((res) => res)
                    .then( (res) =>
                    this.stoneModel.unshift(res.data)
                    // getAPI('stones?ordering=-id').then((res) => {this.stoneModel = res.data.results})
                );
                Toast2.fire({icon: 'success', title: 'Gem added!', text: 'Please refresh the page.'});
                this.reset()
            } else if (mod === "edit") {
                this.submitModel.stone_type = parseInt(Object.keys(this.selectedStoneType)[0]);
                let id = this.submitModel.id
                patchAPI('stones/' + id, this.submitModel)
                    .then((responseJson) => {
                        this.updateStone();
                    })
                    
                Toast2.fire({icon: 'success', title: 'Gem updated!', text: 'Please refresh the page.'});
                
            }
        },
    
        submitStoneType () {
            let modelMode = this.modelMode;
            if (modelMode === "new") {

            this.typeSubmitModel.top_type = parseInt(Object.keys(this.selectedStoneType2)[0]);
            postAPI('stone-types', this.typeSubmitModel)
            Toast2.fire({icon: 'success', title: 'Stone Type added!',text:'Please refresh the page.'});
            // jQuery.noConflict();
            // $('#stoneType').modal('hide');
            jQuery('#stoneType').modal('hide');


            } else if (modelMode === "edit") {
                let stoneType = Object.keys(this.selectedStoneType)[0];
                this.typeSubmitModel.top_type = parseInt(Object.keys(this.selectedStoneType2)[0]);
                patchAPI('stone-types/' + stoneType, this.typeSubmitModel)
                Toast2.fire({icon: 'success', title: 'Stone Type updated!',text:'Please refresh the page.'});
                // jQuery.noConflict();
                // $('#stoneType').modal('hide');
                jQuery('#stoneType').modal('hide');

            }
            this.reset()
        },

        deleteStoneType () {
            let stoneType = Object.keys(this.selectedStoneType)[0];
            deleteAPI('stone-types/' + stoneType, this.typeSubmitModel);
            Toast2.fire({icon: 'success', title: 'Stone Type deleted!',text:'Please refresh the page.'});
            // jQuery.noConflict();
            // $('#stoneType').modal('hide');
            jQuery('#stoneType').modal('hide');
            this.reset()
        },

        editShow () {
            this.typeEdit = true
        },
        selectStoneType () {

            this.submitModel.stone_type = parseInt(Object.keys(this.selectedStoneType)[0]);
        },
        // reset () {
        //     this.offset = 0
        //     this.typeSubmitModel = {
        //     name: null,
        //     top_type: null,
        // }
        // },
        editStoneType() {
            this.reset();
            let stoneType = Object.keys(this.selectedStoneType)[0];
            getAPI('stone-types/' + stoneType).then((res) => {
                this.typeSubmitModel = res.data;
                let obj = {};
                obj[res.data.top_type] = true;
                this.selectedStoneType2 = obj;
                });
            this.modelMode = "edit";
            // jQuery.noConflict();
            // $('#stoneType').modal('show');
            jQuery('#stoneType').modal('show'); 

        },

        addNew(){
            this.reset();
            this.modelMode = "new";
            // jQuery.noConflict();
            // $('#stoneType').modal('show');
            jQuery('#stoneType').modal('show');
        },

        showStone: function() {
            this.isShow = true
        },

        // Stone Methods
       chooseStone () {
           this.mod = "edit"
           let selectedStone = this.selectedStone;
           
           let deneme = this.stoneModel.filter(
               function (stone) {
                   return stone.id === selectedStone
               }
           );
           deneme = deneme[0]
            deneme["stone_properties"] = deneme["stone_properties"].map(function(item) {
                                    return {property:item.property};
                                })
           this.submitModel = deneme

           let id = deneme.stone_type;
           let obj = {};
           obj[id] = true
           this.selectedStoneType = obj

           this.stoneCostCalculator()

       },
       stoneCostCalculator () {
           let price = this.submitModel.price
           let weight = this.submitModel.first_weight
           this.stoneCost = this.formatCurrency( price )
       },
        formatCurrency(value) {
            if (value) {
                return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
                        }
        },
        expandAll() {
            for (let node of this.nodes) {
                this.expandNode(node);
            }
            this.expandedKeys = {...this.expandedKeys};
        },
        collapseAll() {
            this.expandedKeys = {};
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        },

    // NEW STONE WEIGHT METHODS

        showNewStoneWeight (){
            // jQuery.noConflict();
            // $('#newStoneWeight').modal('show');
            jQuery('#newStoneWeight').modal('show');
        },
           submitNewStoneWeight () {
            
            let submitModel = this.newStoneWeightModel;
            submitModel["stone"] = this.selectedStone;
            Object.keys(submitModel).forEach(key => {
                if (submitModel[key] === "") {submitModel[key] = null};
                });
            postAPI('stone-transactions', submitModel).then()
                    .then((responseJson) => {
                        this.updateStoneDetail();
                        this.reset();
                        this.stoneCostCalculator();
                    })
                
                Toast2.fire({icon: 'success', title: 'New Stone added!', text: 'Please refresh the page.'});
            
            jQuery('#newStoneWeight').modal('hide');

        },

    // Show History Methods
    showStoneHistory () {
        this.stoneTransactionEndPoint = `stone-transactions?stone=${this.selectedStone}`
        this.stoneTransactionTableKey += 1
        this.showStoneTransactionTable = true
        jQuery('#stoneHistory').modal('show');
        // getAPI('stones/' + this.selectedStone).then((res) => {this.selectedStoneDetail = res.data;

        // jQuery('#stoneHistory').modal('show');
        // })

    },
    showJewelStones () {
        // getAPI('stones/' + this.selectedStone).then((res) => {this.selectedStoneDetail = res.data;
        // // jQuery.noConflict();
        // // $('#stoneJewels').modal('show');
        // jQuery('#stoneJewels').modal('show');

        // }
        // )
        
        this.jewelStoneEndPoint = `jewel-stones?jewel__product=&stone=${this.selectedStone}`
        this.JewelStoneTableKey += 1
        this.showJewlStoneTable = true
        jQuery('#stoneJewels').modal('show');

    },

    updateStoneDetail () {
        getAPI('stones/' + this.selectedStone).then((res) => {
            this.selectedStoneDetail = res.data;
            this.chooseStone()})

    },
    updateStone () {
        
        getAPI('stones?ordering=-id').then((res) => {this.stoneModel = res.data.results});

    },
    reset () {
        this.selectedStone = null;
        this.submitModel =  {
            date: null,
            number: null,
            first_weight: 0,
            price: 0,
            certificate: null,
            detail:null,
            stone_type: null,
            manufacturer: null,
            reference_no:null,
            stone_properties:[],
        },
        this.newStoneWeightModel = {
            stone:null,
            weight: null,
            price: null,
            add_delete :true,
            manufacturer:null,
            stone:null,
        
        },
        this.stoneCost = 0
        this.mod = "new"
    },
    stonePropertyConverter(id) {
        return {property:id}
    },
    referenceController () {
        getAPI("stones?reference_no=" + this.submitModel.reference_no)
        .then( (res) => {
            if (res.data.results[0]) {
                alert("This reference used by another gem!")
                this.submitModel.reference_no = null
                }
        } )
    },
    scrollGem ({ target: { scrollTop, clientHeight, scrollHeight }}) {
        if (this.stoneModel.length >= this.totalCount) {
            return true
        }

      if ((Math.ceil(scrollTop)  + clientHeight)>= scrollHeight) {
        this.loading = true;

        let endpoint = this.endPointCreate()
        getAPI(endpoint).then((res) => {
            this.stoneModel.push.apply(this.stoneModel,res.data.results);
            this.loading = false
        });
        this.offset += 200
        }
    },
    endPointCreate() {
        const searchData = this.searchFilter || ""
        const search = '&search=' + searchData
        const filterData = this.filterProperties.join(",")
        const filter = 'stone_properties__property__id__in=' + filterData
        const endpoint = 'stones?ordering=-id&limit=200&'+filter+search+'&offset='+this.offset
        return endpoint
    },
    applyFilter () {
        this.loading = true;
        const filterData = this.filterProperties.join(",")
        if (filterData == null || filterData === '') {
            this.offset = 0
            let endpoint = this.endPointCreate()
            getAPI(endpoint).then((res) => {this.stoneModel = res.data.results;this.totalCount = res.data.count;this.loading=false});
            this.offset = 200
            return true
        }   else  {
            this.reset(); 
            this.offset = 0;       
            let endpoint = this.endPointCreate();
            getAPI(endpoint).then((res) => {this.stoneModel = res.data.results;this.totalCount = res.data.count;this.loading=false})
        }

    },
    createExcel (exportArray,name) {
        console.log(name)
        const XLSX = require('xlsx')
        
        let binary_univers = exportArray

        let binaryWS = XLSX.utils.json_to_sheet(binary_univers); 
        
        // Create a new Workbook
        var wb = XLSX.utils.book_new() 

        // Name your sheet
        XLSX.utils.book_append_sheet(wb, binaryWS, 'Stone Jewels') 

        // export your excel
        
        XLSX.writeFile(wb, name + '.xlsx');

    },

    deleteStone(){
        this.deleteDialog = true
    },
    deleteConfirmed() {
        this.deleteDialog = false

        let id = this.submitModel.id
        deleteAPI('stones/' + id, this.submitModel)
        .then((responseJson) => {
            // Toast2.fire({icon: 'success', title: 'Gem deleted!', text: 'Please refresh the page.'});
            this.updateStone();
        })

    },
    hasPermission(roles = null) {
			if (roles === null) { return true }
			let user_groups = this.$store.state.groups
			if (Array.isArray(user_groups)) { } else { user_groups = user_groups.split(',') }
			if (user_groups.some(r => roles.includes(r))) {
				return false
			} else {
				return true
			}
		},

    },

    created: function() {
		// getAPI('tree-stone-types').then((res) => {this.stoneTypeModel = res.data.results});
		getAPI('stones?limit=200&ordering=-id').then((res) => {this.stoneModel = res.data.results;this.totalCount = res.data.count});
        getAPI('manufacturers?type_id=5&limit=10000').then((res) => {this.manufacturerModel = res.data.results});
        getAPI('stone-properties?limit=10000&ordering=name').then((res) => {this.stonePropertyModel = res.data.results});

    }

}
</script>

<style scoped lang="scss">



button {
    margin-right: .5rem;
}

::v-deep(.p-tree) {
    a {
        color: #2196f3;
        text-decoration: none;
    }
}

#form-submit {
  background-color: black;
  width: 120px;
  margin-left:240 px;
  margin-right:auto;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  position: absolute;
  margin-top: 20px;
  left: 50%;

}


.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
    border: none;
}
</style>