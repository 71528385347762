<template>
    <Menu/>
    <div class="container-fluid">
        <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px;">Add Bulk Product Tag </h6>
        <hr>
        
        <div class="row">
            <div class="col-md-2">
				<label for="productTag">Tag</label>
				<v-select
				  :options="tagModel"
                   id="productTag"
                   label="name"
                   :multiple=false
                     v-model="selectedTag"

				/>
			</div>
        </div>
        <div class="row mt-3">
            <div class="col-md-3">
                <div class="mb-3">
                  <label for="" class="form-label">Reference List</label>
                  <textarea v-model="reference" class="form-control" name="" id="" rows="5"></textarea>
                  <small id="helpId" class="form-text text-muted">Enter one reference per line.</small>
                </div>
            </div>

        </div>

        <div class="row mb-2 mt-4">
            <div class="col-md 2">
                <div class="row mb-2 mt-4">
                    <div class="col-md-2 d-grid gap-2">
                        <button type="button" id="editStoneTypeButton" @click="addBulk()" class="btn btn-success">Add Tag</button>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="loading" class="row">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

        <div class="row">
            <label for="success">Successful Products:</label>
            <p>{{ successList }}</p>
        </div>

        <div class="row mt-2">
            <label for="error" class="mb-2">Errors:</label>
            <div class="error" v-for="error in errorList" :key="error.product">
            <p><strong>{{ error.product }}</strong>: {{ error.detail }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {getAPI, postAPI, deleteAPI} from '@/global/callAPI';
import Menu from '@/components/Menu';
export default {
    components: {Menu},
    data () {
        return {
            reference:null,
            fileName:"Product Export",
            products:[],
            errorList:[],
            successList:[],
            loading:false,
            totalEnteredProduct:null,
            totalUniqueProduct:null,
            totalPrintedProduct:null,
            totalDuplicate:null,
            tagModel:[],
            selectedTag:null,
        }
    },
    methods: {
        addBulk() {
        //control tag
        if(this.selectedTag == null || this.selectedTag == "" || this.selectedTag == undefined){
            alert("Please select a tag")
            return
        }
        if(this.reference == null || this.reference == "" || this.reference == undefined){
            alert("Please enter reference")
            return
        }

        this.loading = true
        var lines = this.reference.split('\n');
        let unique = [...new Set(lines)];
        let payload = {reference_no_list:unique,tag:this.selectedTag["id"]}
        this.totalDuplicate = lines.length - unique.length
        const end = "bulk-product-tag"
        console.log(payload)
        
        postAPI(end,payload).then(res => {
                const data = res.data
                this.successList = data["successful_products"]
                this.errorList = data["errors"]
                this.loading = false
                })

        }
    },

    created () {
        getAPI('product-tags?limit=10000&ordering=name&').then((res) => {this.tagModel = res.data.results})

    }



}
</script>

<style>

</style>