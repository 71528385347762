<template>
  <div class="row mt-2">
    <div class="offset-md-10 col-md-2">
      <div class="col-md-7 align-self-end">
        <button @click="addNew" class="btn btn-primary">Add Payment</button>
      </div>
    </div>
  </div>

  <!-- Modal -->

  <PaymentModal
    :payment="payment"
    @submit="updateTableData"
    @update="updateTableData"
    :modal="modal"
  ></PaymentModal>

  <!-- Modal End -->

  <!-- Delete Dialog -->
  <Dialog
    v-model:visible="deleteDialogShow"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
  >
    <div class="confirmation-content">
      <span>Are you sure you want to delete?</span>
    </div>
    <template #footer>
      <div class="row justify-content-center">
        <div class="offset-md-8 col-md-2">
          <button
            @click="deleteDialogShow = false"
            type="button"
            class="btn btn-outline-dark d-flex justify-content-center p-button-text"
          >
            No
          </button>
        </div>
        <div class="col-md-2">
          <button
            @click="deletePayment"
            type="button"
            class="btn btn-outline-danger d-flex justify-content-center"
          >
            Yes
          </button>
        </div>
      </div>
    </template>
  </Dialog>
  <!-- Delete Dialog End -->

  <div class="row justify-content-center">
    <div class="col-md-7">
      <table class="table table-bordered table-responsive text-center">
        <thead>
          <tr>
            <th scope="col">Net Sale (Total Sale - Return)</th>
            <th scope="col">Expense</th>
            <th scope="col">Income (Net Sale + Expense)</th>

            <th scope="col">Sale Payments</th>
            <th scope="col">Other Payments</th>
            <th scope="col">Total Payments</th>
            <th scope="col">Balance</th>
            <th scope="col">Consignee</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ formatCurrency(totalNetSale) }}</td>
            <td>{{ formatCurrency(totalExpense) }}</td>
            <td>{{ formatCurrency(totalNetSale + totalExpense) }}</td>
            <td>{{ formatCurrency(tableData.total_sale_payment) }}</td>

            <td>
              {{
                formatCurrency(
                  tableData.total_payment - tableData.total_sale_payment
                )
              }}
            </td>

            <td>{{ formatCurrency(tableData.total_payment) }}</td>
            <td>
              {{
                formatCurrency(
                  tableData.total_payment - totalNetSale - totalExpense
                )
              }}
            </td>
            <td>{{ formatCurrency(totalConsignee) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <Table
        v-show="showTable && activeIndex === 3"
        excelName="customer-payments"
        excelTabName="Payments"
        :endPoint="endPoint"
        :tableInfo="tableInfo"
        :useCreate="true"
        showRoute="true"
        detailPath="/sales/"
        idName="sale"
        @deleteEvent="confirmDeletePayment"
        @editEvent="editPayment"
        :key="tableKey"
      />
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { getAPI, postAPI, patchAPI, putAPI, deleteAPI } from "@/global/callAPI";
import PaymentModal from "@/components/PaymentModal.vue";
export default {
  components: { Table, PaymentModal },
  data: function () {
    return {
      showTable: true,
      tableInfo: [
        { field: "payment_id", header: "ID", order: true },
        { field: "date", header: "Date", order: true },
        { field: "price", header: "Payment Price", order: true },
        { field: "real_payment", header: "Detail" },
        { field: "payment_type_name", header: "Payment Type", order: true },
        { field: "description", header: "Description" },
        { field: "is_end_of_day", header: "End Of Day" },
        { field: "type", header: "Type" },

        { field: "sale_reference", header: "Sale Reference" },
        { header: "Edit", field: "edit", isEdit: true },
        { header: "Delete", field: "delete", isDelete: true },
      ],
      payment: {
        price: null,
        status: null,
        date: null,
        payment_type: null,
        real_payment: null,
        description: null,
        customer: this.id,
      },
      modal: "new",
      deleteDialogShow: false,
      tableKey: 0,
      totalNetSale: 0,
      totalExpense: 0,
      balance: null,
      totalConsignee: 0,

      tableData: {
        total_payment: 0,
        total_sale_payment: 0,
      },
    };
  },
  props: {
    id: {
      default: null,
    },
    activeIndex: {
      default: 3,
    },
  },
  methods: {
    addNew() {
      this.payment = {
        price: null,
        status: null,
        date: null,
        payment_type: null,
        real_payment: null,
        end_of_day_data: {},
        is_end_of_day: false,
        customer: this.id,
      };

      this.modal = "new";
      jQuery("#payment").modal("show");
    },
    updateTableData() {
      this.tableKey += 1;
    },

    closeModel() {
      jQuery("#payment").modal("hide");
    },
    confirmDeletePayment(data) {
      this.deleteDialogShow = true;
      this.deleteId = data.payment_id;
    },

    deletePayment() {
      deleteAPI("payments/" + this.deleteId).then((res) => {
        if (res) {
          Toast2.fire({
            icon: "success",
            title: "Payment deleted successfully",
          });
          this.tableKey += 1;
        }

        this.deleteDialogShow = false;
      });
    },

    editPayment(data) {
      this.payment = data;
      this.modal = "edit";
      jQuery("#payment").modal("show");
    },

    updatePayment() {
      patchAPI("payments/" + this.payment.payment_id, this.payment).then(
        (res) => {
          if (res.statusText === "OK") {
            Toast2.fire({
              icon: "success",
              title: "Payment updated successfully",
            });
            this.tableKey += 1;
          } else {
            Toast2.fire({ icon: "warning", title: res.statusText });
          }
        }
      );

      jQuery("#payment").modal("hide");
    },
    formatCurrency(value) {
      if (value) {
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      } else {
        return 0;
      }
    },
  },
  created: function () {
    this.endPoint = "payments?" + "customer=" + this.id;
    getAPI("sum-payments?" + "customer=" + this.id).then((res) => {
      this.tableData = res.data;
      console.log(res.data);
    });
    getAPI("sum-sale-customers?" + "&sale_id__customer_id__id=" + this.id).then(
      (res) => {
        const data = res.data;
        this.totalNetSale =
          data.completed_total_net_sale +
          data.awaiting_net_sale -
          data.sale_return_total_net_sale;
        this.totalConsignee =
          data.consignee_total_net_sale - data.consignee_return_total_net_sale;
        this.totalExpense =
          data.completed_total_expense +
          data.awaiting_total_expense -
          data.return_total_expense;
      }
    );
  },
};
</script>

<style></style>
