<template>
    <Menu />

<div class="container-fluid">
    <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px"><strong>New Customer</strong> </h6>
	<hr style="height:0.5px;">
    
    <form @submit.prevent="submitCustomer" id="createCustomerModel">

        <div class="row justify-content-center">
            
            <!-- Name Input -->
            <div class="col-md-2">
                <label for="nameInput">Name</label>
                    <input class="form-control" type="text" name="Customer Name" 
                    id="nameInput" v-model="customerModel.customer_name" required>
            </div>
            <!-- Surname Input -->
            <div class="col-md-2">
                <label for="surnameInput">Surname</label>
                <input type="text" name="Surname" id="surnameInput" class="form-control"
                v-model="customerModel.customer_surname" required>
            </div>
            <div class="col-md-2">
                <label for="customerTag">Tags</label>

                <v-select  :options="tagModel"
                 v-model="customerModel.tags"
                  id="customerTag"
                :reduce = "tag => tagConverter(tag.id)"
                 label="name"
                :multiple=true
                 />

            </div>
                
        </div>

        <div class="row justify-content-center">
            <!-- Mobile Phone Input -->
            <div class="col-md-2">
                <label for="phoneInput">Mobile Phone</label>
                <input type="text" name="Mobile Phone" id="phoneInput" class="form-control" v-model="customerModel.gsm" >
            </div>
            <!-- Other Phone Input -->
            <div class="col-md-2">
                <label for="otherPhoneInput">Other Phone</label>
                <input type="text" name="Other Phone" id="otherPhoneInput" class="form-control" v-model="customerModel.phone1">
            </div>

            <!-- Business Phone -->
            <div class="col-md-2">
                <label for="businessPhone">Business Phone</label>
                <input type="text" name="Business Phone" class="form-control" id="businessPhone" v-model="customerModel.business_phone">
            </div>


        </div>

        <div class="row justify-content-center">

            <!-- Fax Input -->
            <div class="col-md-2">
                <label for="faxInput">Fax</label>
                <input type="text" name="Fax" class="form-control" id="faxInput" v-model="customerModel.fax"> 
            </div>
                <!-- Address Input -->
            <div class="col-md-4">
                <label for="addressInput">Address</label>
                <input type="text" name="Address" class="form-control" id="addressInput" v-model="customerModel.address"> 
            </div>
        </div>

        <div class="row justify-content-center">

            <!-- Disctrict Input -->
            <div class="col-md-2">
                <label for="districtInput">District</label>
                <input type="text" name="Disctrict" class="form-control" id="districtInput" v-model="customerModel.district"> 
            </div>

            <!-- City Input -->
            <div class="col-md-2">
                <label for="cityInput">City</label>
                <input type="text" name="City" class="form-control" id="cityInput" v-model="customerModel.city"> 
            </div>

            <!-- Country Input -->

            <div class="col-md-2">
                <label for="countryInput">Country</label>
                <input type="text" name="Country" class="form-control" id="countryInput" v-model="customerModel.country"> 
            </div>
            
        </div>

        <div class="row justify-content-center">
            <!-- Tax Id Input -->

        <div class="col-md-2">
            <label for="taxIdInput">Tax Id</label>
            <input type="text" name="Tax Id" class="form-control" id="taxIdInput" v-model="customerModel.tax_id"> 
        </div>
        <div class="col-md-4">
            <!-- Business Address Input -->

            <label for="businessAddressInput">Business Address</label>
            <input type="text" name="Business Address" class="form-control" id="businessAddressInput" v-model="customerModel.business_address"> 
        </div>
    </div>

        <div class="row justify-content-center">

            <!-- Business Disctrict Input -->
            <div class="col-md-2">
                <label for="businessDistrictInput">Business District</label>
                <input type="text" name="Business District" class="form-control" id="businessDistrictInput" v-model="customerModel.business_district"> 
            </div>

            <!-- Business City Input -->
            <div class="col-md-2">
                <label for="businessCityInput">Business City</label>
                <input type="text" name="Business City" class="form-control" id="businessCityInput" v-model="customerModel.business_city"> 
            </div>

            <!-- Business Country Input -->

            <div class="col-md-2">
                <label for="businessCountryInput">Business Country</label>
                <input type="text" name="Business Country" class="form-control" id="businessCountryInput" v-model="customerModel.business_country"> 
            </div>

        </div>

        <div class="row justify-content-center">
            <!-- Birth Date Input -->
            <div class="col-md-2">
                <label for="birthDateInput">Birth Date</label>
                <input type="date" name="Birth Date" class="form-control" id="birthDateInput" v-model="customerModel.birth_date"> 
            </div>

            <!-- Finger Size Input -->
            <div class="col-md-1">
                <label for="fingerSizeInput">Finger Size</label>
                <input type="text" name="Finger Size" class="form-control" id="fingerSizeInput" v-model="customerModel.finger_size"> 
            </div>

            <div class="col-md-1">
                <label for="genderInput">Gender</label>
                <select name="Gender" class="form-control" id="genderInput" v-model="customerModel.gender">
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
            </div>

            <!-- E Mail Input -->
            <div class="col-md-2">
                <label for="emailInput">E-Mail</label>
                <input type="email" name="E-Mail" class="form-control" id="emailInput" v-model="customerModel.mail"> 
            </div>
        </div>

        <div class="row justify-content-center">
            <!-- Wedding Date -->
            <div class="col-md-2">
                <label for="weddingDateInput">Wedding Date</label>
                <input type="date" name="Wedding Date" class="form-control" id="weddingDateInput" v-model="customerModel.wedding_date"> 
            </div>

            <!-- Partner Birth Date -->
            <div class="col-md-2">
                <label for="partnerBirthDateInput">Partner Birth Date</label>
                <input type="date" name="Partner Birth Date" class="form-control" id="partnerBirthDateInput" v-model="customerModel.partner_birth_date"> 
            </div>

            <!-- Partner Email -->
            <div class="col-md-2">
                <label for="partnerEmailInput">Partner E-Mail</label>
                <input type="email" name="Partner E-Mail" class="form-control" id="partnerEmailInput" v-model="customerModel.partner_mail"> 
            </div>
        </div>

        <div>
            <div class="row justify-content-center mt-3">
                <div class="col-md-1">
			    <button id="" class="btn btn-secondary" type="submit" >Submit</button>
			    <!-- <button id="form-submit" type="submit" >Submit</button> -->
            </div>
<!-- 
            <div class="col-md-12">
                <Table
                excelName = "deneme"
                excelTabName = "deneme"
                :endPoint = "endPoint"
                :tableInfo = "tableInfo"
                :tableData = "tableData"
                />

                </div> -->
            </div>
		</div>



    </form>

</div>


</template>

<script>
import Menu from '@/components/Menu';
import {getAPI, postAPI} from '@/global/callAPI';

export default {
    name:"NewCustomer",
    components: {Menu},
    data: function() {
        return {
            customerModel: {
                customer_name: null,
                customer_surname:null,
                phone_1: null,
                business_phone: null,
                gsm:null,
                fax:null,
                address: null,
                district: null,
                city: null,
                country: null,
                tax_id:null,
                business_address: null,
                business_district: null,
                business_city: null,
                business_country: null,
                birth_date: null,
                wedding_date: null,
                mail: null,
                gender: null,
                finger_size: null,
                partner_birth_date: null,
                partner_mail:null,
                tags:[]
            },
            tagModel:[]
        }
    },

    methods: {
        submitCustomer: function () {
            let submit = this.customerModel
            Object.keys(submit).forEach(k => submit[k] = submit[k] === '' ? null : submit[k])


            postAPI('customers', submit )
            .then(res => this.$router.push('/customers/'+res.data.id));

        },
        tagConverter(id) {
            return {tag:id}
    },
    },
    created () {
        getAPI('customer-tags?limit=10000&ordering=name').then((res) => {this.tagModel = res.data.results});

    },

}
</script>

<style scoped>
.row justify-content-center {
    padding: 5px;
}


/* .container-fluid {
    background-color: #f5f5f5;
} */

</style>