<template>
  <Menu />
  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      Sale Products List
    </h6>
    <hr />

    <form @submit.prevent="applyFilter()">
      <div class="d-flex flex-fill mb-3">
        <button
          v-for="cFilter in customDateFilter"
          @click="applyCustomDateFilter(cFilter.start_date, cFilter.end_date)"
          :key="cFilter.header"
          type="button"
          class="btn btn-secondary btn-sm mt-2 custom-date-filter-button text-wrap"
        >
          {{ cFilter.label }}
        </button>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-2">
          <label type="date" for="nameInput">Start Date</label>
          <input
            class="form-control"
            type="date"
            name="Customer Name"
            id="nameInput"
            v-model="filterModel.start_date"
          />
        </div>

        <div class="col-md-2">
          <label for="nameInput">End Date</label>
          <input
            class="form-control"
            type="date"
            name="Customer Name"
            id="nameInput"
            v-model="filterModel.end_date"
          />
        </div>
        <div class="col-md-2">
          <label for="saleStatus">Sale Status</label>
          <!-- <select v-model="filterModel.sale_status" id="saleStatus" class="form-select">
                        <option value="consignee,awaiting,completed,canceled">All</option>
                        <option value="completed">Completed</option>
                        <option value="awaiting">Awaiting</option>
                        <option value="consignee">Consignee</option>
                        <option value="canceled">Canceled</option>
                    </select> -->
          <v-select
            :options="statusModel"
            label="label"
            v-model="filterModel.sale_status"
            :reduce="(status) => status.value"
            :multiple="true"
          />
        </div>

        <div class="col-md-2">
          <label for="saleStatus">Seller</label>
          <!-- <select v-model="filterModel.seller" id="saleStatus" class="form-select">
                        <option value="">All</option>
                        <option v-for="seller in sellerModel" :value="seller.id" :key="seller.id">{{ seller.name }}
                        </option>
                    </select> -->
          <v-select
            :options="sellerModel"
            label="name"
            v-model="filterModel.seller"
            :reduce="(seller) => seller.id"
            :multiple="true"
          />
          <!-- <small>{{filterModel.seller}}</small> -->
        </div>

        <div class="col-md-2">
          <label for="customer">Customer</label>
          <SelectCustomer
            id="customerId"
            :customer-model="customerModel"
            v-model="filterModel.customer"
            :selected="filterModel.customer"
          >
          </SelectCustomer>
        </div>

        <div
          class="col-md-2"
          v-if="hasPermission(['limited_seller', 'seller'])"
        >
          <label for="storeInput">Store</label>
          <!-- <select id="storeInput" class="form-control" v-model="filterModel.store">
                        <option value="">All</option>
                        <option v-bind:value="s.id" v-for="s in storeModel" :key="s.id">{{ s.store_name }}</option>
                    </select> -->
          <v-select
            :options="storeModel"
            label="store_name"
            v-model="filterModel.store"
            :reduce="(store) => store.id"
            :multiple="true"
          />
        </div>
        <div class="col-md-2" v-else></div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-md-2">
          <label for="modelNumber">Model Number</label>
          <input
            type="text"
            id="modelNumber"
            v-model="filterModel.model_no"
            class="form-control"
          />
        </div>
        <div class="col-md-2">
          <label for="sale_type">Jewel & Watch</label>
          <select
            id="sale_type"
            v-model="filterModel.product_type"
            class="form-control"
          >
            <option value="">All</option>
            <option
              value="product_id__jewel__product__product_id__isnull=false"
            >
              Jewel
            </option>
            <option value="product_id__jewel__product__product_id__isnull=true">
              Watch
            </option>
          </select>
        </div>

        <div class="col-md-2 justify-content-center">
          <label for="subType">Jewel Model</label>
          <!-- <MultiSelect id="subType"  v-model="filterModel.jewelSubtype" :options="subTypeModel" :filter="true" optionLabel="name" /> -->
          <v-select
            multiple
            v-model="filterModel.jewelSubtype"
            :options="subTypeModel"
            :reduce="(type) => type.id"
            label="name"
          />
        </div>

        <div class="col-md-2">
          <label for="productType">Product Type</label>
          <v-select
            multiple
            v-model="filterModel.jewelProductType"
            :options="producTypeModel"
            :reduce="(type) => type.id"
            label="type_name"
          />
        </div>

        <div class="col-md-2">
          <label for="productType">Watch Brand</label>
          <v-select
            multiple
            v-model="filterModel.watchBrand"
            :options="brandModel"
            :reduce="(brand) => brand.id"
            label="brand_name"
          />
        </div>
        <div class="col-md-2">
          <label for="Sale Tag">Sale Tag</label>
          <v-select
            :options="saleTagModel"
            label="name"
            v-model="filterModel.tags"
            :reduce="(tag) => tag.id"
            :multiple="true"
          />
        </div>
        <!-- <div class="col-md-1" v-show="hasPermission(['limited_seller', 'seller', 'office'])"></div> -->
      </div>

      <div class="row mt-3">
        <div
          v-if="
            filterModel.product_type ===
            'product_id__jewel__product__product_id__isnull=true'
          "
          class="col-md-2"
        >
          <label for="productType">Watch Condition</label>
          <v-select
            multiple
            v-model="filterModel.condition"
            :options="conditionModel"
            :reduce="(condition) => condition.id"
            label="name"
          />
        </div>
        <div class="col-md-2">
          <label for="referenceNo">Reference No</label>
          <input
            type="text"
            id="referenceNo"
            v-model="filterModel.reference_no"
            class="form-control"
          />
        </div>

        <div class="col-md-2">
          <label for="minPrice">Min Price</label>
          <input
            type="number"
            step="0,01"
            id="minPrice"
            v-model="filterModel.min_price"
            class="form-control"
          />
        </div>

        <div class="col-md-2">
          <label for="maxPrice">Max Price</label>
          <input
            type="number"
            step="0,01"
            id="maxPrice"
            v-model="filterModel.max_price"
            class="form-control"
          />
        </div>
      </div>
      <div class="row mt-2 justify-content-center">
        <div class="col-md-2">
          <button id="" class="btn btn-secondary">Filter</button>
        </div>

        <hr class="mt-2" />
      </div>

      <div class="row justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <table class="table table-bordered" v-if="showTable">
            <thead>
              <tr>
                <!-- <th>Count</th> -->
                <!-- <th>Total Sale</th> -->
                <th>Total Net Sale</th>
                <th>Total Tag Price</th>
                <!-- <th>Total Expense </th> -->
                <th v-if="hasPermission(['limited_seller'])">Total Profit</th>
                <th
                  v-if="hasPermission(['limited_seller', 'seller', 'office'])"
                >
                  <span>Total MT</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <!-- <td>{{saleCount}}</td> -->
                <!-- <td>{{formatCurrency(sumModel["total_sale"] + sumModel["total_expense"] || null )}}</td> -->
                <td>{{ formatCurrency(sumModel["total_sale"]) }}</td>
                <td>{{ formatCurrency(sumModel["total_ticket_price"]) }}</td>
                <!-- <td>{{formatCurrency(sumModel["total_expense"])}}</td> -->
                <td v-if="hasPermission(['limited_seller'])">
                  {{ formatCurrency(sumModel["total_profit"]) }}
                </td>

                <td
                  v-if="hasPermission(['limited_seller', 'seller', 'office'])"
                >
                  {{ formatCurrency(sumModel["total_custom_profit"]) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-12">
          <Table
            v-if="showTable"
            excelName="deneme"
            excelTabName="deneme"
            :tableInfo="tableInfo"
            showRoute="true"
            @inputEvent="customProfit"
            detailPath="/sales/"
            idName="sale_id"
            :useCreate="true"
            :key="tableKey"
            :endPoint="endPoint"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { getAPI, patchAPI } from "@/global/callAPI";
import Table from "@/components/Table";
import SelectCustomer from "@/components/SelectCustomer";
import { formatCurrency } from "@/global/functions";

export default {
  name: "CustomerList",
  components: { Menu, Table, SelectCustomer },
  data: function () {
    return {
      showTable: false,
      tableKey: 0,
      endPoint: null,
      customerModel: [],
      tagModel: [],
      sellerModel: [],
      saleCount: 0,
      totalNetSale: null,
      totalSale: null,
      totalExpense: null,
      totalProfit: null,
      totalTicketPrice: null,
      storeModel: [],
      producTypeModel: [],
      subTypeModel: [],
      brandModel: [],
      sumModel: {},
      tableInfo: [
        { field: "thumbnail", header: "Thumbnail", isImage: true },
        { field: "reference_no", header: "Reference No", order: true },
        { field: "sale_reference", header: "Sale Reference" },
        { field: "sale_date", header: "Date", order: true },
        { field: "customer", header: "Customer", order: true },
        { field: "seller", header: "Seller", order: true },
        { field: "store", header: "Store" },
        { field: "ticket_price", header: "Tag Price" },
        { field: "price", header: "Sale Price", order: true },
        { field: "original_price_formatted", header: "Original Sale Price" },

        { field: "sale_status", header: "Status" },
      ],
      filterModel: {
        start_date: null,
        end_date: null,
        customer: null,
        status: "is_last=true&is_return=false",
        seller: null,
        product_type: null,
        model_no: null,
        jewelSubtype: null,
        jewelProductType: null,
        watchBrand: null,
        sale_status: "consignee,awaiting,completed,canceled",
        store: [Number(this.$store.state.store_id)],
        tags: [],
        reference_no: null,
        min_price: null,
        max_price: null,
        condition: [1],
      },
      sales: null,
      oldFilters: null,
      genderFilter: ["male", "female"],
      tagFilter: [],
      loading: false,
      tagInput: null,
      saleTagModel: [],
      today: new Date(),
      customDateFilter: [
        {
          label: "All Time",
          value: "last_7_days",
          start_date: "2000-01-01",
          end_date: new Date(new Date().getFullYear(), 11, 31)
            .toISOString()
            .slice(0, 10),
        },

        {
          label: "Last Month",
          value: "last_month",
          start_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 1,
            2
          )
            .toISOString()
            .slice(0, 10),
          end_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            .toISOString()
            .slice(0, 10),
        },

        {
          label: "Last 6 Months",
          value: "last_month",
          start_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 6,
            2
          )
            .toISOString()
            .slice(0, 10),
          end_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            .toISOString()
            .slice(0, 10),
        },

        {
          label: "Last Year",
          value: "last_year",
          start_date: new Date(new Date().getFullYear() - 1, 0, 2)
            .toISOString()
            .slice(0, 10),
          end_date: new Date(new Date().getFullYear(), 0, 1)
            .toISOString()
            .slice(0, 10),
        },

        {
          label: "This Year",
          value: "this_year",
          start_date: new Date(new Date().getFullYear(), 0, 2)
            .toISOString()
            .slice(0, 10),
          end_date: new Date(new Date().getFullYear(), 11, 31)
            .toISOString()
            .slice(0, 10),
        },
        {
          label: "This Month",
          value: "this_month",
          start_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            2
          )
            .toISOString()
            .slice(0, 10),
          end_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            1
          )
            .toISOString()
            .slice(0, 10),
        },

        {
          label: "Today",
          value: "today",
          start_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          )
            .toISOString()
            .slice(0, 10),
          end_date: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          )
            .toISOString()
            .slice(0, 10),
        },
      ],
      statusModel: [
        { label: "All", value: "consignee,awaiting,completed,canceled" },
        { label: "Completed", value: "completed" },
        { label: "Consignee", value: "consignee" },
        { label: "Awaiting", value: "awaiting" },
        { label: "Canceled", value: "canceled" },
      ],
      conditionModel: [],
    };
  },
  methods: {
    formatCurrency: formatCurrency,
    applyFilter() {
      if (this.filterModel.start_date === null) {
        window.alert("Please enter a start date!");
        return false;
      }

      const isEmpty = Object.values(this.filterModel).every(
        (x) => x === null || x === "" || x.length === 0
      );
      if (isEmpty === true) {
        window.alert("Please enter a filter!");
        return false;
      }

      function listToString(list) {
        try {
          return list.toString();
        } catch (err) {
          console.log("error");
          return list;
        }
      }
      let end_date = this.filterModel.end_date || "";
      let start_date = this.filterModel.start_date || "";
      let customer = this.filterModel.customer || "";
      let status = this.filterModel.status || "";
      let seller = this.filterModel.seller || "";
      let product_type = this.filterModel.product_type || "";
      let model_no = this.filterModel.model_no || "";
      let jewelSubtype = this.filterModel.jewelSubtype || "";
      let jewelProductType = this.filterModel.jewelProductType || "";
      let watchBrand = listToString(this.filterModel.watchBrand) || "";
      let sale_status = this.filterModel.sale_status || "";
      let store = this.filterModel.store || "";
      let tags = this.filterModel.tags || "";
      let reference_no = this.filterModel.reference_no || "";
      let min_price = this.filterModel.min_price || "";
      let max_price = this.filterModel.max_price || "";

      if (sale_status.length === 0 || sale_status === null) {
        sale_status = this.statusModel.filter(
          (item) => item.label === "All"
        )[0]["value"];
      }

      let filterList = [
        `date__lte=${end_date}`,
        `date__gte=${start_date}`,
        `sale_id__customer_id__id__in=${customer}`,
        `sale_id__seller_id__id__in=${seller}`,
        // `product_type=${product_type}`,
        product_type,
        `sale_id__sale_status__in=${sale_status}`,

        // status,
        `product_id__model_no__icontains=${model_no}`,
        `product_id__jewel__subtypes__subtype__id__in=${jewelSubtype}`,
        `product_id__jewel__product_type__product_type__id__in=${jewelProductType}`,
        `product_id__watch__brand__id__in=${watchBrand}`,

        `sale_id__store_id__id__in=${store}`,
        //tags
        `sale_id__tags__tag__id__in=${tags}`,

        `product_id__reference_no__istartswith=${reference_no}`,
        `price__gte=${min_price}`,
        `price__lte=${max_price}`,
      ];

      if (
        this.filterModel.product_type ===
        "product_id__jewel__product__product_id__isnull=true"
      ) {
        filterList.push(
          "product_id__watch__condition__id__in=" +
            (this.filterModel.condition || null)
        );
      }
      let query = filterList
        .join("&")
        .replaceAll("isnull", "nbullx")
        .replaceAll("null", "")
        .replaceAll("nbullx", "isnull");

      getAPI("sum-sale-products?limit=1&" + query).then((res) => {
        this.sumModel = res.data;
      });

      this.loading = true;
      this.showTable = true;
      this.endPoint = "sale-products?" + query;
      this.tableKey += 1;
      // getAPI(`sale-products?`+ query)
      // .then(res => {
      //     this.sales = res.data.results;
      //     this.showTable =true
      //     this.calculator(res.data.results)
      //     })
    },
    calculator(value) {
      var netSale = 0;
      var sale = 0;
      var expense = 0;
      var profit = 0;
      var ticketPrice = 0;

      for (const item of value) {
        netSale += item.price || 0;
        ticketPrice += item.ticket_price || 0;
        // expense += item.total_expense
        // sale += item.total_sale_price + item.total_expense
        profit += item.profit || 0;
      }

      this.totalNetSale = netSale;
      this.totalTicketPrice = ticketPrice;
      // this.totalSale = sale
      // this.totalExpense = expense
      this.totalProfit = profit;
      this.saleCount = value.length || 0;
    },

    applyCustomDateFilter(start_date, end_date) {
      this.filterModel.start_date = start_date;
      this.filterModel.end_date = end_date;
    },

    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }
      let user_groups = this.$store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },
    permission(n) {
      return localStorage.getItem("groups").indexOf(n) > -1;
    },
    customProfit(row) {
      try {
        let payload = { custom_profit: Number(row.custom_profit) };
        patchAPI("sale-products/" + row.sale_product_id, payload);
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Custom Profit updated",
          life: 1000,
        });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 3000,
        });
      }
    },
  },
  mounted: function () {
    getAPI("sale-tags?limit=10000").then((res) => {
      this.saleTagModel = res.data.results;
    });

    getAPI("stores?limit=10000&ordering=store_name&").then((res) => {
      this.storeModel = res.data.results;

      this.storeModel.unshift({ id: null, store_name: "All" });
    });

    getAPI("user-name-list?limit=10000&ordering=name").then((res) => {
      this.sellerModel = res.data.results;
    });
    let roles = ["limited_seller"];
    if (this.hasPermission((roles = roles))) {
      this.tableInfo.splice(7, 0, { field: "profit", header: "Profit" });
    }
    if (this.permission("super_user")) {
      this.tableInfo.splice(11, 0, {
        field: "custom_profit",
        header: "MT",
        editable: true,
      });
    }
    getAPI("product-types?limit=1000&ordering=type_name&").then((res) => {
      this.producTypeModel = res.data.results;
    });
    getAPI("jewel-subtypes?limit=1000&ordering=name&").then((res) => {
      this.subTypeModel = res.data.results;
    });
    getAPI("watch-brands?limit=10000&ordering=brand_name").then((res) => {
      this.brandModel = res.data.results;
    });
    getAPI("watch-conditions?limit=1000&ordering=name&").then((res) => {
      this.conditionModel = res.data.results;
    });

    this.loading = true;
    this.oldFilters = this.filters;
  },
};
</script>

<style scoped>
input {
  border-radius: 0px;
}

select {
  border-radius: 0px;
}

td {
  text-align: center;
  vertical-align: middle;
}

th {
  text-align: center;
  vertical-align: middle;
}

.p-multiselect {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 60%;
  border-radius: 0px;
}

.custom-date-filter-button {
  margin-left: 3px;
  font-size: xx-small;
}
</style>
