<template>

  <div class="d-lg-flex half" style="background-color: #fff; ">
    <div class="bg order-1 order-md-2" style="background-color: #fff; ">
      <img src="./magaza.jpg" alt="">
    </div>
    <div class="contents order-2 order-md-1">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-7">
            <h3><strong>Welcome!</strong></h3>
            <p class="mb-4">Please enter your details below to log in.</p>

            <div class="mb-4 card" v-if="incorrectAuth === true" >
              <div class="card-body" id="wrongCard">
                <p >Invalid username or password! Please try again.</p>
              </div>
            </div>


            <form v-on:submit.prevent="login">

              <div class="form-group first">
                <label for="username">Username</label>
                <input v-model="username" type="text" class="form-control" placeholder="Enter your username" id="username" required>
              </div>


              <div class="form-group last mb-3">
                <label for="password">Password</label>
                <input v-model="password" type="password" class="form-control" placeholder="Enter your password" id="password" required>
              </div>
              
              <input id="login-submit" type="submit" value="Log In" class="btn btn-block btn-primary">

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {reactive} from 'vue';
import {useRouter} from "vue-router";
import { postAPI } from '@/global/callAPI';

export default {
  name: "Login",
  data: function () {
    return {
      image: "../assets/logo.png",
      username: '',
      password: '',
      incorrectAuth: false,
    }
  },
    methods: {
      login () { 
        this.incorrectAuth = false
        this.$store.dispatch('userLogin', {
          username: this.username,
          password: this.password
        })
        .then(() => {
          this.$router.push('/new-sales')
        })
        .catch(err => {
          this.incorrectAuth = true
        })
        }
      }
  
}
</script>
<style scoped>

template {
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #fff; 
  margin-left: auto;}


.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Roboto", sans-serif; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a:hover {
    text-decoration: none !important; }

.half, .half .container > .row {
  height: 100vh; }

@media (max-width: 991.98px) {
  .half .bg {
    height: 500px; } }

.half .contents, .half .bg {
  width: 50%; }
  @media (max-width: 1199.98px) {
    .half .contents, .half .bg {
      width: 100%; } }
      
.half .bg {
  background-size: cover;
  background-position: center; }


#login-submit {
  margin-top: 10%;
  background-color: black;
  width: 120px;
  margin-left:auto;
  margin-right:auto;
  border-radius: 20px;
  font-weight: bold;
  border-color: #fff;
}

::placeholder {
  font-size: small;
}

img {
    width:100%;
    height:100%;
    object-fit:cover;
}

#wrongCard {
  background-color: #F2DEDD;
}

#wrongCard > p {
  color: #BD6C69;
}
</style>