<template>

    <v-select 
        :options="stones"
        label="reference_no" 
        @search="onSearch"
        v-model="selected"
        @option:selected="afterSelected"
        :reduce = "stone => stone.id"
    >
    <!-- Seçtikten sonra reference no resetleniyordu bu yüzden böyle bir sistem yaptım -->
        <template #selected-option="{id}">
            <div style="display: flex; align-items: baseline">
                <p>{{selectedReference}}</p>
            </div>
        </template>

    </v-select>

</template>

<script>
import {getAPI} from '../global/callAPI';
export default {
    name:"SelectStone",
    props: {
        selected: null,
        stoneModel: {
            type: Array,
            default: null
        },
    },
    data: function() {
        return {
            stones: [],
            selectedReference: null,
        }
    },

    created: function() {
        let selectedId = this.selected
        // getAPI('stones?limit=5&').then((res) => 
        // {
        //     this.stones = res.data.results;
            if (selectedId > 0) 
            {
            
               getAPI('stones/' + selectedId) .then((res) => {
                   if (this.stones.indexOf(res.data) === -1) {this.stones.push(res.data); this.selectedReference = res.data.reference_no};
                   })
            }
            this.stoneModel.push(this.stones)
        // })
        ;

    },
        methods: {
            onSearch(query){
                let request = "search=" + query
                getAPI('stones?ordering=id&limit=5&'+ request).then((res) => {this.stones = res.data.results});
            },
            afterSelected () {
                let id = this.selected
                getAPI('stones/'+ id).then((res) => {
                    this.stoneModel.push(res.data);
                    this.selectedReference = res.data.reference_no
                    });
                
            },

        }
}
</script>

<style scoped>

</style>