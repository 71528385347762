<template>
        <Menu />

    <div class="container-fluid">
        <!-- <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px"><strong>Manufacturer: {{manufacturerModel.firm_name}}</strong> </h6>
	    <hr style="height:0.5px;margin-bottom:30px"> -->
            <TabMenu 
            :model="items"

            v-model:activeIndex="activeIndex"
                        />
            <router-view/>
        <form v-show="activeIndex === 0"  @submit.prevent="submitManufacturer" id="createManufacturer">
            <div class="row justify-content-center mt-4">
                <!--Firm Name Input -->
                <div class="col-md-2">
                    <label for="firmNameInput">Firm Name</label>
                    <input class="form-control" type="text" name="Firm Name" 
                        id="firmNameInput" v-model="manufacturerModel.firm_name" required>
                </div>

                <!-- Contact Name Input -->
                <div class="col-md-2">
                    <label for="contactNameInput">Contact Name</label>
                    <input class="form-control" type="text" name="Contact Name" 
                        id="contactNameInput" v-model="manufacturerModel.name_surname" required>
                </div>

                <!-- Mail Input -->
                <div class="col-md-2">
                    <label for="typeInput">Type</label>
					<select id="typeInput" v-model="manufacturerModel.type_id" class="form-control" required>
						<option v-bind:value="t.manufacturer_type_id" v-for="t in typeModel" :key="t.manufacturer_type_id">{{t.manufacturer_type}}</option>
					</select>
                </div>
            </div>

            <div class="row justify-content-center ">
                <!--Mobile Phone Input -->
                <div class="col-md-2">
                    <label for="mobilePhoneInput">Mobile Phone Number</label>
                    <input class="form-control" type="text" name="Mobile Phone" 
                        id="mobilePhoneInput" v-model="manufacturerModel.mobile_phone" >
                </div>

                <!-- Phone Input -->
                <div class="col-md-2">
                    <label for="phoneNumberInput">Phone Number</label>
                    <input class="form-control" type="text" name="Phone Number" 
                        id="phoneNumberInput" v-model="manufacturerModel.phone" >
                </div>

                <!-- Fax -->
                <div class="col-md-2">
                    <label for="faxInput">Fax</label>
                    <input class="form-control" type="text" name="Fax" 
                        id="faxInput" v-model="manufacturerModel.fax">
                </div>
            </div>

            <div class="row justify-content-center ">

                <!-- Mail Input -->
                <div class="col-md-2">
                    <label for="mailInput">Mail</label>
                    <input class="form-control" type="email" name="Mail" 
                        id="mailInput" v-model="manufacturerModel.mail">
                </div>

                <!-- Address Input -->
                <div class="col-md-4">
                    <label for="addressInput">Address</label>
                    <input class="form-control" type="text" name="Address" 
                        id="addressInput" v-model="manufacturerModel.address">
                </div>

            </div>
            <div class="row justify-content-center mt-4">
                    <div class="col-md-1">
                        <button id="" class="btn btn-secondary" type="submit" >Submit</button>
                    </div>
                    <div class="col-md-1">
                        <button type="button" class="btn btn-danger" @click="deleteDialog = true" v-if="hasPermission(roles=['limited_seller','seller','office'])">Delete</button>
                    </div>


                </div>
        </form>

        <div v-show="activeIndex === 1" id="salesPage">
            <Table
                excelName = "manufacturer-products"
                excelTabName = "Products"
                :tableInfo = "[
                                {field: 'id', header: 'Id',order:true},
                                {field: 'reference_no', header: 'Reference'},
                                // {field: 'created_at', header: 'Date'},
                                // {field: 'manufacturer_name', header: 'Manufacturer'},
                                {field: 'type_name', header: 'Type',order:true},
                                {field: 'price', header: 'Price',order:true},

                            ]"
                showRoute = true
                detailPath = "/products/"

                idName = "product"
                :useCreate = true

                :endPoint = "tableEndPoint" 
                
            />
            <!-- <ManufacturerItemProducts  :products="products"/> -->
        </div>

    </div>


<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
    <div class="confirmation-content">
        <span>Are you sure you want to delete?</span>
    </div>
    <template #footer>
        <div class="row justify-content-center ">
            <div class="offset-md-8 col-md-2">
                <button @click="deleteDialog = false" type="button" class="btn btn-outline-dark d-flex justify-content-center p-button-text">No</button>
            </div>
            <div class="col-md-2">
                <button @click="deleteManufacturer" type="button" class="btn btn-outline-danger d-flex justify-content-center">Yes</button>
            </div>
        </div>       
        
    </template>
</Dialog>


</template>

<script>
import Menu from '@/components/Menu';
import {getAPI, postAPI, patchAPI, deleteAPI} from '@/global/callAPI';
import ManufacturerItemProducts from '@/views/Manufacturer/ManufacturerItemProducts.vue'
import Table from '@/components/Table.vue'
export default {
    name:"NewManufacturerItem",
    components: {Menu,ManufacturerItemProducts,Table,},
    data: function () {
        return {
            activeIndex:0,
            tableEndPoint:`product-manufacturers?manufacturer=${this.$route.params.id}`,
            manufacturerModel: {
                name_surname:null,
                firm_name:null,
                address:null,
                phone:null,
                mobile_phone:null,
                fax:null,
                mail:null,
                type_id:null,
            },
            typeModel:null,
            isActive: true,
            products: null,
            items: [
		        {label: 'Edit'},
		        {label: 'Products'}
            ],
            deleteDialog: false,

        }
    },

    methods: {
        tabChange: function () {
            this.isActive = !this.isActive
        },
        submitManufacturer: function () {
            let submit = this.manufacturerModel
            try {
                patchAPI("manufacturers/"+ this.$route.params.id, submit);
                this.$toast.add({severity:'success', summary: 'Success', detail:'Manufacturer updated!', life: 1000});


            } catch (error){
                this.$toast.add({severity:'error', summary: "Error!", detail:error, life: 3000});
            }
        },
        deleteManufacturer () {
            deleteAPI("manufacturers/"+ this.$route.params.id,)
                .then((res) => {

                if(res["status"] === 204){
                    Toast2.fire({icon: 'success', title: 'Manufacturer Deleted!'});
                    this.$router.push("/home")
                } else {
                    Toast2.fire({icon:'warning',title: res.statusText})
                    this.deleteDialog = false
                }
                })},
                hasPermission(roles=null) {
            if (roles === null) {return true}
            let user_groups = this.$store.state.groups
            if (Array.isArray(user_groups)) {} else {user_groups = user_groups.split(',')}
            if (user_groups.some(r=> roles.includes(r)) ) {
                return false
            } else {
                return true
            }
        },
                
    },
    created: function () {
        getAPI("manufacturer-types?limit=1000").then(res => this.typeModel = res.data.results)
        getAPI("manufacturers/detail/" +  this.$route.params.id).then(res => 
        {
            this.manufacturerModel = res.data;
            this.products = res.data.products;
        })

    },
}
</script>

<style>
.row justify-content-center  {
    padding: 5px;
}
</style>