<template>
    
    <v-select 
        :key="tableKey"
        :reduce = "customer => customer.id"
        :options="customers"
        label="customer_name"
        :filterable = false
        @search="onSearch"
        v-model="selected"
        @option:selected="afterSelected"
    >
  
    <!-- Seçtikten sonra reference no resetleniyordu bu yüzden böyle bir sistem yaptım -->
        <template #selected-option="{}">
            <div style="display: flex; align-items: baseline" >
                <p>{{selectedName}} </p>
            </div>
        </template>
        <template #option="{ customer_name, customer_surname }">
            <p>{{customer_name + " " + customer_surname}}</p>
        </template>
        
    </v-select>

</template>

<script>
import {getAPI} from '../global/callAPI';
export default {
    name:"SelectCustomer",
   
    props: {
        multiple: {
            default:false
        },
        selected: null,
        customerModel: {
            type: Array,
            default: null
        },
    },
    data: function() {
        return {
            value:null,
            tableKey:0,
            customers: [],
            selectedName: null,
            inProcess:false,
            closeSelect:false,
        }
    },

    created: function() {
        let selectedId = this.selected
        getAPI('customer-search?limit=15&').then((res) => 
        {
            this.customers = res.data.results;
            if (selectedId > 0) 
            {
            
               getAPI('customer-search/' + selectedId) .then((res) => {
                   if (this.customers.indexOf(res.data) === -1) {this.customers.push(res.data); this.selectedName = res.data.customer_name + " " + res.data.customer_surname};
                   })
            }
            this.customerModel.push(this.customers)
        })
        ;

    },
        methods: {
            onSearch2 (){
 
            },
            onSearch(query){
                if (query === ""){return false}
                    let request = "search=" + query
                    getAPI('customer-search?limit=15&'+request).then((res)=>{this.customers  = res.data.results})
                         
            },
            afterSelected () {

                let id = this.selected
                getAPI('customer-search/'+ id).then((res) => {
                    this.customerModel.push(res.data);
                    this.selectedName = res.data.customer_name + " " + res.data.customer_surname
                    });
                
            },

        }
}
</script>

<style scoped>



</style>