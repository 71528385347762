<template>
<Menu />

    <AddProperty 
        endPoint='stores'
        pageName="Add Store"
        fieldName="store_name"
    />


</template>

<script>
import Menu from '@/components/Menu';
import AddProperty from '@/components/AddProperty.vue'
export default {
    components: {Menu,AddProperty},
    data: function () {
        return {}
    }
    

}
</script>

<style>

</style>