import { Toast } from 'bootstrap'; import { patchAPI } from '@/global/callAPI';
<template>
  <Toast />
  <Menu />
  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      Loan List
    </h6>
    <hr />

    <!-- <form @submit.prevent="applyFilter()"> -->
    <div class="row justify-content-center">
      <!-- <div class="col-md-2">
          <label for="nameInput">Name</label>
          <input
            class="form-control"
            type="text"
            name="Customer Name"
            id="nameInput"
            v-model="filterModel.name"
          />
        </div>

        <div class="col-md-2">
          <label for="nameInput">Surname</label>
          <input
            class="form-control"
            type="text"
            name="Customer Name"
            id="nameInput"
            v-model="filterModel.surname"
          />
        </div>
        <div class="col-md-3">
          <label for="nameInput">Tags</label>

          <v-select
            :options="tagModel"
            v-model="filterModel.tags"
            id="customerTag"
            :reduce="(tag) => tag.id"
            label="name"
            :multiple="true"
          />
        </div>
        <div class="col-md-2 my-3">
          <button id="" class="btn btn-secondary" @click="applyFilter">
            Filter
          </button>
        </div> -->

      <!-- <hr /> -->

      <div class="col-md-11">
        <Table
          :key="componentKey"
          v-if="showTable === true"
          excelName="loans-export"
          excelTabName="loans"
          :tableInfo="tableInfo"
          :showRoute="true"
          idName="id"
          :useCreate="true"
          :endPoint="endPoint"
          detailPath="/loans/"
        />
      </div>
    </div>
    <!-- </form> -->
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { getAPI, patchAPI } from "@/global/callAPI";
import Table from "@/components/Table";
export default {
  name: "CustomerList",
  components: { Menu, Table },
  data: function () {
    return {
      showTable: false,
      tagModel: [],
      tableInfo: [
        { field: "id", header: "Id", order: true },
        {
          field: "is_completed_text",
          header: "Is Approved",
        },

        { field: "reference_no", header: "Reference No", order: true },
        { field: "created_at", header: "Created At", order: true },
        { field: "start_date", header: "Start Date", order: true },
        { field: "finish_date", header: "Finish Date", order: true },
        { field: "store", header: "Store", order: true },
        { field: "creator_user", header: "Creator User", order: true },
        { field: "approved_user", header: "Approved User", order: true },
      ],
      filterModel: {
        // name: this.$route.query.customer_name__istartswith || null,
        // surname: this.$route.query.customer_surname__istartswith || null,
        // tags: this.$route.query.tags__tag__id__in || null,
      },
      loans: null,
      loading: false,
      filters: [],
      componentKey: 0,
      endPoint: "loans",
    };
  },
  methods: {
    applyFilter() {
      //   let customer_name = this.filterModel.name || "";
      //   let customer_surname = this.filterModel.surname || "";
      //   let tags = this.filterModel.tags || "";
      //   let filterList = [
      //     `customer_name__icontains=${customer_name}`,
      //     `customer_surname__icontains=${customer_surname}`,
      //     `tags__tag__id__in=${tags}`,
      //   ];
      //   this.filters = filterList;
      //   let query = filterList
      //     .join("&")
      //     .replaceAll("null", "")
      //     .replace("nbull", "null");
      //   this.endPoint = `customer-search?` + query;
      //   this.componentKey += 1;

      this.endPoint = "loans?";
    },
    showAll() {
      this.applyFilter(1000000);
    },
    descriptionUpdate(data) {
      const description = data["description"];

      patchAPI("loans/" + data["id"], { description: description }).then(
        (response) => {
          if (response.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Description updated",
              life: 1000,
            });
          }
        }
      );
    },
  },
  created: function () {
    this.applyFilter();
    this.componentKey += 1;
    this.loading = true;
    this.oldFilters = this.filters;
    this.showTable = true;
  },
};
</script>

<style scoped></style>
