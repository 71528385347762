<template>
    <Menu />
    
        <AddProperty 
            endPoint='catalog'
            pageName="Add Catalog"
            fieldName="name"
        />
    
    
    </template>
    
    <script>
    import Menu from '@/components/Menu';
    import AddProperty from '@/components/AddProperty.vue'
    export default {
        components: {Menu,AddProperty},
        data: function () {
            return {}
        }
        
    
    }
    </script>
    
    <style>
    
    </style>