<template>
    <Menu />

    <div class="container-fluid">
        <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px"><strong>Connect Database</strong> </h6>
        <hr style="height:0.5px;margin-bottom:30px">

    <div class="row justify-content-center">

        <div class="col-md-8 card" style="width: 50rem;width:50rem;">
            <ul style="margin-top:30px">
            <p >To connect database first you need download <a  target="_blank" href="https://www.pgadmin.org/download/">PgAdmin</a>.</p>
            <p>After downloaded PgAdmin first create password for PgAdmin. This password is just for login PgAdmin application.</p>
            <p>Then you have to create a server group, the name doesn't matter.</p>
            <p>To connect server, right click to server group and register server.</p>
            <p>Name doesn't matter. Go connection tab, and fill inputs like below.</p>

            <ul style="margin-left:15px">
                <li>Host: 52.21.210.70</li>
                <li>Port: 5432</li>
                <li>Maintenance Database: fancy_main</li>
                <li>Username: office_read</li>
                <li>Password: mN)gkbHgqIBxt4)f</li>
            </ul>

            <p style="margin-top:15px">To access tables go: Databases > fancy_app > Schemas > public > Tables </p>

            </ul>
    </div>

    </div>
    </div>
    
    </template>
    
    <script>
    import Menu from '@/components/Menu';
    export default {
        components: {Menu},
        data: function () {
            return {}
        }
        
    
    }
    </script>
    
    <style>
    
    </style>