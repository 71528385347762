<template>
  <Toast />
  <Menu />

  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      New Transfer/Order
    </h6>
    <hr />
    <form @submit.prevent="createTransfer">
      <div class="row justify-content-center">
        <div class="col-md-8 row">
          <div class="col-md-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="transfer"
                v-model="transfer.mode"
                checked
              />
              <label class="form-check-label" for="exampleRadios1">
                Transfer
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios2"
                value="order"
                v-model="transfer.mode"
              />
              <label class="form-check-label" for="exampleRadios2">
                Order
              </label>
            </div>
          </div>
          <div class="col-md-5" v-if="permission('super_user')">
            <label for="storeInput">Sender Store</label>
            <select
              id="storeInput"
              v-model="transfer.sender_store"
              class="form-control"
              required
            >
              <option v-bind:value="s.id" v-for="s in storeModel" :key="s.id">
                {{ s.store_name }}
              </option>
            </select>
          </div>
          <div class="col-md-5">
            <label for="storeInput">Target Store</label>
            <select
              id="storeInput"
              v-model="transfer.target_store"
              class="form-control"
              required
            >
              <option v-bind:value="s.id" v-for="s in storeModel" :key="s.id">
                {{ s.store_name }}
              </option>
            </select>
          </div>
          <!-- Header text area -->
          <div class="col-md-4" v-if="transfer.mode === 'order'">
            <label for="headerInput">Header</label>
            <input
              type="text"
              class="form-control"
              name="header"
              v-model="transfer.order_header"
              id="headerInput"
            />
          </div>
          <div class="col-md-4" v-if="transfer.mode === 'order'">
            <!-- start_date -->
            <label for="dateInput">Start Date</label>
            <input
              type="date"
              class="form-control"
              name="date"
              v-model="transfer.start_date"
              id="dateInput"
              required
            />
          </div>
          <div class="col-md-4" v-if="transfer.mode === 'order'">
            <!-- end_date -->
            <label for="dateInput">End Date</label>
            <input
              type="date"
              class="form-control"
              name="date"
              v-model="transfer.end_date"
              id="dateInput"
              required
            />
          </div>
          <div class="col-md-12">
            <label for="descriptionInput">Description</label>
            <textarea
              type="text"
              class="form-control"
              name="Description"
              v-model="transfer.description"
              id="descriptionInput"
            />
          </div>
        </div>
      </div>

      <div class="row mt-4 justify-content-center mb-4">
        <!-- <TabMenu 
                :model="items"
                            /> -->
        <!-- Transfer Product Table -->
        <!-- <p>{{transfer.products}}</p> -->
        <div class="col-md-1 align-self-end">
          <h5
            class="text-left text-info text-dark"
            style="margin-top: 10px; font-size: 13px"
          >
            Products
          </h5>
        </div>
        <div class="col-md-2 align-self-center my-0">
          <div class="col-md-3">
            <Button
              label="Multi Product"
              style="width: max-content"
              icon="pi pi-external-link"
              @click="openBasic"
              v-if="transfer.mode === 'transfer'"
            />
          </div>
          <Dialog
            header="Multi Product Add"
            v-model:visible="displayBasic"
            :style="{ width: '30vw' }"
          >
            <textarea
              v-model="multiProduct"
              class="form-control"
              :style="{ width: '27vw', height: '10vw' }"
            ></textarea>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                @click="closeBasic"
                class="p-button-text"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                @click="multiProductAdd"
                autofocus
              />
            </template>
          </Dialog>
        </div>
        <div class="col-md-5"></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8"></div>
      </div>
      <div class="row justify-content-center" v-if="showErrors">
        <div class="col-md-8">
          <ul>
            <li>
              Total Products:
              {{ multiProductSuccess.length + multiProductErrors.length }}
            </li>
            <li class="text-success">
              Total Successful Products: {{ multiProductSuccess.length }}
            </li>
            <li class="text-success">
              Successful Products: {{ multiProductSuccess }}
            </li>
            <li class="text-danger">
              Total Errors: {{ multiProductErrors.length }}
            </li>

            <li class="text-danger" v-if="multiProductErrors.length > 0">
              This references are not found or in different sender store :
              {{ multiProductErrors }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8">
          <table id="transferProductsTable" class="table table-bordered">
            <thead>
              <tr>
                <th style="width: 2%" scope="column">#</th>
                <th
                  style="width: 20%"
                  scope="column"
                  v-if="transfer.mode === 'transfer'"
                >
                  Reference No
                </th>
                <th style="width: 75%" scope="column">Description</th>
                <th scope="column" class="d-flex justify-content-center">
                  <button
                    type="button"
                    class="btn btn-success"
                    @click="addProduct"
                  >
                    New
                  </button>

                  <!-- <button type="button" v-on:click="addProduct" class="btn btn-success btn-sm">
                                        <span area-hidden="true">&plus;</span>
                                    </button> -->
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(d, i) in transfer.products" :key="i">
                <td style="text-align: center">
                  {{ i + 1 }}
                </td>
                <td v-if="transfer.mode === 'transfer'">
                  <div class="form-group">
                    <!-- :active=true removed -->
                    <SelectProduct
                      :active="true"
                      :product-model="productModel"
                      v-model="d.product"
                      :selected="d.product"
                      :outProducts="outProducts"
                      :extra-params="store_id"
                      :key="selectProductKey"
                    ></SelectProduct>
                  </div>
                </td>

                <td>
                  <div class="form-group">
                    <textarea
                      type="text"
                      id="productDescription"
                      v-model="d.description"
                      class="form-control"
                    />
                  </div>
                </td>

                <td style="text-align: center">
                  <button
                    @click="removeProduct(i)"
                    type="button"
                    id="remove-button"
                    class="btn btn-danger btn-sm"
                  >
                    <!-- <span class="material-icons-outlined">delete</span> -->
                    <span>Delete</span>
                  </button>
                  <!-- <button type="button" v-on:click="removeProduct(i)" class="btn btn-danger" tabindex="99" >
                                    <span aria-hidden="true">&minus;</span>
                                </button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  END Transfer Product Table -->
      </div>

      <div v-if="transfer.products.length > 0">
        <div class="row justify-content-center">
          <div class="col-md-2">
            <button id="" class="btn btn-secondary">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <Dialog
    header="Transfer Confirmation"
    v-model:visible="showConfirmDialog"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <p v-if="errors.different_store.length" class="text-danger">
      You cannot add the following products because they are not in your store.
    </p>
    <ul>
      <li v-for="diffStore in errors.different_store" :key="diffStore">
        {{ diffStore.reference_no }}
      </li>
    </ul>
    <p v-if="errors.exist_transfer.length" class="text-danger">
      You cannot add the following products as they are still in the transfer
      process.
    </p>
    <ul>
      <li v-for="exist_transfer in errors.exist_transfer" :key="exist_transfer">
        {{ exist_transfer.reference_no }}
      </li>
    </ul>

    <p v-if="errors.same_store.length" class="text-danger">
      The following products are already in the target store.
    </p>
    <ul>
      <li v-for="same_store in errors.same_store" :key="same_store">
        {{ same_store.reference_no }}
      </li>
    </ul>

    <p v-if="confirmSuccessfulProducts.length" class="text-success">
      You can send the following products successfully.
    </p>
    <ul>
      <li v-for="success in confirmSuccessfulProducts" :key="success">
        {{ success.reference_no }}
      </li>
    </ul>

    <template #footer>
      <button class="btn btn-danger" @click="showConfirmDialog = false">
        Cancel
      </button>
      <button
        :disabled="confirmButtonDisabled"
        class="btn btn-success"
        @click="submitTransfer"
      >
        Confirm
      </button>
    </template>
  </Dialog>
</template>

<script>
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import Menu from "@/components/Menu";
import SelectProduct from "@/components/SelectProduct.vue";
import store from "@/store";

export default {
  name: "NewTransfer",
  components: { Menu, SelectProduct },
  data: function () {
    return {
      outProducts: [],
      items: [{ label: "Products", icon: "pi pi-fw pi-tag" }],
      autoMultiplier: null,
      successfulProducts: [],
      multiProductErrors: [],
      multiProductSuccess: [],
      errors: [],
      showErrors: false,
      productModel: [],
      selectedProduct: null,
      storeModel: null,
      displayBasic: false,
      multiProduct: null,
      transfer: {
        target_store: null,
        description: null,
        products: [],
        sender_store: localStorage.getItem("store_id"),
        mode: "transfer",
      },
      transferProduct: {
        product: null,
        description: null,
      },
      store_id: "store_id=" + store.state.store_id,
      store: store.state.store_id,
      errors: {
        different_store: [],
        exist_transfer: [],
        same_store: [],
      },
      confirmSuccessfulProducts: [],
      showConfirmDialog: false,
      selectProductKey: 0,
      confirmButtonDisabled: false,
    };
  },
  created: function () {
    getAPI("stores?limit=10000&ordering=store_name&").then((res) => {
      this.storeModel = res.data.results;
    });
    // getAPI('product-search?ordering=-product_id&limit=100&active=true').then((res) => {this.productModel = res.data.results});
  },

  methods: {
    addProduct: function (e) {
      let store = this.transfer.target_store;
      if (store === null || store === "") {
        alert("Please select a store!");
        return false;
      }
      e.preventDefault();
      let detail = {
        product: null,
        description: null,
      };
      // this.transfer.products.push(detail);
      // add item as first
      this.transfer.products.unshift(detail);
      this.selectProductKey = this.selectProductKey + 1;
    },

    removeProduct: function (index) {
      this.transfer.products.splice(index, 1);
      this.selectProductKey = this.selectProductKey + 1;
    },
    openBasic() {
      this.displayBasic = true;
    },
    closeBasic() {
      this.displayBasic = false;
    },
    multiProductAdd() {
      this.successfulProducts = [];
      this.multiProductErrors = [];
      this.multiProductSuccess = [];
      this.showErrors = false;
      this.closeBasic();
      var lines = this.multiProduct.split("\n");
      lines = [...new Set(lines)];
      const payload = {
        reference_no_list: lines,
        store: this.transfer.sender_store,
        active: true,
      };
      postAPI("multi-product-search", payload).then((response) => {
        this.showErrors = true;
        this.multiProductErrors = response.data.not_found;
        let success = response.data.results;
        for (var i = 0; i < success.length; i++) {
          this.multiProductSuccess.push(success[i]["reference_no"]);
          this.productModel.push(success[i]);
          let detail = {
            product: success[i]["product_id"],
            description: null,
          };
          if (
            this.transfer.products.some((e) => e.product === detail.product)
          ) {
            continue;
          }
          this.transfer.products.push(detail);
          this.successfulProducts.push(success[i]["reference_no"]);
        }
      });
      return;
      for (var i = 0; i < lines.length; i++) {
        let id = lines[i];
        if (/^\s*$/.test(id) === true || id === null) {
          continue;
        }

        try {
          getAPI(
            "product-search?reference_no=" + lines[i] + "&" + this.store_id
          ).then((res) => {
            if (res.data.results[0]) {
              this.productModel.push(res.data.results[0]);
              let detail = {
                product: res.data.results[0]["product_id"],
                description: null,
              };
              this.transfer.products.push(detail);
              this.successfulProducts.push(id);
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: `${id} added!`,
                life: 1000,
              });
            } else {
              this.errors.push(id);
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: `${id} doesn't added!`,
                life: 1000,
              });
            }
          });
        } catch (error) {
          this.errors.push(id);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: `Error ${id} doesn't added!`,
            life: 1000,
          });
        }
      }
      this.showErrors = true;
    },
    createTransfer: function () {
      let data = this.transfer;

      if (
        data.target_store ===
        (this.transfer.sender_store || this.$store.state.store_id)
      ) {
        window.alert("You can't transfer to your own store! ");
        this.transfer.target_store = null;
        return false;
      }
      console.log(data);
      let super_user_sender_store = null;
      if (this.permission("super_user")) {
        super_user_sender_store = data.sender_store;
      }
      const payload = {
        receiver_store_id: data.target_store,
        products: data.products,
        sender_store_id: super_user_sender_store,
      };
      postAPI("transfer-process", payload).then((res) => {
        const data = res.data;
        this.errors.different_store = data.different_store;
        this.errors.exist_transfer = data.exist_transfer;
        this.errors.same_store = data.same_store;
        this.confirmSuccessfulProducts = data.success;

        this.showConfirmDialog = true;
      });

      // data.products.forEach(element => {
      //     element.product = element.product.product_id
      // });
      // postAPI("transfers", data)
      // .then(res => this.$router.push('/transfers/'+res.data.id));
    },

    submitTransfer: function () {
      this.confirmButtonDisabled = true;
      let data = this.transfer;
      data.products = [];
      this.confirmSuccessfulProducts.forEach((element) => {
        const detail = {
          product: element.product,
          description: element.description,
        };
        data.products.push(detail);
      });
      console.log(data);
      postAPI("transfers", data)
        .then((res) => this.$router.push("/transfers/" + res.data.id))
        .finally(() => {
          this.confirmButtonDisabled = false;
        });
    },
    permission(n) {
      return localStorage.getItem("groups").indexOf(n) > -1;
    },
  },
};
</script>

<style lang="css" scoped>
hr {
  border-top: 1px solid black;
}

#remove-button {
  border: 0px;
}
</style>
