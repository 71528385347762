<template>

<div class="col-md-1 d-grid gap-2 mt-3 mb-3">
    <button class="btn no-print btn-outline-secondary ml-4" @click="printWindow">Print</button>
</div>

<div id="gallery" style="@media print{margin:0px};@media print {a[href]:after {content: none !important;}}">
    <div class="row justify-content-center">
        <div class="col-md-12" style="margin-left:3px">


    <div class="gallery row row-bottom-margin justifty-content-center " style="overflow: scroll;"> 

        <div v-for="(product,index) in products" :key="product.id"  class="gallery-item col-sm-1" style="width:160px;height: auto;margin: 6px;margin-left:10px;border: 1px solid #ccc;font-size: x-small;overflow: scroll;">
            <!-- <router-link :to="`/products/${product[productField]}`" target="_blank" >
                <img  v-bind:src="product.thumbnail" style="margin-bottom:9px;margin-top:5px;width: 134px;height: 134px;@media print{width:130px;height:130px}">
            </router-link> -->

            <img  v-bind:src="product.thumbnail" style="margin-bottom:9px;margin-top:5px;width: 134px;height: 134px;@media print{width:130px;height:130px}">



            <div class="description">

                    <table class="table table-bordered table-sm" id="des-table" style="background: transparent;border: transparent;">
                        <tbody style="font-size:x-small">
                            <tr>
                                <strong class="float=left">{{index+1 }}</strong>

                            </tr>
                            <tr>
                                <td><strong>Reference No</strong></td>
                                <td><span>{{product.reference_no}}</span></td>
                            </tr>

                            <tr v-for="item in items" :key="item.id" >
                                <td><b style="font-size:x-small">{{item.name}}</b></td>
                                <td><span style="font-size:x-small">{{product[item.value]}}</span></td>
                            </tr>

                            <tr v-if="product.product_type_name === 'Watch'">
                                <td><strong>Brand</strong></td>
                                <td><span style="font-size:x-small">{{product.watch[0].brand_name}}</span></td>
                            </tr>
                            
                        </tbody>    
                
                    </table>


                    <!-- <div class="row row-bottom-margin" style="margin: 0 auto;">
                        <div class="col-sm-5 left-text" style="font-weight: bold;"><p style="font-size: smaller;">Reference</p></div>
                        <div class="col-sm-7 right-text">
                            <p style="font-size: smaller;">{{product.reference_no}}</p>
                        </div>
                    </div>


                    <div v-for="item in items"  :key="item.id" class="row" style="margin: 0 auto;">
                        <div class="col-md-12">
                                <div class="col-sm-5 left-text"><p style="font-size: smaller;font-weight: bold;">{{item.name}}</p></div>
                                <div class="col-sm-7 right-text"><p style="font-size: smaller;">{{product[item.value]}}</p></div>
                        </div>
                    </div>


                    
                    <div v-if="product.product_type_name === 'Watch'" class="row" style="margin: 0 auto;">
                        <div class="col-sm-5 left-text"><p style="font-size: smaller;">Brand</p></div>
                        <div class="col-sm-7 right-text"><p style="font-size: smaller;">{{product.watch[0].brand_name}}</p></div>
                    </div> -->



                </div>
        </div>
    </div>
</div>
    </div>
</div>

</template>

<script>

export default {
    data: function () {return {
       
    }},
    props: {
        products:{
            type: Array,
            default: null
        },
        items:{
            type: Array,
            default: []
        },
        productField: {
            default: "product_id"
        },
    },
    

    methods: {
        toProduct(product_id) {
            this.$router.push({ path: '/products/'+product_id })
        },
        printWindow: function () {

            var printContent = document.getElementById("gallery");
            var windowUrl = 'FANCY';
            var uniqueName = new Date();
            var windowName = 'Print' + uniqueName.getTime();

            var printWindow = window.open(windowUrl, windowName, 'height=8000,width=8000,scrollbars=1');
            printWindow.document.write('<head><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1"><title>TERZIHAN</title><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous"><style>a{content:none; all: unset;}</style></head>');
            printWindow.document.write(printContent.innerHTML);
            printWindow.focus();

                       },
        
    }

}
</script>

<style scoped>



#des-table {
    background: transparent;
    border: transparent;
    table-layout: fixed;
    width: 100%;
    text-overflow: ellipsis;

}



#gallery  .row {
      margin: 0 auto;

}





#gallery p {
    font-size: smaller;
}

#gallery .left-text {
    font-weight: bold;
}

#gallery #main-header {
    margin: 20px;
}



/* override styles when printing */


  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }


  div {
    line-height: 1;

  }
</style>