<template>
  <div class="container mt-4">
    <div
      v-if="showLoading === true"
      class="d-flex justify-content-center mt-4 mb-4"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div class="row justify-content-begin">
      <!-- <div class="col-md-2 d-flex justify-content-center ">
                <label for="sort">Sort by</label>
                <select @change="clickPage(1)" name="sort" class="form-control" id="sortInput" v-model="sortInput">
                    <option v-if="orderInfo.length > 0"></option>
                    <option v-bind:value="sort.field" v-for="sort in orderInfo" :key="sort.header">{{ sort.header }}
                    </option>
                </select>
            </div> -->
      <div class="col-md-2 d-flex justify-content-center">
        <label for="sort">Sort by</label>
        <div class="input-group">
          <select
            @change="clickPage(1)"
            name="sort"
            class="form-control"
            id="sortInput"
            v-model="sortInput"
          >
            <option v-if="orderInfo.length > 0"></option>
            <option
              v-for="sort in orderInfo"
              :key="sort.header"
              :value="sort.field"
            >
              {{ sort.header }}
            </option>
          </select>
          <div class="input-group-append ml-3" style="margin-left: 5px">
            <button
              class="btn btn-outline-primary"
              type="button"
              @click="toggleSortOrder"
            >
              <span>↑</span>
              <span>↓</span>
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-1 align-self-right">
        <button
          name=""
          id=""
          class="btn btn-secondary"
          @click="clickExcel()"
          role="button"
          :disabled="exportLoading"
        >
          <span v-if="exportLoading === false">Export</span>
          <span
            v-if="exportLoading === true"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div class="col-md-1 align-self-left">
        <button
          name=""
          id=""
          class="btn btn-secondary"
          @click="printWindow()"
          role="button"
          :disabled="exportLoading"
        >
          <span v-if="exportLoading === false">Print</span>
          <span
            v-if="exportLoading === true"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>

      <div class="col-md-3 my-2">
        <small class="form-text text-muted"
          >Export and Print show maximum 1500 results.</small
        >
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12" id="table">
        <div class="d-flex justify-content-between">
          <div class="row justify-content-between">
            <div>
              <strong style="float: left" class=""
                >Total Result: {{ totalItem }}</strong
              >
            </div>

            <div v-if="customTotalShow === true">
              <strong style="float: left" class=""
                >{{ customTotalText }}: {{ customTotal }}</strong
              >
            </div>
          </div>
          <div style="float: right">
            <button
              @click="changeLimit(20)"
              type="button"
              class="btn btn-sm btn-outline-secondary m-1"
            >
              20
            </button>
            <button
              @click="changeLimit(50)"
              type="button"
              class="btn btn-sm btn-outline-secondary m-1"
            >
              50
            </button>
            <button
              @click="changeLimit(100)"
              type="button"
              class="btn btn-sm btn-outline-secondary m-1"
            >
              100
            </button>
            <button
              @click="changeLimit(100000)"
              class="btn btn-sm btn-outline-secondary m-1"
            >
              All
            </button>
            <!-- <strong class="m-1">Total Result: {{ totalItem }}</strong> -->
          </div>
        </div>

        <div>
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th v-if="showIndexNumber == true">#</th>
                <th v-for="column in tableInfo" :key="column.header">
                  {{ column.header }}
                </th>
                <th v-if="showRoute">Detail</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, i) in tableData"
                :key="row"
                :class="[
                  rowClassField && row[rowClassField]
                    ? row[rowClassField]
                    : null,
                ]"
              >
                <td v-if="showIndexNumber == true">
                  {{ (Number(pageNumber) - 1) * limitNumber + i + 1 }}
                </td>
                <td
                  v-for="column in tableInfo"
                  :key="column.header"
                  :class="getColumnClass(row, column)"
                  :style="getCellStyle(row, column)"
                >
                  <span
                    :clas="[column.class || null]"
                    v-if="
                      column.isImage != true &&
                      column.isCurrency != true &&
                      column.isDateTime != true &&
                      column.isDelete != true &&
                      column.editable != true &&
                      column.isRouter != true &&
                      column.customFormat != true &&
                      column.boolean != true
                    "
                  >
                    {{ fixer(row[column.field], row, column) || "" }}
                  </span>
                  <span v-if="column.isCurrency == true">{{
                    "$" + fixer(row[column.field]) || ""
                  }}</span>
                  <span v-if="column.isRouter == true">
                    <router-link
                      :to="{
                        path: column.routerPath + row[column.routerField],
                      }"
                      target="_blank"
                      >{{ row[column.field] }}</router-link
                    >
                  </span>
                  <span v-if="column.customFormat == true">
                    {{ column.customFormatter(row[column.field]) || "" }}
                  </span>
                  <span v-if="column.boolean == true">
                    {{ row[column.field] ? "✅" : "❌" }}
                  </span>
                  <span
                    v-if="
                      column.isDateTime &&
                      row[column.field] &&
                      typeof row[column.field] === 'string'
                    "
                  >
                    {{ row[column.field].substring(0, 10) || "" }}
                  </span>
                  <input
                    style="min-width: 8rem"
                    class="form-control"
                    type="text"
                    v-if="column.editable == true"
                    v-model="row[column.field]"
                    v-on:focusout.prevent="$emit('inputEvent', row)"
                    v-on:keydown.enter.prevent="$emit('inputEvent', row)"
                  />
                  <button
                    class="btn btn-sm btn-danger"
                    v-if="column.isDelete == true"
                    @click="$emit('deleteEvent', row)"
                  >
                    Delete
                  </button>
                  <button
                    class="btn btn-sm btn-primary"
                    v-if="column.isEdit == true"
                    @click="$emit('editEvent', row)"
                  >
                    {{ column.editText || "Edit" }}
                  </button>

                  <!-- <img style="width: 80px;height: 80px;"> -->
                  <Image
                    @error="
                      row[column.field] =
                        'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                    "
                    v-if="column.isImage === true"
                    v-bind:src="row[column.field]"
                    alt="Image"
                    width="80"
                    preview
                  />
                </td>
                <td v-if="showRoute">
                  <router-link :to="{ path: detailPath + row[idName] }"
                    >Detail</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-4 align-self-center mx-auto">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li
              class="mr-2 list-inline-item"
              style="margin-right: 10px; margin-top: 10px"
            >
              <strong>{{ pageNumber }}/{{ maxPage }}</strong>
            </li>

            <li
              :class="['page-item', Number(pageNumber) == 1 ? 'disabled' : '']"
            >
              <a
                @click="clickPage(Number(pageNumber) - 1)"
                class="page-link"
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li
              :class="['page-item', Number(pageNumber) == item ? 'active' : '']"
              v-for="(item, index) in pageNumberRange"
              :key="index"
            >
              <a @click="clickPage(item)" class="page-link" href="#">{{
                item
              }}</a>
            </li>
            <li
              :class="[
                'page-item',
                Number(pageNumber) == maxPage ? 'disabled' : '',
              ]"
            >
              <a
                @click="clickPage(Number(pageNumber) + 1)"
                class="page-link"
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { getAPI } from "@/global/callAPI";
import router from "@/router";
export default {
  data() {
    return {
      tableData: [],
      sortInput: this.$route.query.order || null,
      pageNumber: this.$route.query.pageNumber || 1,
      maxPage: 0,
      totalItem: 0,
      customTotal: 0,
      pageNumberRange: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      orderInfo: [],
      exportLoading: false,
      filterString: "",
      limitNumber: this.limit,
      showLoading: false,
      asc_desc: "",
    };
  },
  props: {
    showIndexNumber: {
      default: true,
    },
    useCreate: {
      default: true,
    },
    id: {
      default: null,
    },
    excelName: {
      default: "export",
    },
    excelTabName: {
      default: "results",
    },

    endPoint: {
      require: true,
    },
    tableInfo: {
      type: Array,
    },
    tableExcelInfo: {
      type: Array,
      default: [],
    },
    limit: {
      default: 20,
    },
    filters: {
      default: [],
      type: Array,
    },
    customColumn: {
      default: false,
      type: Boolean,
    },
    // tableData: {
    //     type: Array,
    //     defaul : []

    // },
    showRoute: {
      default: false,
    },
    idName: {
      default: null,
    },
    isAccounting: {
      default: false,
    },

    detailPath: {
      default: null,
    },

    customTotalShow: {
      default: false,
    },
    customTotalText: {
      default: "Total",
    },
    customTotalField: {
      default: null,
    },
    rowClassField: {
      default: null,
    },
  },
  methods: {
    fixer(n, data, column) {
      if (typeof column !== "undefined") {
        if (typeof column["field"] !== "undefined") {
          if (column["field"] === "accounting_transaction") {
            if (data.payment_type === column.header) {
              return data.amount;
            } else {
              return "";
            }
          }
        }
      }
      if (Number(n) === n && n % 1 !== 0) {
        return n.toFixed(2);
      } else {
        return n;
      }
    },
    clickExcel() {
      this.exportLoading = true;
      let ordering = "&ordering=" + this.sortInput;
      let limit = "&limit=" + 1500;
      getAPI(this.endPoint + limit + ordering).then((res) => {
        let data = res.data.results;
        this.exportExcel(data);
      });
    },

    exportExcel(data) {
      const XLSX = require("xlsx");
      // let data = this.tableData
      let tableHeadersInfo = this.tableInfo;
      if (this.tableExcelInfo.length > 0) {
        tableHeadersInfo = this.tableExcelInfo;
      }
      let exportArray = [];
      if (this.isAccounting === true) {
        data.forEach((item) => {
          let row = {};

          console.log(tableHeadersInfo);
          tableHeadersInfo.forEach((info) => {
            if (info["field"] === "accounting_transaction") {
              if (item["payment_type"] === info["header"]) {
                row[info["header"]] = item["amount"];
              } else {
                row[info["header"]] = null;
              }
            } else {
              row[info["header"]] = item[info["field"]];
            }
          });
          exportArray.push(row);
        });
      } else {
        data.forEach((item) => {
          let row = {};
          tableHeadersInfo.forEach((info) => {
            row[info["header"]] = item[info["field"]];
            // if row field include __ then split and get nested field
            if (info["field"].includes("__")) {
              try {
                let nestedFields = info["field"].split("__");
                if (Array.isArray(item[nestedFields[0]])) {
                  row[info["header"]] =
                    item[nestedFields[0]][0][nestedFields[1]];
                } else {
                  row[info["header"]] = item[nestedFields[0]][nestedFields[1]];
                }
              } catch (error) {
                console.log("Error: " + error);
              }
            }
          });
          exportArray.push(row);
        });
      }
      let binary_univers = exportArray;
      let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

      // Create a new Workbook
      var wb = XLSX.utils.book_new();

      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, this.excelTabName);

      // export your excel

      XLSX.writeFile(wb, this.excelName + ".xlsx");
      this.exportLoading = false;
    },
    printWindow: function () {
      this.exportLoading = true;
      let ordering = "&ordering=" + this.sortInput;
      if (this.sortInput === null) {
        ordering = "";
      }
      let limit = "&limit=" + 1000;
      getAPI(this.endPoint + limit + ordering).then((res) => {
        let data = res.data.results;
        const lastData = this.tableData;
        this.tableData = data;
        setTimeout(() => {
          {
            this.createPrint();
            this.tableData = lastData;
          }
        }, 1000);
      });
    },
    createPrint() {
      var printContent = document.getElementById("table");
      var windowUrl = "FANCY";
      var uniqueName = new Date();
      var windowName = "Print" + uniqueName.getTime();

      var printWindow = window.open(
        windowUrl,
        windowName,
        "left=50000,top=50000,width=0,height=0"
      );
      printWindow.document.write(
        '<head><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1"><title>TERZIHAN</title><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous"><style> body {overflow: scroll;}</style></head>'
      );
      printWindow.document.write(printContent.innerHTML);

      this.exportLoading = false;
    },

    sortByProperty(objArray, prop, direction) {
      if (arguments.length < 2)
        throw new Error(
          "ARRAY, AND OBJECT PROPERTY MINIMUM ARGUMENTS, OPTIONAL DIRECTION"
        );
      if (!Array.isArray(objArray))
        throw new Error("FIRST ARGUMENT NOT AN ARRAY");
      const clone = objArray.slice(0);
      const direct = arguments.length > 2 ? arguments[2] : 1; //Default to ascending
      const propPath = prop.constructor === Array ? prop : prop.split(".");
      clone.sort(function (a, b) {
        for (let p in propPath) {
          if (a[propPath[p]] && b[propPath[p]]) {
            a = a[propPath[p]];
            b = b[propPath[p]];
          }
        }
        // convert numeric strings to integers
        a = a.match(/^\d+$/) ? +a : a;
        b = b.match(/^\d+$/) ? +b : b;
        return a < b ? -1 * direct : a > b ? 1 * direct : 0;
      });
      return clone;
    },
    GetSortOrder(prop) {
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      };
    },
    sortBy() {
      let sort = this.sortInput;

      // this.tableData = this.tableData.sort(this.GetSortOrder(this.sortInput));
    },
    // formatCurrency(value) {
    //     return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    // },

    getData() {
      this.showLoading = true;
      let filters = this.filterString;

      let ordering = "&ordering=" + this.asc_desc + this.sortInput;
      if (this.sortInput === null) {
        ordering = "";
      }
      let limit = "&limit=" + this.limitNumber;
      getAPI(
        this.endPoint +
          `&offset=${(Number(this.pageNumber) - 1) * this.limitNumber}` +
          limit +
          ordering +
          filters
      ).then((res) => {
        this.tableData = res.data.results;
        this.totalItem = res.data.count;
        if (this.customTotalShow === true) {
          this.customTotal = res.data[this.customTotalField];
        }
        this.maxPage = Math.ceil(res.data.count / this.limitNumber);
        this.range();
        this.showLoading = false;
      });
    },
    getOrders() {
      let orderFields = [];
      this.tableInfo.forEach((item) => {
        if (item["order"] === true) {
          orderFields.push(item);
        }
      });

      this.orderInfo = orderFields;
    },
    getFilters() {
      let filters = this.filters;
      let filterQuery = [];

      filters.forEach((filter) => {
        let row = {};

        row["field"] = filter["field"];
        if (filter["value"] != "" && filter["value"] != null) {
          row["value"] = filter["value"];
        }
        // else if (this.$route.query[filter["field"]]) {
        //     row["value"] = this.$route.query[filter["field"]]
        // }
        else {
          row["value"] = "";
        }
        filterQuery.push(row);
      });
      let filterString = "";
      filterQuery.forEach((query) => {
        let str = null;

        str = "&" + query["field"] + "=" + query["value"];
        filterString += str;
      });
      const obj = {};

      for (const key of filterQuery) {
        obj[key["field"]] = key["value"];
      }
      // this.$router.push({ query: Object.assign({}, this.$route.query, obj) })

      this.filterString = filterString;
    },
    clickPage(newPageNumber) {
      this.pageNumber = newPageNumber;
      // this.$router.push({ query: Object.assign({}, this.$route.query, {
      //     pageNumber:newPageNumber,order:this.sortInput
      // }) })
      // this.$router.push({path:this.$route.path,query:{
      //     pageNumber:newPageNumber,order:this.sortInput
      // }})
      this.getData();
    },
    range() {
      const step = 1;
      let start =
        this.maxPage > 10
          ? this.pageNumber < 3
            ? 1
            : Number(this.pageNumber) - 2
          : 1;
      let end =
        this.maxPage > 10
          ? this.pageNumber < 3
            ? 11
            : Number(this.pageNumber) + 7
          : this.maxPage + 1;
      if (end > this.maxPage + 1) {
        end = this.maxPage + 1;
      }

      let output = [];
      if (typeof end === "undefined") {
        end = start;
        start = 0;
      }
      for (let i = start; i < end; i += step) {
        output.push(i);
      }
      this.pageNumberRange = output;
    },
    changeLimit(newLimit) {
      this.limitNumber = newLimit;
      this.loading = true;
      this.getOrders();
      this.getFilters();
      console.log("Table created.");
      if (this.useCreate === true) {
        this.getData();
      }
    },
    getColumnClass(row, column) {
      if (this.customColumn === false) {
        if (column.header === "Is Approved") {
          if (row["is_completed"] === true) {
            return "text-success ";
          } else if (row["is_completed"] === false) {
            return "text-danger";
          } else {
            return "";
          }
        }
        return "";
      }
      try {
        if (row["transaction_type"] === "Input" || row["active"] === true) {
          return "table-success ";
        } else if (
          row["transaction_type"] === "Output" ||
          row["active"] === false
        ) {
          return "table-danger";
        } else {
          return "";
        }
      } catch (error) {
        console.log("Error: " + error);
        return "";
      }
    },

    getCellStyle(row, column) {
      try {
        if (column.header === "Date") {
          return "white-space: nowrap;";
        }
      } catch (error) {}
    },
    toggleSortOrder() {
      if (this.sortInput === null) {
        return;
      }
      if (this.asc_desc === "-") {
        this.asc_desc = "";
      } else {
        this.asc_desc = "-";
      }
      this.clickPage(1);
    },
  },

  mounted: function () {
    this.loading = true;
    this.getOrders();
    this.getFilters();
    console.log("Table created.");
    if (this.useCreate === true) {
      this.getData();
    }
  },
  computed: {},
};
</script>

<style></style>
