<template>
    <Menu/>

    <div id="productPhoto" class="container-fluid">
        <TabMenu :model="items" />
        <router-view/>
         <div class="row">
            <FileUpload name="demo[]" :customUpload="true" @uploader="imageUploader" style="font-size:8;" :multiple="true">
                <template #empty>
                    <p>Drag and drop files to here to upload.</p>
                </template>
            </FileUpload>
        </div>

<!-- Gallery -->
<div class="row justify-content-center">
    <div class="col-md-8">
<div v-if="productPhotos" class="container-image">
<div style="padding: .5rem 0; margin-bottom:30px">
        <Button label="Delete" @click="deletePhoto" class=" p-button-danger p-button-outlined" />
        <ToggleButton v-model="productPhotos[activeIndex].isThumbnail" onLabel="Thumbanil" @click="thumbnailGen" offLabel="Thumbanil" onIcon="pi pi-check" offIcon="pi pi-times" style="width: 10em" />

        <Galleria :value="productPhotos" :responsiveOptions="responsiveOptions" :numVisible="7" containerStyle="max-width: 850px"
            :circular="true" :fullScreen="true"  v-model:activeIndex="activeIndex" :showItemNavigators="true" :showThumbnails="false" v-model:visible="displayBasic2">
            <template #item="slotProps">
                <img :src="slotProps.item.itemImageSrc" style="width: 100%; display: block;" />
            </template>
            <template #thumbnail="slotProps">
                <img :src="slotProps.item.itemImageSrc"  style="display: block;" />
            </template>
        </Galleria>
        <Button label="Show" icon="pi pi-external-link" @click="displayBasic2 = true" />

</div> 

        <Galleria :value="productPhotos" v-model:activeIndex="activeIndex" :responsiveOptions="responsiveOptions" :numVisible="7"
        containerStyle="max-width: 640px" :showItemNavigators="true">
            <template #item="slotProps">
                <img :src="slotProps.item.itemImageSrc" style="width: auto" />
            </template>
            <template #thumbnail="slotProps">
                <img :src="slotProps.item.itemImageSrc" style="width:30px;height:30px" />
            </template>
        </Galleria>
</div> 
</div>
</div>
<!-- Gallery End -->

    </div>

</template>

<script>

import Menu from '@/components/Menu';
import axios from 'axios';
import {getAPI, postAPI, patchAPI, deleteAPI, postProductPhoto} from '@/global/callAPI';

export default {
    name:"ProductPhoto",
    components: {Menu},
    data: function () {
        return {
      index: null,
    photos: [],
    index:null,
    displayBasic2: false,
            productPhotos: null,
            activeIndex: 0,
            responsiveOptions: [
				{
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],

    
    items: [
		{label: 'Edit', icon: 'pi pi-fw pi-pencil', to: "/products/" + this.$route.params.id},
		{label: 'Photo', icon: 'pi pi-fw pi-image', to: '/products/photo/' + this.$route.params.id },
		{label: 'Sale', icon: 'pi pi-fw pi-shopping-cart', to: '/products/sales/' + this.$route.params.id },
		{label: 'Catalog', icon: 'pi pi-fw pi-tags', to: '/products/catalog/' + this.$route.params.id },
        {label: 'Transfer', icon: 'pi pi-fw pi-sync', to: '/products/transfer/' + this.$route.params.id }

        ],
            
    }
    },
    created: function() {
    getAPI('products/' + this.$route.params.id).then((res) => {
        if (res.data.photos.length > 0) {
        this.productPhotos = res.data.photos.map(function(item) {
                        return {itemImageSrc: item.photo, id:item.id, isThumbnail:item.is_thumbnail};
                });}
    });
    },

   methods: {

        thumbnailGen () {
            const trueData =  {is_thumbnail:true}
            const falseData =  {is_thumbnail:false}
           
            if (this.productPhotos[this.activeIndex].isThumbnail ==  true) {
                patchAPI('product-photos/' + this.productPhotos[this.activeIndex].id, trueData)
                .then(this.productPhotos[this.activeIndex].isThumbnail.push(false));
                 
            } else  {
                patchAPI('product-photos/' + this.productPhotos[this.activeIndex].id, falseData)
                this.productPhotos[this.activeIndex].isThumbnail.push(true);

            }
        },

        deletePhoto () {
            
      Swal.fire({

        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
         })
      .then((result) => {

        if (result.value) {
                    deleteAPI('product-photos/' + this.productPhotos[this.activeIndex].id)

            Toast2.fire({
            type: 'success',
            title: 'Deleted  successfully'
          });

        }
      });
        },

        imageUploader (event) {
            event.files.forEach(element => {

            var formData = new FormData();
            formData.append('photo', element)
            formData.append('product', this.$route.params.id);
            postProductPhoto(formData);

            });
        }
    }
}
</script>

<style scoped>
.p-fileupload .p-fileupload-content {
    padding: 0;
}
#productPhoto {
	margin-left: 20%;
	margin-top: 5%;
	width: 76%;
	margin-right: 2%;
}
img {
    max-height: 280px;
}
/* 
#productPhoto {
    margin-top:5%
} */
</style>