<template>
  <Toast />
  <div class="container-fluid">
    <Menu />
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>Accounting Transactions Summary</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />
    <div class="row">
      <div class="col-md-2">
        <label for="firm">Firm</label>
        <v-select
          id="firm"
          :options="firmModel"
          label="firm_name"
          v-model="apiFilters.firms"
          :reduce="(firm) => firm.manufacturer_id"
          multiple
        >
        </v-select>
      </div>
      <div class="col-md-2">
        <label for="typeInput">Types</label>
        <v-select
          id="firm"
          :options="typeModel"
          label="manufacturer_type"
          v-model="apiFilters.types"
          :reduce="(type) => type.manufacturer_type_id"
          multiple
        >
        </v-select>
      </div>
      <!-- add start date and end date filter -->
      <div class="col-md-2">
        <label for="startDate">Start Date</label>
        <input
          type="date"
          id="startDate"
          v-model="apiFilters.start_date"
          class="form-control"
        />
      </div>
      <div class="col-md-2">
        <label for="endDate">End Date</label>
        <input
          type="date"
          id="endDate"
          v-model="apiFilters.end_date"
          class="form-control"
        />
      </div>
      <div class="col-md-2">
        <!--  Filter button -->
        <button
          class="btn btn-secondary mt-4"
          @click="getTransactions"
          style="margin-top: 30px"
        >
          Filter
        </button>
      </div>
    </div>

    <div class="row justify-content-center transactionTable">
      <Table
        excelName="accounnting-transactions-export"
        excelTabName="Transactions"
        :tableInfo="tableInfo"
        :endPoint="endPoint"
        :showRoute="false"
        :useCreate="true"
        :key="tableKey"
        :customColumn="true"
      />
    </div>
  </div>
</template>
<script>
import Menu from "@/components/Menu";
import Table from "@/components/Table.vue";
import { getAPI } from "@/global/callAPI";

export default {
  components: {
    Menu,
    Table,
  },
  data() {
    return {
      endPoint: "accounting-firm-transactions?",
      tableKey: 0,

      tableInfo: [
        { header: "Firm Name", field: "firm_name", order: true },
        { header: "Firm Type", field: "firm_type" },
        { header: "ALTIN +", field: "ALTIN_Input" },
        { header: "ALTIN -", field: "ALTIN_Output" },
        { header: "ALTIN B", field: "ALTIN_Balance" },
        { header: "CHF +", field: "CHF_Input" },
        { header: "CHF -", field: "CHF_Output" },
        { header: "CHF B", field: "CHF_Balance" },
        { header: "EUR +", field: "EUR_Input" },
        { header: "EUR -", field: "EUR_Output" },
        { header: "EUR B", field: "EUR_Balance" },
        { header: "GBP +", field: "GBP_Input" },
        { header: "GBP -", field: "GBP_Output" },
        { header: "GBP B", field: "GBP_Balance" },
        { header: "TL +", field: "TL_Input" },
        { header: "TL -", field: "TL_Output" },
        { header: "TL B", field: "TL_Balance" },
        { header: "USD +", field: "USD_Input" },
        { header: "USD -", field: "USD_Output" },
        { header: "USD B", field: "USD_Balance" },
      ],
      transactions: [],
      typeModel: [],
      firmModel: [],
      apiFilters: {
        types: [],
        firms: [],
        start_date: "",
        end_date: "",
      },
    };
  },
  created() {
    this.getTypes();
    this.getFirms();
  },
  methods: {
    getTypes() {
      getAPI("manufacturer-types?limit=10000").then(
        (res) => (this.typeModel = res.data.results)
      );
    },
    getTransactions() {
      this.endPoint = "accounting-firm-transactions?";
      if (this.apiFilters.types.length > 0) {
        this.endPoint += `&type_id__in=${this.apiFilters.types.join(",")}`;
      }
      if (this.apiFilters.firms.length > 0) {
        this.endPoint += `&manufacturer_id__in=${this.apiFilters.firms.join(
          ","
        )}`;
      }
      if (this.apiFilters.start_date) {
        this.endPoint += `&start_date=${this.apiFilters.start_date}`;
      }
      if (this.apiFilters.end_date) {
        this.endPoint += `&end_date=${this.apiFilters.end_date}`;
      }
      this.tableKey++;
    },
    getFirms() {
      getAPI("manufacturers?limit=10000").then((res) => {
        this.firmModel = res.data.results;
      });
    },
  },
};
</script>
