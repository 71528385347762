<!-- TransactionForm.vue -->
<template>
  <form @submit.prevent="submit">
    <div class="row justify-content-center">
      <div class="col-md-2">
        <label for="costType" class="text-bold">Firm</label>
        <v-select
          id="firm"
          :options="firmModel"
          label="firm_name"
          v-model="transactionModel.firm"
          :reduce="(manuf) => manuf.manufacturer_id"
        >
        </v-select>
      </div>

      <div class="col-md-2">
        <label for="expenseDate" class="text-bold">Date &nbsp;</label>
        <input
          type="date"
          id="expenseDate"
          v-model="transactionModel.date"
          class="form-control"
        />
      </div>

      <div class="col-md-2">
        <label for="invoiceNo" class="text-bold">Ref</label>
        <input
          type="text"
          id="invoiceNo"
          v-model="transactionModel.invoice_no"
          class="form-control"
        />
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-2">
        <label for="transactionType" class="text-bold">Transaction Type</label>
        <select
          name="Transaction Type"
          id="transactionType"
          v-model="transactionModel.transaction_type"
          class="form-control"
          required
        >
          <option value="Output">Output</option>
          <option value="Input">Input</option>
        </select>
      </div>

      <div class="col-md-2">
        <label for="transactionMethod" class="text-bold"
          >Transaction Method</label
        >
        <select
          name="Transaction Method"
          id="transactionMethod"
          v-model="transactionModel.transaction_method_new"
          class="form-control"
        >
          <option value=""></option>
          <option
            v-for="item in transactionMethodModel"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="col-md-2"></div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-2">
        <label for="paymentType" class="text-bold">Payment Type</label>
        <select
          name="Payment Type"
          id="paymentType"
          v-model="transactionModel.payment_type"
          class="form-control"
          required
        >
          <option value="ALTIN">ALTIN</option>
          <option value="CHF">CHF</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
          <option value="TL">TL</option>
          <option value="USD">USD</option>
        </select>
      </div>

      <div class="col-md-2">
        <label for="paymentMethod" class="text-bold">Payment Method</label>
        <select
          name="Payment Method"
          id="paymentMethod"
          v-model="transactionModel.payment_method"
          class="form-control"
        >
          <option value="Check">Check</option>
          <option value="Cash">Cash</option>
        </select>
      </div>

      <div class="col-md-2">
        <label for="amount" class="text-bold">Amount</label>
        <input
          type="number"
          step="0.01"
          placeholder="0.00"
          id="amount"
          v-model="transactionModel.amount"
          class="form-control"
          required
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <label for="description" class="text-bold">Description</label>
        <input
          type="text"
          id="description"
          v-model="transactionModel.description"
          class="form-control"
        />
      </div>
    </div>

    <div class="row justify-content-center mt-3">
      <div class="col-md-2">
        <label for="storage" class="text-bold">Add to Storage</label>
        <v-select
          label="name"
          :options="storageModel"
          v-model="storageTransactionModel.storage"
          :reduce="(storage) => storage.id"
        />
      </div>
      <div class="col-md-2">
        <label for="storageAmount" class="text-bold">Storage Amount</label>
        <input
          type="number"
          step="0.01"
          placeholder="0.00 gr"
          id="storageAmount"
          v-model="storageTransactionModel.amount"
          class="form-control"
          :disabled="storageTransactionModel.storage === null"
        />
      </div>
      <div class="col-md-2">
        <label for="storageDescription" class="text-bold"
          >Storage Description</label
        >
        <textarea
          type="text"
          id="storageDescription"
          v-model="storageTransactionModel.description"
          class="form-control"
          :disabled="storageTransactionModel.storage === null"
        />
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-if="showSubmit">
      <div class="col-md-1">
        <input class="btn btn-secondary" type="submit" value="Submit" />
      </div>
    </div>
  </form>
</template>
<script>
export default {
  name: "TransactionForm",
  props: {
    firmModel: Array,
    transactionMethodModel: Array,
    storageModel: Array,
    transactionModel: Object,
    storageTransactionModel: Object,
    showSubmit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    submit() {
      this.$emit("submitForm");
    },
    validateForm() {
      const requiredFields = ["transaction_type", "payment_type", "amount"];
      const isValid = requiredFields.every(
        (field) => this.transactionModel[field]
      );
      this.$emit("validation", isValid);
    },
  },
  watch: {
    transactionModel: {
      handler() {
        this.validateForm();
      },
      deep: true,
    },
  },
};
</script>
