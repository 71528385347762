<template>
  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>{{ pageName }}</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />

    <div class="row">
      <div class="col-md-3">
        <div class="mb-3">
          <label for="" class="form-label">Property Name</label>
          <input
            type="text"
            class="form-control"
            v-model="submitData[this.fieldName]"
          />
          <small class="form-text text-muted">mode: {{ mode }}</small>
        </div>
      </div>

      <slot name="extra"></slot>

      <div class="col-md-2">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-12 mb-2 justify-content-center">
            <button
              v-if="mode === 'new'"
              type="button"
              @click="submit"
              id="newPropertyButton"
              class="btn btn-outline-dark"
            >
              Submit
            </button>
            <button
              v-if="mode === 'edit'"
              type="button"
              @click="submit"
              id="newPropertyButton"
              class="btn btn-outline-dark"
            >
              Update
            </button>
          </div>

          <div v-if="mode === 'edit'" class="col-md-12 justify-content-center">
            <button
              type="button"
              @click="deleteProperty"
              id="deletePropertyButton"
              class="btn btn-outline-danger btn-block"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <label for="property">Properties</label>
        <select
          @change="selectChange"
          v-model="selectedId"
          id="property"
          class="form-select"
          size="6"
        >
          <option
            v-bind:value="p[this.idField]"
            v-for="p in propertyData"
            :key="p[this.idField]"
          >
            {{ p[this.fieldName] }}
          </option>
        </select>
        <small class="form-text text-muted"
          >Click the item that you want to rename.</small
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";

export default {
  data: function () {
    return {
      propertyData: [],
      selectedId: null,
      mode: "new",
      submitData: {
        [this.fieldName]: null,
      },
    };
  },
  props: {
    pageName: {
      required: true,
    },
    endPoint: {
      required: true,
    },
    fieldName: {
      required: true,
    },
    idField: {
      default: "id",
    },
    extraSubmitData: {
      default: null,
      required: false,
    },
  },
  created() {
    let end = this.endPoint;
    getAPI(end + "?limit=100000").then(
      (res) => (this.propertyData = res.data.results)
    );
  },
  emits: ["selected", "reset"],
  methods: {
    reset() {
      this.submitData = { [this.fieldName]: null };
      this.mode = "new";
      this.selectedId = null;
      this.$emit("reset", null);
    },
    selectChange() {
      this.mode = "edit";
      const selectedProperty = this.propertyData.filter(
        (item) => item[this.idField] === this.selectedId
      )[0];
      this.$emit("selected", selectedProperty);
      this.submitData[this.fieldName] = selectedProperty[this.fieldName];
    },
    submit() {
      let payload = this.submitData;
      if (this.extraSubmitData) {
        payload = { ...payload, ...this.extraSubmitData };
      }

      if (payload[this.fieldName] === null || payload[this.fieldName] === "") {
        window.alert("Please enter a valid value.");
        return false;
      }
      if (this.mode === "new") {
        let end = this.endPoint;
        postAPI(end, payload).then((res) => {
          this.propertyData.push(res.data);
          this.reset();
        });
      }

      if (this.mode === "edit") {
        let end = this.endPoint;
        patchAPI(end + "/" + this.selectedId, payload).then((res) => {
          // this.propertyData.push(res.data);
          this.propertyData.forEach((element, index) => {
            if (element[this.idField] === this.selectedId) {
              this.propertyData[index] = res.data;
              return true;
            }
          });
          this.reset();
        });
      }
    },
    deleteProperty() {
      let end = this.endPoint;
      deleteAPI(end + "/" + this.selectedId).then((res) => {
        // this.propertyData.push(res.data);
        // let deleteIndex = null

        // console.log(res)
        if (String(res.status)[0] === "2") {
          this.propertyData = this.propertyData.filter(
            (property) => property[this.idField] !== this.selectedId
          );
        }
        this.reset();
      });
    },
  },
};
</script>

<style>
.col-centered {
  float: none;
  margin-right: auto;
  margin-left: auto;
}
</style>
