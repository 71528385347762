<template>
<Toast />

 <router-view/>


</template>

<script>
export default {
    components: {},
    }

</script>



<style>
@import '@/assets/styles/app.css';

#form-submit {
  align-self: center;
  background-color: white;
  width: 120px;
  /* margin-left:240 px; */
  margin-right:auto;
  border-radius: 5px;
  font-weight: bold;
  color: black;
  position: absolute;
  margin-top: 20px;
  left: 50%;

}


@media screen and (max-width: 700px) {
  .container-fluid {
	margin-left: 0px; 

  }

}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
  .container-fluid{
	margin-left: 0px; 

  }
}


.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
    border: none;
}

.v-select {
    background: white;
}
.swal2-popup-emre{
  font-size:0.7rem !important;
}
</style>
