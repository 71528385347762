<template>
  <Menu />

  <div class="container-fluid embed-responsive embed-responsive-1by1">
    <!-- <Table
              
                excelName = "deneme"
                excelTabName = "deneme"
                :tableInfo = "tableInfo"
                endPoint = "customer-search?"
                showRoute = true
                detailPath = "/customers/"
                idName = "id"
                :useCreate = true
                :filters = filters
            />
        </div> -->
  </div>
</template>

<script>
import { getAPI } from "@/global/callAPI";
import Table from "@/components/Table";
import Menu from "@/components/Menu.vue";
export default {
  name: "Home",
  components: { Menu, Table, Menu },
  data: function () {
    return {
      showTable: false,
      tagModel: [],
      tableInfo: [
        { field: "id", header: "Id", order: true },
        { field: "customer_name", header: "Name", order: true },
        { field: "customer_surname", header: "Surname", order: true },
        { field: "tags", header: "Tags" },
      ],
      filterModel: {
        name: null,
        surname: null,
        tags: null,
      },
      filters: [{ field: "customer_name__icontains", value: null }],
      customers: null,
      oldFilters: null,
      genderFilter: ["male", "female"],
      tagFilter: [],
      loading: false,
      nameSearchInput: null,
      surnameSearchInput: null,
      tagInput: null,
    };
  },
  methods: {
    applyFilter() {
      const isEmpty = Object.values(this.filterModel).every(
        (x) => x === null || x === "" || x.length === 0
      );
      console.log(this.filterModel);
      if (isEmpty === true) {
        window.alert("Please enter a filter!");
        return false;
      }
      console.log(isEmpty);
      let customer_name = this.filterModel.name || "";
      let customer_surname = this.filterModel.surname || "";
      let tags = this.filterModel.tags || "";

      let filterList = [
        `customer_name__istartswith=${customer_name}`,
        `customer_surname__istartswith=${customer_surname}`,
        `tags__tag__id__in=${tags}`,
        `limit=100000`,
      ];
      let query = filterList
        .join("&")
        .replaceAll("null", "")
        .replace("nbull", "null");

      this.loading = true;
      getAPI(`customer-search?` + query).then((res) => {
        this.customers = res.data.results;
        this.customers.forEach((element, index) => {
          let tags = element.tags;
          this.customers[index]["tags"] = tags.toString();
        });
        this.loading = false;
        this.showTable = true;
      });
    },
    showAll() {
      this.applyFilter(1000000);
    },
  },
  created: function () {
    //     this.loading = true
    //     this.oldFilters = this.filters;
    // getAPI('customer-tags?limit=10000&ordering=name').then((res) => {this.tagModel = res.data.results});
  },
};
</script>
