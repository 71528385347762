<template>
<Menu/>

    <div class="container-fluid">
        <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px;">Customer List </h6>
        <hr>


<form @submit.prevent="applyFilter()">

        <div class="row justify-content-center">
        <div class="col-md-2">
            <label for="nameInput">Name</label>
                <input class="form-control" type="text" name="Customer Name" 
                id="nameInput" v-model="filterModel.name">
        </div>

        <div class="col-md-2">
            <label for="nameInput">Surname</label>
                <input class="form-control" type="text" name="Customer Name" 
                id="nameInput" v-model="filterModel.surname">
        </div>
        <div class="col-md-3">
            <label for="nameInput">Tags</label>

            <v-select  
                    :options="tagModel"
                    v-model="filterModel.tags"
                    id="customerTag"
                    :reduce = "tag => tag.id"
                    label="name"
                    :multiple=true
                    />
        </div>
        <div class="col-md-2 my-3">
            <button id="" class="btn btn-secondary " @click="applyFilter">Filter</button>
        </div>

        <hr>

        <div class="col-md-11">
            <Table
                :key="componentKey"
                v-if="showTable === true"
                excelName = "customer-export"
                excelTabName = "customers"
                :tableInfo = "tableInfo"
                showRoute = true
                detailPath = "/customers/"
                idName = "id"
                :useCreate = true
                :endPoint = endPoint
            />
        </div>
        </div>
    </form>


    </div>


</template>

<script>
import Menu from '@/components/Menu';
import {getAPI} from '@/global/callAPI';
import Table from '@/components/Table';
export default {
    name:"CustomerList",
    components: {Menu, Table},
    data: function () {
        return {
            showTable:false,
            tagModel:[],
            tableInfo: [
                {field: 'customer_name', header: 'Name',order:true},
                {field: 'customer_surname', header: 'Surname',order:true},
                {field: 'tags', header: 'Tags',order:true},

            ],
            filterModel: {
                name:this.$route.query.customer_name__istartswith || null,
                surname:this.$route.query.customer_surname__istartswith || null,
                tags:this.$route.query.tags__tag__id__in || null,
            },
            customers:null,
            oldFilters:null,
            genderFilter: ['male', 'female'],
            tagFilter:[],
            loading:false,
            nameSearchInput: null,
            surnameSearchInput:null,
            tagInput:null,
            filters:[],
            componentKey:0,
            endPoint:"",


    }},
    methods: {

    applyFilter(){
        // // this.showTable = false
        // this.$forceUpdate()

        // const isEmpty = Object.values(this.filterModel).every(x => x === null || x === '' || x.length === 0);
        // if (isEmpty === true) {window.alert("Please enter a filter!"); return false}
        let customer_name = this.filterModel.name || ""
        let customer_surname = this.filterModel.surname || ""
        let tags = this.filterModel.tags || ""

        let filterList = [
            // {field:"customer_name__istartswith",value:customer_name},
            // {field:"customer_surname__istartswith",value:customer_surname},
            // {field:"tags__tag__id__in",value:tags},
            `customer_name__icontains=${customer_name}`,
            `customer_surname__icontains=${customer_surname}`,
            `tags__tag__id__in=${tags}`,
        ]

        this.filters = filterList
        // this.$router.replace({'query': null});
        // this.showTable = true
        
        // this.$router.go(this.$router.currentRoute)

        let query = filterList.join('&').replaceAll("null","").replace('nbull','null')
        this.endPoint = `customer-search?`+ query
        this.componentKey += 1

        // this.loading = true
        // getAPI(`customer-search?`+ query)
        // .then(res => {
        //     this.customers = res.data.results;
        //     this.customers.forEach((element,index) => {
        //         let tags = element.tags
        //         this.customers[index]["tags"] = tags.toString()
        //     })
        //     this.loading = false
        //     this.showTable =true
            
        //     });
    },
    showAll() {
        this.applyFilter(1000000)
    },


    },
    created: function () {
        this.applyFilter()
        this.loading = true
        this.oldFilters = this.filters;
        this.showTable = true
    getAPI('customer-tags?limit=10000&ordering=name').then((res) => {this.tagModel = res.data.results});


}}
</script>

<style scoped>

</style>