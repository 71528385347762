import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"

// import Vuex from 'vuex'
// import IdleVue from 'idle-vue'


import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"


import { createApp, VueElement, Vue } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Swal from 'sweetalert2';

import PrimeVue from 'primevue/config';
import Chips from 'primevue/chips';
import FileUpload from 'primevue/fileupload';
// import DataTable from 'primevue/datatable';

// import Column from 'primevue/column';

// import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Tooltip from 'primevue/tooltip';
// import Calendar from 'primevue/calendar';
// import InputNumber from 'primevue/inputnumber';
// import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Menubar from 'primevue/menubar';
import AutoComplete from 'primevue/autocomplete';
import TabMenu from 'primevue/tabmenu';
import Galleria from 'primevue/galleria';
import Checkbox from 'primevue/checkbox';
import InputSwitch from 'primevue/inputswitch';
import Listbox from 'primevue/listbox';
import ToggleButton from 'primevue/togglebutton';
// import TreeSelect from 'primevue/treeselect';
import Tree from 'primevue/tree';
import OverlayPanel from 'primevue/overlaypanel';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Dialog from 'primevue/dialog';

// import Paginator from 'primevue/paginator';

import vSelect from 'vue-select'
//https://vue-select.org/
import 'vue-select/dist/vue-select.css';
// import $ from "jquery";
import Image from 'primevue/image';



window.Swal = Swal;

const Toast2 = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    showCloseButton: true,
    timerProgressBar: true,


});

const toastTransfer = Swal.mixin({
    toast: true,
    position: 'top-end',
    showCloseButton: true,
    showConfirmButton: true,
    showCancelButton: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Show transfers!',
    width:'20em',
    customClass : {
        popup: 'swal2-popup-emre'
      }

});


window.Toast2 = Toast2;
window.toastTransfer = toastTransfer;


const app = createApp(App);
// app.use(Vuex)

app.use(router)
app.use(PrimeVue)
app.component('Chips', Chips);
app.component('FileUpload', FileUpload);
// app.component('Column', Column);
// app.component('InputText', InputText);
app.component('Button', Button);
app.directive('tooltip', Tooltip);
// app.component('Calendar', Calendar);
// app.component('InputNumber', InputNumber);
// app.component('DataTable', DataTable);
// app.component('Dropdown', Dropdown);
app.component('MultiSelect', MultiSelect);
app.component('Menubar', Menubar);
app.component('AutoComplete', AutoComplete);
app.component('TabMenu', TabMenu);
app.component('Galleria', Galleria);
app.component('Checkbox', Checkbox);
app.component('InputSwitch', InputSwitch);
app.component('ToggleButton', ToggleButton);
// app.component('TreeSelect', TreeSelect);
app.component('OverlayPanel', OverlayPanel);
app.component('Listbox ', Listbox);
app.component('Dialog', Dialog);
app.component('Image', Image);

// app.component('Paginator', Paginator);


app.component('Toast', Toast);
app.component('Tree', Tree);

// const eventsHub = new Vue()

// app.use(IdleVue, {
//     idleTime: 1000
// })


app.component('v-select', vSelect)

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresLogin)) {
        if (!store.getters.loggedIn) {
            next("/login")
        } else {
            next()
        }
    } else {
        next()
    }
})



app.use(store)
app.use(ToastService);
app.mount('#app')