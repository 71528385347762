<template>
  <Menu />
  <div class="container-fluid embed-responsive embed-responsive-1by1">
    <!-- <Table
                
                  excelName = "deneme"
                  excelTabName = "deneme"
                  :tableInfo = "tableInfo"
                  endPoint = "customer-search?"
                  showRoute = true
                  detailPath = "/customers/"
                  idName = "id"
                  :useCreate = true
                  :filters = filters
              />
          </div> -->
    <iframe
      class="embed-responsive-item"
      src="https://lookerstudio.google.com/embed/reporting/0dcbb7bd-ab3e-4853-90b9-6fd9ade5aa3a/page/VPnbD"
      frameborder="0"
      style="
        position: absoulte;
        top: 0;
        left: 0;
        bottom: 0;
        right: 20px;
        width: 100%;
        height: 1000px;
        border: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        z-index: 999999;
      "
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
export default {
  name: "Dashboard",
  components: { Menu },
  data: function () {
    return {};
  },
  methods: {},
  created: function () {
    //     this.loading = true
    //     this.oldFilters = this.filters;
    // getAPI('customer-tags?limit=10000&ordering=name').then((res) => {this.tagModel = res.data.results});
  },
};
</script>
