<template>
  <Toast />
  <Menu />

  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      Update Loan: {{ loan.id }} - {{ loan.reference_no }}
    </h6>
    <hr />
    <form @submit.prevent="updateLoan">
      <div class="row justify-content-center">
        <div class="col-md-8 row">
          <div class="col-md-12 d-flex justify-content-center">
            <h3>
              <span v-if="loan.is_completed" class="badge bg-success"
                >Approved</span
              >
              <span v-else class="badge bg-danger">Not Approved</span>
            </h3>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="reference_no">Reference No</label>
              <input
                type="text"
                class="form-control"
                id="reference_no"
                v-model="loan.reference_no"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="reference_no">Store</label>
              <input
                type="text"
                class="form-control"
                id="reference_no"
                v-model="loan.store"
                required
                disabled
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="start_date">Start Date</label>
              <input
                type="date"
                class="form-control"
                id="start_date"
                v-model="loan.start_date"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="finish_date">Finish Date</label>
              <input
                type="date"
                class="form-control"
                id="finish_date"
                v-model="loan.finish_date"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="reference_no">Creator User</label>
              <input
                type="text"
                class="form-control"
                id="reference_no"
                v-model="loan.creator_user"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="reference_no">Approved User</label>
              <input
                type="text"
                class="form-control"
                id="reference_no"
                v-model="loan.approved_user"
                disabled
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="description">Description</label>
              <textarea
                class="form-control"
                id="description"
                v-model="loan.description"
              ></textarea>
            </div>
          </div>

          <div class="col-md-12 d-flex justify-content-center mt-3">
            <div class="form-group">
              <button
                type="button"
                @click="finishLoan"
                :class="{
                  btn: true,
                  'btn-danger': loan.is_completed,
                  'btn-success': !loan.is_completed,
                }"
              >
                {{ loan.is_completed ? "Remove Approve" : "Approve" }}
              </button>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary">Update</button>
            </div>
          </div>
          <!-- Help text about difference update and approve -->
          <div class="form-group mt-3 d-flex justify-content-center">
            <small class="form-text text-muted">
              If you want to approve the loan, click the approve button. If you
              want to update the loan, click the update button.
            </small>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import Menu from "@/components/Menu";
import SelectProduct from "@/components/SelectProduct.vue";
import store from "@/store";

export default {
  name: "NewLoan",
  components: { Menu },
  data: function () {
    return {
      loan: {
        reference_no: "",
        start_date: null,
        description: "",
      },
    };
  },
  created() {
    this.getLoan();
  },

  methods: {
    updateLoan() {
      patchAPI("loans/" + this.$route.params.id, this.loan).then((response) => {
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Loan created successfully",
          life: 1000,
        });
      });
    },
    getLoan() {
      getAPI("loans/" + this.$route.params.id).then((response) => {
        this.loan = response.data;
      });
    },
    finishLoan() {
      const data = this.loan;
      const finish_date = new Date().toISOString().substr(0, 10);
      const is_completed = !this.loan.is_completed;
      patchAPI("loans/" + this.$route.params.id, {
        is_completed: is_completed,
      }).then((response) => {
        if (response.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Loan finished",
            life: 1000,
          });

          this.loan = response.data;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.d-flex {
  gap: 20px;
}
</style>
