<template>
  <Menu />
  <div class="container-fluid">
    <h6
      v-if="catalogNameModel.length > 0"
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>Add/Delete Product to Catalog</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />

    <div class="row justify-content-center">
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12">
            <label>Catalog</label>
            <v-select
              :options="catalogNameModel"
              label="name"
              v-model="selectedCatalog"
              :reduce="(catalog) => catalog.id"
            >
            </v-select>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-6" v-if="selectedCatalog">
            <label for="multiplier">Multiplier</label>
            <input
              type="number"
              v-model="multiplier"
              id="multiplier"
              placeholder="0.00"
              @keyup.enter="multiPrice"
              class="form-control"
            />
            <small class="form-text text-muted"
              >Multiplies tag price of the products you add.</small
            >
          </div>
        </div>
      </div>

      <div class="offset-md-1 col-md-6">
        <div class="row">
          <div class="col-md-7">
            <label for="" class="form-label">Products</label>
            <textarea
              class="form-control"
              v-model="multiProduct"
              rows="6"
            ></textarea>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4">
            <button
              type="button"
              @click="productAdd"
              v-if="selectedCatalog"
              class="btn btn-primary"
            >
              Add Multi Product
            </button>
          </div>

          <div class="col-md-4">
            <button
              type="button"
              @click="deleteProduct()"
              class="btn btn-danger"
            >
              Delete Product
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <p>Successful Products: {{ successfulProducts }}</p>
    </div>

    <div class="row">
      <p>Errors:</p>
      <ul>
        <li v-for="error in errors" :key="error">
          {{ error.reference_no }}: {{ error.detail }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import Menu from "@/components/Menu";
export default {
  name: "AddDeleteCatalog",
  components: { Menu },
  data() {
    return {
      selectedCatalog: null,
      catalogNameModel: [],
      multiProduct: null,
      successfulProducts: [],
      errors: [],
      showResults: false,
      multiplier: 0,
    };
  },
  created: function () {
    getAPI("catalog?limit=100000&ordering=name&").then((res) => {
      this.catalogNameModel = res.data.results;
    });
  },
  methods: {
    productAdd() {
      this.showResults = false;
      this.successfulProducts = [];
      this.errors = [];
      const catalog = this.selectedCatalog;
      let reqList = [];

      var lines = this.multiProduct.split("\n");
      lines = [...new Set(lines)];
      lines = lines.filter((el) => {
        return el != null && el != "";
      });
      const payload = {
        reference_no_list: lines,
        catalog_id: catalog,
        multiplier: this.multiplier,
      };
      postAPI("add-multiple-catalog-product", payload)
        .then((res) => {
          const results = res.data.results;
          for (var i = 0; i < results.length; i++) {
            const result = results[i];
            if (result["status"] === "success") {
              this.successfulProducts.push(result["reference_no"]);
            } else {
              this.errors.push({
                reference_no: result["reference_no"],
                detail: result["detail"],
              });
            }
          }
        })
        .finally(() => {
          this.showResults = true;
        });
    },
    deleteProduct() {
      this.successfulProducts = [];
      this.errors = [];
      var lines = this.multiProduct.split("\n");
      lines = [...new Set(lines)];
      // remove '' and null from array
      lines = lines.filter((el) => {
        return el != null && el != "";
      });

      const payload = {
        reference_no_list: lines,
      };
      postAPI("catalog-product-deactive", payload)
        .then((res) => {
          this.successfulProducts = res.data.results;
        })
        .finally(() => {
          this.showResults = true;
        });
      //   for (var i = 0; i < lines.length; i++) {
      //     let reference_no = lines[i];
      //     let payload = { reference_no: reference_no };
      //     postAPI("catalog-product-deactive", payload).then((res) => {
      //       var is_removed = res.data["is_removed"];
      //       var result = res["result"];
      //       if (is_removed === true) {
      //         this.successfulProducts.push(reference_no);
      //         // this.$toast.add({severity:'success', summary: 'Success', detail:`${reference_no} removed from catalogs!`, life: 3000})
      //       } else {
      //         this.errors.push({
      //           reference_no: reference_no,
      //           detail: res.data.result,
      //         });
      //         // this.$toast.add({severity:'error', summary: 'Error', detail:result, life: 3000});
      //       }
      //     });
      //   }
    },
  },
};
</script>

<style></style>
