<template>
  <Menu />
  <div id="invoiceApp" class="container-fluid" v-if="productModel.watch">
    <!-- <h3 class="text-left font-weight-bold text-info text-dark">{{ this.productModel.reference_no}}</h3>
		<hr style="height:0.5px;margin-bottom:30px"> -->
    <TabMenu :model="items" />
    <router-view />
    <form id="mainProductForm">
      <div class="row mt-2">
        <div
          class="col-md-2 offset-md-11"
          v-show="hasPermission(['limited_seller', 'seller'])"
        >
          <button
            @click="deleteDialog = true"
            type="button"
            class="btn btn-danger"
          >
            Delete
          </button>
        </div>
      </div>

      <div class="row" v-if="productModel.thumbnail">
        <!-- Photo Cell -->
        <div class="col-md-2 offset-md-4">
          <!-- <img  class=" img-thumbnail thumbnail" v-bind:src="productModel.thumbnail" alt="thumbnail"> -->
          <Image
            v-bind:src="productModel.thumbnail"
            alt="Image"
            width="180"
            preview
          />
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="referenceNumber">Reference Number</label>
          <input
            type="text"
            id="referenceNumber"
            v-model="productModel.reference_no"
            class="form-control"
            disabled
          />
        </div>

        <div class="col-md-2">
          <label for="store">Store</label>
          <select
            id="store"
            v-model="productModel.store"
            class="form-control"
            disabled
          >
            <option
              v-bind:value="s.id"
              v-for="s in storeModel.results"
              :key="s.id"
            >
              {{ s.store_name }}
            </option>
          </select>
        </div>
        <div class="col-md-1">
          <label for="saleStatus">Status</label>
          <input
            type="text"
            id="saleStatus"
            v-model="productModel.sale_status"
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-2">
          <label for="saleStatus">Created Date</label>
          <input
            type="text"
            id="saleStatus"
            :value="cratedDateShower(productModel.created_date)"
            class="form-control"
            disabled
          />
        </div>

        <div class="col-md-1 form-group col align-self-end">
          <button
            @click="submitWatchChanges"
            type="button"
            class="btn btn-secondary"
          >
            Submit Changes
          </button>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="firstPrice"> Tag Price</label>
          <input
            type="number"
            step=".01"
            id="firstPrice"
            v-model="productModel.first_price"
            class="form-control"
            required
          />
        </div>

        <div class="col-md-2">
          <label for="currency">Currency</label>
          <select
            id="currencY"
            v-model="productModel['currency']"
            class="form-control"
          >
            <option value="USD" selected>USD</option>
            <option value="TL">TL</option>
            <option value="CHF">CHF</option>
            <option value="EUR">EUR</option>
          </select>
        </div>

        <div class="col-md-2 flex-column d-flex justify-content-end">
          <div>
            <input
              class="form-check-input"
              type="checkbox"
              v-model="productModel.active"
              id="stock"
            />
            <label class="form-check-label" style="margin-left: 4px" for="stock"
              >Stock</label
            >
          </div>

          <div class="">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="productModel.show_in_merchant"
              id="merchantCheck"
            />
            <label class="form-check-label" style="margin-left: 4px" for="stock"
              >Show To Merchant</label
            >
          </div>
        </div>
        <div class="col-md-2"></div>

        <!-- <div
          class="form-group col align-self-end col-md-2"
          v-show="hasPermission(['limited_seller', 'seller', 'office']) != true"
        ></div> -->
      </div>
      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="firstPrice"> List Price (EUR)</label>
          <input
            type="number"
            id="firstPrice"
            v-model="productModel.second_price"
            class="form-control"
          />
        </div>
        <div class="col-md-2">
          <label for="firstPrice"> List Price (USD)</label>
          <input
            type="number"
            id="firstPrice"
            v-model="productModel.third_price"
            class="form-control"
          />
        </div>
        <div class="col-md-2">
          <label for="firstPrice"> List Price (AED)</label>
          <input
            type="number"
            id="firstPrice"
            v-model="productModel.fourth_price"
            class="form-control"
          />
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="condition">Condition</label>
          <select
            id="condition"
            v-model="productModel.watch[0].condition"
            class="form-control"
            required
          >
            <option v-for="c in conditionModel" :key="c.id" v-bind:value="c.id">
              {{ c.name }}
            </option>
          </select>
        </div>

        <div class="col-md-2">
          <label for="purchaseDate">Purchase Date</label>
          <input
            type="date"
            id="purchaseDate"
            v-model="productModel.watch[0].purchase_date"
            class="form-control"
          />
        </div>

        <div class="col-md-2">
          <label for="purchaseStore">Purchase Store</label>
          <select
            id="purchaseStore"
            v-model="productModel.watch[0].purchase_store"
            class="form-control"
          >
            <option value="0">-Select-</option>
            <option
              v-for="p in manufacturers.results"
              :key="p.manufacturer_id"
              v-bind:value="p.manufacturer_id"
            >
              {{ p.firm_name }}
            </option>
          </select>
        </div>

        <div v-if="newTagModel" class="col-md-2">
          <!-- getapi yi değiştirmeyi unutma -->
          <label for="productTag">Tags</label>
          <v-select
            :options="newTagModel"
            label="name"
            v-model="productModel.tags"
            :reduce="(tag) => tagConverter(tag.id)"
            :multiple="true"
          />
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="brand">Brand</label>
          <select
            id="purchaseStore"
            v-model="productModel.watch[0].brand"
            class="form-control"
            required
          >
            <option value="0">-Select-</option>
            <option
              v-for="p in brandModel.results"
              :key="p.id"
              v-bind:value="p.id"
            >
              {{ p.brand_name }}
            </option>
          </select>
        </div>

        <div class="col-md-2">
          <label for="originalReference">Original Reference</label>
          <input
            type="text"
            class="form-control"
            v-model="productModel.watch[0].original_reference"
          />
        </div>

        <div class="col-md-2">
          <label for="modelNumber">Model Number</label>
          <input
            type="text"
            id="modelNumber"
            v-model="productModel.model_no"
            class="form-control"
          />
        </div>

        <div class="col-md-2"></div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-4">
          <label for="description">Description</label>
          <textarea
            type="text"
            id="description"
            v-model="productModel.description"
            class="form-control"
          ></textarea>
        </div>

        <div class="col-md-2">
          <label for="ticketInfo">Ticket Info</label>
          <input
            type="text"
            id="ticketInfo"
            v-model="productModel.ticket_info"
            class="form-control"
          />
        </div>

        <div class="col-md-2">
          <div class="row justify-content-center">
            <!-- <div>
						  <input class="form-check-input" type="checkbox" v-model="productModel.new_product" id="newProduct">
							<label class="form-check-label" for="newProduct">
								New Product
							</label>
					</div> -->
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Watc Properties -->
        <div class="col-md-4 offset-md-2">
          <div>
            <p class="text-left text-bold text-info text-dark">
              Properties
              <small class="form-text text-muted"
                >(If the property is empty, that line is deleted!)</small
              >
            </p>
            <!-- <button @click="propertyShowClick" type="button" class="btn btn-outline-dark" style="font-size: 12px; padding: 6px 10px;margin-left:5px" >{{propertyShowLabel}}</button> -->
          </div>
          <table
            id="manuf-table"
            class="table table-bordered"
            v-if="propertyShow"
          >
            <thead>
              <tr class="text-center">
                <th width="50%">Property</th>
                <th width="30%">Value</th>
                <th width="20%">
                  <button
                    type="button"
                    v-on:click="addItem"
                    class="btn btn-success btn-sm"
                  >
                    <span area-hidden="true">&plus;</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(d, i) in productModel.watch[0].watch_property"
                :key="i"
                style
              >
                <td width="10%">
                  <div class="form-group">
                    <select
                      id="propertyType"
                      v-model="d.property"
                      class="form-control"
                      required
                    >
                      <option value="0">-Select-</option>
                      <option
                        v-for="p in watchPropertyModel.results"
                        :key="p.property_id"
                        v-bind:value="p.property_id"
                      >
                        {{ p.property_name }}
                      </option>
                    </select>
                    <!-- <v-select :options="manufacturers.results" label="manufacturer_id" :reduce="manufacturer_id => manufacturer_id.name_surname" /> -->
                  </div>
                </td>

                <td width="10%">
                  <div class="form-group">
                    <input
                      type="text"
                      id="Price"
                      v-model="d.property_value"
                      class="form-control"
                    />
                  </div>
                </td>
                <td width="10%">
                  <button
                    type="button"
                    v-on:click="removeItem(d, i)"
                    class="btn btn-danger btn-sm"
                    tabindex="99"
                  >
                    <span aria-hidden="true">&minus;</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Watch Properties -->
        </div>
      </div>
    </form>
  </div>

  <Dialog
    v-model:visible="deleteDialog"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
  >
    <div class="confirmation-content">
      <span>Are you sure you want to delete?</span>
    </div>
    <template #footer>
      <div class="row justify-content-center">
        <div class="offset-md-8 col-md-2">
          <button
            @click="deleteDialog = false"
            type="button"
            class="btn btn-outline-dark d-flex justify-content-center p-button-text"
          >
            No
          </button>
        </div>
        <div class="col-md-2">
          <button
            @click="deleteWatch"
            type="button"
            class="btn btn-outline-danger d-flex justify-content-center"
          >
            Yes
          </button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Menu from "@/components/Menu";
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import { useRouter } from "vue-router";

export default {
  name: "WatchItem",
  components: { Menu },
  data: function () {
    return {
      title: null,
      thumbnail: null,
      tag: "",
      tags: null,
      productModel: {},
      storeModel: null,
      propertyShowLabel: "Hide",
      propertyShow: true,
      manufacturers: [],
      producTypeModel: [],
      manufactureTypes: [],
      brandModel: [],
      watchPropertyModel: [],
      conditionModel: [],
      newTagModel: null,
      items: [
        {
          label: "Edit",
          icon: "pi pi-fw pi-pencil",
          to: "/products/" + this.$route.params.id,
        },
        {
          label: "Photo",
          icon: "pi pi-fw pi-image",
          to: "/products/photo/" + this.$route.params.id,
        },
        {
          label: "Sale",
          icon: "pi pi-fw pi-shopping-cart",
          to: "/products/sales/" + this.$route.params.id,
        },
        {
          label: "Catalog",
          icon: "pi pi-fw pi-tags",
          to: "/products/catalog/" + this.$route.params.id,
        },
        {
          label: "Transfer",
          icon: "pi pi-fw pi-sync",
          to: "/products/transfer/" + this.$route.params.id,
        },
      ],
      deleteDialog: false,
    };
  },
  created: function () {
    getAPI("stores?limit=10000").then((res) => {
      this.storeModel = res.data;
    });
    getAPI("manufacturers?type_id=4&ordering=firm_name&limit=10000").then(
      (res) => {
        this.manufacturers = res.data;
      }
    );
    getAPI("watch-brands?limit=10000&order=brand_name").then((res) => {
      this.brandModel = res.data;
    });
    getAPI("watch-property-types?limit=1000").then((res) => {
      this.watchPropertyModel = res.data;
    });
    getAPI("products/" + this.$route.params.id).then((res) => {
      this.productModel = res.data;
      this.productModel["tags"] = this.productModel["tags"].map(function (
        item
      ) {
        return { tag: item.tag };
      });
    });

    getAPI("product-tags?limit=1000&ordering=name&").then((res) => {
      this.newTagModel = res.data.results;
    });
    getAPI("watch-conditions?limit=1000&ordering=name&").then((res) => {
      this.conditionModel = res.data.results;
    });
  },

  methods: {
    submitWatchChanges: function () {
      // if ( Array.isArray(this.productModel.tag)) {this.productModel.tag = this.productModel.tag.toString();}

      let payload = JSON.parse(JSON.stringify(this.productModel));
      if (payload.tag) {
        payload.tag = payload.tag.toString();
      }
      delete payload["thumbnail"];

      patchAPI("products/" + this.$route.params.id, payload);
      Toast2.fire({
        title: "Product updated successfully",
      });
      // if (typeof this.productModel.tag === 'string') {this.productModel.tag = this.productModel.tag.split(",");}
      // this.productModel.tag = this.productModel.tag.split(",");
    },

    // Property methods
    propertyShowClick: function () {
      this.propertyShow = !this.propertyShow;
      if (this.propertyShowLabel === "Show") {
        this.propertyShowLabel = "Hide";
      } else {
        this.propertyShowLabel = "Show";
      }
    },
    removeItem: function (data, id) {
      deleteAPI("watch-properties/" + data["id"]);
      this.productModel.watch[0].watch_property.splice(id, 1);
      Toast2.fire({
        title: "Deleted successfully",
      });
      // this.productModel.watch[0].watch_property.splice(id, 1);
    },
    addItem: function (e) {
      e.preventDefault();

      let detail = {
        property: null,
        property_value: null,
      };
      this.productModel.watch[0].watch_property.push(detail);
    },
    tagConverter(id) {
      return { tag: id };
    },

    // Form methods
    createWatch() {
      this.productModel.tag = this.productModel.tag.toString();
      postAPI("products", this.productModel)
        .then()
        .then((res) => useRouter().push("/products/" + res.data.product_id));
    },
    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }
      let user_groups = this.$store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },
    deleteWatch() {
      const productId = this.$route.params.id;
      deleteAPI("products/" + productId).then((res) => {
        if (res["status"] === 204) {
          Toast2.fire({ icon: "success", title: "Watch Deleted!" });
          this.$router.push("/home");
        } else {
          Toast2.fire({ icon: "warning", title: res.statusText });
          this.deleteDialog = false;
        }
        console.log(productId);
      });
    },
    cratedDateShower(date) {
      try {
        return date.slice(0, 10);
      } catch {
        return date;
      }
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  margin: 0 auto;
  padding: 9px;
  color: #fff;
}
.header h1 {
  font-size: 3.3em;
}
.text-bold {
  font-weight: 500;
}
.total-underline {
  border-bottom: 3px solid black;
  float: right;
}

hr {
  border-top: 1px solid black;
}

.btn.btn-danger.btn-sm {
  display: block;
  margin: auto;
}

#form-submit {
  background-color: black;
  width: 120px;
  margin-left: 240 px;
  margin-right: auto;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  position: absolute;
  margin-top: 20px;
  left: 50%;
}
#invoice-table {
  background-color: white !important;
}
/* .row justify-content-center justify-content-center  {
    margin-top: 20px;
} */

.p-multiselect {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.thumbnail {
  object-fit: cover;
  width: 180px;
  height: auto;
  float: right;
}

input {
  border-radius: 0px;
}

select {
  border-radius: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
