<template>
  <Menu />
  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      {{ header }}
    </h6>
    <hr />

    <div class="col-md-11">
      <div class="row">
        <div
          class="offset-md-10 col-md-2 justify-content-center align-itmes-center"
        >
          <div class="form-check form-switch checkBox text-center">
            <input
              class="form-check-input"
              type="checkbox"
              id="awaitingCheck"
              :checked="isAwaiting"
              v-model="isAwaiting"
              @change="updateEndpoint"
            />
            <label class="form-check-label" for="flexSwitchCheckDefault"
              >Show Awaitings</label
            >
          </div>
        </div>
      </div>

      <div
        class="row"
        v-if="hasPermission(['seller', 'limited_seller', 'office'])"
      >
        <div class="col-md-2">
          <label for="search">Search</label>
          <input
            type="text"
            class="form-control"
            v-model="apiFliters.search"
            @change="updateEndpoint"
          />
        </div>
        <div class="col-md-2">
          <label for="storeFilter">Sender Store</label>
          <v-select
            multiple
            v-model="apiFliters.sender_store"
            :options="storeModel"
            :reduce="(store) => store.id"
            label="store_name"
          />
        </div>
        <div class="col-md-2">
          <label for="storeFilter">Target Store</label>
          <v-select
            multiple
            v-model="apiFliters.target_store"
            :options="storeModel"
            :reduce="(store) => store.id"
            label="store_name"
          />
        </div>
        <div class="col-md-2 align-content-center">
          <button
            type="button submit"
            class="btn btn-secondary"
            @click="updateEndpoint"
          >
            Filter
          </button>
        </div>
      </div>
      <Table
        v-if="showTable"
        class="status"
        excelName="transfer-export"
        excelTabName="Transfers"
        :tableInfo="tableInfo"
        :endPoint="endPoint"
        showRoute="true"
        detailPath="/transfers/"
        idName="id"
        :useCreate="true"
        :key="tableKey"
      />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
// import { getAPI } from "@/global/callAPI";
import Table from "@/components/Table";
import { getAPI } from "@/global/callAPI";

export default {
  props: ["mode", "header"],
  name: "TransferList",
  components: { Menu, Table },
  data: function () {
    return {
      endPoint: `transfer-list?mode=${this.mode}&`,
      isAwaiting: false,
      tableKey: 0,
      showTable: true,
      storeModel: [],
      apiFliters: {
        sender_store: [],
        target_store: [],
        search: "",
      },
      tableInfo: [
        { header: "Id", field: "id", order: true },
        { header: "Mode", field: "mode" },
        {
          header: "Date",
          field: "created_date",
          isDateTime: true,
          order: true,
        },
        { header: "Sender Store", field: "sender_store_name" },
        { header: "Sender", field: "sender_name" },
        { header: "Target Store", field: "target_store_name" },
        { header: "Receiver", field: "receiver_name" },
        { header: "Status", field: "status", class: "status", order: true },
      ],
      transfers: null,
      oldFilters: null,
      // filters: {
      //     'global' : {value: null, matchMode: FilterMatchMode.CONTAINS},
      //     'id': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      //     'sender_store_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      //     'sender_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      //     'target_store_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      //     'receiver_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      //     'status': {value: null, matchMode: FilterMatchMode.EQUALS},

      // },
      statusFilter: ["Completed", "Awaiting"],
    };
  },
  created() {
    this.loading = true;

    this.oldFilters = this.filters;
    getAPI("stores?limit=10000").then((res) => {
      this.storeModel = res.data.results;
    });
    // getAPI("transfers?limit=1000")
    // .then(res => {
    //     this.transfers = res.data.results
    //     this.transfers.forEach(item=> {
    //         let data = new Date(item.created_date)
    //         let cDate = data.toLocaleDateString()
    //         item.created_date = cDate
    //     })

    // })
    // .then(this.loading = false);
  },
  methods: {
    rowClick(e) {
      this.$router.push({ path: "/transfers/" + e.data.id });
    },

    clearFilter() {
      this.filters = this.oldFilters;
    },

    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    updateEndpoint() {
      const params = {
        mode: this.mode,
        status: this.isAwaiting ? "Awaiting" : "",
        sender_store_id__in: this.apiFliters.sender_store,
        target_store_id__in: this.apiFliters.target_store,
        search: this.apiFliters.search,
      };
      const base = `transfer-list?`;
      console.log(base + new URLSearchParams(params).toString());
      this.endPoint = base + new URLSearchParams(params).toString();
      this.tableKey += 1;
    },
    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }
      let user_groups = this.$store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#awaitingCheck {
  width: 4em;
  height: 2em;
  align-content: center;
}

#checkBox {
  width: 5em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-items: center;
}
</style>
