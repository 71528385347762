<template>
  <Menu />
  <div id="invoiceApp" class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>New Watch</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 15px" />
    <form @submit.prevent="createWatch" id="mainProductForm">
      <div class="row justify-content-center">
        <div class="row justify-content-end">
          <div class="col-md-1">
            <input
              type="number"
              step=".01"
              @keydown.enter.prevent
              id="firstPrice"
              v-model="priceCalculatorInput"
              class="form-control"
              style="width: 86px; height: 20px; font-size: 11px"
            />
          </div>
          <div class="col-md-1 align-items-center justify-content-end">
            <b>/ &nbsp; </b>
            <span>{{ priceCalculatorInput * 4.5 || 0 }}</span>
          </div>
          <div class="row justify-content-end" style="">
            <div class="col-md-2">
              <small class="form-text text-muted"
                >Capital calculator ( * 4,5)</small
              >
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="d-flex">
            <label for="referenceNumber">Reference Number </label>

            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="getLastRepairReference"
              style="
                height: 20px;
                width: 40px;
                align-items: center;
                font-size: 10px;
                padding: 0px 5px;
                margin-top: 0px;
                margin-bottom: 0px;
              "
            >
              Repair
            </button>
          </div>
          <input
            type="text"
            @change="referenceController"
            id="referenceNumber"
            v-model="productModel.reference_no"
            class="form-control"
            required
          />
        </div>

        <div class="col-md-2">
          <label for="firstPrice"> Tag Price</label>
          <input
            type="number"
            step=".01"
            id="firstPrice"
            v-model="productModel.first_price"
            class="form-control"
            required
          />
        </div>

        <div class="col-md-2">
          <label for="currency">Currency</label>
          <select
            id="currencY"
            v-model="productModel['currency']"
            class="form-control"
          >
            <option value="USD" selected>USD</option>
            <option value="TL">TL</option>
            <option value="CHF">CHF</option>
            <option value="EUR">EUR</option>
          </select>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="firstPrice"> List Price (EUR)</label>
          <input
            type="number"
            id="firstPrice"
            v-model="productModel.second_price"
            class="form-control"
          />
        </div>
        <div class="col-md-2">
          <label for="firstPrice"> List Price (USD)</label>
          <input
            type="number"
            id="firstPrice"
            v-model="productModel.third_price"
            class="form-control"
          />
        </div>
        <div class="col-md-2">
          <label for="firstPrice"> List Price (AED)</label>
          <input
            type="number"
            id="firstPrice"
            v-model="productModel.fourth_price"
            class="form-control"
          />
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="condition">Condition</label>
          <select
            id="condition"
            v-model="productModel.watch[0].condition"
            class="form-control"
            required
          >
            <option v-for="c in conditionModel" :key="c.id" v-bind:value="c.id">
              {{ c.name }}
            </option>
          </select>
        </div>

        <div class="col-md-2">
          <label for="purchaseDate">Purchase Date</label>
          <input
            type="date"
            id="purchaseDate"
            v-model="productModel.watch[0].purchase_date"
            class="form-control"
          />
        </div>

        <div class="col-md-2">
          <label for="purchaseStore">Purchase Store</label>
          <!-- <select id="purchaseStore" v-model="productModel.watch[0].purchase_store" class="form-control" required>
					<option value="0">-Select-</option>
					<option v-for="p in manufacturers" :key="p.manufacturer_id" v-bind:value="p.manufacturer_id" >{{p.firm_name}}</option>
				</select> -->
          <!-- <v-select
					id="purchaseStore"
					:options="manufacturers"
					label="name_surname"
					v-model="productModel.watch[0].purchase_store"
					:reduce = "manufacturer => manufacturer.manufacturer_id">
				</v-select> -->

          <select
            id="purchaseStore"
            v-model="productModel.watch[0].purchase_store"
            class="form-control"
          >
            <option value="0">-Select-</option>
            <option
              v-for="p in manufacturers"
              :key="p.manufacturer_id"
              v-bind:value="p.manufacturer_id"
            >
              {{ p.firm_name }}
            </option>
          </select>
        </div>
        <div class="col-md-2">
          <label for="productTag">Tags</label>
          <v-select
            :options="tagModel"
            v-model="productModel.tags"
            id="productTag"
            :reduce="(tag) => tagConverter(tag.id)"
            label="name"
            :multiple="true"
          />
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="brand">Brand</label>
          <select
            id="purchaseStore"
            v-model="productModel.watch[0].brand"
            class="form-control"
          >
            <option value="0">-Select-</option>
            <option v-for="p in brandModel" :key="p.id" v-bind:value="p.id">
              {{ p.brand_name }}
            </option>
          </select>
        </div>
        <div class="col-md-2">
          <label for="modelNumber">Model Number</label>
          <input
            type="text"
            id="modelNumber"
            v-model="productModel.model_no"
            class="form-control"
          />
        </div>
        <div class="col-md-2">
          <label for="originalReference">Original Reference</label>
          <input
            type="text"
            class="form-control"
            v-model="productModel.watch[0].original_reference"
          />
        </div>

        <div class="col-md-2 my-4">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="productModel.active"
            id="stock"
          />
          <label class="form-check-label" style="margin-left: 4px" for="stock"
            >Stock</label
          >
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-4">
          <label for="description">Description</label>
          <textarea
            type="text"
            id="description"
            v-model="productModel.description"
            class="form-control"
          ></textarea>
        </div>
        <div class="col-md-3">
          <label for="ticketInfo">Ticket Info</label>
          <input
            type="text"
            id="ticketInfo"
            v-model="productModel.ticket_info"
            class="form-control"
          />
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row">
        <!-- <div class="col-md-6">
                        <label for="tagsInput">Tags</label>
 							<Chips id="tagsInput" v-model="productModel.tag" class="form-control p-fluid" style="height:50px; border-style: none;" />

				</div> -->

        <!-- Watc Properties -->
        <div class="col-md-4 offset-md-2">
          <!-- <div style="margin-top:30px">
		        <h5 class="text-left text-info text-dark" style="display: inline;">Properties</h5>
                <button @click="propertyShowClick" type="button" class="btn btn-outline-dark" style="font-size: 12px; padding: 6px 10px;margin-left:5px" >{{propertyShowLabel}}</button>
        </div> -->
          <div>
            <p class="text-left text-bold text-info text-dark">
              Properties
              <small class="form-text text-muted"
                >(If the property is empty, that line is deleted!)</small
              >
            </p>
            <!-- <button @click="manufShowClick" type="button" class="btn btn-outline-dark" style="font-size: 12px; padding: 6px 10px;margin-left:5px" >{{manufShowLabel}}</button> -->
          </div>
          <table
            id="manuf-table"
            class="table table-bordered"
            v-if="propertyShow"
          >
            <thead>
              <tr class="text-center">
                <th width="50%">Property</th>
                <th width="30%">Value</th>
                <th width="20%">
                  <button
                    type="button"
                    v-on:click="addItem"
                    class="btn btn-success btn-sm"
                  >
                    <span area-hidden="true">&plus;</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(d, i) in productModel.watch[0].watch_property"
                :key="i"
                style
              >
                <td width="20%">
                  <div class="form-group">
                    <select
                      id="propertyType"
                      v-model="d.property"
                      class="form-control"
                    >
                      <option value="0">-Select-</option>
                      <option
                        v-for="p in watchPropertyModel"
                        :key="p.property_id"
                        v-bind:value="p.property_id"
                      >
                        {{ p.property_name }}
                      </option>
                    </select>
                    <!-- <v-select :options="manufacturers.results" label="manufacturer_id" :reduce="manufacturer_id => manufacturer_id.name_surname" /> -->
                  </div>
                </td>

                <td width="10%">
                  <div class="form-group">
                    <input
                      type="text"
                      id="Price"
                      v-model="d.property_value"
                      class="form-control"
                    />
                  </div>
                </td>
                <td width="5%">
                  <button
                    type="button"
                    v-on:click="removeItem(i)"
                    class="btn btn-danger btn-sm"
                    tabindex="99"
                  >
                    <span aria-hidden="true">&minus;</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Watch Properties -->
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-md-1">
          <div
            v-if="submitLoading"
            class="spinner-border text-primary spinner-border-lg"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button v-else id="" class="btn btn-outline-secondary" type="submit">
            Submit
          </button>
        </div>
      </div>
      <!-- <div>
			<button id="form-submit" @click="createWatch" type="submit" >Submit</button>
	</div> -->
    </form>
  </div>

  <div class="modal" id="usedReference" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">This Reference Has Been Used Before</h5>
          <button
            type="button"
            class="btn-close"
            data-coreui-dismiss="modal"
            @click="closeUsedReference"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            This reference has been used before. Do you want to create a copy of
            this product?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeUsedReference"
            data-coreui-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-danger" @click="createCopy">
            Create Copy
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { getAPI, postAPI } from "@/global/callAPI";
import useRouter from "vue-router";

export default {
  name: "NewWatch",
  components: { Menu },
  data: function () {
    return {
      submitLoading: false,

      title: "New Watch",
      thumbnail: null,
      tag: "",
      tags: [],
      productModel: {
        reference_no: null,
        store: null,
        description: null,
        first_price: null,
        second_price: null,
        currency: "USD",
        description: null,
        active: true,
        new_product: true,
        model_no: null,
        tags: [],
        watch: [
          {
            model: null,
            original_reference: null,
            purchase_date: null,
            purchase_store: null,
            brand: null,
            condition: null,
            watch_property: [
              { property: null, property_value: null },
              { property: null, property_value: null },
              { property: null, property_value: null },
            ],
          },
        ],
        jewel: [],
        manufacturers: [],
      },
      storeModel: null,
      propertyShowLabel: "Hide",
      propertyShow: true,
      manufacturers: [],
      producTypeModel: null,
      manufactureTypes: null,
      brandModel: null,
      watchPropertyModel: null,
      tagModel: [],
      copyReference: null,
      isSumbitOk: null,
      conditionModel: [],
      priceCalculatorInput: 0,
    };
  },
  created: function () {
    getAPI("stores").then((res) => {
      this.storeModel = res.data.results;
    });
    getAPI("manufacturers?type_id=4&ordering=firm_name&limit=10000").then(
      (res) => {
        this.manufacturers = res.data.results;
      }
    );

    getAPI("watch-brands?limit=10000&ordering=brand_name").then((res) => {
      this.brandModel = res.data.results;
    });
    getAPI("watch-property-types?limit=1000&ordering=property_name").then(
      (res) => {
        this.watchPropertyModel = res.data.results;
      }
    );
    getAPI("product-tags?limit=1000&ordering=name&").then((res) => {
      this.tagModel = res.data.results;
    });
    getAPI("watch-conditions?limit=1000&ordering=name&").then((res) => {
      this.conditionModel = res.data.results;
    });
  },

  methods: {
    // Property methods
    propertyShowClick: function () {
      this.propertyShow = !this.propertyShow;
      if (this.propertyShowLabel === "Show") {
        this.propertyShowLabel = "Hide";
      } else {
        this.propertyShowLabel = "Show";
      }
    },
    removeItem: function (id) {
      this.productModel.watch[0].watch_property.splice(id, 1);
    },
    addItem: function (e) {
      e.preventDefault();

      let detail = {
        property: null,
        property_value: null,
      };
      this.productModel.watch[0].watch_property.push(detail);
    },
    tagConverter(id) {
      return { tag: id };
    },

    // Form methods
    createWatch() {
      Promise.resolve(this.referenceController).then(this.submitProcess);
    },

    submitProcess() {
      this.submitLoading = true;

      // Conver tag list to string becaus tag store as string in database
      // if (this.productModel.tag !== null ) {
      // this.productModel.tag = this.productModel.tag.toString();};

      // Delete blank properties
      // if ( this.productModel.watch[0].watch_property.length){
      // this.productModel.watch[0].watch_property.forEach(function(item, index, object) {
      // if (item.property == null) {
      // 	object.splice(index, 1);
      // }
      // });}

      // delete blank properties
      if (this.isSumbitOk === true) {
        var pro = this.productModel.watch[0].watch_property;
        var i;
        for (i = 0; i < pro.length; i += 1) {
          if (pro[i].property === null || pro[i].property === "") {
            pro.splice(i, 1);
            i -= 1;
          }
        }
        const payload = JSON.parse(JSON.stringify(this.productModel));

        // if (payload.tag !== null ) {
        // payload.tag = payload.tag.toString()}

        postAPI("products", payload)
          .then((res) => {
            if (res.status === 201) {
              Toast2.fire({
                icon: "success",
                title: `${payload["reference_no"]} created successfully.`,
              });
              this.productModel["reference_no"] = null;
            } else {
              Toast2.fire({
                icon: "danger",
                title: `${payload["reference_no"]} couldn't be created. Error ${res.status}`,
              });
            }

            //   this.$router.push('/products/' + res.data.product_id)
          })
          .finally(() => {
            this.submitLoading = false;
          });
      }
    },

    referenceController() {
      this.copyReference = this.productModel["reference_no"];
      this.isSumbitOk = null;
      getAPI(
        "product-search?reference_no=" + this.productModel.reference_no
      ).then((res) => {
        if (res.data.results[0]) {
          this.openUsedReference();
          this.isSumbitOk = false;
          return true;
          // alert("This reference used by another product!")
        } else {
          this.isSumbitOk = true;

          return false;
        }
      });
    },
    openUsedReference() {
      jQuery("#usedReference").modal("show");
      // this.productModel.reference_no = null
      this.productModel["reference_no"] = null;
    },
    closeUsedReference() {
      jQuery("#usedReference").modal("hide");
      this.productModel["reference_no"] = null;
    },

    createCopy() {
      const reference = this.copyReference;
      getAPI("products?reference_no=" + reference).then((res) => {
        if (res.data.results[0]) {
          console.log(res.data.results[0]);

          let model = {};
          const data = res.data.results[0];
          console.log(data.watch.length);
          if (data.watch.length === 0) {
            window.alert("This product is not a watch.");
            this.productModel.reference_no = null;
            jQuery("#usedReference").modal("hide");
            return false;
          }

          model["description"] = data.description || null;
          model["first_price"] = data.first_price || null;
          model["second_price"] = data.second_price || null;
          model["model_no"] = data.model_no || null;
          model["ticket_info"] = data.ticket_info || null;
          model["active"] = true;
          model["new_product"] = true;
          model["watch"] = [{}];
          model["jewel"] = [];

          model["watch"][0]["model"] = data["watch"][0]["model"];
          model["watch"][0]["original_reference"] =
            data["watch"][0]["original_reference"];
          model["watch"][0]["purchase_date"] =
            data["watch"][0]["purchase_date"];
          model["watch"][0]["purchase_store"] =
            data["watch"][0]["purchase_store"];
          model["watch"][0]["brand"] = data["watch"][0]["brand"];

          model["watch"][0]["watch_property"] = [];

          data["watch"][0]["watch_property"].forEach((element, index) => {
            model["watch"][0]["watch_property"][index] = {};
            model["watch"][0]["watch_property"][index]["property"] =
              element.property;
            model["watch"][0]["watch_property"][index]["property_value"] =
              element.property_value;
          });

          model["manufacturers"] = [];
          data["manufacturers"].forEach((element, index) => {
            model["manufacturers"][index] = {};
            model["manufacturers"][index]["manufacturer"] =
              element.manufacturer;
            model["manufacturers"][index]["type"] = element.type;
            model["manufacturers"][index]["price"] = element.price;
          });

          model["tags"] = [];

          data["tags"].forEach((element, index) => {
            model["tags"][index] = {};
            model["tags"][index]["tag"] = element.tag;
          });

          this.productModel = model;
          this.closeUsedReference();
          console.log(model);

          let productModel = {
            tags: [],
            watch: [
              {
                model: null,
                original_reference: null,
                purchase_date: null,
                purchase_store: null,
                brand: null,
                watch_property: [
                  { property: null, property_value: null },
                  { property: null, property_value: null },
                  { property: null, property_value: null },
                ],
              },
            ],
            jewel: [],
            manufacturers: [],
          };
        }
      });
    },
    getLastRepairReference() {
      getAPI("last-repair-watch-reference").then((res) => {
        this.productModel.reference_no = res.data.new_reference_no;
        // control reference number
        this.referenceController();
      });
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  margin: 0 auto;
  padding: 9px;
  color: #fff;
}
.header h1 {
  font-size: 3.3em;
}

.total-underline {
  border-bottom: 3px solid black;
  float: right;
}

.btn.btn-danger.btn-sm {
  display: block;
  margin: auto;
}

#invoice-table {
  background-color: white !important;
}

#manuf-table {
  /* width:40%; */
  margin-top: 10px;
}

h3.text-left.font-weight-bold.text-info.text-dark {
  margin-top: 70px;
}
div.vs__dropdown-toggle {
  border-radius: 0px;
}
.p-multiselect {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 60%;
  border-radius: 0px;
}
input {
  border-radius: 0px;
}

select {
  border-radius: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
