import Vuex from "vuex";
import axios from "axios";
import { ACTIVE_CUSTOM_IP } from "@/global/ip";

const getLocalStorageItem = (key) => window.localStorage.getItem(key);
const setLocalStorageItem = (key, value) =>
  window.localStorage.setItem(key, value);
const clearLocalStorage = () => window.localStorage.clear();

const axiosInstance = axios.create({
  baseURL: ACTIVE_CUSTOM_IP,
  timeout: 1000,
});

export default new Vuex.Store({
  state: {
    accessToken: getLocalStorageItem("token"),
    refreshToken: null,
    APIData: "",
    username: getLocalStorageItem("username"),
    id: getLocalStorageItem("id"),
    store: getLocalStorageItem("store"),
    store_id: getLocalStorageItem("store_id"),
    groups: getLocalStorageItem("groups"),
    page_permissions: getLocalStorageItem("page_permissions"),
  },
  mutations: {
    updateStorage(
      state,
      {
        access,
        store,
        store_id,
        groups,
        username,
        id,
        page_permissions,
        refresh,
      }
    ) {
      setLocalStorageItem("token", access);
      setLocalStorageItem("username", username);
      setLocalStorageItem("id", id);
      setLocalStorageItem("store", store);
      setLocalStorageItem("store_id", store_id);
      setLocalStorageItem("groups", groups);
      setLocalStorageItem("page_permissions", JSON.stringify(page_permissions));

      state.accessToken = access;
      state.refreshToken = refresh;
      state.username = username;
      state.id = id;
      state.store = store;
      state.store_id = store_id;
      state.groups = groups;
      state.page_permissions = page_permissions;
    },
    destroyToken(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.username = null;
      state.id = null;
      state.store = null;
      state.store_id = null;
      state.groups = null;
      state.page_permissions = null;
      clearLocalStorage();
    },
  },
  getters: {
    loggedIn(state) {
      return state.accessToken != null;
    },
  },
  actions: {
    userLogout({ getters, commit }) {
      if (getters.loggedIn) {
        commit("destroyToken");
      }
    },
    userLogin({ commit }, usercredentials) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/api/token/", {
            username: usercredentials.username,
            password: usercredentials.password,
          })
          .then((response) => {
            const {
              access,
              refresh,
              username,
              id,
              store,
              store_id,
              groups,
              page_permissions,
            } = response.data;
            commit("updateStorage", {
              access,
              refresh,
              username,
              id,
              store,
              store_id,
              groups,
              page_permissions,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
