.<template>


</template>

<script>
import {getAPI, postAPI, patchAPI, deleteAPI} from '../global/callAPI';

export default {
    data: function () {
        return {
            stoneData:null,
            nestedData:null,
        }
    },

    methods: {
        tree () {
            
        }
    },

    created () {
        getAPI('stone-types?limit=10000').then(res => {
            this.stoneData = res.data
        })
    },


}
</script>

<style scoped>

</style>