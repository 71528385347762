<template>
    <Menu />

    <div class="container-fluid">
        <h6 class="text-center font-weight-bold text-info text-dark" style="font-size:15px"><strong>Karat Price</strong>
        </h6>
        <hr style="height:0.5px;margin-bottom:30px">
        <div class="container">

            <button @click="showModal = true" class="btn btn-outline-success" data-bs-toggle="modal" data-bs-target="#modalId">New Karat</button>
            <div class="row justify-content-center">
                <div class="col-md-8 col-sm-12">
                    <table class="table mt-3">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in karats" :key="index">
                                <td>{{ item.id }}</td>
                                <td>
                                    <input type="text" class="form-control" v-model.number="item.name" />
                                </td>
                                <td>
                                    <input type="number" class="form-control" v-model.number="item.price" step="0.01" />
                                </td>
        
                                <td>
                                    <button @click="updateItem(item.id,item.name,item.price,index)" class="btn btn-outline-secondary" id="updateButton" >Update</button>
        
                                    <button @click="deleteItem(item.id,index)" class="btn btn-outline-danger">Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
            </div>
            
        </div>


        <div class="modal fade" id="modalId" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog"
            aria-labelledby="modalTitleId" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalTitleId">New Karat</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form @submit.prevent="createKarat">

                    <div class="modal-body">
                            <div class="form-group">
                                <label for="name">Name:</label>
                                <input type="text" class="form-control" id="name" v-model="newKaratModel.name" required />
                            </div>
                            <div class="form-group">
                                <label for="price">Price:</label>
                                <input type="number" class="form-control" id="price" v-model.number="newKaratModel.price"
                                    step="0.01" />
                            </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-success" >Save</button>
                    </div>

                </form>
                </div>
            </div>
        </div>


        <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <span>Are you sure you want to delete?</span>
            </div>
            <template #footer>
                <div class="row justify-content-center ">
                    <div class="offset-md-8 col-md-2">
                        <button @click="deleteDialog = false" type="button" class="btn btn-outline-dark d-flex justify-content-center p-button-text">No</button>
                    </div>
                    <div class="col-md-2">
                        <button @click="deleteConfirmed" type="button" class="btn btn-outline-danger d-flex justify-content-center">Yes</button>
                    </div>
                </div>       
                
            </template>
        </Dialog>


    </div>
</template>
    
<script>
import Menu from '@/components/Menu';
import { getAPI, patchAPI, postAPI,deleteAPI } from '@/global/callAPI';

export default {
    components: { Menu },
    data: function () {
        return {

            karats: [],
            newKaratModel: {
                name: null,
                price: null
            },
            showModal: false,
            deleteDialog:false,
            deleteId:null,
            deleteIndex:null,

        }
    },
    mounted() {
        getAPI("gold-karats?limit=100000").then((res) => { this.karats = res.data.results });

    },
    methods: {

        createKarat: function () {
            postAPI("gold-karats", this.newKaratModel).then((res) => {
                this.karats.push(res.data)
                this.newKaratModel = {
                    name: null,
                    price: null
                }


            this.$toast.add({severity:'success', summary: 'Success', detail:'Karat Created!', life: 2000});

            })
            $('#modalId').modal('hide');

        },
        deleteItem: function(id,index) {
            this.deleteDialog = true
            this.deleteIndex = index
            this.deleteId = id 
        },
        deleteConfirmed: function() {
            this.deleteDialog = false
            deleteAPI("gold-karats/"+this.deleteId).then((res) => {
                this.karats.splice(this.deleteIndex,1)
                this.$toast.add({severity:'success', summary: 'Success', detail:'Karat Deleted!', life: 2000});

            })

        },
        updateItem:function (id,name,price,index){
            patchAPI("gold-karats/"+id,{name:name,price:price}).then((res) => {
                this.karats[index] = res.data
                this.$toast.add({severity:'success', summary: 'Success', detail:'Karat Updated!', life: 2000});

            })
        }
        }
    




}
</script>
    
<style>

#updateButton {
    margin-right: 8px;
}

</style>