<template>

    <!-- <DataTable                     
    @row-dblclick="rowClick($event)"
    :value="products"
    :paginator="true" 
    :rows="10"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    :rowsPerPageOptions="[10,20,50]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
    responsiveLayout="scroll">
        <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"></Column>
    </DataTable> -->

<div></div>

</template>

<script>
import {getAPI} from '@/global/callAPI';
export default {
    name:"ManufacturerItemProducts",
    
    data: function () {
        return  {
            columns:null,
        }
    },
    props: {
        products:{
            type: Array,
            default: null
        },
    },
    methods: {
        rowClick(e) {this.$router.push({ path: '/products/' +e.data.product}) },
        formatCurrency(value) {
            return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        },
    },

    created: function () {
        
        this.products.forEach(item => {
            item.price = this.formatCurrency(item.price);
        })


        this.columns = [
            {field: 'product', header: 'Id'},
            {field: 'reference_no', header: 'Reference No'},
            // {field: 'created_date', header: 'Created Date'},
            {field: 'type_name', header: 'Type'},
            {field: 'price', header: 'Price'},
            // {field: 'store_name', header: 'Store'},

        ]

    }
}
</script>

<style>

</style>