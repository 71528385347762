<template>
  <Toast />
  <Menu />

  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      New Loan
    </h6>
    <hr />
    <form @submit.prevent="createLoan">
      <div class="row justify-content-center">
        <div class="col-md-8 row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="reference_no">Reference No</label>
              <input
                type="text"
                class="form-control"
                id="reference_no"
                v-model="loan.reference_no"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="start_date">Start Date</label>
              <input
                type="date"
                class="form-control"
                id="start_date"
                v-model="loan.start_date"
                required
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="description">Description</label>
              <textarea
                class="form-control"
                id="description"
                v-model="loan.description"
              ></textarea>
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-center mt-3">
            <div class="form-group">
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import Menu from "@/components/Menu";
import SelectProduct from "@/components/SelectProduct.vue";
import store from "@/store";

export default {
  name: "NewLoan",
  components: { Menu },
  data: function () {
    return {
      loan: {
        reference_no: "",
        start_date: new Date().toISOString().substr(0, 10),
        description: "",
      },
    };
  },

  methods: {
    createLoan() {
      postAPI("loans", this.loan).then((response) => {
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Loan created successfully",
          life: 1000,
        });
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
