<template>
  <Menu />

  <div class="container-fluid" v-if="saleModel" id="sale-item-container">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong class="mr-1"
        >Sale: {{ saleModel.sale_reference }} / Customer: &nbsp;</strong
      >
      <!-- add router to customer id show customer name -->
      <router-link
        target="_blank"
        :to="'/customers/' + saleModel.customer_id"
        >{{ saleModel.customer_name }}</router-link
      >
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />
    <form @submit.prevent="submitSaleChanges">
      <!-- <h2  class="text-left text-info text-dark" style="display:inline;" >{{saleModel.sale_reference}} </h2> -->
      <!-- <hr class="solid"> -->

      <button
        @click="excelExport"
        type="button"
        class="btn btn-success"
        style="float: right; position: absolute"
      >
        Excel Export
      </button>

      <div class="row justify-content-center">
        <div class="form-group col-md-2">
          <label for="sale-id-input">Sale Reference</label>
          <input
            id="sale-id-input"
            v-model="saleModel.sale_reference"
            type="text"
            class="form-control"
          />
        </div>

        <div class="form-group col-md-2">
          <label for="user" class="text-bold">Seller</label>
          <select
            v-if="permission('super_user')"
            id="user"
            class="form-control"
            v-model="saleModel.seller_id"
          >
            <option v-for="u in userListModel" :key="u.id" v-bind:value="u.id">
              {{ u.name }}
            </option>
          </select>

          <select
            v-if="permission('super_user') === false"
            id="user"
            v-model="saleModel.seller_id"
            class="form-control"
            disabled
          >
            <option v-for="u in userListModel" :key="u.id" v-bind:value="u.id">
              {{ u.name }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-2">
          <label for="date-input">Date</label>
          <input
            id="date-input"
            v-model="saleModel.sale_date"
            type="date"
            class="form-control"
          />
        </div>

        <div class="col-md-2">
          <label for="sale_type">Product Type</label>
          <select
            id="sale_type"
            v-model="saleModel.product_type"
            class="form-control"
            required
          >
            <option value="Jewel">Jewel</option>
            <option value="Watch">Watch</option>
            <option value="Jewel&Watch">Jewel&Watch</option>
          </select>
        </div>

        <div class="form-group col-md-1 align-self-end">
          <button
            type="button submit"
            class="btn btn-secondary"
            v-if="showSubmit === true"
          >
            Submit Changes
          </button>
        </div>
      </div>
      <div class="row justify-content-center mb-2 mt-2">
        <div v-if="saleModel.customer_id" class="col-md-2">
          <div class="form-group">
            <label for="customerId" class="text-bold">Customer</label>

            <input
              id="status-input"
              v-model="saleModel.customer_name"
              type="text"
              class="form-control"
              readonly
            />

            <!-- <SelectCustomer
                    :disabled="true"
                    id="customerId"
                    :customer-model="customerModel"
                    v-model="saleModel.customer_id"
                    :selected="saleModel.customer_id">
                  </SelectCustomer> -->
          </div>
        </div>

        <div class="form-group col-md-2">
          <label for="sale-type-input">Sale Type</label>
          <select
            id="sale-type-input"
            v-model="saleModel.sale_type_id"
            class="form-control"
            disabled
          >
            <option
              v-bind:value="s.sale_type_id"
              v-for="s in saleTypeModel"
              :key="s.sale_type_id"
            >
              {{ s.sale_type_name }}
            </option>
          </select>
        </div>

        <div class="col-md-2">
          <label for="store">Store</label>
          <select
            id="store"
            v-model="saleModel.store_id"
            class="form-control"
            disabled
          >
            <option v-bind:value="s.id" v-for="s in storeModel" :key="s.id">
              {{ s.store_name }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-2">
          <label for="status-input">Status</label>
          <input
            id="status-input"
            v-model="saleModel.sale_status"
            type="text"
            class="form-control"
            readonly
          />
        </div>

        <div class="col-md-1 align-self-end">
          <button
            type="button"
            class="btn btn-danger"
            @click="deleteDialog = true"
            v-if="
              hasPermission((roles = ['limited_seller', 'seller', 'office']))
            "
          >
            Delete
          </button>
        </div>
      </div>

      <div
        class="row justify-content-center"
        id="description-row justify-content-center"
      >
        <div class="col-md-2">
          <label for="currency">Currency</label>
          <select
            id="currency"
            v-model="saleModel.currency"
            class="form-control"
          >
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="TRY">TRY</option>
            <option value="AED">AED</option>
          </select>
          <span class="text-muted">
            Exchange Rate:
            <span class="text-danger fw-bold">{{ exchangeRate }}</span>
          </span>
        </div>
        <div class="col-md-2">
          <label for="taxRate">Tax Rate (%)</label>
          <input
            @keydown.enter.prevent
            type="number"
            id="taxRate"
            v-model="saleModel.tax_rate"
            class="form-control"
            @change="taxRateChange"
          />
        </div>
        <div class="form-group col-md-5">
          <label for="description-input">Description</label>
          <textarea
            v-model="saleModel.description"
            id="description-input"
            class="form-control"
            rows="3"
          ></textarea>
        </div>
      </div>

      <div class="row justify-content-center mt-2">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-3">
              <label for="Sale Tag">Sale Tag</label>
              <v-select
                :options="saleTagModel"
                label="name"
                v-model="saleModel.tags"
                :reduce="(tag) => tagConverter(tag.id)"
                :multiple="true"
              />
            </div>
            <div class="form-group col-md-2">
              <label for="cancel_control" style="font-size: 13px"
                >Canceled</label
              >
              <input
                v-model="saleModel.is_canceled"
                id="cancel_control"
                type="checkbox"
                class="form-check-input"
                style="margin-left: 5px"
                @click="cancelSale"
              />
            </div>

            <div class="form-group col-md-2">
              <label for="cancel-date-input">Canceled Date</label>
              <input
                v-model="cancelDate"
                id="cancel-date-input"
                type="date"
                class="form-control"
              />
            </div>

            <div class="col-md-1"></div>
            <div
              v-if="![8, 9].includes(saleModel.sale_type_id)"
              class="form-group col-md-2"
            >
              <label for="paid_control">Execute Sale</label>
              <input
                @click="isPaid"
                v-model="saleModel.is_paid"
                id="paid_control"
                type="checkbox"
                class="form-check-input"
                style="margin-left: 5px"
              />
            </div>
            <div
              v-if="![8, 9].includes(saleModel.sale_type_id)"
              class="form-group col-md-2"
            >
              <label for="execution_date">Execute Date</label>
              <input
                v-model="saleModel.execution_date"
                id="execution_date"
                type="date"
                class="form-control"
                style="margin-left: 5px"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row justify-content-center mt-2">
      <div class="col-md-9">
        <hr />
      </div>
    </div>
    <!-- Expenses -->
    <div class="row justify-content-center mt-3 mb-3">
      <div class="col-md-9">
        <!-- TOTALS -->
        <table class="table table table-bordered">
          <thead>
            <tr class="text-center">
              <th v-show="showOriginalPrice">Original Price</th>
              <th>Net Sale</th>
              <th>Total Expense</th>
              <th v-if="saleModel.tax_rate > 0">Tax Amount</th>
              <th v-if="saleModel.tax_rate > 0">Net Sale + Tax</th>

              <th>Total Sale (Net Sale + Expense)</th>
              <th>Total Tag Price</th>
              <th>Total Payment</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody style="border-style: groove">
            <tr style="border-style: groove" class="text-center">
              <td style="border-style: groove" v-show="showOriginalPrice">
                {{ currencySymbol }} {{ getTotalOriginalPrice() }}
              </td>
              <td style="border-style: groove">&dollar; {{ getTotal() }}</td>
              <td style="border-style: groove">
                {{ currencySymbol }} {{ getExpenseTotal() }}
              </td>
              <td v-if="saleModel.tax_rate > 0" style="border-style: groove">
                {{ currencySymbol }}
                {{ taxAmount }}
              </td>
              <td v-if="saleModel.tax_rate > 0" style="border-style: groove">
                {{ currencySymbol }}
                {{ priceWithTax }}
              </td>

              <td style="border-style: groove">
                {{ currencySymbol }} {{ totalSale }}
              </td>
              <td style="border-style: groove">
                &dollar; {{ getTotalTicket() }}
              </td>
              <td style="border-style: groove">
                {{ currencySymbol }}{{ getPaymentTotal() }}
              </td>

              <td style="border-style: groove">
                {{ currencySymbol }}
                {{ getPaymentTotal() - totalSale }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- TOTALS END -->
      </div>
    </div>
    <!-- Expenses -->
    <div class="row justify-content-center mb-4 mt-4">
      <div class="col-md-9">
        <div class="col-md-2">
          <div class="row">
            <div class="col-md-6 align-self-center">
              <h5 class="text-left text-info text-dark" style="font-size: 15px">
                Expenses
              </h5>
            </div>
            <div class="col-md-6">
              <!-- <button @click="expenseShowClick" type="button" class="btn btn-outline-dark" style="font-size: 12px; padding: 6px 10px;margin-left:5px" >{{expenseShowLabel}}</button> -->
            </div>
          </div>
        </div>

        <table
          id="expense-type"
          class="table table-bordered"
          v-if="expenseShow"
        >
          <thead>
            <tr class="text-center">
              <th>on Client / on Company</th>
              <th>Expense Type</th>
              <th>Description</th>
              <th>Expense Amount</th>
              <th>
                <button
                  type="button"
                  v-on:click="addExpense"
                  class="btn btn-success btn-sm"
                >
                  <span area-hidden="true">&plus;</span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(d, i) in saleModel.expense" :key="i">
              <td width="20%">
                <div class="form-group">
                  <select
                    id="client-company"
                    v-model="d.client_or_company"
                    class="form-control"
                  >
                    <option value="client" selected>Client</option>
                    <option value="company">Company</option>
                  </select>
                </div>
              </td>
              <td width="20%">
                <div class="form-group">
                  <select
                    id="expenses"
                    v-model="d.expense_type"
                    class="form-control"
                  >
                    <option value="0">-Select-</option>
                    <option
                      v-bind:value="e.id"
                      v-for="e in expenseTypeModel"
                      :key="e.id"
                    >
                      {{ e.expense_name }}
                    </option>
                  </select>
                </div>
              </td>

              <td width="20%">
                <div class="form-group">
                  <input
                    type="text"
                    id="expense-description"
                    v-model="d.description"
                    class="form-control"
                  />
                </div>
              </td>

              <td width="10%">
                <div class="form-group">
                  <input
                    type="number"
                    step=".01"
                    id="expense-amount"
                    v-model="d.expense_amount"
                    class="form-control"
                  />
                </div>
              </td>

              <td width="5%" style="text-align: center">
                <button
                  type="button"
                  v-on:click="removeExpense(d, i)"
                  class="btn btn-danger btn-sm"
                  tabindex="99"
                >
                  <span aria-hidden="true">&minus;</span>
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td><strong>Items:</strong> &Sigma; {{ getExpenseCount() }}</td>
              <td colspan="1" class="text-center">
                <strong>Totals:</strong>
              </td>
              <td>
                <span class="text-info text-bold text-danger"
                  >&dollar; {{ getExpenseTotal() }}</span
                >
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!-- Expenses END -->

    <!-- Sale Docs -->
    <div class="row justify-content-center mt-3">
      <div class="col-md-11">
        <div class="row">
          <SaleDocs />
        </div>
      </div>
    </div>
    <!-- Sale Docs END -->
    <!-- Invoice Products -->
    <div class="row justify-content-center mt-3">
      <div class="col-md-11">
        <div class="col-md-2">
          <div class="row">
            <div class="col-md-6 align-self-center">
              <h5 class="text-left text-info text-dark" style="display: inline">
                Products
              </h5>
            </div>
            <div class="col-md-6">
              <!-- <button @click="productShowClick" type="button" class="btn btn-outline-dark" style="font-size: 12px; padding: 6px 10px;margin-left:5px" >{{productShowLabel}}</button> -->
            </div>
          </div>
        </div>

        <div></div>
        <table
          id="invoice-table"
          class="table table-bordered"
          v-if="productShow"
        >
          <thead>
            <tr class="text-center">
              <th>#</th>
              <th>Photo</th>
              <th>Product</th>
              <th>Ticket Price</th>
              <th>Description</th>
              <th v-show="showOriginalPrice">Original Price</th>
              <th>Sale Price</th>
              <th v-if="hasPermission((roles = ['limited_seller']))">
                Net Profit
              </th>
              <!-- <th>Cancel</th> -->
              <!-- <th>
						<button type="button" v-on:click="addItem" class="btn btn-success btn-sm">
							<span area-hidden="true">&plus;</span>
						</button>
					</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(d, i) in saleModel.sale" :key="i">
              <td width="5%" class="text-center">{{ i + 1 }}</td>
              <td width="10%">
                <div class="form-group">
                  <Image
                    v-bind:src="(photo = getPhoto(d.product_id))"
                    alt="Image"
                    width="80"
                    preview
                  />
                </div>
              </td>
              <td width="20%">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="d.reference_no"
                    class="form-control"
                    readonly
                  />
                </div>
              </td>

              <td width="10%">
                <div class="form-group text-center">
                  <span
                    >&dollar;
                    {{ (ticketPrice = getTicket(d.product_id)) }}</span
                  >
                </div>
              </td>

              <td width="30%">
                <div class="form-group text-center">
                  <input
                    type="text"
                    id="invoice-description"
                    v-model="d.description"
                    class="form-control"
                  />
                </div>
              </td>
              <!-- original price -->

              <td width="15%" v-show="showOriginalPrice">
                <div class="form-group text-center">
                  <input
                    type="number"
                    step=".01"
                    id="originalPrice"
                    v-model="d.original_price"
                    class="form-control"
                    @change="calculatePricewCurrency(d.product_id, i)"
                  />
                </div>
              </td>
              <td width="15%">
                <div class="form-group text-center">
                  <input
                    type="number"
                    step=".01"
                    id="Price"
                    v-model="d.price"
                    class="form-control"
                  />
                </div>
              </td>

              <td width="15%">
                <div
                  class="form-group text-center"
                  v-if="hasPermission((roles = ['limited_seller']))"
                >
                  <span type="number"
                    >&dollar;
                    {{
                      (
                        parseFloat(d.price) -
                        parseFloat(getTicket(d.product_id)) / 4.5
                      ).toFixed(2)
                    }}</span
                  >
                </div>
              </td>

              <!-- <td width="5%">
						<div class="form-group text-center">
            <input class="form-check-input" type="checkbox" v-on:click="productCancel(d,i)" v-model="d.is_canceled" value="" id="flexCheckDefault">
						</div>
					</td> -->

              <!-- <td width="5%" >
						<button type="button" v-on:click="removeItem(d,i)" class="btn btn-danger btn-sm" tabindex="99" >
							<span aria-hidden="true">&minus;</span>
						</button>
					</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- invoice end -->
    <!-- Payments -->
    <div class="row justify-content-center mb-4">
      <div class="col-md-11">
        <div class="col-md-4">
          <div class="row mb-3">
            <div class="col-md-3 align-self-center">
              <h5
                class="text-left text-info text-dark"
                style="margin-top: 30px; font-size: 15px"
              >
                Payments
              </h5>
            </div>
            <div class="col-md-7 align-self-end">
              <button @click="addNew" class="btn btn-primary">
                Add Payment
              </button>
            </div>
          </div>
        </div>

        <!-- <button data-toggle="modal" data-target="#payment" type="button"  class="btn btn-primary offset-md-9  ">Add Payment</button> -->

        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-center">#</th>
              <th>Payment Date</th>
              <th>Payment Type</th>
              <th>Orginal Payment</th>
              <th>Description</th>
              <th>End of Day</th>
              <th>Price</th>
            </tr>
          </thead>

          <tbody>
            <tr
              :key="payment.payment_id"
              v-for="(payment, index) in saleModel.payments"
            >
              <td class="text-center">{{ index + 1 }}</td>
              <td>
                {{ payment.date }}
              </td>

              <td>
                {{ payment.payment_type_name }}
              </td>

              <td>
                {{ payment.real_payment }}
              </td>
              <td>
                {{ payment.description }}
              </td>
              <td>
                {{ payment.is_end_of_day ? "✅" : "🚫" }}
              </td>
              <td>
                {{ payment.price }}
              </td>

              <td>
                <button class="btn btn-primary" @click="editPayment(payment)">
                  Edit
                </button>
                <button
                  @click="deletePayment(payment)"
                  class="btn btn-danger center"
                  style="margin-left: 2px"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal -->
      <PaymentModal
        :payment="payment"
        @submit="updateSaleData"
        @update="updateSaleData"
        :modal="modal"
      ></PaymentModal>
    </div>
  </div>

  <Dialog
    v-model:visible="deleteDialog"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
  >
    <div class="confirmation-content">
      <span>Are you sure you want to delete?</span>
    </div>
    <template #footer>
      <div class="row justify-content-center">
        <div class="offset-md-8 col-md-2">
          <button
            @click="deleteDialog = false"
            type="button"
            class="btn btn-outline-dark d-flex justify-content-center p-button-text"
          >
            No
          </button>
        </div>
        <div class="col-md-2">
          <button
            @click="deleteSale"
            type="button"
            class="btn btn-outline-danger d-flex justify-content-center"
          >
            Yes
          </button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Menu from "@/components/Menu";
import SelectProduct from "@/components/SelectProduct.vue";
import {
  getAPI,
  postAPI,
  patchAPI,
  deleteAPI,
  saleExcelExport,
  putAPI,
} from "@/global/callAPI";
import SelectCustomer from "@/components/SelectCustomer.vue";
import SaleDocs from "@/views/Sale/SaleDocs.vue";
import PaymentModal from "@/components/PaymentModal.vue";
export default {
  name: "SaleItem",
  components: { Menu, SelectProduct, SelectCustomer, SaleDocs, PaymentModal },
  data: function () {
    return {
      showSubmit: true,
      deleteDialog: false,
      outProducts: [],
      modal: null,
      saleModel: [],
      customerModel: [],
      saleTypeModel: null,
      paymentTypeModel: null,
      expenseTypeModel: null,
      productModel: [],
      storeModel: null,
      expenseShow: true,
      expenseShowLabel: "Hide",
      productShow: true,
      productShowLabel: "Hide",
      paymentShow: null,
      paymentShowLabel: "Show",
      sale_id: null,
      sale_date: null,
      description: null,
      customer_id: null,
      seller_id: null,
      sale_type_id: null,
      is_canceled: null,
      canceled_date: null,
      invoice_subtotal: 0,
      invoice_total: 0,
      invoice_tax: 5,
      saleTagModel: [],
      invoice_products: [
        {
          product_no: "",
          product_name: "",
          product_price: "",
          product_qty: "",
          line_total: 0,
        },
      ],
      payments: null,
      payment: {
        sale: this.$route.params.id,
        payment_id: null,
        price: null,
        status: null,
        date: null,
        payment_type: null,
        description: null,
      },
      activeItem: null,
      tag: null,
      userListModel: [],
      groups: localStorage.getItem("groups"),
      exchangeRatesModel: [],
    };
  },
  computed: {
    cancelDate: {
      get() {
        const cDate = this.saleModel.canceled_date;
        return cDate ? new Date(cDate).toISOString().slice(0, 10) : null;
      },
      set(value) {
        this.saleModel.canceled_date = value;
      },
    },
    currencySymbol: function () {
      if (this.saleModel.currency === "USD") {
        return "$";
      } else if (this.saleModel.currency === "EUR") {
        return "€";
      } else if (this.saleModel.currency === "TRY") {
        return "₺";
      } else if (this.saleModel.currency === "AED") {
        return "AED";
      } else {
        return "$";
      }
    },
    totalSale: function () {
      if (this.showOriginalPrice) {
        return this.getTotalOriginalPrice() + this.getExpenseTotal();
      } else {
        return this.getTotal() + this.getExpenseTotal();
      }
    },
    taxAmount: function () {
      if (this.showOriginalPrice) {
        return this.getTotalOriginalPrice() * (this.saleModel.tax_rate / 100);
      } else {
        return this.getTotal() * (this.saleModel.tax_rate / 100);
      }
    },
    priceWithTax: function () {
      if (this.showOriginalPrice) {
        return (
          this.getTotalOriginalPrice() * (this.saleModel.tax_rate / 100) +
          this.getTotalOriginalPrice()
        );
      } else {
        return (
          this.getTotal() * (this.saleModel.tax_rate / 100) + this.getTotal()
        );
      }
    },

    exchangeRate: function () {
      let exchangeRate = this.exchangeRatesModel.filter((rate) => {
        return rate.rate_from === this.saleModel.currency;
      });
      if (exchangeRate.length > 0) {
        return exchangeRate[0].exchange_rate;
      } else {
        return 1;
      }
    },
    showOriginalPrice: function () {
      if (this.saleModel.currency !== "USD") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    permission(n) {
      return this.groups.indexOf(n) > -1;
    },

    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }
      let user_groups = this.$store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },
    expenseShowClick: function () {
      this.expenseShow = !this.expenseShow;
      if (this.expenseShowLabel === "Show") {
        this.expenseShowLabel = "Hide";
      } else {
        this.expenseShowLabel = "Show";
      }
    },
    productShowClick: function () {
      this.productShow = !this.productShow;
      if (this.productShowLabel === "Show") {
        this.productShowLabel = "Hide";
      } else {
        this.productShowLabel = "Show";
      }
    },

    // Expense Methods
    addExpense: function (e) {
      e.preventDefault();
      let detail = {
        expense_type: 0,
        expense_amount: 0,
        description: null,
        client_or_company: "client",
      };
      this.saleModel.expense.push(detail);
    },
    removeExpense: function (data, id) {
      deleteAPI("sale-expenses/" + data["id"]);

      Toast2.fire({
        title: "Expense deleted successfully",
      });
      this.saleModel.expense.splice(id, 1);
    },
    getExpenseCount: function () {
      if (this.saleModel.expense) {
        let c = this.saleModel.expense.length;
        return c;
      }
    },
    getExpenseTotal: function () {
      let total = 0;
      if (this.saleModel.expense) {
        for (let i = this.saleModel.expense.length - 1; i >= 0; i--) {
          total += parseFloat(this.saleModel.expense[i].expense_amount);
          this.total_expense = total;
        }
        return +total.toFixed(2);
      }
    },
    getPaymentTotal: function () {
      let total = 0;
      if (this.saleModel.payments) {
        for (let i = this.saleModel.payments.length - 1; i >= 0; i--) {
          total += parseFloat(this.saleModel.payments[i].price);
          this.total_payments = total;
        }
        return +total.toFixed(2);
      }
    },
    // Expense Method End

    // Sale Product Methods

    productCancel(data, id) {
      // cancel
      if (data.is_canceled === false || data.is_canceled === null) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, cancel it!",
        }).then((result) => {
          if (result.value) {
            patchAPI("sale-products/" + data.sale_product_id, {
              is_canceled: true,
            });

            Toast2.fire({
              title: "Canceled  successfully",
            });
          }
        });
      } else {
        patchAPI("sale-products/" + data.sale_product_id, {
          is_canceled: false,
        });
        Toast2.fire({
          title: "Cancel removed!",
        });
      }
    },
    getTicket(id) {
      let targetProduct = this.saleModel.sale.filter(
        (product) => product.product_id === id
      )[0];
      if (targetProduct) {
        targetProduct = targetProduct;
        return targetProduct.ticket_price;
      }
    },
    getPhoto(id) {
      let targetProduct = this.saleModel.sale.filter(
        (product) => product.product_id === id
      )[0];
      if (targetProduct) {
        // if (i !== null) {
        // this.invoiceModel.sale[i]["price"] = targetProduct.first_price };
        return targetProduct.thumbnail;
      }
    },
    addItem: function (e) {
      e.preventDefault();
      let detail = {
        product_id: 0,
        price: 0,
        description: null,
      };
      this.saleModel.sale.push(detail);
    },

    removeItem: function (data, id) {
      deleteAPI("sale-products/" + data["sale_product_id"]);

      Toast2.fire({
        title: "Product deleted successfully",
      });
      this.saleModel.sale.splice(id, 1);
    },
    getItemCount: function () {
      let c = this.saleModel.sale.length;
      return c;
    },
    getSubTotal: function () {
      let subtotal = 0;
      for (let i = this.saleModel.sale.length - 1; i >= 0; i--) {
        subtotal += parseFloat(this.saleModel.sale[i].SubTotal);
      }
      return subtotal.toFixed(2);
    },
    getTotalTax: function () {
      let totalTax = 0;
      for (let i = this.saleModel.sale.length - 1; i >= 0; i--) {
        totalTax += parseFloat(this.saleModel.sale[i].tax_price);
      }
      return +totalTax.toFixed(2);
    },
    getTotalCompanyExpenses: function () {
      let total = 0;
      this.saleModel.expense.forEach((element) => {
        if (element.client_or_company === "company") {
          total += parseFloat(element.expense_amount);
        }
      });
      return total;
    },
    getTotal: function () {
      let total = 0;
      if (this.saleModel.sale) {
        for (let i = this.saleModel.sale.length - 1; i >= 0; i--) {
          total += parseFloat(this.saleModel.sale[i].price);
          // this.total_sale_price = total;
        }
        const totalCompanyExp = this.getTotalCompanyExpenses();
        total += -totalCompanyExp;
        this.total_sale_price = total;
        return +total.toFixed(2);
      }
    },
    getTotalTicket: function () {
      if (this.saleModel.sale) {
        let sum = 0;
        let productArray = this.saleModel.sale;

        productArray.forEach((element) => {
          let ticketPrice = this.getTicket(element.product_id);
          if (ticketPrice > 0) {
            sum += ticketPrice;
          }
        });
        return sum;
      }
    },
    getTotalExpense: function () {
      let total = 0;
      this.expenseModel.forEach((element) => {
        let expenseAmount = element.expense_amount;
        if (expenseAmount > 0) {
          total += expenseAmount;
        }
      });
      return total;
    },
    // Sale Product End

    excelExport: function () {
      saleExcelExport(this.$route.params.id).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          this.saleModel.sale_reference + ".xlsx"
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    reset() {
      this.payment = {
        sale: this.$route.params.id,
        payment_id: null,
        price: null,
        status: null,
        date: null,
        payment_type: null,
        real_payment: null,
        end_of_day_data: {},
      };
    },
    addNew() {
      this.modal = "new";
      this.reset();

      // var myModal = new bootstrap.Modal(document.getElementById("payment"), {});
      // document.onreadystatechange = function () {
      //   myModal.show();
      // };

      jQuery("#payment").modal("show");
      // $('#payment').modal('show');
    },
    checkSubmit() {
      if (
        this.saleModel.is_canceled === true &&
        this.hasPermission(["super_user"])
      ) {
        this.showSubmit = false;
      }
    },

    editPayment(payment) {
      this.modal = "edit";
      this.payment = payment;

      // jQuery.noConflict();
      // $('#payment').modal('show');
      jQuery("#payment").modal("show");
    },
    submitSaleChanges() {
      patchAPI("sales/" + this.$route.params.id, this.saleModel).then((res) => {
        Toast2.fire({
          title: "Sale updated successfully",
        });
        getAPI("sales/" + this.$route.params.id).then((res) => {
          this.saleModel = res.data;
          this.checkSubmit();
          this.saleModel["tags"] = this.saleModel["tags"].map(function (item) {
            return { tag: item.tag };
          });
        });
      });
    },

    deletePayment(payment) {
      this.payment = payment;

      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          deleteAPI("payments/" + this.payment.payment_id).then((res) => {
            Toast2.fire({
              title: "Deleted  successfully",
            });
            updateSaleData();
          });
        }
      });
    },
    isPaid() {
      if (this.saleModel.is_paid == true) {
        Swal.fire({
          title: "Are you sure cancel?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
            let formData = { is_paid: false, execution_date: null };
            patchAPI("sales/" + this.$route.params.id, formData).then((res) => {
              Toast2.fire({
                title: "Sale payments canceled!",
              });
              this.saleModel.is_paid = false;
              this.saleModel.execution_date = null;

              getAPI("sales/" + this.$route.params.id).then((res) => {
                this.saleModel = res.data;
                this.checkSubmit();
              });
            });
          } else {
            this.saleModel.is_paid = true;
          }
        });
      } else {
        Swal.fire({
          title: "Are you sure fully paid?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, paid!",
        }).then((result) => {
          if (result.value) {
            var today = new Date();
            var year = today.getFullYear();
            var month = (today.getMonth() + 1).toString().padStart(2, "0");
            var day = today.getDate().toString().padStart(2, "0");

            var formattedDate = year + "-" + month + "-" + day;
            patchAPI("sales/" + this.$route.params.id, {
              is_paid: true,
              execution_date: formattedDate,
            }).then((res) => {
              Toast2.fire({
                title: "Sale payments completed!",
              });
              getAPI("sales/" + this.$route.params.id).then((res) => {
                this.saleModel = res.data;
                this.checkSubmit();
              });
            });
          } else {
            this.saleModel.is_paid = false;
          }
        });
      }
    },
    updateSaleData() {
      getAPI("sales/" + this.$route.params.id).then((res) => {
        this.saleModel = res.data;
        this.checkSubmit();
      });
    },
    deleteSale() {
      deleteAPI("sales/" + this.$route.params.id).then((res) => {
        if (res["status"] === 204) {
          Toast2.fire({ icon: "success", title: "Sale Deleted!" });
          this.$router.push("/new-sales");
        } else {
          Toast2.fire({ icon: "warning", title: res.statusText });
          this.deleteDialog = false;
        }
      });
    },
    tagConverter(id) {
      return { tag: id };
    },
    taxRateChange() {
      if (
        this.saleModel.tax_rate === null ||
        this.saleModel.tax_rate === undefined ||
        this.saleModel.tax_rate === ""
      ) {
        this.saleModel.tax_rate = 0;
      }
    },

    calculatePricewCurrency(id, i) {
      const exchangeRate = this.exchangeRate;
      this.saleModel.sale[i]["price"] = (
        this.saleModel.sale[i]["original_price"] * exchangeRate
      ).toFixed(2);
    },

    getTotalOriginalPrice() {
      if (this.saleModel.sale === null || this.saleModel.sale === undefined) {
        return 0;
      }
      let sum = 0;
      let productArray = this.saleModel.sale;
      productArray.forEach((element) => {
        const originalPrice = element.original_price;
        if (originalPrice > 0) {
          sum += originalPrice;
        }
      });
      // return sum to fixed to 2
      const companyExpenses = this.getTotalCompanyExpenses();
      sum += -companyExpenses;
      return sum;
    },
    cancelSale() {
      if (this.saleModel.is_canceled === false) {
        this.saleModel.canceled_date = new Date().toISOString().slice(0, 10);
        // alert
        alert(
          "Canceling this sale will permanently delete all associated payments. This action cannot be undone.\n\nIf you are sure you want to proceed, please click the 'Submit Changes' button."
        );
      } else {
        this.saleModel.canceled_date = null;
        alert(
          "Removing the cancellation will not restore any deleted payments.\n\nIf you wish to proceed with removing the cancellation, please click the 'Submit Changes' button."
        );
      }
    },
  },

  watch: {
    saleModel: {
      deep: false,
      handler: function (newVal) {
        const updatedTags = newVal.tags.map(function (item) {
          return { tag: item.tag };
        });
        Object.assign(this.saleModel, {
          tags: updatedTags,
        });
      },
    },
  },
  created: function () {
    getAPI("sale-tags?limit=10000").then((res) => {
      this.saleTagModel = res.data.results;
    });

    getAPI("sales/" + this.$route.params.id).then((res) => {
      this.saleModel = res.data;
      this.checkSubmit();
      this.saleModel["tags"] = this.saleModel["tags"].map(function (item) {
        return { tag: item.tag };
      });
    }),
      getAPI("sale-types/?limit=1000").then((res) => {
        this.saleTypeModel = res.data.results;
      });

    getAPI("expense-types?limit=10000").then((res) => {
      this.expenseTypeModel = res.data.results;
    });
    getAPI("stores?limit=10000").then((res) => {
      this.storeModel = res.data.results;
    });

    getAPI("user-list?limit=1000&ordering=username").then((res) => {
      this.userListModel = res.data.results;
    });
    getAPI("exchange-rates?is_last=true&limit1000").then((res) => {
      this.exchangeRatesModel = res.data.results;
    });
  },
};
</script>

<style scoped>
/* #sale-item-container{
    margin-left: 19%;
    margin-top: 4.8%;
    margin-bottom: 4%;
    	margin-right: 2%;

} */

/* .row justify-content-center{
    margin-top: 1%;
} */

.header-divider {
  margin-top: 2%;
}

#description-input {
  height: 60px;
}

#cancel-date-input {
  margin-bottom: 10px;
}
.pt-3-half {
  padding-top: 1.4rem;
}

input {
  border-radius: 0px;
}

select {
  border-radius: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
