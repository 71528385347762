<template>

<!-- <button class="btn no-print" @click="createPDF">Export</button> -->
<button class="btn no-print btn-outline-dark ml-4" @click="printWindow">Print</button>

<div id="gallery" style="@media print{margin:0px}">
    <!-- <h3 id="main-header" style="margin: 20px;" class="text-left font-weight-bold text-info text-dark">(GÜNEY) Akche Avrupa Salonu</h3>
    <hr style="height:0.5px;margin-bottom:30px"> -->
    <div class="gallery row " style="margin: 0 auto;"> 

        <div v-for="product in products" :key="product.id"  class="gallery-item col-sm-1" style="width:min-content;height: auto;margin: 6px;border: 1px solid #ccc;font-size: x-small;">
            <img @dblclick="toProduct(product.product)" v-bind:src="product.thumbnail" style="width: 130px;height: 130px;@media print{width:130px;height:130px}">
            <div class="description">
                <div class="row" style="margin: 0 auto;">
                    <div class="col-sm-5 left-text" style="font-weight: bold;"><p style="font-size: smaller;">Reference:</p></div>
                        <div class="col-sm-7 right-text">
                            <p @dblclick="toProduct(product.product)" style="font-size: smaller;">{{product.reference_no}}</p>
                        </div>
                    </div>

            
                <div class="row" style="margin: 0 auto;">
                    <div class="col-sm-5 left-text"><p style="font-size: smaller;">Date:</p></div>
                    <div class="col-sm-7 right-text"><p style="font-size: smaller;">{{product.created_at}}</p></div>
                </div>

                <div class="row" style="margin: 0 auto;">
                    <div class="col-sm-5 left-text"><p style="font-size: smaller;">Description:</p></div>
                    <div class="col-sm-7 right-text"><p style="font-size: smaller;">{{product.description}}</p></div>
                </div>
            </div>
        </div>
    </div>

</div>

</template>

<script>

export default {
    data: function () {return {
       
    }},
    props: {
        products:{
            type: Array,
            default: null
        },
    },
    

    methods: {
        toProduct(product_id) {
            this.$router.push({ path: '/products/'+product_id })
        },
        printWindow: function () {

            var printContent = document.getElementById("gallery");
            var windowUrl = 'FANCY';
            var uniqueName = new Date();
            var windowName = 'Print' + uniqueName.getTime();

            var printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=0,height=0');
            printWindow.document.write('<head><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1"><title>TERZIHAN</title><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous"></head>');
            printWindow.document.write(printContent.innerHTML);
            // win.document.write('</body></html>');
            
            // printWindow.focus();
            // printWindow.print();
            // printWindow.close(); 
                       },
        
    }

}
</script>

<style scoped>
/* .gallery {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
grid-template-columns: repeat(5, 1fr);} */

#gallery  .row {
      margin: 0 auto;

}

#gallery  .gallery-item {
    width:min-content;height: auto;margin: 10px;border: 1px solid #ccc;
}

 #gallery img {
    width: 180px;height: 180px;
}

#gallery p {
    font-size: smaller;
}

#gallery .left-text {
    font-weight: bold;
}

#gallery #main-header {
    margin: 20px;
}



/* override styles when printing */


  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }

  img {
      width:130px;
      height: 130px;
  }
  

</style>