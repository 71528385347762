<template>
    <Toast />
    <div class="container-fluid">
        <Menu />
        <h6 class="text-center font-weight-bold text-info text-dark" style="font-size: 15px">
            <strong>Change Password</strong>
        </h6>
        <hr style="height: 0.5px; margin-bottom: 15px" />
        <form @submit.prevent="submit" id="mainProductForm">
            <div class="row justify-content-center">
                <div class="col-md-3">
                    <label for="user" class="text-bold">User</label>
                    <select v-if="permission('super_user')" id="user" v-model="userModel['user']" class="form-control">
                        <option v-for="u in userListModel" :key="u.id" v-bind:value="u.id">
                            {{ u.username }}
                        </option>
                    </select>

                    <select v-if="permission('super_user') === false" id="user" v-model="userModel['user']"
                        class="form-control" disabled>
                        <option v-for="u in userListModel" :key="u.id" v-bind:value="u.id">
                            {{ u.username }}
                        </option>
                    </select>
                    <!-- <input v-if="permission('super_user')===false" type="text" id="password" v-model="userListModel[0].username" class="form-control" disabled> -->

                    <!-- <select v-if="permission('super_user')===false" id="user" v-model="userModel.id" class="form-control" disabled>
				<option v-for="u in userListModel" :key="u.id" v-bind:value="u.id">{{u.username}}</option>
			</select> -->
                </div>

                <div class="col-md-3">
                    <label for="password" class="text-bold">New Password</label>
                    <!-- <input type="text" id="password" v-model="userModel.password" class="form-control" required> -->
                    <!-- <input type="password" v-model="userModel.password" autocomplete="new-password" 
  passwordrules="required: upper; required: lower; required: digit; 
                 minlength: 25; allowed: [-().&@?'#,/&quot;+]; max-consecutive: 2" class="form-control" required> -->
                    <input type="password" v-model="userModel.password" class="form-control" id="psw" name="psw"
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        required />
                </div>
            </div>

            <div class="row mt-5 justify-content-center">
                <div class="col-md-1">
                    <button class="btn-secondary btn" type="submit">Submit</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { getAPI, postAPI, patchAPI, deleteAPI } from "@/global/callAPI";
import Menu from "@/components/Menu";
export default {
    components: { Menu },
    data: function () {
        return {
            userModel: {
                password: null,
                user: null,
            },
            userId: localStorage.getItem("id"),
            storeModel: [],
            groupModel: [],
            userListModel: [],
            groups: localStorage.getItem("groups"),
        };
    },

    created: function () {
        getAPI("user-list?limit=1000&ordering=username").then((res) => {
            this.userListModel = res.data.results;
            this.userModel["user"] = this.userId;
        });
    },

    methods: {
        permission(n) {
            return this.groups.indexOf(n) > -1;
        },
        submit() {
            let payload = this.userModel;
            // payload["user"] = this.userId
            console.log(payload);
            postAPI("change-password", payload)
                .then((res) => res)
                .then((res) => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: `Password updated!`,
                        life: 3000,
                    });
                    this.userModel["password"] = null;
                });
        },
    },
};
</script>

<style>

</style>